import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SupportMessageService {

  BASE_URL = '/api/common';

  constructor(private http: HttpClient) {
  }

  postMessage(value) {
    return this.http.post(`${this.BASE_URL}/sendsupportmsg`, value).toPromise();
  }
}
