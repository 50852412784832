import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hse-hse-range-select',
  templateUrl: './hse-range-select.component.html',
  styleUrls: ['./hse-range-select.component.scss']
})
export class HseRangeSelectComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
