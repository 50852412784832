import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Standard } from '@backend/kosuip/catalogue/interfaces/standard.interface';
import {
  StudyPlan,
  StudyPlanCopyParams,
  StudyPlanCopyResponse,
  StudyPlanCPInfo,
  StudyPlanStatus
} from '@backend/kosuip/catalogue/interfaces/study-plan.interface';
import { PracticePlaceType } from '@backend/kosuip/catalogue/interfaces/practice-place-type.interface';
import { PracticeType } from '@backend/kosuip/catalogue/interfaces/practice-type.interface';
import { SubjectType } from '@backend/kosuip/catalogue/interfaces/subject-type.interface';
import { StandartType } from '@backend/kosuip/catalogue/interfaces/standart-type.interface';
import { UserProfileService } from '@backend/user-profile/user-profile.service';
import { TypeOfClasses } from '@backend/kosuip/catalogue/interfaces/type-of-classes.interface';
import { Qualification } from '@backend/kosuip/catalogue/interfaces/qualification.interface';
import { ProfessionalField } from '@backend/kosuip/catalogue/interfaces/professional-field.interface';
import { SubjectClass } from '@backend/kosuip/catalogue/interfaces/subject-class.interface';
import { SubjectArea } from '@backend/kosuip/catalogue/interfaces/subject-area.interface';
import { EducationStandardStructureBackend } from '@backend/kosuip/catalogue/interfaces/education-standard-structure.interface';
import { PostGradSchool } from '@backend/kosuip/catalogue/interfaces/post-grad-school.interface';
import { StudyPlanFile } from '@backend/kosuip/catalogue/interfaces/study-plan-file.interface';
import { Department } from '@backend/kosuip/catalogue/interfaces/department.interface';
import { Discipline, DisciplineCopiedId } from '@backend/kosuip/catalogue/interfaces/discipline.interface';
import { ProfessionFieldInStandart } from '@backend/kosuip/catalogue/interfaces/profession-field-in-standart.interface';
import { EducationResult, EducationResultDeleteBody, EducationResultLinks, ReplaceEducationResultBody, SaveEducationResultBody } from '@backend/kosuip/catalogue/interfaces/education-result.interface';
import { Directory } from '@backend/kosuip/catalogue/interfaces/directory.interface';
import { EducationResultInStandart } from '@backend/kosuip/catalogue/interfaces/education-result-in-standart.interface';
import { ProfessionalObjective } from '@backend/kosuip/catalogue/interfaces/professional-objective.interface';
import { ProfessionObjectiveInStandart } from '@backend/kosuip/catalogue/interfaces/profession-objective-in-standart.interface';
import { EducationLevel } from '@backend/kosuip/catalogue/interfaces/education-level.interface';
import { LearnPeriod } from '@backend/kosuip/catalogue/interfaces/learn-period.interface';
import { Speciality } from '@backend/kosuip/catalogue/interfaces/speciality.interface';
import { Filial } from '@backend/kosuip/catalogue/interfaces/filials.interface';
import { Faculty } from '@backend/kosuip/catalogue/interfaces/faculty.interface';
import { LearnForm } from '@backend/kosuip/catalogue/interfaces/learn-form.interface';
import { ProgramLanguage } from '@backend/kosuip/catalogue/interfaces/program-language.interface';
import { StudyPlanType } from '@backend/kosuip/catalogue/interfaces/study-plan-type.interface';
import { Language } from '@backend/kosuip/catalogue/interfaces/language.interface';
import { DisciplineToCopy, DisciplineUniteBody, DisciplineUniteCheckBody, DisciplineUniteCheckResponse, DisciplineUniteResponse, PlanDiscipline } from '@backend/kosuip/catalogue/interfaces/plan-discipline.interface';
import { CPIRequisites, PostRequisiteSaveBody, PreRequisiteSaveBody } from '@backend/kosuip/catalogue/interfaces/post-pre-requisite.interface';
import { MessageType } from '@backend/kosuip/catalogue/interfaces/message-type.interface';
import { ProfessionalStatus, ProfessionalStatusSaveParams } from '@backend/kosuip/catalogue/interfaces/professional-status.interface';
import { Cache } from '@backend/cache.decorator';
import { StructureSaveResult } from '@backend/kosuip/catalogue/interfaces/structure-save-result.interface';
import { CPICheck, CustomPlanItem, CustomPlanItemDelete, CustomPlanItemSavedId, CustomPlanPeriod, CustomPlanPeriodId, PPK, StructureElementData } from '@backend/kosuip/catalogue/interfaces/structure-element-data.interface';
import { ElementType } from '@backend/kosuip/catalogue/interfaces/element-type.interface';
import { Competence, CompetenceDeleteBody, CompetenceSaveBody, CompetenceSaveResponse } from '@backend/kosuip/catalogue/interfaces/competence.intreface';
import { PlanCampagin } from '@backend/kosuip/catalogue/interfaces/plan-campagin.interface';
import { EducationResultItem, EducationResultsTranslationsParams } from '@backend/kosuip/catalogue/interfaces/education-result-item.interface';
import EDUCATION_RESULTS_ITEMS from '@backend/kosuip/catalogue/data/education-results-item.data';
import { CustomItemSource } from '@backend/kosuip/catalogue/interfaces/custom-item-source.interface';
import { ChangeLPHeadBody, ChangeLPManagerBody, Elearnig, LearnProgram, LearnProgramCloseBody } from '@backend/kosuip/catalogue/interfaces/learn-program.interface';
import { CostType } from '@backend/kosuip/catalogue/interfaces/cost-type.interface';
import { StudyArea } from '@backend/kosuip/catalogue/interfaces/study-area.interface';
import { StudyFormat } from '@backend/kosuip/catalogue/interfaces/study-format.interface';
import { ControlType } from '@backend/kosuip/catalogue/interfaces/control-type.interface';
import { LoadRatio } from '@backend/kosuip/catalogue/interfaces/load-ratio.interface';
import { DistantSubject } from '@backend/kosuip/catalogue/interfaces/distant-subject.interface';
import { DataCulture } from '@backend/kosuip/catalogue/interfaces/data-culture.interface';
import { CPIYearCreateResponse, CustomPlanYear } from '@backend/kosuip/catalogue/interfaces/custom-plan-year.interface';
import { AdditionalPlanPassport } from '@backend/kosuip/catalogue/interfaces/additional-plan-passport.interface';
import { Annotation } from '@backend/kosuip/catalogue/interfaces/annotation.interface';
import { AudienceReach } from '@backend/kosuip/catalogue/interfaces/audience-reach.interface';
import { SubjectCategory } from '@backend/kosuip/catalogue/interfaces/subject-category.interface';
import { DisciplineUniversity, DisciplineUniversityCreatedResponse } from '@backend/kosuip/catalogue/interfaces/discipline-university.interface';
import { LearnPeriodCount } from '@backend/kosuip/catalogue/interfaces/learn-period-count.interface';
import { Restriction, RestrictionCreatedId } from '@backend/kosuip/catalogue/interfaces/restriction.interface';
import { LearnCourse } from '@backend/kosuip/catalogue/interfaces/learn-course.interface';
import { MinorType } from '@backend/kosuip/catalogue/interfaces/minor-type.interface';
import { AsyncFormResponseId, PrintFormBase64 } from '@backend/kosuip/catalogue/interfaces/print-form-base-64.interface';
import { MKD } from '@backend/kosuip/catalogue/interfaces/mkd.intreface';
import { StatusHistory } from '@backend/kosuip/catalogue/interfaces/status-history.interface';
import { Comment2, Comment3 } from '@backend/kosuip/catalogue/interfaces/comment.interface';
import { CurrentApprovalYear } from '@backend/kosuip/catalogue/interfaces/current-approval-year.interface';
import { SubjectUnit, SubjectUnitSavedId } from '@backend/kosuip/catalogue/interfaces/subject-unit.interface';
import { Specialization, SpecializationCLP, SpecializationCreateBody, SpecializationQualification } from '@backend/kosuip/catalogue/interfaces/specialization.interface';
import { TrajectoryType } from '@backend/kosuip/catalogue/interfaces/trajectory-type.interface';
import { Person } from '@backend/kosuip/catalogue/interfaces/person.interface';
import { TypesDoc } from '@backend/kosuip/catalogue/interfaces/types-doc.interface';
import { OtherPlan } from '@backend/kosuip/catalogue/interfaces/other-plan.interface';
import { PlanInCop } from '@backend/kosuip/catalogue/interfaces/plan-in-cop.interface';
import { BranchScience } from '@backend/kosuip/catalogue/interfaces/branch-science.interface';
import { ScienceSpeciality } from '@backend/kosuip/catalogue/interfaces/science-speciality.interface';
import { AdditionalModuleRole } from '@backend/kosuip/catalogue/interfaces/additional-module-role.interface';
import { AdditionalModuleCostType } from '@backend/kosuip/catalogue/interfaces/additional-module-cost-type.interface';
import { AdditionalModuleIncludePlanType } from '@backend/kosuip/catalogue/interfaces/additional-module-include-plan-type.interface';
import { AdditionalStudyPlan, AdditionalStudyPlanSaveResponse } from '@backend/kosuip/catalogue/interfaces/additional-study-plan.interface';
import { AdditionalModuleMicrodegreeLevel } from '@backend/kosuip/catalogue/interfaces/additional-module-microdegree-level.interface';
import { OnlineCourse, OnlineCourseBody, OnlineCourseProps } from '@backend/kosuip/catalogue/interfaces/online-course.interface';
import { OnlineCourseType } from '@backend/kosuip/catalogue/interfaces/online-course-type.interface';
import { OnlineCoursePlatform } from '@backend/kosuip/catalogue/interfaces/online-course-platform.interface';
import { OnlineCourseRealization, OnlineCourseRealizationProps } from '@backend/kosuip/catalogue/interfaces/online-course-realization.interface';
import { AsavStudyPlan } from '@backend/kosuip/catalogue/interfaces/asav-study-plan.interface';

export interface AsyncResult {
  fileData: any
  filename: string
}

@Injectable({
  providedIn: 'root'
})
export class CatalogueService {

  BASE_URL = '/api';
  // BASE_URL = '/hse_api';
  // BASE_URL = '/KOSUIP_WORK/hs/catalogue';
  cache: any = {};
  headers = new HttpHeaders();
  additionalEmail;

  constructor(private http: HttpClient, private userProfile: UserProfileService) {
    this.additionalEmail = this.userProfile.getSelectedChangedUser();
    this.headers.set('HSE-Auth-Replacement', btoa(this.additionalEmail));
  }

  /**
   * Возможные фильтры:
   *  standart_type_id, ТипСтандарта.Код (массив id через запятую)
   *  plan_status_id, СтатусСтандарта.Код (массив id через запятую)
   *  ed_level_id, УровеньОбразования.Код (массив id через запятую)
   *  speciality_id, НаправлениеСпециальность.Код (массив id через запятую)
   *  begin_year, ГодНачалаДействия.УчебныйГод (int) с этого года включая
   *  end_year, ГодОкончанияДействия.УчебныйГод (int) по этот год включая
   */
  getStandards(params?): Promise<HttpResponse<Standard[]>> {
    return this.http.get<Standard[]>(`${this.BASE_URL}/getstandart`, {params, observe: 'response'}).toPromise();
  }

  saveAdditionalStandardsToPlan(data) {
    return this.http.post(`${this.BASE_URL}/save?cname=additionalplanpassports`, data).toPromise();
  }

  getAdditionalPlanPassports(params?: {learn_plan_id: string}): Promise<AdditionalPlanPassport[]> {
    return this.http.get<AdditionalPlanPassport[]>(`${this.BASE_URL}/getadditionalplanpassports`, {params}).toPromise();
  }

  getStandardTypes(): Promise<StandartType[]> {
    return this.http.get<StandartType[]>(`${this.BASE_URL}/getstandarttypes`).toPromise();
  }

  getTypesOfClasses(params?): Promise<TypeOfClasses[]> {
    return this.http.get<TypeOfClasses[]>(`${this.BASE_URL}/getypeclasses`, {params}).toPromise();
  }

  // все данные справочника Квалификация
  getQualifications(): Observable<Qualification[]> {
    return this.http.get<Qualification[]>(`${this.BASE_URL}/getqualifications`);
  }

  // все данные справочника ОбластиПрофессиональнойДеятельности
  getProfessionalFields(params?, ignoreCache = false): Promise<HttpResponse<ProfessionalField[]>> {
    return this.http.get<ProfessionalField[]>(`${this.BASE_URL}/getprofessionalfields`, {params, observe: 'response'}).toPromise();
  }

  downloadFile(params?): Promise<any> {
    return this.http.get<any>(`${this.BASE_URL}/lp_getfile`, {params}).toPromise();
  }

  @Cache()
  async getSubjectClass(): Promise<SubjectClass[]> {
    return this.http.get<SubjectClass[]>(`${this.BASE_URL}/getsubjectclass`).toPromise();
  }

  @Cache()
  async getSubjectArea(): Promise<SubjectArea[]> {
    return this.http.get<SubjectArea[]>(`${this.BASE_URL}/getsubjectarea`).toPromise();
  }

  getPassportStructure(params?): Promise<EducationStandardStructureBackend[]> {
    return this.http.get<EducationStandardStructureBackend[]>(`${this.BASE_URL}/getstructure2`, {params}).toPromise();
  }

  @Cache()
  async getPostGradSchools(params?): Promise<PostGradSchool[]> {
    return this.http.get<PostGradSchool[]>(`${this.BASE_URL}/getpostgradschool`, {params}).toPromise();
  }

  savePassportStructure(data: StructureElementData): Promise<StructureSaveResult> {
    return this.http.post<StructureSaveResult>(`${this.BASE_URL}/save?cname=structure`, data).toPromise();
  }

  @Cache()
  async getElementTypes() {
    return this.http.get<ElementType[]>(`${this.BASE_URL}/getelementtype`).toPromise();
  }

  getAttachedFiles(params?): Promise<StudyPlanFile[]> {
    return this.http.get<StudyPlanFile[]>(`${this.BASE_URL}/lp_getfilelist`, {params}).toPromise();
  }

  saveAttachedFile(data): Promise<any> {
    return this.http.post(`${this.BASE_URL}/lp_savefile`, data).toPromise();
  }

  deleteFile(data): Promise<any> {
    return this.http.post(`${this.BASE_URL}/lp_deletefile`, {}, {params: data}).toPromise();
  }

  getDepartments(params?): Promise<HttpResponse<Department[]>> {
    return this.http.get<Department[]>(`${this.BASE_URL}/getdepartments`, {params, observe: 'response'}).toPromise();
  }

  getDisciplines(params?): Promise<HttpResponse<Discipline[]>> {
    return this.http.get<Discipline[]>(`${this.BASE_URL}/getuniversitysubjects`, {params, observe: 'response'}).toPromise();
  }

  /**
   * Создание паспорта направления
   */
  savePassport(data): Promise<{ standart_id: string, structure_id: string }> {
    // @ts-ignore
    return this.http.post(`${this.BASE_URL}/save?cname=stdpassport`, data).toPromise();
  }

  saveStudyPlan(data): Promise<{ standart_id: string, structure_id: string }> {
    // @ts-ignore
    return this.http.post(`${this.BASE_URL}/save?cname=stdlearnplan`, data).toPromise();
  }

  saveInterProgramStudyPlan(data): Promise<{ custom_learn_plan_id: string, structure_id: string }> {
    // @ts-ignore
    return this.http.post(`${this.BASE_URL}/save?cname=stdlearnplan`, data).toPromise();
  }

  // Создание\Обновление ОбластиПрофессиональнойДеятельности
  saveProfessionalField(data) {
    return this.http.post(`${this.BASE_URL}/createprofessionalfields`, data).toPromise();
  }

  saveProfessionalFieldTranslation(data) {
    return this.http.post(`${this.BASE_URL}/save?cname=professionalfields`, data).toPromise();
  }

  saveProfessionalObjectiveTranslation(data) {
    return this.http.post(`${this.BASE_URL}/save?cname=professionalobjectives`, data).toPromise();
  }

  moveDiscipline(data: {discipline_id: number | string, section_id: number}) {
    return this.http.post(`${this.BASE_URL}/movediscipline`, {}, {params: data}).toPromise();
  }

  // все данные справочника СвязьСтандартаИОПД
  getProfessionalFieldInStandart(): Observable<ProfessionFieldInStandart[]> {
    return this.http.get<ProfessionFieldInStandart[]>(`${this.BASE_URL}/getprofessionalfieldinstandart`);
  }

  getEducationResultsTranslations({params}: EducationResultsTranslationsParams): Promise<HttpResponse<EducationResult[]>> {
    return this.http.get<EducationResult[]>(`${this.BASE_URL}/geteducationresults`, {params, observe: 'response'}).toPromise();
  }

  getDirectories(params?): Promise<HttpResponse<Directory[]>> {
    return this.http.get<Directory[]>(`${this.BASE_URL}/getSection`, {params, observe: 'response'}).toPromise();
  }

  deleteDirectory(params): Promise<{}> {
    return this.http.post<any>(`${this.BASE_URL}/deleteSection`, params).toPromise();
  }

  createDirectory(params: Directory): Promise<{ id: number}> {
    return this.http.post<any>(`${this.BASE_URL}/createSection`, params).toPromise();
  }

  editDirectory(params: Directory): Promise<{ id: number}> {
    return this.http.post<any>(`${this.BASE_URL}/updateSection`, params).toPromise();
  }

  getCompetenciesTranslations(params?): Promise<HttpResponse<Competence[]>> {
    return this.http.get<Competence[]>(`${this.BASE_URL}/getcompetence`, {params, observe: 'response'}).toPromise();
  }

  getPlanCampaign(params): Promise<PlanCampagin[]> {
    return this.http.get<PlanCampagin[]>(`${this.BASE_URL}/getplancampaign`, {params}).toPromise();
  }

  // все данные справочника КлючевыеОбразовательныеРезультаты
  getEducationResults(): Promise<EducationResultItem[]> {
    // return this.http.get<any[]>(`${this.BASE_URL}/geteducationresults`, {params: new HttpParamsparams).toPromise();
    return new Promise((resolve) => {
      resolve(EDUCATION_RESULTS_ITEMS);
    });
  }

  deleteCustomPlanItem(data: CustomPlanItemDelete): Promise<{}> {
    return this.http.post(`${this.BASE_URL}/save?cname=delcustomplanitem`, data).toPromise();
  }

  // Создание\Обновление КлючевыеОбразовательныеРезультаты
  saveEducationResult(data: Partial<EducationResult>) {
    return this.http.post(`${this.BASE_URL}/createeducationresult`, {}, {params: data});
  }

  // все данные справочника СвязьСтандартаИКОР
  getEducationResultsInStandart(params?): Observable<EducationResultInStandart[]> {
    return this.http.get<EducationResultInStandart[]>(`${this.BASE_URL}/geteducationresultinstandart`, {params});
  }

  // все данные справочника ТипыЗадачПрофессиональнойДеятельности
  getProfessionalObjectives(params?): Promise<HttpResponse<ProfessionalObjective[]>> {
    return this.http.get<ProfessionalObjective[]>(`${this.BASE_URL}/getprofessionalobjectives`, {params, observe: 'response'}).toPromise();
  }

  // Создание\Обновление ТипыЗадачПрофессиональнойДеятельности
  saveProfessionalObjective(data: Partial<EducationResult>) {
    return this.http.post(`${this.BASE_URL}/createprofessionalobjective`, {}, {params: data});
  }

  // все данные справочника СвязьСтандартаТиповЗадачПрофДеятельности
  getProfessionObjectivesInStandart(params?): Observable<ProfessionObjectiveInStandart[]> {
    return this.http.get<ProfessionObjectiveInStandart[]>(`${this.BASE_URL}/getprofessionobjectivesinstandart`
    , {params});
  }

  // увовни образования
  @Cache()
  async getEducationLevels(params?): Promise<EducationLevel[]> {
    return this.http.get<EducationLevel[]>(`${this.BASE_URL}/geteducationlevels`, {params}).toPromise();
  }

  // год начала/окончания реализации
  @Cache()
  async getLearnPeriods(params?): Promise<LearnPeriod[]> {
    return this.http.get<LearnPeriod[]>(`${this.BASE_URL}/getlearnperiods`, {params}).toPromise();
  }


  // направление подготовки
  @Cache()
  async getSpecialities(params?): Promise<Speciality[]> {
    return this.http.get<Speciality[]>(`${this.BASE_URL}/getspeciality`, {params}).toPromise()
  }

  // филиал
  @Cache()
  async getFilials(params?): Promise<Filial[]> {
    return this.http.get<Filial[]>(`${this.BASE_URL}/getfilials`, {params}).toPromise();
  }

  // факультет
  @Cache()
  async getFaculties(params?): Promise<Faculty[]> {
    return this.http.get<Faculty[]>(`${this.BASE_URL}/getfaculty`, {params}).toPromise();
  }

  // форма обучения
  @Cache()
  async getLearnForm(): Promise<LearnForm[]> {
    return this.http.get<any>(`${this.BASE_URL}/getlearnform`).toPromise();
  }

  // язык реализации
  @Cache()
  async getProgramLanguages(): Promise<ProgramLanguage[]> {
    return this.http.get<ProgramLanguage[]>(`${this.BASE_URL}/getproglanguages`).toPromise();
  }

  // статусы планов/стандартов
  @Cache()
  async getPlanStatuses(params?): Promise<StudyPlanStatus[]> {
    return this.http.get<StudyPlanStatus[]>(`${this.BASE_URL}/getplanstatuses`, {params}).toPromise();
  }

  // типы планов
  @Cache()
  async getPlanTypes(params?): Promise<StudyPlanType[]> {
    return this.http.get<StudyPlanType[]>(`${this.BASE_URL}/getplantypes`, {params}).toPromise();
  }

  // типы стандартов
  @Cache()
  async getStandartTypes(): Promise<StandartType[]> {
    return this.http.get<StandartType[]>(`${this.BASE_URL}/getstandarttypes`).toPromise();
  }

  // языки
  getLanguages(): Promise<Language[]> {
    return this.http.get<Language[]>(`${this.BASE_URL}/getforeignlanguage`).toPromise();
  }

  getCustomItemSources(): Promise<CustomItemSource[]> {
    return this.http.get<CustomItemSource[]>(`${this.BASE_URL}/getcustomitemsource`).toPromise();
  }

  saveEducationResultTranslation(data: SaveEducationResultBody) {
    return this.http.post<SaveEducationResultBody>(`${this.BASE_URL}/save?cname=educationresults`, data).toPromise();
  }

  getDisciplinePlan(params?): Promise<PlanDiscipline[]> {
    return this.http.get<PlanDiscipline[]>(`${this.BASE_URL}/getdisciplineplan`, {params}).toPromise();
  }

  // программа обучения
  @Cache()
  async getLearnProgram(params?): Promise<LearnProgram[]> {
    return this.http.get<LearnProgram[]>(`${this.BASE_URL}/getlearnprogram`, {params}).toPromise();
  }

  @Cache()
  async getCostType(params?): Promise<CostType[]> {
    return this.http.get<CostType[]>(`${this.BASE_URL}/getcosttype`, {params}).toPromise();
  }

  saveLearnProgram(data: LearnProgram) {
    return this.http.post<LearnProgram>(`${this.BASE_URL}/save?cname=create_lp`, data).toPromise();
  }

  updateLearnProgramVersion(data: LearnProgram) {
    return this.http.post(`${this.BASE_URL}/save?cname=update_lp`, data).toPromise();
  }

  closeLearnProgram(data: LearnProgramCloseBody): Promise<string> {
    return this.http.post<string>(`${this.BASE_URL}/save?cname=close_lp`, data).toPromise();
  }

  changeLearnProgramManager(data: ChangeLPManagerBody) {
    return this.http.post(`${this.BASE_URL}/save?cname=change_manager_lp`, data).toPromise();
  }

  changeLearnProgramHead(data: ChangeLPHeadBody): Promise<string> {
    return this.http.post<string>(`${this.BASE_URL}/save?cname=change_head_lp`, data).toPromise();
  }

  uniteLearnPrograms(data) {
    return this.http.post(`${this.BASE_URL}/save?cname=united_lp`, data).toPromise();
  }

  // программа обучения
  @Cache()
  async getElearning(params?): Promise<Elearnig[]> {
    return this.http.get<Elearnig[]>(`${this.BASE_URL}/getelearning`, {params}).toPromise();
  }

  // программа обучения
  async getLearnProgram4(params?): Promise<LearnProgram[]> {
    try {
      return this.http.get<LearnProgram[]>(`${this.BASE_URL}/getlearnprogram4`, {params}).toPromise();
    } catch (e) {
      console.error(e);
    }
  }

  async getLearnProgram4Response(params?): Promise<HttpResponse<LearnProgram[]>> {
    try {
      return this.http.get<LearnProgram[]>(`${this.BASE_URL}/getlearnprogram4`, {observe: 'response', params}).toPromise();
    } catch (e) {
      console.error(e);
    }
  }

  // учебные планы
  getStudyPlans(params?): Promise<HttpResponse<StudyPlan[]>> {
    return this.http.get<StudyPlan[]>(`${this.BASE_URL}/getlearnplan`, {params, observe: 'response'}).toPromise();
  }

  @Cache()
  async getPracticePlaceType(): Promise<PracticePlaceType[]> {
    return this.http.get<PracticePlaceType[]>(`${this.BASE_URL}/getpracticeplacetype`).toPromise();
  }

  @Cache()
  async getStudyAries(): Promise<StudyArea[]> {
    return this.http.get<StudyArea[]>(`${this.BASE_URL}/getstudyaries`).toPromise();
  }

  @Cache()
  async getPracticeType(params?): Promise<PracticeType[]> {
    return this.http.get<PracticeType[]>(`${this.BASE_URL}/getpracticetype`, {params}).toPromise();
  }

  @Cache()
  async getSubjectType(): Promise<SubjectType[]> {
    return this.http.get<SubjectType[]>(`${this.BASE_URL}/getsubjecttype`).toPromise();
  }

  // форматы изучения дисциплины
  @Cache()
  async getStudyFormat(): Promise<StudyFormat[]> {
    return this.http.get<StudyFormat[]>(`${this.BASE_URL}/getstudyformat`).toPromise();
  }

  @Cache()
  async getControlTypes(): Promise<ControlType[]> {
    return this.http.get<ControlType[]>(`${this.BASE_URL}/getcontroltype`).toPromise();
  }

  @Cache()
  async getLoadRatio(): Promise<LoadRatio[]> {
    return this.http.get<LoadRatio[]>(`${this.BASE_URL}/getloadratio`).toPromise();
  }

  @Cache()
  async getDistantSubject(params?): Promise<HttpResponse<DistantSubject[]>> {
    return this.http.get<DistantSubject[]>(`${this.BASE_URL}/getdistantsubject`, {params, observe: 'response'}).toPromise();
  }

  @Cache()
  async getDataCulture(): Promise<DataCulture[]> {
    return this.http.get<DataCulture[]>(`${this.BASE_URL}/getdataculture`).toPromise();
  }

  getCustomPlanYear(params?): Promise<CustomPlanYear[]> {
    return this.http.get<CustomPlanYear[]>(`${this.BASE_URL}/getcustomplanyear`, {params}).toPromise();
  }

  saveCustomPlanYear(data: Partial<CustomPlanYear>) {
    return this.http.post(`${this.BASE_URL}/save?cname=customplanyear`, data).toPromise();
  }

  saveCustomPanItemPeriod(data: Partial<CustomPlanPeriod>, useHeader = false) {
    const options: any = {};
    const updatedHeaders = {
      headers: new HttpHeaders()
    };
    const additionalEmail = this.userProfile.getSelectedChangedUser();

    if (additionalEmail && useHeader) {
      updatedHeaders.headers = updatedHeaders.headers.set('HSE-Auth-Replacement', btoa(additionalEmail));
      options.headers = updatedHeaders.headers;
    }

    return this.http.post<CustomPlanPeriodId>(`${this.BASE_URL}/save?cname=customplanitemperiod`, data, options).toPromise();
  }

  saveModulePPK(data: Partial<CustomPlanPeriod>, useHeader = false): Promise<CustomPlanPeriodId> {
    const options: any = {};
    const updatedHeaders = {
      headers: new HttpHeaders()
    };
    const additionalEmail = this.userProfile.getSelectedChangedUser();

    if (additionalEmail && useHeader) {
      updatedHeaders.headers = updatedHeaders.headers.set('HSE-Auth-Replacement', btoa(additionalEmail));
      options.headers = updatedHeaders.headers;
    }

    // @ts-ignore
    return this.http.post<CustomPlanPeriodId>(`${this.BASE_URL}/save?cname=customplanitemperiod&ppk=true`, data, options).toPromise();
  }

  deleteCustomPanItemPeriod(data: CustomPlanPeriodId[], useHeader = false) {
    const options: any = {};
    const updatedHeaders = {
      headers: new HttpHeaders()
    };
    const additionalEmail = this.userProfile.getSelectedChangedUser();

    if (additionalEmail && useHeader) {
      updatedHeaders.headers = updatedHeaders.headers.set('HSE-Auth-Replacement', btoa(additionalEmail));
      options.headers = updatedHeaders.headers;
    }

    return this.http.post<{}>(`${this.BASE_URL}/delete?cname=customplanitemperiod`, data, options).toPromise();
  }

  deleteModulePPK(data: CustomPlanPeriodId[], useHeader = false) {
    const options: any = {};
    const updatedHeaders = {
      headers: new HttpHeaders()
    };
    const additionalEmail = this.userProfile.getSelectedChangedUser();

    if (additionalEmail && useHeader) {
      updatedHeaders.headers = updatedHeaders.headers.set('HSE-Auth-Replacement', btoa(additionalEmail));
      options.headers = updatedHeaders.headers;
    }

    return this.http.post<{}>(`${this.BASE_URL}/delete?cname=customplanitemperiod&ppk=true`, data, options).toPromise();
  }

  saveDataCulture(structureId: string, dataCultureLevels: string[]) {
    const data = {
      structure_id: structureId,
      data_culture_level: dataCultureLevels
    };

    return this.http.post<[]>(`${this.BASE_URL}/save?cname=dataculturelevelforitem`, data).toPromise();
  }

  saveCompetencies(value: CompetenceSaveBody) {
    return this.http.post<CompetenceSaveResponse>(`${this.BASE_URL}/save?cname=competenceinitem`, value).toPromise();
  }

  saveEducationResultInItem(value) {
    return this.http.post(`${this.BASE_URL}/save?cname=educationresultinitem`, value).toPromise();
  }

  saveCustomPlanItem(data: Partial<CustomPlanItem>, useHeader = false) {
    const options: any = {};
    const updatedHeaders = {
      headers: new HttpHeaders()
    };
    const additionalEmail = this.userProfile.getSelectedChangedUser();

    if (additionalEmail && useHeader) {
      updatedHeaders.headers = updatedHeaders.headers.set('HSE-Auth-Replacement', btoa(additionalEmail));
      options.headers = updatedHeaders.headers;
    }

    return this.http.post<CustomPlanItemSavedId>(`${this.BASE_URL}/save?cname=customplanitem`, data, options).toPromise();
  }

  savePPK(data: Partial<PPK>, useHeader = false): Promise<CustomPlanItemSavedId> {
    const options: any = {};
    const updatedHeaders = {
      headers: new HttpHeaders()
    };
    const additionalEmail = this.userProfile.getSelectedChangedUser();

    if (additionalEmail && useHeader) {
      updatedHeaders.headers = updatedHeaders.headers.set('HSE-Auth-Replacement', btoa(additionalEmail));
      options.headers = updatedHeaders.headers;
    }

    // @ts-ignore
    return this.http.post<CustomPlanItemSavedId>(`${this.BASE_URL}/save?cname=customplanitem&ppk=true`, data, options).toPromise();
  }

  copyDiscipline(data: DisciplineToCopy, useHeader = false): Promise<DisciplineCopiedId> {
    const options: any = {};
    const updatedHeaders = {
      headers: new HttpHeaders()
    };
    const additionalEmail = this.userProfile.getSelectedChangedUser();

    if (additionalEmail && useHeader) {
      updatedHeaders.headers = updatedHeaders.headers.set('HSE-Auth-Replacement', btoa(additionalEmail));
      options.headers = updatedHeaders.headers;
    }

    // @ts-ignore
    return this.http.post(`${this.BASE_URL}/copydiscipline`, data, options).toPromise();
  }

  copyPlan(data: StudyPlanCopyParams) {
    return this.http.post<StudyPlanCopyResponse>(`${this.BASE_URL}/copylp`, {}, {params: data as any}).toPromise();
  }

  recountLearnPlanSums(params: {learn_plan_id: string}, data: {recalculate: boolean}) {
    return this.http.post<'ОК'>(`${this.BASE_URL}/save`, data, {
      params: new HttpParams({fromObject: {...params, cname: 'recalculatelp'}})
    }).toPromise();
  }

  deleteCompetencies(value: CompetenceDeleteBody) {
    return this.http.post<'OK'>(`${this.BASE_URL}/delete?cname=competenceinlearnplan`, value).toPromise();
  }

  deleteKOR(value: EducationResultDeleteBody) {
    return this.http.post<'OK'>(`${this.BASE_URL}/delete?cname=educationresultlearnplan`, value).toPromise();
  }

  resaveCompetencies(value: Partial<Competence>[]) {
    return this.http.post(`${this.BASE_URL}/save?cname=competence`, value).toPromise();
  }

  getPrerequisites(params: {cpi_id: number}): Promise<CPIRequisites> {
    return this.http.get<CPIRequisites>(`${this.BASE_URL}/getprerequisites`, {params}).toPromise();
  }

  savePreRequisites(value: PreRequisiteSaveBody): Promise<CPIRequisites> {
    return this.http.post<CPIRequisites>(`${this.BASE_URL}/save?cname=predprerequisites`, value).toPromise();
  }

  savePostRequisites(value: PostRequisiteSaveBody): Promise<string> {
    return this.http.post<'OK'>(`${this.BASE_URL}/save?cname=postprerequisites`, value).toPromise();
  }

  getCpi(params?, useHeader = false) {
    const options: any = { observe: 'response', params: new HttpParams({fromObject: params}) };
    const updatedHeaders = {
      headers: new HttpHeaders()
    };
    const additionalEmail = this.userProfile.getSelectedChangedUser();

    if (additionalEmail && useHeader) {
      updatedHeaders.headers = updatedHeaders.headers.set('HSE-Auth-Replacement', btoa(additionalEmail));
      options.headers = updatedHeaders.headers;
    }

    return this.http.get<CustomPlanItem[] | PPK[] | HttpResponse<PPK[]>>(`${this.BASE_URL}/getcpi`, options).toPromise();
  }

  getStudyPlanStatus(params?) {
    return this.http.get<StudyPlanStatus[]>(`${this.BASE_URL}/getallstatuslearnplan`, {params}).toPromise();
  }

  async getAnnotation(params?) {
    try {
      return this.http.get<Annotation[]>(`${this.BASE_URL}/getannotation`, {params}).toPromise();
    } catch (e) {
      console.log(e);
    }
  }

  @Cache()
  async getAudienceReach(params?) {
    return this.http.get<AudienceReach[]>(`${this.BASE_URL}/getaudiencereach`, {params}).toPromise();
  }

  @Cache()
  async getSubjectCategories(params?) {
    return this.http.get<SubjectCategory[]>(`${this.BASE_URL}/getsubjectcategories`, {params}).toPromise();
  }

  getSupportMessageTypes() {
    return this.http.get<MessageType[]>(`${this.BASE_URL}/getsupportmsgtypes`).toPromise();
  }

  createDisciplineUniversity(data: DisciplineUniversity) {
    return this.http.post<DisciplineUniversityCreatedResponse>(`${this.BASE_URL}/createDisciplineUniversity`, data).toPromise();
  }

  updateDisciplineUniversity(data: DisciplineUniversity) {
    return this.http.post<'OK'>(`${this.BASE_URL}/updateDisciplineUniversity`, data).toPromise();
  }

  getSimilarDisciplines(params?, useHeader = false): Promise<PPK[]> {
    const options: any = {
      params: new HttpParams({fromObject: params})
    };
    const updatedHeaders = {
      headers: new HttpHeaders()
    };
    const additionalEmail = this.userProfile.getSelectedChangedUser();

    if (additionalEmail && useHeader) {
      updatedHeaders.headers = updatedHeaders.headers.set('HSE-Auth-Replacement', btoa(additionalEmail));
      options.headers = updatedHeaders.headers;
    }

    // @ts-ignore
    return this.http.get(`${this.BASE_URL}/getdisciplineunitelist`, options).toPromise();
  }

  @Cache()
  async getLearnPeriodCount(params?) {
    return this.http.get<LearnPeriodCount[]>(`${this.BASE_URL}/getlearnperiodpcount`, {params}).toPromise();
  }

  uniteDisciplines(params, data: DisciplineUniteBody, useHeader = false) {
    const options: any = {
      params: new HttpParams({fromObject: params})
    };
    const updatedHeaders = {
      headers: new HttpHeaders()
    };
    const additionalEmail = this.userProfile.getSelectedChangedUser();

    if (additionalEmail && useHeader) {
      updatedHeaders.headers = updatedHeaders.headers.set('HSE-Auth-Replacement', btoa(additionalEmail));
      options.headers = updatedHeaders.headers;
    }

    return this.http.post<DisciplineUniteResponse>(`${this.BASE_URL}/unitedisciplines`, data, options).toPromise();
  }

  checkDisciplinesToUnite(params, data: DisciplineUniteCheckBody, useHeader = false): Promise<DisciplineUniteCheckResponse> {
    const options: any = {
      params: new HttpParams({fromObject: params})
    };

    const updatedHeaders = {
      headers: new HttpHeaders()
    };
    const additionalEmail = this.userProfile.getSelectedChangedUser();

    if (additionalEmail && useHeader) {
      updatedHeaders.headers = updatedHeaders.headers.set('HSE-Auth-Replacement', btoa(additionalEmail));
      options.headers = updatedHeaders.headers;
    }

    // @ts-ignore
    return this.http.post(`${this.BASE_URL}/checkdisciplinestounite`, data, options).toPromise();
  }

  getDisciplineRestrictions(params?) {
    return this.http.get<Restriction[]>(`${this.BASE_URL}/getdisciplineconstraints`, {params}).toPromise();
  }

  @Cache()
  async getLearnCourses(params?) {
    return this.http.get<LearnCourse[]>(`${this.BASE_URL}/getlearncourses`, {params}).toPromise();
  }

  createRestrictions(data: Restriction, useHeader = false) {
    const options: any = {};
    const updatedHeaders = {
      headers: new HttpHeaders()
    };
    const additionalEmail = this.userProfile.getSelectedChangedUser();

    if (additionalEmail && useHeader) {
      updatedHeaders.headers = updatedHeaders.headers.set('HSE-Auth-Replacement', btoa(additionalEmail));
      options.headers = updatedHeaders.headers;
    }

    return this.http.post<RestrictionCreatedId>(`${this.BASE_URL}/createdisconstraints`, data, options).toPromise();
  }

  deleteRestrictions(id: number) {
    return this.http.post<'OK'>(`${this.BASE_URL}/deldisconstraints?id=${id}`, {}).toPromise();
  }

  @Cache()
  async getMinorTypes() {
    return this.http.get<MinorType[]>(`${this.BASE_URL}/getminors`).toPromise();
  }

  getAsavLearnPlans(params?) {
    return this.http.get<AsavStudyPlan[]>(`${this.BASE_URL}/getsasavlearnplan2`, {params}).toPromise();
  }

  getPrintForm(params?, useHeader = false): Promise<PrintFormBase64> {
    const options: any = {
      params: new HttpParams({fromObject: params})
    };
    const updatedHeaders = {
      headers: new HttpHeaders()
    };
    const additionalEmail = this.userProfile.getSelectedChangedUser();

    if (additionalEmail && useHeader) {
      updatedHeaders.headers = updatedHeaders.headers.set('HSE-Auth-Replacement', btoa(additionalEmail));
      options.headers = updatedHeaders.headers;
    }

    // @ts-ignore
    return this.http.get<PrintFormBase64>(`${this.BASE_URL}/common/getprintformbase64`, options).toPromise();
  }

  async putAsyncForm(params?, useHeader = false): Promise<AsyncFormResponseId> {
    const options: any = {
      params: new HttpParams({fromObject: params}),

    };
    const updatedHeaders = {
      headers: new HttpHeaders(),
    };
    const additionalEmail = this.userProfile.getSelectedChangedUser();

    if (additionalEmail && useHeader) {
      updatedHeaders.headers = updatedHeaders.headers.set('HSE-Auth-Replacement', btoa(additionalEmail));
      options.headers = updatedHeaders.headers;
    }

    // @ts-ignore
    return this.http.get(`${this.BASE_URL}/common/putassincform`, options).toPromise();
  }

  getAsyncResult(params?) {
    return this.http.get<AsyncResult>(`${this.BASE_URL}/common/getassincresult`, {params}).toPromise();
  }

  getListCustomPlanUnderCount() {
    return this.http.get(`${this.BASE_URL}/getListCustomPlanUnderCount`).toPromise();
  }

  // TODO unused
  getMKD(params?) {
    return this.http.get(`${this.BASE_URL}/getmkd`, {params}).toPromise();
  }

  recalculateMKD(params?) {
    return this.http.post<MKD>(`${this.BASE_URL}/recalculatemkd`, {}, {params}).toPromise();
  }

  // TODO unused
  getCustomPlanItemPeriod(params?) {
    return this.http.get(`${this.BASE_URL}/getcustomplanitemperiod`, {params}).toPromise();
  }

  @Cache()
  async getPostGradeSchool(params?) {
    return this.http.get<PostGradSchool[]>(`${this.BASE_URL}/getpostgradschool`, {params}).toPromise();
  }

  delcompetence(params: {id: number, type_competence: number}) {
    return this.http.post<'Ok'>(`${this.BASE_URL}/delComptenece`, {}, {params}).toPromise();
  }

  transferToASAV(data: {id: string, flag: boolean}) {
    return this.http.post<'OK'>(`${this.BASE_URL}/lp_transfertoasav`, data).toPromise();
  }

  getStatusHistory(params: {id: string}) {
    return this.http.get<StatusHistory[]>(`${this.BASE_URL}/getstatushistory`, {params}).toPromise();
  }

  getComment2(params: {object_type_2: string}) {
    return this.http.get<Comment2[]>(`${this.BASE_URL}/bp/getcomment2`, {params}).toPromise();
  }

  getComment3(params?) {
    return this.http.get<Comment3[]>(`${this.BASE_URL}/bp/getcomment3`, {params}).toPromise();
  }

  archivePlan(id: string) {
    return this.http.post<'OK'>(`${this.BASE_URL}/bp/archiveted?id=${id}`, {}).toPromise();
  }

  getCustomPlanInfo(params: {id: string}) {
    return this.http.get<StudyPlanCPInfo>(`${this.BASE_URL}/getcpy`, {params}).toPromise();
  }

  getCurrentApprovalYear() {
    return this.http.get<CurrentApprovalYear>(`${this.BASE_URL}/getcurrentapprovalyear`).toPromise();
  }

  getSubjectUnits(params: {cpi_id: number, is_archive: boolean}) {
    return this.http.get<SubjectUnit[]>(`${this.BASE_URL}/getsubjectunits`, {params}).toPromise();
  }

  saveSubjectUnit(data: SubjectUnit, useHeader = false) {
    const options: any = {};
    const updatedHeaders = {
      headers: new HttpHeaders()
    };
    const additionalEmail = this.userProfile.getSelectedChangedUser();

    if (additionalEmail && useHeader) {
      updatedHeaders.headers = updatedHeaders.headers.set('HSE-Auth-Replacement', btoa(additionalEmail));
      options.headers = updatedHeaders.headers;
    }

    return this.http.post<SubjectUnitSavedId>(`${this.BASE_URL}/save?cname=subjectunits`, data, options).toPromise();
  }

  deleteSubjectUnit(data: {id: string}) {
    return this.http.post<'OK'>(`${this.BASE_URL}/delsubjectunit`, data).toPromise();
  }

  copySubjectUnit(data) {
    return this.http.post<'OK'>(`${this.BASE_URL}/copysubjectunits`, {}, {params: data}).toPromise();
  }

  // TODO unused
  markKugFile(data) {
    return this.http.post(`${this.BASE_URL}/lp_markkugfile`, {}, {params: data}).toPromise();
  }

  createCPIYear(data) {
    return this.http.post<CPIYearCreateResponse>(`${this.BASE_URL}/create_cpi_year`, data).toPromise();
  }

  deleteCPIYear(data: {cpi_id: number}) {
    return this.http.post<'OK'>(`${this.BASE_URL}/delete_cpi_year`, data).toPromise();
  }

  markMissedCPIYear(data) {
    return this.http.post<'OK'>(`${this.BASE_URL}/marked_missed_cpi_year`, data).toPromise();
  }

  getSpecializationClp(params) {
    return this.http.get<SpecializationCLP[]>(`${this.BASE_URL}/getspecializationclp`, {params}).toPromise();
  }

  getSpecialization(params?) {
    return this.http.get<Specialization[]>(`${this.BASE_URL}/getspecialization`, {params, observe: 'response'}).toPromise();
  }

  getSpecializationQualifications(params?) {
    return this.http.get<SpecializationQualification[]>(`${this.BASE_URL}/getspecializationqualification`, {params}).toPromise();
  }

  createSpecialization(data: SpecializationCreateBody) {
    return this.http.post(`${this.BASE_URL}/save?cname=specialization`, data).toPromise();
  }

  getEduTrajectoryType(params?) {
    return this.http.get<TrajectoryType[]>(`${this.BASE_URL}/getedutrajectorytype`, {params}).toPromise();
  }

  getPersons(params?) {
    return this.http.get<Person[]>(`${this.BASE_URL}/getpersons`, {params}).toPromise();
  }

  getPersons2(params?) {
    return this.http.get<Person[]>(`${this.BASE_URL}/getpersons2`, {params}).toPromise();
  }

  getTypesDoc(params?) {
    return this.http.get<TypesDoc[]>(`${this.BASE_URL}/gettypesdoc`, {params}).toPromise();
  }

  getPlanInCop(params?) {
    return this.http.get<any[]>(`${this.BASE_URL}/getplanincop`, {params}).toPromise();
  }

  checkCPI(params?) {
    return this.http.get<CPICheck[]>(`${this.BASE_URL}/checkcpi`, {params}).toPromise();
  }

  getOtherPlan(params?) {
    return this.http.get<OtherPlan[]>(`${this.BASE_URL}/getotherplan`, {params}).toPromise();
  }

  savePlanInCop(data: PlanInCop) {
    return this.http.post<PlanInCop[]>(`${this.BASE_URL}/save?cname=planincop`, data).toPromise();
  }

  // список учебных планов, у которых в структуре есть дисциплины, у которых есть ссылки на УП, id которого передан на входе
  getReferredPlans(params?) {
    return this.http.get<StudyPlan[]>(`${this.BASE_URL}/getreferredplans`, {params}).toPromise();
  }

  getBranchScience(params?) {
    return this.http.get<BranchScience[]>(`${this.BASE_URL}/getbranchscience`, {params}).toPromise();
  }

  getScienceSpecialities(params?) {
    return this.http.get<ScienceSpeciality[]>(`${this.BASE_URL}/getsciencespecialities`, {params}).toPromise();
  }

  recalculateCPI(learnPlanId) {
    return this.http.post<'OK'>(`${this.BASE_URL}/save?cname=recalculatecpi&learn_plan_id=${learnPlanId}`, {}).toPromise();
  }

  getListCustomPlanFilling(learnPlanId) {
    return this.http.get<string[]>(`${this.BASE_URL}/getListCustomPlanFilling?learn_plan_id=${learnPlanId}`).toPromise();
  }

  /**
   * Роль сотрудника в дополнительном модулей
   */
  getRoleInAdditionalModule() {
    return this.http.get<AdditionalModuleRole[]>(`${this.BASE_URL}/getroleinadditionalmodule`).toPromise();
  }

  /**
   * Виды стоимости обучения
   */
  getCostTypeAdditionalModule() {
    return this.http.get<AdditionalModuleCostType[]>(`${this.BASE_URL}/getcosttypeadditionalmodule`).toPromise();
  }

  /**
   * Варианты включения ДМ в УП
   */
  getIncludedInPlan() {
    return this.http.get<AdditionalModuleIncludePlanType[]>(`${this.BASE_URL}/getincludedinplan`).toPromise();
  }

  /**
   * получение учебных планов дополнительных модулей
   */
  getAdditionalLearnPlan(params?: any) {
    return this.http.get<AdditionalStudyPlan[]>(`${this.BASE_URL}/getadditionallearnplan`, {params, observe: 'response'}).toPromise();
  }

  /**
   * получить уровни микростепени
   */
  getMicrodegreeLevels() {
    return this.http.get<AdditionalModuleMicrodegreeLevel[]>(`${this.BASE_URL}/getmicrodegreelevels`, {params: {is_archive: false}}).toPromise();
  }

  saveAdditionalModule(data: AdditionalStudyPlan) {
    return this.http.post<AdditionalStudyPlanSaveResponse>(`${this.BASE_URL}/save?cname=additionalmodulelp`, data).toPromise();
  }

  removeAdditionalModule(learnPlanId: string) {
    return this.http.post<'OK'>(`${this.BASE_URL}/deladditionalmodulelp?learn_plan_id=${learnPlanId}`, {}).toPromise();
  }

  getProfessionalStatus(params: any = {}): Promise<ProfessionalStatus> {
    return this.http.get<ProfessionalStatus>(`${this.BASE_URL}/getprofessionalstatus`, {params}).toPromise();
  }

  saveProfessionalStatus(params: ProfessionalStatusSaveParams) {
    return this.http.post<'OK'>(`${this.BASE_URL}/save?cname=professionalstatus`, params).toPromise();
  }

  getEducationResultLinks(id) {
    return this.http.get<EducationResultLinks>(`${this.BASE_URL}/geteducationresultsclp?education_result_id=${id}`).toPromise();
  }

  replaceEducationResult(body: ReplaceEducationResultBody) {
    return this.http.post<{}>(`${this.BASE_URL}/replaceresults`, body).toPromise();
  }

  getOnlineCourses(props: OnlineCourseProps): Promise<HttpResponse<OnlineCourse[]>> {
    // @ts-ignore
    const params = new HttpParams({ fromObject: props });

    return this.http.get<OnlineCourse[]>(`${this.BASE_URL}/getonlinecourse`, {params, observe: 'response'}).toPromise();
  }

  getCourseTypes(): Promise<OnlineCourseType[]> {
    return this.http.get<OnlineCourseType[]>(`${this.BASE_URL}/gettypecourse`).toPromise();
  }

  getCoursePlatform(params = {}): Promise<OnlineCoursePlatform[]> {
    return this.http.get<OnlineCoursePlatform[]>(`${this.BASE_URL}/getplatform`, { params }).toPromise();
  }

  getCoursePlatformResponse(params = {}): Promise<HttpResponse<OnlineCoursePlatform[]>> {
    return this.http.get<OnlineCoursePlatform[]>(`${this.BASE_URL}/getplatform`, { params, observe: 'response' }).toPromise();
  }

  saveCourse(body: OnlineCourseBody): Promise<any> {
    return this.http.post(`${this.BASE_URL}/save?cname=onlinecourse`, body).toPromise();
  }

  savePlatform(body: OnlineCoursePlatform): Promise<any> {
    return this.http.post(`${this.BASE_URL}/save?cname=platform`, body).toPromise();
  }

  removePlatform(body: {id: number}): Promise<any> {
    return this.http.post(`${this.BASE_URL}/delete?cname=platform`, body).toPromise()
  }

  getCourseRealizations(props: OnlineCourseRealizationProps): Promise<OnlineCourseRealization[]> {
    //@ts-ignore
    const params = new HttpParams({ fromObject: props });
    return this.http.get<OnlineCourseRealization[]>(`${this.BASE_URL}/getrealizationcourse`, { params }).toPromise();
  }

  saveCourseRealization(body: OnlineCourseRealization): Promise<any> {
    return this.http.post<any>(`${this.BASE_URL}/save?cname=platformincourse`, body).toPromise();
  }

  removeCourseRealization(id: string): Promise<any> {
    return this.http.post(`${this.BASE_URL}/delete?cname=platformincourse`, { id }).toPromise();
  }
}
