import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import _forEach from '@tools/fp/forEach';
import { HseTableRow } from '@shared/components/common-ui/hse-table-new/hse-table-row.interface';
import { HseTableColumn } from '@shared/components/common-ui/hse-table-new/hse-table-column.interface';


@Component({
    selector: 'hse-table-row',
    templateUrl: './hse-table-row.component.html',
    styleUrls: ['./hse-table-row.component.scss']
})
export class HseTableRowComponent implements OnInit {

    @Input() columns: HseTableColumn[];
    @Input() row: HseTableRow<any>;

    @Input() extendControls = false;
    @Input() isRowClickable = false;
    @Input() depth = 0;
    @Input() emptyCell = '-';

    @Output() rowExpandChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() rowClick: EventEmitter<any> = new EventEmitter<any>();

    arrowOffset = 10;

    ngOnInit() {
        this.isRowClickable = Boolean(this.row.onClick) || this.rowClick.observers.length > 0;
    }

    toggleRowExpanded(row: HseTableRow<any>, $event) {
        $event.stopPropagation();
        row.isExpanded = !row.isExpanded;
        this.setRowsVisibility(row.rows, row.isExpanded);
        this.rowExpandChanged.emit(row);
    }

    setRowsVisibility(rows, value) {
        _forEach((subRow) => {
            subRow.visible = value;
            if (subRow.visible) {
                this.setRowsVisibility(subRow.rows, subRow.isExpanded);
            } else {
                this.setRowsVisibility(subRow.rows, false);
            }
        })(rows);
    }
}
