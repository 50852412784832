<div class="hse-select">
	<div *ngIf="!disabled">

		<div [matMenuTriggerFor]="menu"
		     #notDisabled
		     [ngClass]="getHeaderClasses(false)"
		     [ngStyle]="customStyle"
		     [style.width.px]="getWidth()"
		     (menuClosed)="optionListClosed()"
		     (menuOpened)="optionListOpened()">

			<div [style.width.px]="getWidth()" [ngClass]="getPlaceholderContainerClass()">
			  <span [ngClass]="getPlaceholderClass()">
				{{placeholder}}
			  </span>
			</div>

			<span *ngIf="!selected && !nullText" class="hse-select-span"></span>
			<span *ngIf="selected || nullText" class="hse-select-span">
          		{{ selectedName || nullText }}
        	</span>
			<div class="fx row v-center">
				<hse-icon [name]="'lightning'"
				          *ngIf="valueChanged"
				          class="hse-input__lightning"></hse-icon>
				<hse-icon name="expandMore" class="select-arrow"></hse-icon>
			</div>

		</div>

		<mat-menu #menu="matMenu"
		          tabindex="-1"
		          [overlapTrigger]="false"
		          [xPosition]="xPos"
		          [yPosition]="yPos"
		          class="hse-select-options-list">
			<div class="hse-select-search" *ngIf="useSearch">
				<input [(ngModel)]="searchString"
				       (input)="filterOptions()"
				       [style.width.px]="getWidth() - 36"
				       (click)="stopPropagation($event)"
				       placeholder="{{searchPlaceholder}}">
				<hse-icon name="search" class="search-icon"></hse-icon>
			</div>
			<div class="hse-select-content-options-list"
           [style.overflow]="isBigList ? 'hidden' : null"
			     [style.width]="equalWidth ? getWidth() - 2 + 'px' : 'auto'"
			     [style.max-height.px]="getHeight()"
			     [style.min-width.px]="getWidth() - 2">
				<div *ngIf="nullText"
				     mat-menu-item
				     (click)="changeValue(null)"
				     [ngClass]="getItemClass(null)">
					<span class="hse-select-span">{{nullText}}</span>
				</div>

        <cdk-virtual-scroll-viewport class="items-container" *ngIf="isBigList"
                                     #virtualScrollViewport
                                     [style.height.px]="getHeight()"
                                     [itemSize]="32">
          <div *cdkVirtualFor="let item of filteredCollection; templateCacheSize: 0"
               (click)="changeValue(item[idField])"
               [ngClass]="getItemClass(item)"
               [class.hse-custom-template]="optionTpl">
            <ng-template *ngTemplateOutlet="optionTpl; context: {$implicit: item}"></ng-template>
            <span *ngIf="!optionTpl"
                  class="hse-select-span"
                  matTooltip="{{ getTooltip(item) }}"
                  [matTooltipPosition]="'above'">{{item[nameField]}}</span>
          </div>
        </cdk-virtual-scroll-viewport>

        <section *ngIf="!isBigList">
          <div mat-menu-item
               *ngFor="let item of filteredCollection"
               (click)="changeValue(item[idField])"
               [ngClass]="getItemClass(item)"
               [class.hse-custom-template]="optionTpl">
            <ng-template *ngTemplateOutlet="optionTpl; context: {$implicit: item}"></ng-template>
            <span *ngIf="!optionTpl"
                  class="hse-select-span"
                  matTooltip="{{ getTooltip(item) }}"
                  [matTooltipPosition]="'above'">{{item[nameField]}}</span>
          </div>
        </section>

			</div>
		</mat-menu>
	</div>

	<div *ngIf="disabled">
		<div [style.width.px]="getWidth()"
		     [style.color]="'#6a7a98'"
		     [ngClass]="getHeaderClasses(true)">
			<div [style.width.px]="getWidth()"
			     class="hse-select-label-container">
        <span [ngClass]="getPlaceholderClass()">
          {{placeholder}}
        </span>
			</div>
			<span *ngIf="!selected && !nullText" class="hse-select-span">
      </span>
			<span *ngIf="selected || nullText" class="hse-select-span">
        {{ selectedName || nullText }}
      </span>
			<hse-icon name="expandMore" class="select-arrow"></hse-icon>
		</div>
	</div>
</div>
