import { Component, Input, OnInit } from '@angular/core';
import { UserProfileService } from '@backend/user-profile/user-profile.service';
import { AuthService } from '@backend/auth/auth.service';
import { PrintFormsUnloadService } from '@backend/kosuip/print-forms-unload/print-forms-unload.service';
import * as moment from 'moment';

import {
  map as _map
} from 'lodash/fp';

@Component({
  selector: 'hse-print-forms-unload-learn-plan',
  templateUrl: './print-forms-unload-learn-plan.component.html',
  styleUrls: ['./print-forms-unload-learn-plan.component.scss']
})
export class PrintFormsUnloadLearnPlanComponent implements OnInit {

  public roles;
  public loaded = false;
  public showYearSelector = false;
  public selectedPeriod;
  public selectedItem;
  public modalOpened = false;
  public selectedFormat;
  public availableFormats;
  public selectedLanguage = 'rus';
  public shortForm = false;
  public availableLanguages = [
    {
      value: 'rus',
      text: 'Русский'
    },
    {
      value: 'eng',
      text: 'Английский'
    },
  ];

  @Input() id: number;
  @Input() planType?: string;
  @Input() type: string;
  @Input() config?: any[];
  @Input() periods?: any[];

  constructor(public authService: AuthService,
              private userProfile: UserProfileService,
              private unload: PrintFormsUnloadService) {
  }

  ngOnInit() {
    this.init();
  }

  async init() {
    try {
      this.roles = await this.userProfile.getCurrentRoles();
      this.loaded = true;
    } catch (e) {
      console.error(e);
    }
  }

  open() {
    this.shortForm = false;
    if (!this.config) {
      this.unload.getAllFileType(this.type).subscribe(data => this.config = data);
    }
  }

  toBase64(fileType, file) {
    if (fileType === 'pdf') {

      return 'data:application/pdf;base64,' + file;
    }

    if (fileType === 'docx') {
      return 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,' + file;
    }

    return 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + file;
  }

  downloadFile(name, fileType, file) {
    const downloadLink = document.createElement('a');
    const fileName = name;

    downloadLink.href = file;
    downloadLink.download = fileName;
    downloadLink.click();

    this.cancelUnload();
  }

  async unloadFile() {
    try {
      const date = moment().format('YYYYMMDD');
      let unload;
      if (this.selectedItem.form_type === 'wlpprintform' || this.selectedItem.form_type === 'wlpshortprintform') {
        unload = await this.unload.getFileLearnPlanByYear(this.selectedItem.form_type, this.selectedFormat, date, this.id, this.selectedPeriod, this.selectedLanguage);
      } else {
        unload = await this.unload.getFileLearnPlan(this.selectedItem.form_type, this.selectedFormat, date, this.id, this.selectedLanguage, false, this.shortForm);
      }
      const origin = unload.fileData;
      const converted = origin.replace(/'\r\n'/g, '');
      const result = this.toBase64(this.selectedFormat, converted);

      this.downloadFile(unload.filename, this.selectedFormat, result);
    } catch (e) {
      console.error('Ошибка выгрузки файла', e);
    }
  }

  selectPeriod($event) {
    this.selectedPeriod = $event;
  }

  selectLanguage($event) {
    this.selectedLanguage = $event;
  }

  openModal(item) {
    this.modalOpened = true;
    this.selectedItem = item;
    this.availableFormats = _map(($format: any) => {
      return {
        description: $format,
        id: $format
      };
    })(this.selectedItem.available_formats);
  }

  acceptUnload() {
    this.unloadFile();
  }

  cancelUnload() {
    this.modalOpened = false;
    this.selectedItem = null;
    this.selectedPeriod = null;
    this.selectedFormat = null;
    this.selectedLanguage = 'rus';
  }

  disableUnload() {
    if (!this.selectedFormat) {
      return true;
    }

    if (this.selectedItem.form_type === 'wlpprintform' || this.selectedItem.form_type === 'wlpshortprintform') {
      if (!this.selectedPeriod) {
        return true;
      }
    }

    return false;
  }
}
