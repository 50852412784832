import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Directory } from '@backend/kosuip/catalogue/interfaces/directory.interface';

export interface TranslationCallbackResponse {
  description: string;
  eng_name: string;
}

@Component({
  selector: 'hse-create-edit-directory',
  templateUrl: './create-edit-directory.component.html',
  styleUrls: ['./create-edit-directory.component.scss']
})
export class CreateEditDirectoryComponent implements OnInit, OnDestroy {

  @Input() directory?: Directory;
  @Input() disablePopup = false;
  @Input() edit = false;
  @Output() callback: EventEmitter<TranslationCallbackResponse> = new EventEmitter<TranslationCallbackResponse>();

  private body: HTMLBodyElement;
  public confirmMessage = '';
  public showConfirmPopup = false;
  public isAvailableToBack = false;
  public isAvailableToForward = false;
  public bodyHeight: number;
  public iterationIndex = 0;
  public margin: number;
  public description: string;
  public engName: string;
  public iterations = ['description', 'engName', 'final'];
  public enterSubscription: Subscription;
  public progress;
  public passedItems = [];

  translations = {
    INPUT: '',
    FROM: '',
    SYMBOLS: '',
  };

  constructor(private translate: TranslateService) {
    this.translations.INPUT = this.translate.instant('UNIVERSITY-DISCIPLINE.INPUT');
    this.translations.FROM = this.translate.instant('UNIVERSITY-DISCIPLINE.FROM');
    this.translations.SYMBOLS = this.translate.instant('UNIVERSITY-DISCIPLINE.SYMBOLS');
  }

  ngOnInit() {
    this.progress = {
      description: {
        value: '',
        passed: false,
        title: this.translate.instant('DIRECTORY-NAMES.LIST.NAME')
      },
      engName: {
        value: '',
        passed: false,
        title: this.translate.instant('DIRECTORY-NAMES.LIST.ENG-NAME')
      }
    };
    this.confirmMessage = this.translate.instant('DIRECTORY-NAMES.CREATE.MESSAGE');
    this.body = document.getElementsByTagName('body')[0];
    this.setBodyHeight();
    this.enterSubscription = fromEvent(window, 'keyup')
      .subscribe((event: KeyboardEvent) => {
        if (event.code !== 'Enter') {
          return;
        }
        if (this.iterations[this.iterationIndex] === 'final') {
          return;
        }

        this.saveField();
      });
    if (this.edit) {
      this.prepareToEdit();
    }
    this.calculateMargin();
  }

  calculateMargin(): void {
    this.margin = 100 - this.passedItems.length * 20;
  }

  prepareToEdit(): void {
    this.progress.description.value = this.directory.description;
    this.progress.description.passed = true;
    this.progress.engName.value = this.directory.eng_description;
    this.progress.engName.passed = true;
    this.goToIteration('final');
  }

  goToIteration(key: string, reset = false): void {
    this.updatePassedItems();
    if (reset) {
      this.progress[key].passed = false;
    }
    this.iterationIndex = this.iterations.indexOf(key);
    this.checkAvailabilityToShift();
  }

  setBodyHeight(): void {
    this.bodyHeight = this.body.scrollHeight;
  }

  goBack() {
    if (!this.isAvailableToBack) {
      return;
    }

    this.goToIteration(this.iterations[this.iterationIndex - 1]);
  }

  goForward(ignore = false) {
    if (!this.isAvailableToForward && !ignore) {
      return;
    }

    this.goToIteration(this.iterations[this.iterationIndex + 1]);
  }

  saveField(): void {
    this.progress[this.iterations[this.iterationIndex]].passed = true;
    if (this.edit) {
      this.goToIteration('final');

      return;
    }
    this.goForward(true);
  }

  checkAvailabilityToShift() {
    this.isAvailableToBack = this.iterationIndex > 0;
    this.isAvailableToForward = this.iterationIndex < 1 && this.progress.engName.passed;
  }

  updatePassedItems() {
    this.passedItems = [];

    for (const key of Object.keys(this.progress)) {
      if (this.progress[key].passed) {
        this.passedItems.push({
          title: this.progress[key].title,
          value: this.progress[key].value,
          key
        });
      }
    }

    this.calculateMargin();
  }

  save(): void {
    const params: any = {};
    params.description = this.progress.description.value;
    params.eng_description = this.progress.engName.value;
    if (this.edit) {
      params.id = this.directory.id;
    }
    this.callback.emit(params);
  }

  ngOnDestroy(): void {
    this.enterSubscription.unsubscribe();
  }

  cancel(): void {
    if (this.disablePopup) {
      this.callback.emit(null);

      return;
    }
    this.showConfirmPopup = true;
  }

  confirmPopupCallback(value: boolean): void {
    if (value) {
      this.callback.emit(null);
    }

    this.showConfirmPopup = false;
  }
}
