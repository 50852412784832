import {
  OnInit,
  OnChanges,
  OnDestroy,
  Directive,
  Input,
  HostBinding,
  Renderer2,
  ElementRef,
  SimpleChanges
} from '@angular/core';
import { fromEvent, merge } from 'rxjs';
import _guid from '@tools/fp/guid';

/**
 * <div class="card"
 *     [hseCgBusy]="condition">
 * </div>
 */
@Directive({
  selector: '[hseCgBusy]'
})
export class CgBusyDirective implements OnInit, OnChanges, OnDestroy {
  @HostBinding('style.position') hostPosition = 'relative';
  @Input() hseCgBusy = false;
  uid: string;
  events: any;

  constructor(private targetEl: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.uid = 'loading-container-' + _guid();

    const loadingContainer = this.renderer.createElement('div');
    this.renderer.setStyle(
      loadingContainer,
      'display',
      this.hseCgBusy ? 'flex' : 'none'
    );
    this.renderer.setStyle(loadingContainer, 'justify-content', 'center');
    this.renderer.setStyle(loadingContainer, 'align-items', 'center');
    this.renderer.addClass(loadingContainer, this.uid);
    this.renderer.setStyle(loadingContainer, 'position', 'absolute');
    this.renderer.setStyle(loadingContainer, 'z-index', '2000');
    this.renderer.setStyle(loadingContainer, 'top', '0');
    this.renderer.setStyle(loadingContainer, 'left', '0');
    this.renderer.setStyle(loadingContainer, 'background', 'rgba(106, 122, 152, 0.5)');
    this.renderer.setStyle(loadingContainer, 'width', '100%');
    this.renderer.setStyle(loadingContainer, 'height', '100%');
    this.renderer.setStyle(loadingContainer, 'border-radius', getComputedStyle(this.targetEl.nativeElement).borderRadius);

    const spinnerContainer = this.renderer.createElement('div');
    this.renderer.addClass(spinnerContainer, 'lds-ellipsis');
    const spinnerInnerDiv1 = this.renderer.createElement('div');
    const spinnerInnerDiv2 = this.renderer.createElement('div');
    const spinnerInnerDiv3 = this.renderer.createElement('div');
    const spinnerInnerDiv4 = this.renderer.createElement('div');

    this.renderer.appendChild(spinnerContainer, spinnerInnerDiv1);
    this.renderer.appendChild(spinnerContainer, spinnerInnerDiv2);
    this.renderer.appendChild(spinnerContainer, spinnerInnerDiv3);
    this.renderer.appendChild(spinnerContainer, spinnerInnerDiv4);

    this.renderer.appendChild(loadingContainer, spinnerContainer);
    // custom spinner -- end

    this.renderer.appendChild(this.targetEl.nativeElement, loadingContainer);

    this.events = merge(
      fromEvent(loadingContainer, 'click'),
      fromEvent(loadingContainer, 'mouseenter'),
      fromEvent(loadingContainer, 'mouseleave'),
      fromEvent(loadingContainer, 'mouseover')
    ).subscribe((event: Event) => {
      event.stopPropagation();
    });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.hseCgBusy) {
      const container = this.targetEl.nativeElement;
      const div = container.querySelector('.' + this.uid);
      if (div) {
        this.renderer.setStyle(
          div,
          'display',
          this.hseCgBusy ? 'flex' : 'none'
        );
      }
    }
  }

  ngOnDestroy(): void {
    this.events.unsubscribe();
  }
}
