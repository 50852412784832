<div class="hse-user-info fx h-v-center" *ngIf="authService.session && loaded">

    <div class="menu-trigger__wrapper">
        <div [matMenuTriggerFor]="menu"
             #t="matMenuTrigger"
             class="menu-trigger"
             [ngClass]="{ 'opened': t.menuOpen }"
             (menuOpened)="open()">
            <hse-icon class="menu-trigger__verify" [name]="'verify'"></hse-icon>
        </div>
    </div>

    <mat-menu #menu="matMenu"
              tabindex="-1"
              class="custom-mtk-menu"
              [overlapTrigger]="false">

        <div class="mtk-menu__title fx row h-v-center" (click)="$event.stopPropagation()">
            <span>{{ titleLabelYear }}</span>
        </div>

        <div class="mtk-menu__empty" *ngIf="!items.length && !mtkLoaded" (click)="$event.stopPropagation()">
            <span>Загрузка...</span>
        </div>

        <div class="mtk-menu__empty" *ngIf="!items.length && mtkLoaded" (click)="$event.stopPropagation()">
            <span>МТК не заведены</span>
        </div>

        <div class="mtk-menu__list" *ngIf="items.length" (click)="$event.stopPropagation()">

            <div class="item" *ngFor="let item of items">

                <div class="item__info fx row v-center">

                    <div class="item__wrapper fx column">
                        <div class="item__name">
                            <span>{{ item.description || item.name }}</span>
                        </div>

                        <div class="item__result_done" *ngIf="item.verification_result">
                            <span>Условие выполнено</span>
                        </div>

                        <div class="item__result_notdone" *ngIf="!item.verification_result">
                            <span>Несоответствие МТК</span>

                        </div>
                    </div>

                    <div *ngIf="item.verification_result" class="item__icon-wrapper">
                        <hse-icon class="item__verification_done" [name]="'check'"></hse-icon>
                    </div>

                    <div *ngIf="!item.verification_result">
                        <hse-icon class="item__verification_notdone" [name]="'close'"></hse-icon>
                    </div>

                </div>

            </div>

        </div>

    </mat-menu>


</div>
