<div class="fx column left-bar" hseSticky
     [stackName]="'leftBar'"
     [offsetTop]="50" [zIndex]="100"
     [style.width.px]="leftMenuService.menuWidth"
     [width]="leftMenuService.menuWidth">
	<div class="expand-icon fx row v-center" (click)="toggleExpand()" [class.rotated]="leftMenuService.menuExpanded">
		<hse-icon [name]="'shortArrowRight'"></hse-icon>
		<span *ngIf="leftMenuService.menuExpanded">Свернуть</span>
	</div>

	<ng-template ngFor let-menuItem [ngForOf]="menuItems">
		<div *ngIf="menuItem.visible"
		     class="menu-item fx row v-center"
		     (click)="goTo(menuItem)"
		     [style.margin-bottom.px]="menuItem.expanded ? 0 : 16"
		     [class.active]="menuItem.active">
			<hse-icon [name]="menuItem.icon"
			          [matTooltip]="!leftMenuService.menuExpanded ? menuItem.title : ''"
			          [matTooltipPosition]="'above'"
			          [class.department]="menuItem.icon === 'menuDepartmentDisciplines'"></hse-icon>

			<span *ngIf="leftMenuService.menuExpanded">{{ menuItem.title }}</span>
			<hse-icon [name]="'arrowDown'"
			          class="item-expand-icon"
			          [class.rotated]="!menuItem.expanded"
			          *ngIf="leftMenuService.menuExpanded && menuItem.expandable"></hse-icon>
		</div>

		<ng-template [ngIf]="menuItem.expanded">
			<ng-template ngFor let-childItem [ngForOf]="menuItem.children">
				<div *ngIf="childItem.visible"
				     class="child-menu-item fx row v-center"
				     (click)="goTo(childItem)"
				     [class.active]="childItem.active">

					<span *ngIf="leftMenuService.menuExpanded">{{ childItem.title }}</span>
				</div>
			</ng-template>
		</ng-template>
	</ng-template>
</div>
