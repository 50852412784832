<ng-container *ngFor="let step of steps; let last = last">
    <button class="hse-stepper__step"
            [class.offset]="!addSeparateIcon"
            [class.active]="step.value === selected"
            [disabled]="step.disabled || false"
            (click)="change(step.value)">
        <ng-container *ngIf="step.icon">
            <hse-icon class="hse-stepper__status-icon" [name]="step.icon"></hse-icon>
        </ng-container>
        {{ step.label }}
    </button>
    <ng-container *ngIf="!last && addSeparateIcon">
        <hse-icon class="fx row h-v-center hse-stepper__separate-icon" [name]="separateIcon"></hse-icon>
    </ng-container>
</ng-container>
