import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from '@backend/auth/auth.service';
import { Router } from '@angular/router';
import { LoaderInterceptorService } from '@shared/components/hse-loader/services/loader-interceptor.service';
import { HseAlertService } from '@core/hse-alert/hse-alert.service';
import { catchError, finalize, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService,
              private alert: HseAlertService,
              private router: Router,
              private loader: LoaderInterceptorService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.authService.getToken();

    const authReq = req.clone({
      headers: req.headers.set('HSE-Auth-Token', authToken || '')
    });

    this.loader.interceptRequest({
      url: authReq.url,
      method: authReq.method
    });

    return next.handle(authReq).pipe(
      catchError((err) => {
        this.loader.interceptError({
          url: authReq.url,
          method: authReq.method
        });

        if (/^(\/api)/i.test(authReq.url)) {
          if (err.status === 401 && this.authService.isAuth()) {
            this.authService.dropSession();
            // TODO при размещении на домене hse.ru можно будет грохать куки чтобы не заходить на LOGOUT_URL
            window.open(this.authService.LOGOUT_URL, '_blank');
            this.router.navigateByUrl('/constructor/auth');
          } else if (err.status === 423) {
            this.alert.warn('Идёт пересчёт кредитов по учебному плану');
          } else if (authReq.url.indexOf('save?cname=stdlearnplan') !== -1 && err.status === 430) {
            this.alert.error('Перед удалением образовательной траектории необходимо удалить все добавленные в рамках образовательной траектории дисциплины, проекты и практики.');
          } else if (authReq.url.indexOf('save?cname=stdpassport') !== -1 && err.status === 400) {
            this.alert.error('Для выбранного направления подготовки и заданного периода паспорт уже существует');
          } else if (authReq.url.indexOf('save?cname=customplanitem') !== -1 && err.status === 424) {
            this.alert.error('Текущий контингент с учетом введенного количества обучающихся превышает максимальную численность обучающихся на дисциплине. Менеджер не разрешил превышать максимальную численность обучающихся на дисциплине');
          } else if (authReq.url.indexOf('createDisciplineUniversity') !== -1 && err.status === 400) {
            this.alert.error('Дисциплина ВУЗа с таким Названием или Наименованием на английском языке уже создана');
          } else if (authReq.url.indexOf('copylp') !== -1 && err.status === 400) {
            this.alert.error('Учебный план нельзя скопировать. Образовательная программа или факультет неактивен.');
          } else if (authReq.url.indexOf('copylp') !== -1 && err.status === 430) {
            this.alert.error('Учебный план не может быть скопирован на выбранный учебный год. Образовательный стандарт не действует для выбранного учебного года');
          } else if ((authReq.url.indexOf('createSection') !== -1 || authReq.url.indexOf('updateSection') !== -1) && err.status === 400) {
            this.alert.error('Раздел с таким Наименованием и Наименованием на английском языке уже создан.');
          } else if ((authReq.url.indexOf('deleteSection') !== -1) && err.status === 400) {
            this.alert.error('На основании записи справочника разделов создан раздел УП. Удаление записи раздела невозможно.');
          } else if (authReq.url.indexOf('/save?cname=stdlearnplan') !== -1 && err.status === 400) {
            this.alert.error('Попытка создания дубликата УП');
          } else if (authReq.url.indexOf('/save?cname=specialization') !== -1 && err.status === 400) {
            this.alert.error('Попытка создания дубликата специализации');
          } else if (authReq.url.indexOf('/save?cname=educationresults') !== -1 && err.status === 400) {
            this.alert.error('Ключевой образовательный результат уже существует. Добавьте Ключевой образовательный результат из справочника.');
          } else if (authReq.url.indexOf('/save?cname=educationresults') !== -1 && err.status === 433) {
            this.alert.error('Данный КОР уже добавлен в образовательную траекторию');
          } else if (authReq.url.indexOf('/lp_transfertoasav') !== -1 && err.status === 400) {
            console.log('Ошибка передачи в АСАВ');
          } else if (authReq.url.indexOf('/save?cname=onlinecourse') !== -1) {
            switch (err.status) {
              case 400:
                this.alert.error('Дистанционный курс с таким названием уже создан в системе');
                break;
              case 416:
                this.alert.error('Попытка создания дубликата');
                break;
              case 501:
                this.alert.error(`Для архивации курса необходимо, чтобы у каждой реализации платформы "Опубликовать" был равным "Нет"`);
                break;
            }
          } else if (authReq.url.indexOf('/save?cname=platformincourse') !== -1) {
            this.alert.error('Попытка создания дубликата реализации');
          } else if (authReq.url.indexOf('/save?cname=platform') !== -1) {
            this.alert.error('Платформа с таким названием уже создана в системе');
          } else if (authReq.url.indexOf('/delete?cname=platform') !== -1) {
            this.alert.error(`Невозможно удалить платформу, т.к. есть дистанционный курс, который реализуется на этой платформе`);
          } else if (err.status === 416) {
            console.log('Нет данных');
          } else if (authReq.url.indexOf('getnotification') !== -1) {
            console.log('Ошибка уведомлений');
          } else if (authReq.url.indexOf('/save?cname=additionalmodulelp') !== -1 && err.status === 400) {
            const errorMessage = `Учебный план с таким наименованием на выбранный учебный год уже создан: ${err.error.message.toString().match(/\d+/)}`;

            this.alert.error(errorMessage);
          } else {
            if (err.status !== 401
              && authReq.url.indexOf('/save?cname=stdpassport') === -1
              && authReq.url.indexOf('checkdisciplinestounite') === -1
              && authReq.url.indexOf('getdisciplineplan') === -1
            ) {
              this.alert.error('Внимание! В процессе работы программы произошла ошибка. Обратитесь в техподдержку');
            }
          }
        }

        return throwError(err);
      }),
      finalize(() => this.loader.interceptResponse({
        url: authReq.url,
        method: authReq.method
      }))
    );

  }


}
