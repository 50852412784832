import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, HostBinding, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { random as _random } from 'lodash/fp';

export interface HseRadioButton {
  label: string;
  value: string;
}

export type HseRadioGroup = HseRadioButton[];


@Component({
  selector: 'hse-radio-button-group',
  templateUrl: './hse-radio-button-group.component.html',
  styleUrls: ['./hse-radio-button-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HseRadioButtonGroupComponent),
      multi: true
    }
  ]
})
export class HseRadioButtonGroupComponent implements ControlValueAccessor {
  @HostBinding('class.hse-radio-button-group') readonly baseCss: boolean = true;

  @Input() name!: string;
  @Input() labelField = 'label';
  @Input() valueField = 'value';
  @Input() disabled = false;
  @Input() direction: 'row' | 'column' = 'column';
  @Input() size: 'small' | 'medium' | 'large' = 'small';
  @Input() items: HseRadioGroup = [];
  @Input()
  set model(value: string) {
    this._value = value;
  }

  get model(): string {
    return this._value;
  }

  @Output() modelChange: EventEmitter<string> = new EventEmitter<string>();
  @HostBinding('class') get additionalClasses(): string {
    return `hse-radio-button-group-${this.size} hse-radio-button-group__${this.direction}`;
  }

  private minValue = 1000000;
  private maxValue = 9999999;
  private _value = '';
  private _onChange = (value: string) => {};
  private _onTouched = () => {};

  public readonly radioGroupId: string;

  constructor() {
    this.radioGroupId = `${_random(this.minValue, this.maxValue)}-hse-radio-button-group-${Date.now()}`;
  }

  writeValue(value: string): void {
    this._value = value;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  onChangeValue(value: string): void {
    this._value = value;

    this._onChange(this._value);
    this._onTouched();
    this.modelChange.emit(this._value);
  }

}
