import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hse-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class HseLeftMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
