import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

/**
 *
 * Для того чтобы начать использовать меню, ставим <hse-menu></hse-menu>
 * Внутри этого тега создаётся триггер и тело меню.
 * Для того, чтобы создать триггер, ставим блоку атрибут hse-menu-trigger.
 * Для того, чтобы создать тело, ставим блоку атрибут hse-menu-body.
 * Каждый элемент меню можно помечать атрибутом hse-menu-item, но по сути это просто дефолтная стилизация,
 * содержимое тела меню может быть любым.
 * Для того, чтобы предотвратить закрытие меню при клике по конкретному элементу, ставим атрибут prevent-menu-close.
 *
 * Для более тонкой настройки есть ряд биндингов:
 * [offsetX] - смещение меню по горизонтали
 * [offsetY] - смещение меню по вертикали
 * [width] - ширина выпадающего тела меню
 * [customStyle] - кастомизация блока menu-body
 * [addTriangle] - добавляет треугольник на верхней границе меню (сделано по стайлгайду)
 * [triangleOffset] - смещение треугольника по горизонтали
 * (menuIsOpened) - коллбэк на открытие/закрытие меню
 *
 * Пример использования
 *   <hse-menu [offsetX]="-10"
 *     [offsetY]="30"
 *     [addTriangle]="true"
 *     [triangleOffset]="120"
 *     (menuIsOpened)="menuCallback($event)">
 *     <div hse-menu-trigger>
 *       <span>Open menu!</span>
 *     </div>
 *
 *   <div hse-menu-body>
 *     <div hse-menu-item class="hse-menu-item" (click)="clickMenuItem('1')">
 *       <span>Lol</span>
 *     </div>
 *     <div hse-menu-item (click)="clickMenuItem('2')">
 *       <span>Kek</span>
 *     </div>
 *     <div hse-menu-item prevent-menu-close (click)="clickMenuItem('3')">
 *       <span>Cheburek (prevent close)</span>
 *     </div>
 *     <div class="custom-menu-template" prevent-menu-close>
 *       <custom-template></custom-template>
 *     </div>
 *   </hse-menu>
 */

export interface HseMouseEvent extends MouseEvent {
  path: Array<Element>;
}

@Component({
  selector: 'hse-menu',
  templateUrl: './hse-menu.component.html',
  styleUrls: ['./hse-menu.component.scss']
})

export class HseMenuComponent {

  menuOpened = false;
  @Input() offsetX ? = '0';
  @Input() offsetY ? = '';
  @Input() addTriangle ? = false;
  @Input() triangleOffset ? = 0;
  @Input() width ? = '';
  @Input() customStyle ? = {};
  @Input() disabled: boolean;

  @Output() menuIsOpened?: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('menuTrigger', { static: true }) triggerRef: ElementRef;

  openMenu(event: HseMouseEvent) {
    if (this.checkIfClosePrevented(event) || this.disabled) {
      return;
    }
    // если мы будем смотреть на элемент самого триггера (triggerRef), то в итоге меню будет открываться и
    // закрываться по всей ширине контейнера триггера. поэтому сначала мы смотрим на дочерний элемент контейнера
    // (это у нас ng-content) и на дочерние элементы ng-content, которые задумываются как первоначальный
    // триггер для меню

    // let triggerIsClicked = false;
    //
    // if (this.triggerRef.nativeElement.firstChild.children.length) {
    //   for (const element of this.triggerRef.nativeElement.firstChild.children) {
    //     if (element.contains(event.target)) {
    //       triggerIsClicked = true;
    //
    //       break;
    //     }
    //   }
    // } else {
    //   triggerIsClicked = this.triggerRef.nativeElement.firstChild.contains(event.target);
    // }

    if (!this.offsetY) {
      this.offsetY = this.triggerRef.nativeElement.offsetHeight;
    }

    this.menuOpened = !this.menuOpened;
    this.menuIsOpened.emit(this.menuOpened);
  }

  clickOutside() {
    this.menuOpened = false;
    this.menuIsOpened.emit(this.menuOpened);
  }

  bodyClicked(event: HseMouseEvent) {
    if (!this.checkIfClosePrevented(event)) {
      this.menuOpened = false;
      this.menuIsOpened.emit(this.menuOpened);
    }
  }

  checkIfClosePrevented(event: HseMouseEvent) {
    if (window.navigator.userAgent.indexOf('Mozilla') > -1) {
      return false;
    }

    let preventClose = false;
    for (const element of event.path) {
      if (element.attributes && element.attributes.getNamedItem('prevent-menu-close')) {
        preventClose = true;

        break;
      }
    }

    return preventClose;
  }
}
