import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HseStickyDirective } from './hse-sticky.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [HseStickyDirective],
  exports: [HseStickyDirective]
})
export class HseStickyModule {
}
