import { Injectable } from '@angular/core';
import { CatalogueService } from '@backend/kosuip/catalogue/catalogue.service';
import { SupportMessageService } from '@backend/kosuip/support-message/support-message.service';
import { Subject } from 'rxjs';
import { MessageType } from '@backend/kosuip/catalogue/interfaces/message-type.interface';

@Injectable({
  providedIn: 'root'
})
export class SupportDialogService {

  showDialog: Subject<boolean> = new Subject<boolean>();
  messageTypes: MessageType[] = [];

  constructor(private catalogue: CatalogueService,
              private support: SupportMessageService) { }

  async openDialog(): Promise<void> {
    try {
      if (!Boolean(this.messageTypes.length)) {
        this.messageTypes = await this.getMessageTypes();
      }
      this.showDialog.next(true);
    } catch (e) {
      console.error(e);
    }
  }

  getMessageTypes(): Promise<MessageType[]> {
    return this.catalogue.getSupportMessageTypes();
  }

  async postMessage(data) {
    try {
      await this.support.postMessage(data);
    } catch (e) {
      console.error(e);
    }
  }

  closeDialog(): void {
    this.showDialog.next(false);
  }
}
