import { AfterContentChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { StudyPlanExtend } from '../../../constructor/components/study-plans/study-plan-extend.interface';
import { compact as _compact, filter as _filter, find as _find, flow as _flow, forEach as _forEach, includes as _includes, map as _map, sortBy as _sortBy, uniq as _uniq } from 'lodash/fp';
import { CustomPlanItemExtended } from '../../../constructor/components/educational-standards/components/educational-standard-structure/educational-standard-structure.component';
import { StudyPlanCompetence } from '@backend/kosuip/catalogue/interfaces/study-plan.interface';
import { TranslateService } from '@ngx-translate/core';
import { EducationResult } from '@backend/kosuip/catalogue/interfaces/education-result.interface';

export interface CompetenceType {
  name: string;
  code: string;
  id: string;
}

export interface CompetenceExtended extends StudyPlanCompetence {
  selected?: boolean;
  hint?: string;
  specialization_id?: string;
  speciality_id?: string;
  isFullDescriptionShow?: boolean;
}

@Component({
  selector: 'hse-add-competencies',
  templateUrl: './add-competencies.component.html',
  styleUrls: ['./add-competencies.component.scss']
})
export class AddCompetenciesComponent implements OnInit, AfterContentChecked {

  @Input() studyPlan: StudyPlanExtend;
  @Input() structureState = false;
  @Input() masterLevel ? = false;
  @Input() educationTrajectoryId: string = null;
  @Input() customPlanItem: CustomPlanItemExtended;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('competencies', { static: true }) competenciesElem: ElementRef;

  bodyHeight = 0;
  margin = 200;
  textareaWidth = 0;
  body: any;

  passedItems: {title: string, value: string}[] = [];

  selectedSpeciality: any;
  competenceTypeId: string;
  competenceTypeName: string;
  competenceTypes: CompetenceType[];
  competencies: CompetenceExtended[];
  educationResults: any[];
  filteredCompetencies: CompetenceExtended[];
  codes: number[];
  languagePrefix = '';
  showSpecialitySelect = false;

  constructor(private translate: TranslateService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.competenceTypes = [
      {
        code: this.translate.instant('STUDY-PLAN.ADD-COMPETENCIES-POPUP.UK-CODE'),
        name: this.translate.instant('STUDY-PLAN.ADD-COMPETENCIES-POPUP.UK'),
        id: '1'
      },
      {
        code: this.translate.instant('STUDY-PLAN.ADD-COMPETENCIES-POPUP.OPK-CODE'),
        name: this.translate.instant('STUDY-PLAN.ADD-COMPETENCIES-POPUP.OPK'),
        id: '2'
      },
      {
        code: this.translate.instant('STUDY-PLAN.ADD-COMPETENCIES-POPUP.PK-CODE'),
        name: this.translate.instant('STUDY-PLAN.ADD-COMPETENCIES-POPUP.PK'),
        id: '5'
      }
    ];

    if (this.masterLevel) {
      this.competenceTypes.push({
        code: this.translate.instant('STUDY-PLAN.ADD-COMPETENCIES-POPUP.KOR-CODE'),
        name: this.translate.instant('STUDY-PLAN.ADD-COMPETENCIES-POPUP.KOR'),
        id: 'KOR'
      });
    }

    this.body = document.getElementsByTagName('body')[0];
    this.setBodyHeight(true);
    this.init();
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  init() {
    this.prepareCompetencies();
    this.selectCompetenceType(this.structureState ? this.competenceTypes[0] : this.competenceTypes[2]);
    this.updatePassedItems();
    setTimeout(() => {
      this.setTextareaWidth();
    });
  }

  mapCodes() {
    this.codes = _flow([
      _filter({type_id: '5'}),
      _map((item: CompetenceExtended) => Number(item.code.split('-')[1]))
    ])(this.filteredCompetencies);

    for (let i = 0; i < this.codes[this.codes.length - 1]; i++) {
      if (this.codes[i] !== i + 1) {
        this.codes.splice(i, 0, 0);
      }
    }
  }

  setTextareaWidth() {
    const button = document.getElementsByClassName('button')[0];
    this.textareaWidth = Boolean(button) ? button.clientWidth : 0;
  }

  setBodyHeight(init = false): void {
    this.bodyHeight = this.body.scrollHeight;

    if (!init) {
      this.setTextareaWidth();
    }
  }

  prepareCompetencies() {
    const competenceIds = this.structureState ? _flow([
      _filter((competence: CompetenceExtended) => {
        const itemIds = _flow([
          _map('id'),
          _compact,
          _uniq
        ])(competence.custom_plan_items);

        return _includes(this.customPlanItem.id)(itemIds);
      }),
      _map('competence_id'),
      _compact,
      _uniq
    ])(this.studyPlan.competencies) : null;

    this.competencies = _flow([
      _filter((competence: CompetenceExtended) => {
        if (this.studyPlan.education_level_id === '5' && competence.type_id === '5') {
          return this.educationTrajectoryId === 'common' ? !Boolean(competence.specialization_id) : competence.specialization_id === this.educationTrajectoryId;
        }

        return true;
      }),
      _map<CompetenceExtended, CompetenceExtended>((competence: CompetenceExtended) => {
        this.showSpecialitySelect = this.showSpecialitySelect || Boolean(competence.speciality_id);
        competence.selected = this.structureState ? _includes(competence.competence_id)(competenceIds) : false;
        competence.hint = _flow([
          _filter((item: any) => this.educationTrajectoryId === 'common' ? !Boolean(item.specialization_id) : item.specialization_id === this.educationTrajectoryId),
          _map('description')
        ])(competence.custom_plan_items).join('\n');

        return competence;
      })
    ])(this.studyPlan.competencies);

    if (this.masterLevel) {
      const KORIds = _flow([
        _filter((result: EducationResult) => {
          const itemIds = _flow([
            _map('id'),
            _compact,
            _uniq
          ])(result.custom_plan_items);

          return _includes(this.customPlanItem.id)(itemIds);
        }),
        _map('education_result_id'),
        _compact,
        _uniq
      ])(this.studyPlan.education_results_lp);

      this.educationResults = _flow([
        _filter((result: EducationResult) => {
          if (this.studyPlan.education_level_id === '5') {
            return this.educationTrajectoryId === 'common' ? !Boolean(result.specialization_id) : result.specialization_id === this.educationTrajectoryId;
          }

          return true;
        }),
        _map<any, any>((result: any) => {
          result.selected = this.structureState ? _includes(result.education_result_id)(KORIds) : false;
          result.hint = _map('description')(result.custom_plan_items).join('\n');

          return result;
        })
      ])(this.studyPlan.education_results_lp);
    }
  }

  selectCompetenceType(type: CompetenceType) {
    this.competenceTypeId = type.id;
    this.competenceTypeName = type.name;
    if (type.id !== 'KOR') {
      this.filteredCompetencies = _flow([
        _filter<CompetenceExtended>({type_id: this.competenceTypeId}),
        _sortBy((item: CompetenceExtended) => Number(item.code.split('-')[1]))
      ])(this.competencies);

      if (type.id === '5') {
        this.mapCodes();
      }
    }
  }

  filterCompetencies(event) {
    this.filteredCompetencies = _flow([
      _filter<CompetenceExtended>((item) => {
        return item.type_id === this.competenceTypeId && item.speciality_id === event;
      }),
      _sortBy((item: CompetenceExtended) => Number(item.code.split('-')[1]))
    ])(this.competencies);
  }

  checkCompetence(competence: any) {
    this.updatePassedItems();
  }

  isOverflow(item) {
    if (this.competenciesElem) {
        const itemNode = this.competenciesElem.nativeElement.querySelector(`#item-${item.id} .ngx-ellipsis-inner`);
        if (itemNode) {
          return  itemNode.innerText.slice(-3) === '...';
        }
    }

    return false;
  }

  addCompetence() {
    let code: number;
    const index = this.codes.indexOf(0);

    if (index > -1) {
      code = index + 1;
      this.codes.splice(index, 1, code);
    } else {
      code = this.codes.length + 1;
      this.codes.push(code);
    }

    const dummyCompetence: CompetenceExtended = {
      code: `ПК-${code}`,
      id: null,
      selected: true,
      description: '',
      competence_id: null,
      custom_plan_items: [],
      type: null,
      type_id: '5',
      specialization_id: this.educationTrajectoryId
    };

    this.competencies.push(dummyCompetence);
    this.selectCompetenceType(_find<CompetenceType>({id: this.competenceTypeId})(this.competenceTypes));
  }

  updatePassedItems() {
    this.passedItems = this.structureState ? [
      {
        title: this.translate.instant('STUDY-PLAN.ADD-COMPETENCIES-POPUP.DISCIPLINE') + ':',
        value: this.customPlanItem.description
      }
    ] : [];

    if (this.structureState) {
      _forEach((type: CompetenceType) => {
        if (type.id === 'KOR') {
          const KORResult = _flow([
            _filter('selected'),
            _sortBy((item: any) => Number(item.code_link.split('-')[1]))
          ])(this.educationResults);

          if (Boolean(KORResult.length)) {
            this.passedItems.push({
              title: `${type.name}:`,
              value: _map('code_link')(KORResult).join(', ')
            });
          }

          return;
        }

        const result = _flow([
          _filter({type_id: type.id, selected: true}),
          _sortBy((item: CompetenceExtended) => Number(item.code.split('-')[1]))
        ])(this.competencies);

        if (Boolean(result.length)) {
          if (type.id === '2' && this.studyPlan.speciality && this.studyPlan.speciality.length) {
            this.passedItems.push({
              title: `${type.name}:`,
              value: _map(($item: any) => {
                const speciality = _find<any>({ speciality_id: $item.speciality_id })(this.studyPlan.speciality);

                return `${$item.code} (${speciality ? speciality.speciality_code : '-'})`;
              })(result).join(', ')
            });
          } else {
            this.passedItems.push({
              title: `${type.name}:`,
              value: _map('code')(result).join(', ')
            });
          }
        }
      })(this.competenceTypes);
    } else {
      this.passedItems.push({
        title: this.translate.instant('STUDY-PLAN.ADD-COMPETENCIES-POPUP.PK'),
        value: _map('code')(this.filteredCompetencies).join(', ')
      });
    }

    this.calculateMargin();
  }

  calculateMargin() {
    const passedNumber = this.passedItems.length;
    // @ts-ignore
    const initial = this.structureState ? 200 : 269;

    // @ts-ignore
    this.margin = initial - 16 * passedNumber;
  }

  confirm() {
    if (this.structureState) {
      if (this.masterLevel) {
        this.close.emit(
          [
            {
              custom_plan_item_id: this.customPlanItem.id,
              competence_ids: _flow([
                _filter('selected'),
                _map('competence_id'),
                _compact,
                _uniq
              ])(this.competencies),
              competencies: _flow([
                _filter((item: CompetenceExtended) => {
                  return item.type_id === '5' && item.selected;
                }),
                _map((item: CompetenceExtended) => {
                  const {competence_id, type_id, code, description} = item;
                  return {id: competence_id, type_id, code, description};
                })
              ])(this.competencies)
            },
            {
              custom_plan_item_id: this.customPlanItem.id,
              educationresult_ids: _flow([
                _filter('selected'),
                _map('education_result_id')
              ])(this.educationResults)
            }
          ]);
      } else {
        this.close.emit({
          custom_plan_item_id: this.customPlanItem.id,
          competence_ids: _flow([
            _filter('selected'),
            _map('competence_id'),
            _compact,
            _uniq
          ])(this.competencies),
          competencies: _flow([
            _filter((item: CompetenceExtended) => {
              return item.type_id === '5' && item.selected;
            }),
            _map((item: CompetenceExtended) => {
              const {competence_id, type_id, code, description} = item;
              return {id: competence_id, type_id, code, description};
            })
          ])(this.competencies)
        });
      }
    } else {
      this.close.emit(_map((competence: CompetenceExtended) => {
        // @ts-ignore
        const {type_id, competence_id, eng_description, description, code, specialization_id} = competence;
        return {
          specialization_id: specialization_id === 'common' ? null : specialization_id,
          learn_plan_id: this.studyPlan.id,
          competence_type_id: type_id,
          id: competence_id,
          code,
          description,
          eng_description
        };
      })(this.filteredCompetencies));
    }
  }

  onClose() {
    this.close.emit(null);
  }

  selectLanguage(fieldName: string): void {
    this.languagePrefix = fieldName;
  }

  isDisabledAddButton() {
    const dummyCompetence = this.filteredCompetencies.find((competence: any) => !competence.id);

    return !dummyCompetence || dummyCompetence && !dummyCompetence.description;
  }
}
