<div class="hse-input__container" [style.width.px]="width">
	<input id="{{inputId}}"
	       #input
	       class="hse-input__input"
		   [class.icon-mode]="currencyMode"
	       [class.hse-input__input--not-empty]="checkModel() && !disablePlaceholderAnimation"
	       [class.border-without-label]="disablePlaceholderAnimation"
	       [class.hide-controls]="hideControls"
	       [class.custom-invalid]="customInvalid"
	       [class.custom-info]="infoState"
	       [(ngModel)]="model"
	       [type]="type"
	       maxlength="{{maxLength}}"
	       (focus)="emitFocusEvent()"
	       (blur)="emitBlurEvent()"
	       (keypress)="emitKeyPressEvent($event)"
	       (keydown)="emitKeyDownEvent($event)"
	       [disabled]="disabled"
	       [placeholder]="placeholder">
	<span class="hse-input__label-container" *ngIf="!disablePlaceholderAnimation">
    <label for="{{inputId}}" class="hse-input__label">
      {{placeholder}}
    </label>
  </span>
	<hse-icon [name]="'inputInfo'"
	          *ngIf="infoState"
	          matTooltip="{{infoMessage}}"
	          class="hse-input__icon"></hse-icon>

	<hse-icon [name]="'lightning'"
	          *ngIf="valueChanged"
	          class="hse-input__lightning"></hse-icon>

	<hse-icon class="hse-input__ruble" name="ruble" *ngIf="currencyMode"></hse-icon>
</div>
