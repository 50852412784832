import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { filter as _filter, find as _find, map as _map, forEach as _forEach, flow as _flow, includes as _includes, uniq as _uniq } from 'lodash/fp';
import { AddConfig } from '../add-element/add-element.component';
import { CatalogueService } from '@backend/kosuip/catalogue/catalogue.service';
import { HseAlertService } from 'src/app/core/hse-alert/hse-alert.service';
import _getNumEndings from '@tools/fp/getNumEnding';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { ElementType } from '@backend/kosuip/catalogue/interfaces/element-type.interface';
import { SubjectArea } from '@backend/kosuip/catalogue/interfaces/subject-area.interface';
import { Directory } from '@backend/kosuip/catalogue/interfaces/directory.interface';
import { EducationStandardStructureBackend } from '@backend/kosuip/catalogue/interfaces/education-standard-structure.interface';
import { ElementTypes } from '@backend/kosuip/catalogue/interfaces/element-types.enum';

export interface ElementTypeExtended extends ElementType {
  selected?: boolean;
  showRadio?: boolean;
  required?: {text: string, value: boolean, selected: boolean}[];
  min?: string;
  max?: string;
  invalid?: boolean;
  disabled?: boolean;
  actualCount?: number;
}

@Component({
  selector: 'hse-add-directory',
  templateUrl: './add-directory.component.html',
  styleUrls: ['../add-element/add-element.component.scss']
})
export class AddDirectoryComponent implements OnInit {

  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  @Input() level: number;
  @Input() config?: AddConfig;
  @Input() edit = false;
  @Input() studyPlan = false;
  @Input() createNewDirectoryEvent: Subject<number> = null;

  public filteredSubjectFields: any[] = [];
  public subjectFields: SubjectArea[] = [];

  public progress;
  public translations = {
    EDIT: '',
    ADD: '',
    ACCEPT: '',
    NEXT: '',
    FROM: '',
    TO: ''
  };

  public optional = false;
  public disableAdds = false;
  public endings;
  public directoryTable;
  public directoryFilters = {
    page_num: 1,
    page_limit: 60,
    full_description: '',
    complete_match: false
  };
  private directoriesTotalPages: number = null;
  public elementTypes: ElementTypeExtended[] = [];
  public directories: Directory[] = [];
  public filteredElementTypes: ElementTypeExtended[] = [];
  public path: string[];
  public passedItems: {title: string, value: string, path: string}[] = [];

  bodyHeight = 0;
  margin = 200;
  body: any;

  searchString = '';

  public iterationIndex = 0;
  public disableTypesButton = false;
  public isAvailableToBack = false;
  public isAvailableToForward = false;

  constructor(private catalogue: CatalogueService,
              private translate: TranslateService,
              private alert: HseAlertService) {
  }

  ngOnInit(): void {
    if (Boolean(this.createNewDirectoryEvent)) {
      this.createNewDirectoryEvent
        .subscribe((value: any) => {
          this.directoryFilters.full_description = null;
          this.selectDirectory(value);
          this.setDirectory();
        });
    }
    this.translations.EDIT = this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.ADD-CATALOGUE.EDIT');
    this.translations.ADD = this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.ADD-CATALOGUE.ADD');
    this.translations.ACCEPT = this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.ADD-CATALOGUE.ACCEPT');
    this.translations.NEXT = this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.ADD-CATALOGUE.NEXT');
    this.translations.FROM = this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.ADD-CATALOGUE.FROM');
    this.translations.TO = this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.ADD-CATALOGUE.TO');

    this.endings = [
      this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.ELEMENT-COUNTING.EL-1'),
      this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.ELEMENT-COUNTING.EL-2'),
      this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.ELEMENT-COUNTING.EL-3')
    ];

    this.progress = {
      directoryFromCatalogue: {
        value: null,
        passed: false,
        label: '',
        title: ''
      },
      subjectField: {
        value: null,
        passed: false,
        label: '',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.CATALOGUE-PROGRESS.AREAS') + ':'
      },
      engSubjectField: {
        value: null,
        passed: false,
        label: '',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.CATALOGUE-PROGRESS.ENG-AREAS') + ':'
      },
      duration: {
        value: null,
        passed: false,
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.CATALOGUE-PROGRESS.CREDITS') + ':',
        label: ''
      },
      name: {
        value: null,
        passed: false,
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.CATALOGUE-PROGRESS.NAME') + ':',
        label: ''
      },
      englishName: {
        value: null,
        passed: false,
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.CATALOGUE-PROGRESS.ENG-NAME') + ':',
        label: ''
      },
      elementType: {
        passed: false,
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.CATALOGUE-PROGRESS.TYPES') + ':',
        label: '',
        value: []
      },
      additional: {
        optional: false,
        check: true,
        min: null,
        max: null,
        label: '',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.CATALOGUE-PROGRESS.PROPERTIES') + ':',
        passed: false
      }
    };

    this.directoryTable = [
      {
        name: 'id',
        title: 'id',
        width: 100
      },
      {
        name: 'description',
        title: this.translate.instant('DIRECTORY-NAMES.LIST.NAME'),
        width: 350
      },
      {
        name: 'eng_description',
        title: this.translate.instant('DIRECTORY-NAMES.LIST.ENG-NAME-SHORT'),
        width: 300
      }
    ];

    this.body = document.getElementsByTagName('body')[0];
    this.setBodyHeight();

    this.loadData();
  }

  async loadData() {
    try {
      this.subjectFields = await this.catalogue.getSubjectArea();
      this.filterFields('');
      const elementTypes = await this.catalogue.getElementTypes();

      this.filteredElementTypes = _flow([
        _filter((item: ElementTypeExtended) => {
          const type = _find<ElementType>({id: item.id})(this.config.properties.elementTypes);
          return this.edit ? Boolean(type) && !type.is_system_element : Boolean(_find({id: item.id})(this.config.properties.parent.elementTypes));
        }),
        _map((type: ElementTypeExtended) => {
          type.selected = false;
          type.showRadio = false;
          type.disabled = false;
          type.invalid = false;
          type.actualCount = 0;
          type.required = [
            {
              text: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.REQUIRED.YES'),
              value: true,
              selected: true
            },
            {
              text: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.REQUIRED.NO'),
              value: false,
              selected: false
            }
          ];

          if (this.edit) {
            type.actualCount = Boolean(this.config.item.children.length)
              ? _filter({elementTypeId: type.id})(this.config.item.children).length
              : 0;
          }

          return type;
        })
      ])(elementTypes);

      if (this.edit) {
        this.fillToEdit();
      } else {
        this.startFilling();
      }
    } catch (e) {
      console.error(e);
    }
  }

  fillToEdit() {
    if (Boolean(_find({id: '00000000001'})(this.config.properties.elementTypes))) {
      const labelParts = [
        `${this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-1')}`,
        '-',
        `${this.progress.additional.check
          ? this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-YES')
          : this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-NO')
        }`,
        '\n',
        `${this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-2')}`,
        `${this.progress.additional.min || '-'}`,
        `${this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-3')}`,
        `${this.progress.additional.max || '-'}`,
        '\n',
        `${this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.ADD-CATALOGUE.OPTIONAL-DIRECTORY')}`,
        '-',
        `${this.progress.additional.optional
          ? this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-YES')
          : this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-NO')
        }`
      ];

      this.path = ['directoryFromCatalogue', 'duration', 'elementType', 'additional', 'final'];

      this.progress.name.passed = true;
      this.progress.name.value = this.config.item.description;
      this.progress.name.label = this.config.item.description;

      this.progress.englishName.passed = true;
      this.progress.englishName.value = this.config.item.eng_name;
      this.progress.englishName.label = this.config.item.eng_name;

      this.progress.directoryFromCatalogue.value = this.config.item.catalogue_directory_id;
      this.progress.directoryFromCatalogue.passed = true;

      this.progress.duration.passed = true;
      this.progress.duration.value = this.config.item.basic_credits;
      this.progress.duration.label = this.config.item.basic_credits.toString();

      this.progress.additional.check = this.config.item.is_calculated;
      this.progress.additional.min = this.config.item.group_capacity_from.toString();
      this.progress.additional.max = this.config.item.group_capacity_to.toString();
      this.progress.additional.optional = this.config.item.subject_type_id === '1';
      this.progress.additional.label = labelParts.join(' ');
      this.progress.additional.passed = true;

      this.optional = !Boolean(this.progress.duration.value);

      const typeIds = _map('element_type_id')(this.config.children_structures);
      const alreadyAddedTypeIds = _flow([
        _map('elementTypeId'),
        _uniq
      ])(this.config.item.children);

      this.filteredElementTypes = _map<ElementTypeExtended, ElementTypeExtended>((type: ElementTypeExtended) => {
        const structure = _find<EducationStandardStructureBackend>({element_type_id: type.id})(this.config.children_structures);
        type.selected = Boolean(structure) && _includes(type.id)(typeIds);
        if (type.selected) {
          type.min = structure.min.toString();
          type.max = structure.max.toString();
          type.disabled = _includes(type.id)(alreadyAddedTypeIds);
          type.invalid = false;
          _forEach((req: any) => {
            req.selected = (req.value && Boolean(structure.min)) || (!req.value && !Boolean(structure.min));
          })(type.required);
        }

        return type;
      })(this.filteredElementTypes);

      this.setElementTypes();
      this.updatePassedItems();
    } else if (Boolean(_find({id: '00000000002'})(this.config.properties.elementTypes))) {
      this.path = ['subjectField', 'duration', 'additional', 'final'];

      this.progress.subjectField.passed = true;

      const subjectField = _find<SubjectArea>({id: this.config.item.subject_area_id})(this.filteredSubjectFields);
      const labelParts = [
        `${this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-1')}`,
        '-',
        `${this.progress.additional.check
          ? this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-YES')
          : this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-NO')
        }`,
        '\n',
        `${this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-2')}`,
        `${this.progress.additional.min || '-'}`,
        `${this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-3')}`,
        `${this.progress.additional.max || '-'}`,
        '\n',
        `${this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.ADD-CATALOGUE.OPTIONAL-DIRECTORY')}`,
        '-',
        `${this.progress.additional.optional
          ? this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-YES')
          : this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-NO')
        }`
      ];

      this.progress.subjectField.value = subjectField.id;
      this.progress.subjectField.label = subjectField.name;

      this.progress.engSubjectField.value = subjectField.eng_description;
      this.progress.engSubjectField.label = subjectField.eng_description || '-';
      this.progress.engSubjectField.passed = true;

      this.progress.duration.passed = true;
      this.progress.duration.value = this.config.item.basic_credits;
      this.progress.duration.label = this.config.item.basic_credits.toString();

      this.progress.additional.check = this.config.item.is_calculated;
      this.progress.additional.min = this.config.item.group_capacity_from.toString();
      this.progress.additional.max = this.config.item.group_capacity_to.toString();
      this.progress.additional.optional = this.config.item.subject_type_id === '1';
      this.progress.additional.label = labelParts.join(' ');
      this.progress.additional.passed = true;

      this.optional = !Boolean(this.progress.duration.value);

      this.updatePassedItems();
    } else if (Boolean(_find({id: '00000000019'})(this.config.properties.elementTypes))) {
      this.path = ['name', 'englishName', 'duration', 'final'];

      this.progress.name.passed = true;
      this.progress.name.value = this.config.item.description;
      this.progress.name.label = this.config.item.description;

      this.progress.englishName.passed = true;
      this.progress.englishName.value = this.config.item.eng_name;
      this.progress.englishName.label = this.config.item.eng_name;

      this.progress.duration.passed = true;
      this.progress.duration.value = this.config.item.basic_credits;
      this.progress.duration.label = this.config.item.basic_credits.toString();

      this.updatePassedItems();
    } else {
      const labelParts = [
        `${this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-1')}`,
        '-',
        `${this.progress.additional.check
          ? this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-YES')
          : this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-NO')
        }`,
        '\n',
        `${this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-2')}`,
        `${this.progress.additional.min || '-'}`,
        `${this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-3')}`,
        `${this.progress.additional.max || '-'}`,
        '\n',
        `${this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.ADD-CATALOGUE.OPTIONAL-DIRECTORY')}`,
        '-',
        `${this.progress.additional.optional
          ? this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-YES')
          : this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-NO')
        }`
      ];

      // default
      this.path = ['name', 'englishName', 'duration', 'elementType', 'additional', 'final'];

      this.progress.name.passed = true;
      this.progress.name.value = this.config.item.description;
      this.progress.name.label = this.config.item.description;

      this.progress.englishName.passed = true;
      this.progress.englishName.value = this.config.item.eng_name;
      this.progress.englishName.label = this.config.item.eng_name;

      this.progress.duration.passed = true;
      this.progress.duration.value = this.config.item.basic_credits;
      this.progress.duration.label = this.config.item.basic_credits.toString();

      this.progress.additional.check = this.config.item.is_calculated;
      this.progress.additional.min = this.config.item.group_capacity_from.toString();
      this.progress.additional.max = this.config.item.group_capacity_to.toString();
      this.progress.additional.optional = this.config.item.subject_type_id === '1';
      this.progress.additional.label = labelParts.join(' ');
      this.progress.additional.passed = true;

      this.optional = !Boolean(this.progress.duration.value);

      const typeIds = _map('element_type_id')(this.config.children_structures);
      const alreadyAddedTypeIds = _flow([
        _map('elementTypeId'),
        _uniq
      ])(this.config.item.children);

      this.filteredElementTypes = _map<ElementTypeExtended, ElementTypeExtended>((type: ElementTypeExtended) => {
        const structure = _find<EducationStandardStructureBackend>({element_type_id: type.id})(this.config.children_structures);
        type.selected = Boolean(structure) && _includes(type.id)(typeIds);
        if (type.selected) {
          type.min = structure.min.toString();
          type.max = structure.max.toString();
          type.disabled = _includes(type.id)(alreadyAddedTypeIds);
          type.invalid = false;
          _forEach((req: any) => {
            req.selected = (req.value && Boolean(structure.min)) || (!req.value && !Boolean(structure.min));
          })(type.required);
        }

        return type;
      })(this.filteredElementTypes);

      this.setElementTypes();
      this.updatePassedItems();
    }

    this.goToIteration('final');
  }

  startFilling() {
    if (Boolean(this.config.element_type_id) && this.config.element_type_id === '00000000019') {
      switch (this.config.education_level_id) {
        case '3':
          this.path = ['name', 'englishName', 'duration', 'final'];

          break;
        default:
          this.path = ['name', 'englishName', 'duration', 'final'];

          break;
      }
    } else if (Boolean(_find({id: '00000000002'})(this.config.properties.elementTypes))) {
      switch (this.config.education_level_id) {
        default:
          this.path = ['subjectField', 'duration', 'additional', 'final'];

          break;
      }
    } else if (Boolean(_find({id: '00000000001'})(this.config.properties.elementTypes))) {
      this.loadDirectories();
      switch (this.config.education_level_id) {
        case '3':
          this.path = ['directoryFromCatalogue', 'duration', 'elementType', 'final'];

          break;
        default:
          this.path = ['directoryFromCatalogue', 'duration', 'elementType', 'additional', 'final'];

          break;
      }
    } else {
      // default
      this.loadDirectories();
      switch (this.config.education_level_id) {
        default:
          this.path = ['directoryFromCatalogue', 'duration', 'elementType', 'additional', 'final'];

          break;
      }
    }
  }

  setAdditionalInfo() {
    const labelParts = [
      `${this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-1')}`,
      '-',
      `${this.progress.additional.check ? this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-YES') : this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-NO')}`,
      '\n',
      `${this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-2')}`,
      `${this.progress.additional.min || '-'}`,
      `${this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-3')}`,
      `${this.progress.additional.max || '-'}`,
      '\n',
      `${this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.ADD-CATALOGUE.OPTIONAL-DIRECTORY')}`,
      '-',
      `${this.progress.additional.optional ? this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-YES') : this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-NO')}`
    ];

    this.progress.additional.passed = true;
    this.progress.additional.label = labelParts.join(' ');

    this.updatePassedItems();
    this.nextIteration();
  }

  setBodyHeight(): void {
    this.bodyHeight = this.body.scrollHeight;
  }

  onClose() {
    this.close.emit(null);
  }

  goToIteration(path: string) {
    this.updatePassedItems();
    if (['name', 'englishName'].indexOf(path) > -1) {
      this.iterationIndex = this.path.indexOf('directoryFromCatalogue');
    } else {
      this.iterationIndex = this.path.indexOf(path);
    }
    this.checkAvailabilityToShift();
    this.calculateMargin();
  }

  nextIteration(mode = null) {
    if (mode === 'confirm') {
      return this.addElement();
    }

    this.updatePassedItems();
    this.iterationIndex++;
    this.checkAvailabilityToShift();
    this.calculateMargin();
  }

  selectDirectory(directory: Directory): void {
    this.progress.directoryFromCatalogue.value = directory.id;
    this.progress.directoryFromCatalogue.passed = true;
    this.progress.name.value = (this.progress.name.label = directory.description);
    this.progress.englishName.value = (this.progress.englishName.label = directory.eng_description);
  }

  setDirectory(): void {
    this.progress.name.passed = true;
    this.progress.englishName.passed = true;
    if (this.edit) {
      this.goToIteration('final');
    } else {
      this.nextIteration();
    }
  }

  async loadDirectories(more = false): Promise<void> {
    try {
      this.directoryFilters.page_num = more ? this.directoryFilters.page_num + 1 : 1;
      const params: any = {};

      for (const key in this.directoryFilters) {
        if (key === 'complete_match' || Boolean(this.directoryFilters[key])) {
          params[key] = this.directoryFilters[key];
        }
      }
      if (more && this.directoryFilters.page_num > this.directoriesTotalPages) {
        return;
      }

      const directoriesResponse = await this.catalogue.getDirectories(params);
      if (more) {
        this.directories = [...this.directories, ...directoriesResponse.body];
      } else {
        this.directories = directoriesResponse.body;
        this.directoriesTotalPages = Number(directoriesResponse.headers.get('totalpages'));
      }
    } catch (e) {
      console.error(e);
    }
  }

  addElement() {
    let directory: any = {};

    // это раздел "Государственные экзамены", element_type_id: 19
    if (this.config.element_type_id && this.config.element_type_id === '00000000019') {
      directory = {
        structure_id: this.config.structure_id,
        custom_plan_item: [
          {
            is_calculated: this.progress.additional.check,
            group_capacity_from: Number(this.progress.additional.min || '0'),
            group_capacity_to: Number(this.progress.additional.max || '0'),
            description: this.progress.name.value,
            eng_description: this.progress.englishName.value,
            subject_class_id: _find<ElementType>({id: ElementTypes.SECTION_STATE_EXAMS})(this.config.properties.elementTypes).subject_class_id,
            basic_credits: this.progress.duration.value || 0,
            id: this.edit ? this.config.item.id : null,
            parent_id: this.config.properties.parent.id
          }
        ]
      };

      if (!this.progress.additional.check) {
        directory.custom_plan_item[0].sum_credits = Number(this.progress.duration.value) || 0;
      }

      this.close.emit(directory);

      return;
    } else if (Boolean(_find({id: '00000000002'})(this.config.properties.elementTypes))) {
      directory = {
        structure_id: this.config.structure_id,
        custom_plan_item: [
          {
            description: this.progress.subjectField.label,
            eng_description: this.progress.engSubjectField.value,
            is_calculated: this.progress.additional.check,
            group_capacity_from: Number(this.progress.additional.min || '0'),
            group_capacity_to: Number(this.progress.additional.max || '0'),
            subject_class_id: this.config.properties.elementTypes[0].subject_class_id,
            basic_credits: Number(this.progress.duration.value) || 0,
            id: this.edit ? this.config.item.id : null,
            subject_area_id: this.progress.subjectField.value,
            parent_id: this.config.properties.parent.id
          }
        ]
      };
    } else {
      let structure;
      if (!this.edit) {
        structure = _map((type: any) => {
          const elementType = this.edit ? _find({id: type.id})(this.config.item.elementTypes) : null;
          const str = Boolean(elementType)
            ? _find<EducationStandardStructureBackend>({element_type_id: elementType.id})(this.config.children_structures)
            : null;

          return {element_type_id: type.id, id: Boolean(elementType) && Boolean(str) ? str.id : null, is_parent_blocked: false, min: Number(type.min), max: Number(type.max)};
        })(this.progress.elementType.value);
      } else {
        const children = _flow(
          _filter((str: EducationStandardStructureBackend) => Boolean(_find<ElementTypeExtended>({id: str.element_type_id})(this.progress.elementType.value))),
          _map((str: EducationStandardStructureBackend) => {
            const type = _find<ElementTypeExtended>({id: str.element_type_id})(this.progress.elementType.value);

            if (str.element_type_id === '00000000001') {
              if (!Boolean(str.custom_plan_item.length)) {
                return {element_type_id: str.element_type_id, id: str.id, is_parent_blocked: false, min: Boolean(type) ? Number(type.min) : 0, max: Boolean(type) ? Number(type.max) : 0};
              }

              return {element_type_id: str.element_type_id, id: str.id, is_parent_blocked: false, min: Number(str.min), max: Number(str.max)};
            }

            return {element_type_id: str.element_type_id, id: str.id, is_parent_blocked: false, min: Number(type.min), max: Number(type.max)};
          })
        )(this.config.children_structures);
        const types = _flow([
          _filter((type: ElementTypeExtended) => {
            return !Boolean(_find({element_type_id: type.id})(children));
          }),
          _map((type: ElementTypeExtended) => {
            return {element_type_id: type.id, id: null, is_parent_blocked: false, min: Number(type.min), max: Number(type.max)};
          })
        ])(this.progress.elementType.value);


        structure = _filter((item: any) => {
          return Boolean(_find({id: item.element_type_id})(this.progress.elementType.value));
        })([...children, ...types]);
      }
      directory = {
        element_type_id: '00000000001',
        is_parent_blocked: false,
        min: 1,
        max: 1,
        structure,
        custom_plan_item: [
          {
            subject_type_id: this.progress.additional.optional ? '1' : null,
            description: this.progress.name.value,
            eng_description: this.progress.englishName.value,
            catalogue_directory_id: this.progress.directoryFromCatalogue.value,
            is_calculated: this.progress.additional.check,
            group_capacity_from: Number(this.progress.additional.min || '0'),
            group_capacity_to: Number(this.progress.additional.max || '0'),
            subject_class_id: '6',
            id: this.edit ? this.config.item.id : null,
            basic_credits: Number(this.progress.duration.value) || 0,
            parent_id: this.config.properties.parent.id
          }
        ]
      };
      if (this.edit) {
        directory.structure_id = this.config.structure_id;
      } else {
        directory.parent_id = this.config.properties.parent.structure_id;
      }
    }

    if (!this.progress.additional.check) {
      directory.custom_plan_item[0].sum_credits = Number(this.progress.duration.value) || 0;
    }

    if (this.studyPlan) {
      directory.learn_plan_id = this.config.standart_id;
    } else {
      directory.standart_id = this.config.standart_id;
    }

    this.close.emit(directory);
  }

  goBack() {
    if (!this.isAvailableToBack) {
      return;
    }

    this.goToIteration(this.path[this.iterationIndex - 1]);

    this.checkAvailabilityToShift();
  }

  goForward() {
    if (!this.isAvailableToForward) {
      return;
    }

    this.goToIteration(this.path[this.iterationIndex + 1]);

    this.checkAvailabilityToShift();
  }

  checkAvailabilityToShift() {
    this.isAvailableToBack = this.iterationIndex > 0;
    this.isAvailableToForward = this.iterationIndex < this.path.length - 1 && this.progress[this.path[this.iterationIndex]].passed;
  }

  calculateMargin() {
    const passedNumber = this.passedItems.length;

    // @ts-ignore
    this.margin = 216 - 16 * passedNumber;
  }

  filterFields(event) {
    this.filteredSubjectFields = _filter((option: any) => {
      return option.name.toUpperCase().indexOf(event.toUpperCase()) !== -1;
    })(this.subjectFields);
  }

  selectOption(item: ElementTypeExtended, index) {
    if (this.optional && item.required[index].value) {
      return;
    }

    if (!item.required[index].value) {
      item.min = '';
    }
    _forEach((option: any) => {
      option.selected = false;
    })(item.required);
    item.required[index].selected = true;

    this.validate(item);
  }

  selectSubjectField(payload) {
    const field = _find<SubjectArea>({id: payload.value})(this.filteredSubjectFields);
    this.progress.engSubjectField.value = field.eng_description;
    this.progress.engSubjectField.label = field.eng_description || '-';
    this.progress.engSubjectField.passed = true;

    this.selectValue(payload);
  }

  selectValue({title, value}) {
    this.progress[this.path[this.iterationIndex]].label = title;
    this.progress[this.path[this.iterationIndex]].value = value;

    this.setValue();
  }

  setValue() {
    if (['duration', 'name', 'englishName'].indexOf(this.path[this.iterationIndex]) > -1) {
      this.progress[this.path[this.iterationIndex]].label = this.progress[this.path[this.iterationIndex]].value || 0;
    }

    this.progress[this.path[this.iterationIndex]].passed = true;

    this.updatePassedItems();

    if (this.edit) {
      this.goToIteration('final');
    } else {
      this.nextIteration();
    }
  }

  select(option: any, key: string) {
    this[key] = _map((item: any) => {
      item.selected = false;

      return item;
    })(this[key]);

    option.selected = true;
  }

  checkTypesDisable() {
    return this.disableTypesButton || !_find('selected')(this.filteredElementTypes);
  }

  setElementTypes() {
    this.progress.elementType.passed = true;
    const result = [];

    this.progress.elementType.value = _flow([
      _filter('selected'),
      _map((item: ElementTypeExtended) => {
        return {name: item.name, required: _find<any>('selected')(item.required).value, min: item.min || 0, max: item.max || 0, id: item.id};
      })
    ])(this.filteredElementTypes);

    _map((item: ElementTypeExtended) => {
      if (item.selected) {
        const requiredNamePart = _find<any>('selected')(item.required).value
          ? this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.REQUIRED.YES')
          : this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.REQUIRED.NO');

        const nameParts = [
          `${item.name}`,
          '-',
          `${requiredNamePart}`,
          '-',
          `${this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-5')}`,
          `${item.min || '-'}`,
          `${this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-1-4')}`,
          `${item.max || '-'}`
        ];

        result.push(nameParts.join(' '));
      }
    })(this.filteredElementTypes);

    this.progress.elementType.label = result.join('\n');

    if (!this.edit) {
      this.nextIteration();
    } else {
      this.goToIteration('final');
    }
  }

  validate(item: ElementTypeExtended) {
    const max = Boolean(item.max) ? Number(item.max) : 0;
    const min = Boolean(item.min) ? Number(item.min) : 0;
    item.invalid = (item.selected && !(!Boolean(max) || max >= min && max >= item.actualCount));

    this.disableTypesButton = Boolean(_find('invalid')(this.filteredElementTypes))
    || (Boolean(_find((req: any) => req.selected && req.value)(item.required)) && !Boolean(min));
  }

  updatePassedItems() {
    this.passedItems = [];

    for (const key of Object.keys(this.progress)) {
      if (this.progress[key].passed && key !== 'directoryFromCatalogue') {
        this.passedItems.push({
          title: this.progress[key].title,
          value: this.progress[key].label,
          path: key
        });
      }
    }
  }

  checkOptionality(value: boolean) {
    if (!value) {
      return;
    }

    this.filteredElementTypes = _map((type: ElementTypeExtended) => {
      type.required = [
        {
          text: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.REQUIRED.YES'),
          value: true,
          selected: false
        },
        {
          text: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.REQUIRED.NO'),
          value: false,
          selected: true
        }
      ];

      return type;
    })(this.filteredElementTypes);

    this.progress.duration.value = null;
    this.setValue();
  }

  disableCredits(): boolean {
    return this.optional || !Boolean(this.progress.duration.value);
  }

  checkMaxOfType(type: ElementTypeExtended) {
    if (!this.edit) {
      return;
    } else {
      const max = Boolean(type.max) ? Number(type.max) : 0;
      setTimeout(() => {
        if (Boolean(max) && max < type.actualCount) {
          const errorMessage = [
            `${this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-2-1')}`,
            `${type.actualCount}`,
            `${_getNumEndings(this.endings)(type.actualCount)}`,
            `${this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.HINT-2-2')}`
          ].join(' ');

          this.alert.error(errorMessage);
        }
      }, 0);
    }
  }

  validateAdds() {
    this.disableAdds = (Boolean(this.progress.additional.max)
      && Boolean(this.progress.additional.min)
      && (Number(this.progress.additional.max) < Number(this.progress.additional.min)))
      || (this.edit && this.config.directoriesRequired && Number(this.progress.additional.max) < 1);
  }
}
