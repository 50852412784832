import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { map as _map, range as _range } from 'lodash/fp';
import _int from '@tools/fp/int';

@Component({
  selector: 'hse-pagination',
  templateUrl: './hse-pagination.component.html',
  styleUrls: ['./hse-pagination.component.scss']
})
export class HsePaginationComponent implements OnInit, OnChanges {
  @Input() totalPages: number;
  @Input() page: number;
  @Input() perPage: number;
  @Input() withoutPerPage: boolean;
  /* tslint:disable:no-magic-numbers */
  @Input() perPageVariants = [15, 30, 45, 60];
  @Output() pageChange = new EventEmitter<number>();
  @Output() perPageChange = new EventEmitter<number>();

  pagesToShow = [];
  defaultPerPage = 30;

  perPageData = [];

  ngOnInit() {
    this.perPage = this.perPage || this.defaultPerPage;
    this.perPageData = _map((value) => Object({perPage: value}))(this.perPageVariants);
  }

  ngOnChanges() {
    this.buildPages();
  }

  /**
   * Сформировать массив страниц для отображения
   */
  private buildPages() {
    const byLine = 5;
    const offsetLeft = 3;
    const offsetRight = 2;
    if (this.totalPages > byLine) { // 7
      if (this.page < byLine) {
        this.pagesToShow = [..._range(1, byLine + 1), '...', this.totalPages];
      } else if (this.page > (this.totalPages - byLine + offsetRight)) { // 9
        this.pagesToShow = [1, '...', ..._range(this.totalPages - byLine + 1, this.totalPages + 1)];
      } else if (this.page >= byLine && this.page <= this.totalPages - byLine) { // 8
        this.pagesToShow = ['...', ..._range(this.page - (byLine - offsetLeft),
            this.page + (byLine - offsetRight)), '...'];
      }
    } else {
      this.pagesToShow = [..._range(1, this.totalPages + 1)];
    }
  }

  /**
   * Выбрать страницу
   */
  setPage(page) {
    if (page === '...' || page === this.page) {
      return;
    }

    this.page = page;
    this.buildPages();
    this.pageChange.emit(this.page);
  }

  /**
   * Выбрать первую страницу
   */
  firstPage() {
    this.setPage(1);
  }

  /**
   * Выбрать последнюю страницу
   */
  lastPage() {
    const page = _int(this.totalPages || 1);
    this.setPage(page);
  }

  /**
   * Выбрать предыдущую старницу
   */
  prevPage() {
    if (this.page > 1) {
      const page = this.page - 1;
      this.setPage(page);
    }
  }

  /**
   * Выбрать следующую страницу
   */
  nextPage() {
    if (this.page < this.totalPages) {
      const page = this.page + 1;
      this.setPage(page);
    }
  }

  /**
   * Изменить число записей для отображение на странице
   */
  switchPerPage(perPage) {
    this.perPage = perPage;
    this.perPageChange.emit(this.perPage);
  }
}
