import { Component, OnInit } from '@angular/core';
import { UserProfileService } from '@backend/user-profile/user-profile.service';
import { AuthService } from '@backend/auth/auth.service';
import { EventsService } from '@backend/kosuip/events/events.service';
import { Router } from '@angular/router';
import Debounce from 'debounce-decorator';
import { UnfinishedChangesIndicatorService } from '@core/unfinished-changes-indicator/unfinished-changes-indicator.service';

@Component({
  selector: 'hse-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  public roles;
  public loaded = false;

  constructor(public authService: AuthService,
              public eventsService: EventsService,
              private changesIndicator: UnfinishedChangesIndicatorService,
              private router: Router,
              private userProfile: UserProfileService) { }

  ngOnInit() {
    this.init();
  }

  async init() {
    try {
      this.roles = await this.userProfile.getCurrentRoles();
      this.loaded = true;
      this.eventsService.startPinging();
    } catch (e) {
      console.error(e);
    }
  }

  // tslint:disable-next-line:no-magic-numbers
  @Debounce(300)
  markNotification(event) {
    if (!event.delivered) {
      event.delivered = true;
      this.$markNotification(event);
    }
  }

  $markNotification(event) {
    this.eventsService.removeEvent(event);
    this.getCounter();
  }

  // tslint:disable-next-line:no-magic-numbers
  @Debounce(1000)
  getCounter() {
    this.eventsService.getCounter();
  }

  getEventClass(event) {
    switch (event.style_notification) {
      case '000000001':
        return 'red';
        break;
      case '000000002':
        return 'blue';
        break;
      case '000000003':
        return 'yellow';
        break;
      case '000000004':
        return 'green';
        break;
      default:
        return 'yellow';
        break;
    }
  }

  getEventText(event) {
    const linkRegExp = new RegExp(/http(s)?:\/\/[a-zA-Z.?/=0-9]+/g);

    if (event.approve && event.custom_learn_plan) {
      const planId = event.custom_learn_plan;

      return event.text_notification.replace(planId.toString(), `<a href="/constructor/study_plan/edit/${planId}">${planId}</a>`);
    } else {
      return event.text_notification.replace(linkRegExp, (match) => {
        return `<a href="${match}" target="_blank">${match}</a>`;
      });
    }
  }

  showAll() {
    if (this.changesIndicator.unfinishedChangesEditByYear) {
      this.changesIndicator.actionToDo = {
        type: 'navigate',
        url: '/constructor/events/list'
      };

      this.changesIndicator.fire$.next();

      return;
    }

    this.router.navigateByUrl('/constructor/events/list');
  }
}
