<div class="fx column" #mainContent>
	<div class="fx row space-between v-center top-bar" hseSticky [width]="barWidth" [stackName]="'topBar'" [offsetTop]="0" [zIndex]="150">
		<div class="fx row space-between v-center">
			<hse-icon [name]="'logo3'" (click)="goToStartScreen()" class="logo-icon"></hse-icon>

			<hse-breadcrumbs></hse-breadcrumbs>
		</div>

		<div class="fx row v-center">
			<div class="lang-menu__wrapper">
				<div [matMenuTriggerFor]="menu"
				     class="fx row v-center"
				     #t="matMenuTrigger">
					<span style="color: #6B7A99" *ngIf="currentLocale === 'ru'">RU</span>
					<span style="color: #6B7A99" *ngIf="currentLocale === 'en'">EN</span>
					<hse-icon *ngIf="t.menuOpen" name="arrowSmallUp" class="menu-trigger__arrow"></hse-icon>
					<hse-icon *ngIf="!t.menuOpen" name="arrowSmallDown" class="menu-trigger__arrow"></hse-icon>
				</div>
			</div>

			<mat-menu #menu="matMenu"
			          tabindex="-1"
			          [overlapTrigger]="false">
				<div>
					<div class="fx row v-center lang-menu__item" (click)="changeLocale('ru')">
						<span>Russian</span>
						<hse-icon name="check" *ngIf="currentLocale === 'ru'"></hse-icon>
					</div>
					<div class="fx row v-center lang-menu__item" (click)="changeLocale('en')">
						<span>English</span>
						<hse-icon name="check" *ngIf="currentLocale === 'en'"></hse-icon>
					</div>
				</div>
			</mat-menu>

			<a class="support fx row v-center"
			   href="https://pmo.hse.ru/servicedesk/customer/portal/81/group/300"
			   target="_blank">{{ 'COMMON.SUPPORT' | translate }}</a>
			<div class="icon-section">
				<hse-mtk-verification [studyPlan]="studyPlan" [learnPeriods]="learnPeriods"></hse-mtk-verification>
			</div>
			<div class="icon-section" style="border-left: 1px solid #d3d7e1; padding-left: 24px;" *ngIf="loaded && ready && !isReadOnly">
				<hse-comments (sendCallback)="sendComment($event)"
				              [disabled]="disableComments()"
				              [isStudyPlan]="true"
				              [studyPlanId]="studyPlan.id"
				              (deleteCommentCallback)="deleteComment($event)"
				              (updateCommentCallback)="updateComment($event)"
				              (importantCallback)="markAsImportant($event)"
				              (markAsSolvedCallback)="markAsSolved($event)"
				              [currentYear]="studyPlan.current_approval_year_id"
				              [comments]="comments"></hse-comments>
			</div>
			<div class="icon-section">
				<hse-events></hse-events>
			</div>
			<hse-user-info></hse-user-info>
		</div>
	</div>
	<div class="fx row">
		<!--<div class="left-bar hse-card fx column space-between">-->
		<!--&lt;!&ndash;<router-outlet name="left-bar"></router-outlet>&ndash;&gt;-->
		<!--<hse-icon [name]="'logo'" (click)="goToStartScreen()"></hse-icon>-->
		<!--<hse-user-info></hse-user-info>-->
		<!--</div>-->
		<div class="content-wrapper">
			<router-outlet></router-outlet>
		</div>
	</div>
</div>
