<ng-container *ngFor="let item of items; index as idx">
    <div class="hse-radio-button">
        <input class="hse-radio-button__input" id="{{ radioGroupId }}-{{ idx }}" type="radio"
               [name]="name"
               [ngModel]="model"
               (ngModelChange)="onChangeValue($event)"
               [disabled]="disabled"
               [value]="item[valueField]"
        >
        <label class="hse-radio-button__label" for="{{ radioGroupId }}-{{ idx }}">
            {{ item[labelField] }}
        </label>
    </div>
</ng-container>
