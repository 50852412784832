import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'hse-button',
  templateUrl: './hse-button.component.html',
  styleUrls: ['./hse-button.component.scss']
})
/**
 * Кнопка. Пример:
 * <hse-button
 *   [color]="'primary'" - цвет кнопки, согласно стайлгайду ('primary', 'secondary')
 *   [disabled]="false" - дизейбл кнопки (по умолчанию false)
 *   [small]="true" - маленькая кнопка
 *   (callback)="save()" - коллбэк на нажатие кнопки
 * </hse-button>
 */
export class HseButtonComponent implements OnInit, AfterViewInit {

  @Input() color?: 'primary' | 'secondary';
  @Input() opacity = false;
  @Input() disabled?: boolean;
  @Input() small?: boolean;
  @Input() redesign?: boolean;
  @Input() filters?: boolean;
  @Input() width?: number;

  @Output() callback?: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('button', { static: true }) button: ElementRef;

  ngOnInit() {
    if (!Boolean(this.color)) {
      this.color = 'primary';
    }
    if (this.disabled) {
      return;
    }
  }

  ngAfterViewInit(): void {
    this.button.nativeElement.style.width = this.width || 'auto';
  }

  callbackTrigger() {
    if (this.callback) {
      this.callback.emit();
    }
  }

  classNameGetter(): string {
    const result: Array<string> = ['hse-button', 'h-center', 'v-center'];

    if (this.filters) {
      result.push('filters');

      return result.join(' ');
    }

    if (this.small) {
      if (this.color === 'primary') {
        result.push('small-primary');
      }

      if (this.color === 'secondary') {
        result.push('small-secondary');
      }
    } else {
      if (this.color === 'primary') {
        result.push('primary');
      }

      if (this.color === 'secondary') {
        result.push('secondary');
      }
    }

    if (this.color === 'secondary' && this.opacity) {
      result.push('opacity');
    }

    if (this.redesign) {
      result.push('redesign');
    }

    return result.join(' ');
  }

}
