<div class="fx row wrap">
    <div class="trajectory-item"
         [class.selected]="!selectedTrajectoryId"
         [class.disabled]="disableCommonPart()"
         *ngIf="showAllPlanTrajectory"
         (click)="selectEducationTrajectory(null)">
        <span>{{ 'STUDY-PLAN.EDIT.EDUCATION-TRAJECTORIES.WHOLE-PLAN' | translate }}</span>
    </div>
    <div class="trajectory-item"
         [class.selected]="selectedTrajectoryId === 'common'"
         [class.disabled]="disableCommonPart()"
         *ngIf="showCommonPart"
         (click)="selectEducationTrajectory('common')">
        <span>{{ 'STUDY-PLAN.EDIT.EDUCATION-TRAJECTORIES.COMMON-PART' | translate }}</span>
    </div>
    <div class="trajectory-item"
         [class.selected]="trajectory.specialization_id === selectedTrajectoryId"
         [class.disabled]="trajectory.disabled"
         (click)="selectEducationTrajectory(trajectory.specialization_id)"
         *ngFor="let trajectory of educationTrajectories">
        <span>{{ trajectory.description }} ({{ trajectory.edu_trajectory_type_description }})</span>
    </div>
</div>
