import { Component, OnInit } from '@angular/core';
import { ExpandableLeftMenuService } from '@shared/components/expandable-left-menu/expandable-left-menu.service.service';
import { menuItems } from './left-menu-items.const';
import { NavigationEnd, Router } from '@angular/router';
import { forEach as _forEach, some as _some, find as _find } from 'lodash/fp';
import { UserProfileService } from '@backend/user-profile/user-profile.service';
import { TranslateService } from '@ngx-translate/core';
import { UnfinishedChangesIndicatorService } from '@core/unfinished-changes-indicator/unfinished-changes-indicator.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'hse-expandable-left-menu',
  templateUrl: './expandable-left-menu.component.html',
  styleUrls: ['./expandable-left-menu.component.scss']
})
export class ExpandableLeftMenuComponent implements OnInit {

  public menuItems;

  userRoles: any;

  constructor(
    public leftMenuService: ExpandableLeftMenuService,
    public userProfile: UserProfileService,
    private changesIndicator: UnfinishedChangesIndicatorService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.menuItems = menuItems;

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        const url = event.urlAfterRedirects;

        _forEach((menuItem: any) => {
          menuItem.title = this.translate.instant(menuItem.translate);

          menuItem.active = _some(($match: string) => {
            return url.indexOf($match) !== -1;
          })(menuItem.match);

          if (menuItem.expandable) {
            _forEach((childItem: any) => {
              childItem.title = this.translate.instant(childItem.translate);

              childItem.active = _some(($match: string) => {
                return url.indexOf($match) !== -1;
              })(childItem.match);
            })(menuItem.children);
          }
        })(this.menuItems);

        this.checkAllowedElements();
      });
  }

  ngOnInit() {
  }

  async checkAllowedElements() {
    try {
      this.userRoles = await this.userProfile.getUserRoleMatrix();

      _forEach((menuItem: any) => {
        if (menuItem.expandable) {
          _forEach((childItem: any) => {
            childItem.visible = this.checkMenuItemAccess(childItem);
          })(menuItem.children);

          menuItem.visible = !!_find({visible: true})(menuItem.children);
        } else {
          menuItem.visible = this.checkMenuItemAccess(menuItem);
        }
      })(this.menuItems);
    } catch (e) {
      console.error(e);
    }
  }

  checkMenuItemAccess(menuItem: any) {
    if (menuItem.disallow.length) {
      return !_find<string>((allowRole) => {
        return this.userRoles[allowRole];
      })(menuItem.disallow);
    }

    if (menuItem.allow.length) {
      return !!_find<string>((allowRole) => {
        return this.userRoles[allowRole];
      })(menuItem.allow);
    }

    return true;
  }

  toggleExpand() {
    this.leftMenuService.toggleExpand();

    if (this.leftMenuService.menuExpanded) {
      _forEach((menuItem: any) => {
        if (menuItem.active && menuItem.expandable) {
          menuItem.expanded = true;
        }
      })(this.menuItems);
    } else {
      _forEach((menuItem: any) => {
        if (menuItem.active && menuItem.expandable) {
          menuItem.expanded = false;
        }
      })(this.menuItems);
    }
  }

  goTo(item) {
    if (item.expandable) {
      item.expanded = !item.expanded;

      if (!this.leftMenuService.menuExpanded) {
        this.toggleExpand();
      }
    } else {
      if (this.changesIndicator.unfinishedChangesEditByYear) {
        this.changesIndicator.actionToDo = {
          type: 'navigate',
          url: item.url
        };

        this.changesIndicator.fire$.next();

        return;
      }

      this.router.navigateByUrl(item.url);
    }
  }
}
