import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
    OnChanges,
    ViewChild,
    ElementRef, OnDestroy
} from '@angular/core';
import {
    compact as _compact,
    forEach as _forEach,
    includes as _includes,
    filter as _filter,
    map as _map,
    flow as _flow,
    uniq as _uniq
} from 'lodash/fp';
import { HseComboBoxItem } from '@shared/components/common-ui/hse-combo-box/hse-combo-box.interface';
import { HseInputComponent } from '@shared/components/common-ui/hse-input/hse-input.component';
import { TranslateService } from '@ngx-translate/core';

/**
 * Это комбо-бокс (селект с множественным выбором).
 *
 * Пример использования:
 * <hse-ya-combo-box [collection]="selectCollection"
 *   (change)="comboChange($event)"
 *   [(model)]="comboModel"
 *   [nameField]="'name'"
 *   [selectedField]="'selected'"
 *   [width]="300"
 *   ></hse-combo-box>
 *
 *   где collection - исходная коллекция
 *   change - коллбэк на изменение модели
 *   model - коллекция (!) из выбранных значений
 *   nameField - ключ отображаемого поля
 *   selectedField - ключ поля значения выбрано/не выбрано
 *   visibleItemsCount - кол-во отображаемых опций в нераскрытом режиме
 *   useSearch - отображать поиск
 *   alphabetModeCount - кол-во элементов при превышении которого активируется группировка по буквам алфавита
 *   width - ширина
 */
@Component({
    selector: 'hse-ya-combo-box',
    templateUrl: './hse-ya-combo-box.component.html',
    styleUrls: ['./hse-ya-combo-box.component.scss']
})
export class HseYaComboBoxComponent implements OnInit, OnChanges {

    modelValue = [];
    completeMatch = false;
    searchString: string;
    isOpened = false;
    translations = {
        SHOW: '',
        HIDE: ''
    };

    @Input() collection: HseComboBoxItem[];
    filteredCollection: HseComboBoxItem[] = [];
    alphCollection: Array<any>;
    @Input() nameField ? = 'name';
    @Input() completeMatchCheckbox = false;
    @Input() searchField ? = null;
    @Input() selectedField ? = 'selected';
    @Input() idField ? = 'id';
    @Input() placeholder ? = '';
    @Input() maxHeight ? = 40;
    @Input() width ?: string;
    @Input() chipsContainerWidth ?: string;
    @Input() searchPlaceholder: string;
    @Input() instantDisplayCollection ? = false;
    @Input() visibleItemsCount ? = 3;
    @Input() alphabetModeCount ? = 10;
    @Input() useSearch ? = true;
    @Input() name: string;
    @Input() useTooltip ? = false;

    @Input()
    get model() {
        return this.modelValue;
    }

    set model(val) {
        this.modelValue = val;
        this.modelChange.emit(this.modelValue);
    }

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() changeSelected ?: EventEmitter<any> = new EventEmitter<any>();
    @Output() completeMatchChange ?: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('optionsList', { static: true }) optionsList: ElementRef;
    @ViewChild('searchInput', { static: true }) searchInput: HseInputComponent;

    constructor(private translate: TranslateService) {}

    ngOnInit() {
        if (!Boolean(this.searchField)) {
            this.searchField = this.nameField;
        }
        this.translations.SHOW = this.translate.instant('UI.COMBO-BOX.SHOW');
        this.translations.HIDE = this.translate.instant('UI.COMBO-BOX.HIDE');
        this.buildAlphabeticOptionList();
        if (this.instantDisplayCollection) {
            this.filteredCollection = [...this.collection];
        }

        if (this.model) {
            _forEach((item: HseComboBoxItem) => {
                item.selected = _includes(item[this.idField])(this.model);
            })(this.collection);
        }

        this.filterOptions();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (Boolean(changes.collection) && !changes.collection.isFirstChange()
            || Boolean(changes.model) && !changes.model.isFirstChange()) {

            if (Boolean(changes.collection)) {
                this.buildAlphabeticOptionList();
            }

            if (this.model) {
                _forEach((item: HseComboBoxItem) => {
                    item.selected = _includes(item[this.idField])(this.model);
                })(this.collection);
            }

            this.filterOptions();
        }
    }

    itemClicked(item: HseComboBoxItem, event) {
        event.preventDefault();
        item.selected = !Boolean(item.selected);
        this.model = _flow(_filter({selected: true}), _map(this.idField))(this.collection);
        this.changeSelected.emit(this.model);
    }

    getMenuRowClass(item: HseComboBoxItem) {
        return _compact(['menu-row', item[this.selectedField] && 'selected']).join(' ');
    }

    filterOptions() {
        if (this.completeMatch) {
            this.filteredCollection = this.searchString
              ? _filter((item: any) => item[this.searchField].toUpperCase() === this.searchString.toUpperCase())(this.collection)
              : [...this.collection];
        } else {
            this.filteredCollection = this.searchString
              ? _filter((item: any) => item[this.searchField].toUpperCase().indexOf(this.searchString.toUpperCase()) > -1)(this.collection)
              : [...this.collection];
        }
        // console.log(this.filteredCollection, this.searchField);
    }

    toggleIsOpened($event) {
        $event.stopPropagation();
        this.isOpened = !this.isOpened;
        this.searchString = null;
        this.filterOptions();
        if (this.useSearch) {
            setTimeout(() => {
                this.searchInput.inputElem.nativeElement.focus();
            });
        }
    }

    buildAlphabeticOptionList() {
        this.alphCollection = _flow(
            _map((item) => {
                return item[this.nameField][0].toUpperCase();
            }),
            _uniq,
            _map((letter) => {
                return {
                    letter,
                    options: _filter((option) => option[this.nameField].toUpperCase().startsWith(letter))(this.collection)
                };
            })
            // _sortBy((v) => v)
        )(this.collection);
    }
}
