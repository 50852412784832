<div class="fx row">
  <div class="left-bar hse-card fx column space-between">
    <hse-icon [name]="'logo3'" (click)="goToStartScreen()"></hse-icon>
    <hse-user-info></hse-user-info>
  </div>
  <div class="content-wrapper">
    <div class="content">
      <router-outlet></router-outlet>
    </div>
    <hse-footer></hse-footer>
  </div>
</div>
