import { Component, Input, OnInit } from '@angular/core';
import { UserProfileService } from '@backend/user-profile/user-profile.service';
import { AuthService } from '@backend/auth/auth.service';
import { MtkVerificationService } from '@backend/kosuip/mtk-verification/mtk-verification.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { toNumber as _toNumber, find as _find } from 'lodash/fp';

@Component({
  selector: 'hse-mtk-verification',
  templateUrl: './mtk-verification.component.html',
  styleUrls: ['./mtk-verification.component.scss']
})
export class MtkVerificationComponent implements OnInit {

  public roles;
  public loaded = false;
  public items = [];
  public titleLabel = 'Проверка МТК';
  public titleLabelYear;
  public mtkLoaded = false;

  url: string;
  params: number;

  @Input() studyPlan: any;
  @Input() learnPeriods: any;


  constructor(public authService: AuthService,
              private userProfile: UserProfileService,
              private router: Router,
              private mtkVerificationService: MtkVerificationService) {

    this.getUrl();
  }

  ngOnInit() {
    this.init();
  }


  public async init() {
    try {
      this.roles = await this.userProfile.getCurrentRoles();
      // this.loaded = true;

      this.checkUrl();

      // if (this.loaded) {
      //   this.getAllVerification(this.params);
      // }
    } catch (e) {
      console.error(e);

    }
  }

  private getUrl() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        this.url = event.urlAfterRedirects;
        this.checkUrl();
      });
  }

  private getIdFromURL(url: string) {
    const id = _toNumber(url.split('/').pop());
    if (Number(id)) {

      return id;
    }

    return -1;
  }

  private checkUrl() {
    const URL_BY_YEAR = '/constructor/study_plan/edit_by_year/';
    const URL_BY_EDIT = '/constructor/study_plan/edit/';

    this.loaded = (this.url.indexOf(URL_BY_EDIT) !== -1) || (this.url.indexOf(URL_BY_YEAR) !== -1);

    if (this.loaded) {
      this.params = this.getIdFromURL(this.url);
    }
  }

  private async getAllVerification(id: number) {
    if (id !== -1) {
      try {
        this.items = await this.mtkVerificationService.getVerification('00000000001', id);
        if (this.learnPeriods && this.learnPeriods.length && this.studyPlan && this.studyPlan.current_approval_year_id) {
          const year = _find<any>({ id: this.studyPlan.current_approval_year_id })(this.learnPeriods);

          if (year) {
            this.titleLabelYear = `${this.titleLabel} ${year.description.substr(0, 9)} год`;
          }
        }

        this.mtkLoaded = true;

        return;
      } catch (e) {
        console.error(e);
      }
    }

    this.items = [];
  }

  public open() {
    this.mtkLoaded = false;
    this.getAllVerification(this.params);
  }
}
