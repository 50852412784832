import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'hse-constructor-layout',
  templateUrl: './constructor-layout.component.html',
  styleUrls: ['./constructor-layout.component.scss']
})
export class ConstructorLayoutComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  goToStartScreen() {
    this.router.navigateByUrl('/constructor');
  }
}
