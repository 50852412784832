import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { random as _random } from 'lodash/fp';

/**
 * Hse-checkbox component
 *
 * <hse-checkbox [model]="checkboxValue" - модель
 *               (modelChange)="changeValue($event)" - callback на изменение модели
 * ></hse-checkbox>
 */
@Component({
    selector: 'hse-checkbox',
    templateUrl: './hse-checkbox.component.html',
    styleUrls: ['./hse-checkbox.component.scss']
})
export class HseCheckboxComponent {

    @Input() stopClickPropagation: boolean;

    /**
     * Значение чекбокса
     */
    @Input() model: boolean | number;

    /**
     * Включить/отключить чекбокс
     */
    @Input() disabled: boolean;

    /**
     * Применить ли уменьшенный чекбокс (default: false)
     */
    @Input() small: boolean;

    /**
     * Позиция лейбла
     * */
    @Input() labelPosition?: string;

    @Input() customInvalid = false;
    @Input() disableModel = false;

    /**
     * callback на изменение значения чекбокса
     */
    @Output() modelChange = new EventEmitter();

    checkboxId: string;
    minValue = 1000000;
    maxValue = 9999999;

    constructor() {
        this.checkboxId = `${_random(this.minValue, this.maxValue)}-hse-checkbox-${Date.now()}`;
    }

    /**
     * Эмитим модель
     * В случае, когда клик был произведен по иконке, предварительно изменяем значение модели
     */
    onChangeValue(fromInput = false) {
        if (this.disabled) {
            return;
        }

        if (!fromInput) {
            this.model = !this.model;
        }

        this.modelChange.emit(this.model);
    }

    onClick($event) {
      if (this.stopClickPropagation || this.disableModel) {
        $event.stopPropagation();
      }
    }
}
