import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HseLoaderService } from './hse-loader.service';

import { finalize } from 'rxjs/operators';

import { some as _some } from 'lodash/fp';

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptorService implements HttpInterceptor {

  /**
   * если true, показываем лоадер независимо от skipperUrls
   */
  public coverAllmethods = false;
  /**
   * Количество активных запросов
   */
  private activeRequestCount = 0;
  private activeBackgroundRequestCount = 0;

  /**
   * Список url, для которых не отображать лоудер
   * Пример: '/subjects'
   */
  private skippedUrls: Array<string> = [
    '/sessions',
    'assets/images/icons',
    'api/getsumcredits',
    'api/bp/getnotification',
    'api/bp/marknotification',
    'api/getListCustomPlanUnderCount',
    'api/common/getassincresult'
  ];

  /**
   * Список url, для которых отображать альтернативный лоадер
   * Пример: '/subjects'
   */
  private backgroundLoadingUrls: Array<string> = [
    'api/getsumcredits'
  ];

  /**
   * http методы, для которых не отображать лоудер
   * Пример: 'GET'
   */
  private skippedMethods: Array<string> = [
  ];

  constructor(private hseLoaderService: HseLoaderService) { }

  /**
   * Перехватить запросы, переданные через HttpClient.
   * Использовать для конфигурации HTTP_INTERCEPTORS в нужном модуле.
   * Данный метод не может перехватить restangular запросы,
   * т.к. restangular использует HttpBackend и пропускает стадию перехватчиков.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.interceptRequest(request);

    return next.handle(request).pipe(
      finalize(() => {
        this.interceptResponse(request);
      })
    );
  }

  /**
   * Перехватить запрос
   * В параметр запроса может попасть не только HttpRequest,
   * но также синтетический объект, переданный в перехватчике запроса restangular'a.
   */
  interceptRequest(request: any) {
    const shouldBypass = this.shouldBypass(request);
    const shouldDoBackground = this.shouldDoBackground(request);

    if (shouldDoBackground) {
      if (this.activeBackgroundRequestCount === 0) {
        this.hseLoaderService.show(true);
      }

      this.activeBackgroundRequestCount++;
    }

    if (!shouldBypass) {
      if (this.activeRequestCount === 0) {
        this.hseLoaderService.show();
      }

      this.activeRequestCount++;
    }
  }

  /**
   * Перехватить ответ
   */
  interceptResponse(request: any) {
    const shouldBypass = this.shouldBypass(request);
    const shouldDoBackground = this.shouldDoBackground(request);

    if (!shouldBypass) {
      this.activeRequestCount--;
    }

    if (shouldDoBackground) {
      this.activeBackgroundRequestCount--;
    }

    if (this.activeRequestCount === 0) {
      this.hseLoaderService.hide();
    }

    if (this.activeBackgroundRequestCount === 0) {
      this.hseLoaderService.hide(true);
    }
  }

  /**
   * Перехватить ошибку
   */
  interceptError(request: any) {
    const shouldBypass = this.shouldBypass(request);
    const shouldDoBackground = this.shouldDoBackground(request);

    if (!shouldBypass) {
      if (this.activeRequestCount === 0) {
        this.hseLoaderService.show();
      }

      this.activeRequestCount++;
    }

    if (shouldDoBackground) {
      if (this.activeBackgroundRequestCount === 0) {
        this.hseLoaderService.show(true);
      }

      this.activeBackgroundRequestCount++;
    }

    this.interceptResponse(request);
  }

  /**
   * Проверить, следует ли пропустить запрос, переданный в параметр
   */
  private shouldBypass(request: HttpRequest<any>): boolean {
    if (((window.location.href.indexOf('department_discipline/create') !== -1
        || window.location.href.indexOf('study_plan/edit_by_year') !== -1
        || window.location.href.indexOf('study_plan/edit_structure') !== -1
        || window.location.href.indexOf('department_discipline/list') !== -1)
        && request.url.indexOf('api/save?cname=customplanitem') !== -1)
      || this.coverAllmethods) {
      return false;
    }

    if (window.location.href.indexOf('events/list') !== -1
      && (request.url.indexOf('api/bp/marknotification') !== -1 || (request.url.indexOf('api/bp/getnotification') !== -1 && request.url.indexOf('api/bp/getnotificationcounter') === -1))) {
      return false;
    }

    return this.shouldBypassMethod(request.method)
      || this.shouldBypassUrl(request.url);
  }

  private shouldDoBackground(request: HttpRequest<any>): boolean {
    if (((window.location.href.indexOf('department_discipline/create') !== -1
        || window.location.href.indexOf('study_plan/edit_by_year') !== -1
        || window.location.href.indexOf('study_plan/edit_structure') !== -1
        || window.location.href.indexOf('department_discipline/list') !== -1)
        && request.url.indexOf('api/save?cname=customplanitem') !== -1)
      || this.coverAllmethods) {
      return false;
    }

    if (window.location.href.indexOf('events/list') !== -1
      && (request.url.indexOf('api/bp/marknotification') !== -1 || (request.url.indexOf('api/bp/getnotification') !== -1 && request.url.indexOf('api/bp/getnotificationcounter') === -1))) {
      return false;
    }

    return _some((forbcloading: string) => {
      return request.url.indexOf(forbcloading) !== -1;
    })(this.backgroundLoadingUrls);
  }

  /**
   * Проверить, следует пропустить запрос по заданному url
   */
  private shouldBypassUrl(url: string): boolean {
    return _some((skipped: string) => {
      return url.indexOf(skipped) !== -1;
    })(this.skippedUrls);
  }

  /**
   * Проверить, следует ли пропустить запрос с заданным http-методом
   */
  private shouldBypassMethod(method: string) {
    return _some((skipped: string) => {
      return new RegExp(skipped, 'i').test(method);
    })(this.skippedMethods);
  }
}
