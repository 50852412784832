import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomCookieService } from '@backend/cookies/custom-cookie.service';
import { UserSettings, UserTableColumnSettings } from '@backend/user-settings/user-settings.interface';
import { remove as _remove, find as _find, findIndex as _findIndex, forEach as _forEach } from 'lodash/fp';
import { ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserSettingsService {
    BASE_URL = '/api/user-settings';
    currentSettings: UserSettings[];

    loadSettingsPromise = null;

    constructor(private http: HttpClient, private cookies: CustomCookieService) {
    }

    async getUserSettings() {
        try {
            this.currentSettings = await (this.loadSettingsPromise = this.http.get<UserSettings[]>(this.BASE_URL).toPromise());
        } catch (err) {
            this.currentSettings = [];
        }
    }

    async updateUserSettings(settingsName: string, settingsData: UserSettings) {
        await this.http.put(this.BASE_URL, settingsData).toPromise();

        const ix = _findIndex({name: settingsName})(this.currentSettings);
        if (ix !== -1) {
            this.currentSettings.splice(ix, 1, settingsData);
        }
    }

    async getSettings(settingsName: string): Promise<UserSettings> {
        if (!this.currentSettings) {
            await this.loadSettingsPromise;
        }

        let settingsData = _find<UserSettings>({name: settingsName})(this.currentSettings);

        if (!settingsData) {
            settingsData = {
                name: settingsName,
                settings: []
            };
        }

        return settingsData;
    }

    async getFilters(settingsName: string) {
        const settings = await this.getSettings(settingsName);
        const filtersObject = {};
        _forEach<UserTableColumnSettings>((columnSettings) => {
            if (columnSettings.filterSelectedValue) {
                filtersObject[columnSettings.name] = columnSettings.filterSelectedValue;
            }
        })(settings.settings);

        return filtersObject;
    }
}
