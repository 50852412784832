<div class="hse-comments fx h-v-center">
	<div class="menu-trigger__wrapper">
		<div [matMenuTriggerFor]="menu"
		     #t="matMenuTrigger"
		     class="menu-trigger"
		     (menuOpened)="toggleMenu(true)"
		     (menuClosed)="toggleMenu(false)"
		     [ngClass]="{ 'opened': t.menuOpen }">
			<hse-icon [name]="'comment'"
			          [class.solved]="markAsSolved"
			          [ngClass]="getMainIndicatorClass()"
			          [class.has-comments]="comments && comments.length"></hse-icon>
		</div>
	</div>
	<mat-menu #menu="matMenu"
	          tabindex="-1"
	          class="custom-comments-menu">

		<div class="comment-list-wrapper"
		     (click)="handleMenuClick(); $event.stopPropagation()">
			<div class="fx column mark-as-solved">
				<hse-checkbox [(model)]="filterByYear" (modelChange)="filterCommentsByYear($event)">
					Показать только за текущий год согласования
				</hse-checkbox>
				<hse-checkbox
						[style.marginTop.px]="8"
						[model]="withArchiveComment"
						(modelChange)="filterArchiveComments($event)">
					Показывать комментарии удаленных объектов
				</hse-checkbox>
			</div>

			<div class="comment-list" *ngIf="filteredMainComments && filteredMainComments.length">
				<div *ngFor="let comment of filteredMainComments" class="fx column comment">
					<div class="show-all-modal__comment-title" *ngIf="comment.object">
						{{ comment.object  }}
					</div>
					<div class="fx row">
						<div>
							<div class="avatar"></div>
						</div>
						<div class="fx column comment-item">
							<div class="fx row space-between comment-info">
								<div class="fx row v-center">
									<span style="font-size: 16px; font-weight: 600;">{{ comment.user || ' ' }}</span>

									<div class="indicator" [ngClass]="getIndicatorClass(comment)"></div>

									<hse-checkbox [(model)]="comment.is_important"
									              [disabled]="!comment['ableToMark']"
									              (modelChange)="importantChange($event, comment)">Критично</hse-checkbox>
								</div>

								<div>
									<span>{{ comment.$date }}</span>
								</div>
							</div>

							<div *ngIf="!comment.editMode">
								<span>{{ comment.Comment }}</span>
							</div>

							<div class="fx column" *ngIf="comment.editMode">
								<hse-textarea [(ngModel)]="commentEditText"
								              [rowsCount]="3"
								              [width]="312"></hse-textarea>

								<div class="fx row edit-controls">
									<hse-button [color]="'primary'" (callback)="updateComment(comment)">Сохранить</hse-button>
									<hse-button [color]="'secondary'" (callback)="cancelEditingComment(comment)">Отменить</hse-button>
								</div>
							</div>

							<div class="comment-controls fx row h-end">
								<div class="fx row" *ngIf="comment[ableToEditField] && !comment.editMode">
									<hse-icon [name]="'editSmall'" (click)="editModeComment(comment)"></hse-icon>
									<hse-icon [name]="'delete'" (click)="deleteComment(comment)"></hse-icon>
								</div>
							</div>
						</div>
					</div>

					<!-- __________ -->

					<div class="fx row answers" *ngFor="let $comment of comment.childComments">
						<div>
							<div class="avatar">
							</div>
						</div>
						<div class="fx column answer-item">
							<div class="fx row space-between answer-info">
								<div>
									<span style="font-size: 16px; font-weight: 600;">{{ $comment.user || ' ' }}</span>
								</div>

								<div>
									<span>{{ $comment.$date }}</span>
								</div>
							</div>

							<div *ngIf="!$comment.editMode">
								<span>{{ $comment.Comment }}</span>
							</div>

							<div class="fx column" *ngIf="$comment.editMode">
								<hse-textarea [(ngModel)]="commentEditText"
								              [rowsCount]="3"
								              [width]="212"></hse-textarea>

								<div class="fx row edit-controls">
									<hse-button [color]="'primary'" (callback)="updateComment($comment)">Сохранить</hse-button>
									<hse-button [color]="'secondary'" (callback)="cancelEditingComment($comment)">Отменить</hse-button>
								</div>
							</div>

							<div class="comment-controls fx row h-end">
								<div class="fx row" *ngIf="comment[ableToEditField] && !comment.editMode">
									<hse-icon [name]="'editSmall'" (click)="editModeComment($comment)"></hse-icon>
									<hse-icon [name]="'delete'" (click)="deleteComment($comment)"></hse-icon>
								</div>
							</div>
						</div>
					</div>

					<!-- __________ -->

					<div class="fx row v-center" style="margin-bottom: 12px;" *ngIf="!comment.newAnswerAdding">
						<div class="add-new-answer fx row v-center"
						     (click)="addAnswer($event, comment);">
							<span>Ответить</span>
						</div>

						<hse-checkbox [(model)]="comment.solved"
						              [disabled]="!comment['ableToMark']"
						              (modelChange)="markCommentsAsSolved($event, comment)">Отметить как решённое</hse-checkbox>
					</div>

					<div class="add-answer fx column"
					     (click)="$event.stopPropagation()"
					     *ngIf="comment.newAnswerAdding">
						<textarea [(ngModel)]="commentText"
						          #commentInput
						          class="add-answer__input"></textarea>
						<hse-button [color]="'primary'"
						            [disabled]="!commentText"
						            (callback)="sendAnswer(comment)">
							Отправить
						</hse-button>
					</div>
				</div>
			</div>

			<div class="add-new-comment fx row v-center"
			     *ngIf="!newCommentAdding"
			     (click)="addComment($event);">
				<span>Добавить новый комментарий</span>
			</div>

			<div class="fx h-end show-all" *ngIf="isStudyPlan">
				<span (click)="showAll(); t.closeMenu()">Показать все</span>
			</div>
		</div>

		<div class="add-comment fx column"
		     (click)="$event.stopPropagation()"
		     *ngIf="newCommentAdding">
			<textarea [(ngModel)]="commentText"
			          #commentInput
			          class="add-comment__input"></textarea>
			<hse-button [color]="'primary'"
			            [disabled]="!commentText"
			            (callback)="sendComment()">
				Отправить
			</hse-button>
		</div>
	</mat-menu>
</div>


<div *ngIf="showAllModal" class="show-all-modal__wrapper">
	<div style="padding-bottom: 48px">
		<div class="fx row space-between show-all-modal__header">
			<h1>Сообщения</h1>

			<hse-icon [name]="'close'" (click)="closeShawAll()"></hse-icon>
		</div>

		<div class="fx column h-center show-all-modal__content">
			<div class="fx row show-all-modal__tabs">
				<ng-template ngFor let-tab [ngForOf]="allModalTabs">
					<div class="show-all-modal__tabs__item fx row h-v-center"
					     [class.selected]="tab.selected"
					     (click)="selectTab(tab)">
						<span>{{ tab.title }}</span>
					</div>
				</ng-template>
			</div>

			<div style="width: 600px; margin-bottom: 24px; padding-left: 8px;">
				<hse-checkbox [model]="filterAllByYear" (modelChange)="toggleFilterAllByYear($event)">
					Показать только за текущий год согласования
				</hse-checkbox>
				<ng-container *ngIf="selectedTab === 0">
					<hse-checkbox [model]="withArchiveComment" (modelChange)="filterArchiveComments($event)">
						Показывать комментарии удаленных объектов
					</hse-checkbox>
				</ng-container>
			</div>

			<div *ngIf="selectedTab === 0">
				<div class="fx column show-all-modal__comment-row" *ngFor="let item of filteredAllCommentsByYear">
					<div class="show-all-modal__comment-title">{{ item.object || '-' }}</div>
					<div class="fx row">
						<div class="comment-avatar"></div>

						<div class="fx column" style="width: 100%;">
							<div class="fx row v-center space-between" style="width: 100%; margin-bottom: 4px;">
								<div class="fx row v-center">
									<div class="all-comment-user-name">
										<span>{{ item.user || '-' }}</span>
									</div>

									<div class="indicator" [ngClass]="getIndicatorClass(item)"></div>

									<div class="approve-date">
										<span>{{ getApproveDate(item.Period) }}</span>
									</div>
								</div>

								<div class="fx row approve-status"
									 [ngClass]="item.is_arhive ? 'not-active' : 'active'"
									 matTooltip="{{ item.is_arhive ? 'Объект удален' : 'Активно' }}"
									 matTooltipPosition="above">
									<span>{{ item.is_arhive ? 'Объект удален' : 'Активно' }}</span>
								</div>
							</div>

							<div style="margin-bottom: 16px;">
								<span>{{ item.Comment }}</span>
							</div>

							<div class="fx row space-between">
								<div class="fx row v-center answer-count"
									 *ngIf="item.childComments.length" (click)="item.expanded = !item.expanded">
									<hse-icon [name]="'arrowDown'" [ngClass]="{ 'rotated': item.expanded }"></hse-icon>

									<span>{{ item.childComments.length + ' ' + getAnswerCountLabel(item) }}</span>
								</div>

								<div>
									<hse-checkbox [(model)]="item.is_important"
												  style="margin-right: 16px;"
												  [disabled]="true"
												  (modelChange)="importantChange($event, item)">Критично</hse-checkbox>

									<hse-checkbox [(model)]="item.solved"
												  [disabled]="!item['ableToMark']"
												  (modelChange)="markCommentsAsSolved($event, item)">Отметить как решённое</hse-checkbox>
								</div>
							</div>

							<div class="fx column child-rows" *ngIf="item.expanded">
								<div class="fx row show-all-modal__child-row" *ngFor="let item of item.childComments">
									<div class="comment-avatar-small"></div>

									<div class="fx column" style="width: 100%;">
										<div class="fx row v-center space-between" style="width: 100%; margin-bottom: 4px;">
											<div class="fx row v-center">
												<div class="all-comment-user-name" style="margin-right: 16px;">
													<span>{{ item.user || '-' }}</span>
												</div>

												<div class="approve-date">
													<span>{{ getApproveDate(item.Period) }}</span>
												</div>
											</div>
										</div>

										<div style="margin-bottom: 16px;">
											<span>{{ item.Comment }}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div *ngIf="selectedTab === 1">
				<div class="fx row show-all-modal__approve-row" *ngFor="let item of filteredAcceptHistory">
					<div class="approve-avatar"></div>

					<div class="fx column" style="width: 100%;">
						<div class="fx row v-center space-between" style="width: 100%; margin-bottom: 4px;">
							<div class="fx row v-center">
								<div class="user-name">
									<span>{{ item.user || '-' }}</span>
								</div>

								<div class="approve-date">
									<span>{{ getApproveDate(item.period) }}</span>
								</div>
							</div>

							<div class="fx row approve-status history-status"
							     matTooltip="{{ item.learn_plan_status }}"
							     [matTooltipPosition]="'above'">
								<span>{{ item.learn_plan_status }}</span>
							</div>
						</div>

						<div>
							<span>{{ getLearnPeriod(item.learn_period_id) }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
