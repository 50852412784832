import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApprovalService {

  BASE_URL = '/api';
  API_URL = '/bp';

  constructor(private http: HttpClient) { }

  async approve(planId, processModel) {
    try {
      return this.http
        .post(`${this.BASE_URL}${this.API_URL}/movenextpoint?process_model=${processModel}&learn_plan=${planId}&approved=true`, {})
        .toPromise();
    } catch (e) {
      console.error(e);
    }
  }

  async disapprove(planId, processModel) {
    try {
      return this.http
        .post(`${this.BASE_URL}${this.API_URL}/movenextpoint?process_model=${processModel}&learn_plan=${planId}&approved=false`, {})
        .toPromise();
    } catch (e) {
      console.error(e);
    }
  }

  async getCurrentPoint(planId, processModel) {
    try {
      return this.http
        .get(`${this.BASE_URL}${this.API_URL}/getcurrentpoint?process_model=${processModel}&learn_plan=${planId}`)
        .toPromise();
    } catch (e) {
      console.error(e);
    }
  }

  async withdrawLearnPlan(planId, processModel, comment) {
    try {
      return this.http
        .post(`${this.BASE_URL}${this.API_URL}/withdrawlp?process_model=${processModel}&learn_plan=${planId}&comment=${comment}`, {})
        .toPromise();
    } catch (e) {
      console.error(e);
    }
  }
}
