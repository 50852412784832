<div class="tab-switch fx row stretch" #tabsRef>

    <div class="tab-item fx fx-1 h-center v-center"
         [ngClass]="tabClass"
         *ngFor="let tab of tabs"
         [class.disabled]="tab.disabled"
         [class.error]="tab.$invalid"
         (click)="switchTab(tab.id, false, tab.disabled)">
        <div *ngIf="addContextMenu === tab.name" style="width: 24px">
        </div>
        <span class="name">{{tab.name}}</span>
        <hse-icon [name]="'lightning'" *ngIf="tab.changedValue" class="lightning-icon"></hse-icon>
        <span class="credits" *ngIf="showCredits"
              [class.green]="(tab.sum_credits || 0) >= tab.basic_credits"
              [class.red]="(tab.sum_credits || 0) < tab.basic_credits">({{(tab.sum_credits || 0)}}/{{tab.basic_credits}})</span>

        <div [matMenuTriggerFor]="menu"
             (menuClosed)="menuClosed()"
             (click)="$event.stopPropagation()">
            <hse-icon name="dots"
                      class="tabs__dots-icon"
                      *ngIf="addContextMenu === tab.name"></hse-icon>
        </div>
        <mat-menu #menu="matMenu"
                  tabindex="-1"
                  [overlapTrigger]="false">
            <div (click)="$event.stopPropagation()">
                <div class="menu__level fx row space-between">
                    <div class="menu__section-title">
                        <span>{{ 'UI.TAB-SWITCH.CREDITS-COUNT' | translate }}</span>
                    </div>

                    <div class="fx row v-center">
                        <span *ngIf="!editCredits">{{ numberOfCredits }}</span>
                        <hse-input *ngIf="editCredits"
                                   [disablePlaceholderAnimation]="true"
                                   [width]="50"
                                   (ngModelChange)="creditsChanged = true"
                                   [(ngModel)]="numberOfCredits"></hse-input>
                        <hse-icon [name]="'editBig'" (click)="editCredits = true"></hse-icon>
                    </div>
                </div>

                <div class="menu__section-title">
                    <span>{{ 'UI.TAB-SWITCH.LEVEL' | translate }}</span>
                </div>

                <div class="menu__list">
                    <div *ngFor="let item of contextMenuData"
                         matTooltip="{{ item.description }}"
                         [matTooltipPosition]="'above'"
                         class="fx row v-center menu__list-item">
                        <hse-checkbox [(model)]="item.checked"
                                      (modelChange)="dataCultureChanged = true">{{ item.description }}</hse-checkbox>
                    </div>
                </div>
            </div>
        </mat-menu>
        <!--<span [class.valid]="tab.currentCount >= tab.minTotalCount"-->
              <!--[class.invalid]="tab.currentCount < tab.minTotalCount"-->
        <!--&gt;({{tab.currentCount}}/{{tab.minTotalCount}})</span>-->
    </div>


    <div class="add-button fx row h-center v-center" (click)="addTab()" *ngIf="!disableAddition">
        <hse-icon name="add"></hse-icon>
        <span>{{ 'UI.TAB-SWITCH.CATALOGUE' | translate }}</span>
    </div>

    <div class="slider"
         [style.width.px]="sliderWidth"
         [style.left.px]="sliderPosition"
         (window:resize)="onResize()"></div>

</div>
