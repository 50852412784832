import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EducationStandardStructureBackend } from '@backend/kosuip/catalogue/interfaces/education-standard-structure.interface';

@Component({
  selector: 'hse-education-results-popup',
  templateUrl: './education-results-popup.component.html',
  styleUrls: ['./education-results-popup.component.scss']
})
export class EducationResultsPopupComponent implements OnInit {

  // Это входящая структура
  @Input() module: EducationStandardStructureBackend;
  @Input() selectedItem;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() save: EventEmitter<any> = new EventEmitter<any>();

  public description = '';
  public credits = 0;

  constructor() { }

  ngOnInit() {
    this.description = this.selectedItem.discipline_description;
    this.credits = this.selectedItem.basic_credits;
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  numberOnly(event): boolean {
    // tslint:disable:no-magic-numbers
    return !(event.which > 31 && (event.which < 48 || event.which > 57));
  }

  cancelCallback() {
    if (this.cancel) {
      this.cancel.emit();
    }
  }

  saveCallback() {
    this.save.emit({
      id: this.selectedItem.id,
      structure_id: this.module.id,
      discipline_description: this.description || '',
      basic_credits: this.credits || 0,
    });
    this.cancel.emit();
  }
}
