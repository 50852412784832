import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CustomCookieService {

  private readonly path = '/';
  private readonly expires = null;

  constructor(private cookies: CookieService) { }

  check(name: string): boolean {
    return this.cookies.check(name);
  }

  get(name: string): string {
    return this.cookies.get(name);
  }

  getAll(): {} {
    return this.cookies.getAll();
  }

  set(name: string, value: string): void {
    this.cookies.set(name, value, this.expires, this.path);
  }

  delete(name: string): void {
    this.cookies.delete(name, this.path);
  }

  deleteAll(): void {
    this.cookies.deleteAll(this.path);
  }
}
