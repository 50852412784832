import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DiplomService {

  BASE_URL = '/api';

  constructor(private http: HttpClient) { }

  getDiplom(params): Promise<any> {
    return this.http.get<any>(`${this.BASE_URL}/get_diploma` + this.makeParams(params)).toPromise();
  }

  private makeParams(params) {
    let result = '';
    if (params) {
      const data = [];
      for (const param in params) {
        if (params.hasOwnProperty(param)) {
          data.push(`${param}=${params[param]}`);
        }
      }
      result = `?${data.join('&')}`;
    }

    return result;
  }

}
