import { Component, Input, OnInit } from '@angular/core';
import { ExpandableLeftMenuService } from '@shared/components/expandable-left-menu/expandable-left-menu.service.service';

@Component({
  selector: 'hse-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  @Input() isOpen: boolean;

  public rightPanelWidth = 254;

  constructor(public leftMenuService: ExpandableLeftMenuService) { }

  ngOnInit() {
  }

}
