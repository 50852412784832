<div class="left-menu fx column space-between">
    <section>
        <hse-icon name="logo3" class="logo"></hse-icon>
        <!--<div class="niu-hse">{{ 'HSE-main.NIU-HSE' | translate }}</div>-->
        <!--<h2 class="constructor-title">{{ 'HSE-main.TITLE' | translate }}</h2>-->
        <div class="constructor-desc">
            <div>
                {{ 'HSE-main.TEXT-1' | translate }}
                <a href="https://it.hse.ru/lk" target="_blank" rel="noopener">https://it.hse.ru/lk</a>.
            </div>
            <div>
                {{ 'HSE-main.TEXT-2' | translate }}
            </div>
        </div>
    </section>
    <hse-user-info></hse-user-info>
</div>
