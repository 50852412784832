<div class="hse-user-info fx h-v-center" *ngIf="authService.session && loaded">

    <div class="menu-trigger__wrapper">
        <div [matMenuTriggerFor]="menu"
             #t="matMenuTrigger"
             class="menu-trigger"
             [ngClass]="{ 'opened': t.menuOpen }"
             (menuOpened)="open()">

            <hse-icon class="menu-trigger__down"
                      style="color: #2953fc"
                      [name]="'download'"></hse-icon>
            <div class="menu-trigger__title">Выгрузить</div>
            <hse-icon *ngIf="t.menuOpen" name="chevronLeft" class="menu-trigger__arrow"></hse-icon>
            <hse-icon *ngIf="!t.menuOpen" name="chevronRight" class="menu-trigger__arrow"></hse-icon>
        </div>
    </div>

    <mat-menu #menu="matMenu"
              tabindex="-1"
              class="custom-unload-menu"
              [overlapTrigger]="false">
        <div class="unload-menu__list">
            <div *ngFor="let item of items" class="item" (click)="unloadFile(item)">
                <div class="item__info fx row v-center">
                    <hse-icon class="item__type" [name]="item.type"></hse-icon>
                    <div class="item__filename">
                        <span>{{item.caption}}</span>
                    </div>
                </div>
            </div>
        </div>
    </mat-menu>

    <div class="approve__modal__wrapper fx row h-v-center" *ngIf="showYearSelector">
        <div class="approve__modal__container fx column">
            <div class="approve__modal__title">
                <span>Выбор года для выгрузки печатной формы</span>

                <hse-icon [name]="'close'"
                          class="approve__modal__close"
                          (click)="showYearSelector = false"></hse-icon>
            </div>

            <div class="select-year">
                <hse-select [collection]="periods"
                            [(ngModel)]="selectedPeriod"
                            (ngModelChange)="selectPeriod($event)"
                            [disablePlaceholderAnimation]="true"
                            [width]="230"
                            [placeholder]="'Выбрать'"
                            [nameField]="'description'"
                ></hse-select>
            </div>

            <div class="approve__modal__controls fx row space-around">
                <hse-button (callback)="unloadFile(selectedItem, selectedPeriod)"
                            [width]="160"
                            [disabled]="!selectedPeriod"
                            [color]="'primary'">Выгрузить</hse-button>
                <hse-button (callback)="showYearSelector = false"
                            [width]="160"
                            [color]="'secondary'">Отмена</hse-button>
            </div>
        </div>
    </div>

</div>
