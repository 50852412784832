import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { HseTableColumn } from '@shared/components/common-ui/hse-table-new/hse-table-column.interface';
import { find as _find, includes as _includes } from 'lodash/fp';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'hse-table-column-settings',
  templateUrl: './hse-table-column-settings.component.html',
  styleUrls: ['./hse-table-column-settings.component.scss']
})
export class HseTableColumnSettingsComponent implements OnInit {

  @Input() config: HseTableColumn[];
  @Input() column: HseTableColumn;
  @Input() fixationAvailable = true;
  @Input() orderAvailable = true;
  @Output() columnHide: EventEmitter<any> = new EventEmitter<any>();
  @Output() columnSort: EventEmitter<any> = new EventEmitter<any>();
  @Output() columnFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() columnMove: EventEmitter<any> = new EventEmitter<any>();
  @Output() columnFixationChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() columnsReset: EventEmitter<any> = new EventEmitter<any>();
  @Output() inputCallback: EventEmitter<any> = new EventEmitter<any>();

  dragColumnFromIx: number;
  dragElement: HTMLElement;

  mouseMoveListener: any;
  mouseMoveUpListener: any;
  pageYOffset = 0;
  inputStr = '';

  translations = {
    CHOOSE: ''
  };

  constructor(private translate: TranslateService) {
  }

  ngOnInit() {
    this.translations.CHOOSE = this.translate.instant('UI.TABLE.CHOOSE');
  }

  toggleColumnHidden($event, column) {
    $event.stopPropagation();
    this.columnHide.emit(column);
  }

  toggleColumnFixed($event, column) {
    $event.stopPropagation();
    column.fixed = !column.fixed;
    this.columnFixationChanged.emit(column);
  }

  sortColumn($event, sortDirection) {
    $event.stopPropagation();
    this.column.sortDir = this.column.sortDir === sortDirection ? null : sortDirection;
    this.columnSort.emit(this.column);
  }

  filterByColumn($event?) {
    if ($event) {
      this.column.filter.selectedValue = $event;
    }
    this.columnFilter.emit(this.column);
  }

  onDragColumn($event, index) {
    const wrapperElement = _find((element: any) => {
      return _includes('drag-wrapper')(element.classList);
    })($event.path);

    this.dragElement = wrapperElement.cloneNode(true);
    this.dragElement.classList.add('dragged-item');
    document.body.appendChild(this.dragElement);
    this.dragColumnFromIx = index;

    this.mouseMoveListener = this.onDragOver.bind(this);
    window.addEventListener('mousemove', this.mouseMoveListener);

    this.mouseMoveUpListener = this.onMouseUp.bind(this);
    window.addEventListener('mouseup', this.mouseMoveUpListener);
  }

  onMouseUp(event) {
    event.preventDefault();
    this.onDragEnd();
    window.removeEventListener('mousemove', this.mouseMoveListener, false);
    window.removeEventListener('mouseup', this.mouseMoveUpListener, false);
  }


  onDropColumn($event, index) {
    this.columnMove.emit({from: this.dragColumnFromIx, to: index, columns: this.config});
  }

  onDragOver($event) {
    this.dragElement.style.left = $event.clientX + 'px';
    this.dragElement.style.top = $event.clientY + this.pageYOffset + 'px';
  }

  onDragEnd() {
    this.dragColumnFromIx = null;
    if (this.dragElement) {
      document.body.removeChild(this.dragElement);
    }
  }

  resetColumnsSettings() {
    this.columnsReset.emit();
  }

  menuOpened() {
    this.pageYOffset = window.pageYOffset;
  }

  stringChanged(str) {
    this.inputCallback.emit(str);
  }

  checkInput($event) {
    if ($event.keyCode === 13) {
      this.stringChanged(this.inputStr);
    }

    if (this.column.inputMode === 'number') {
      if (!(($event.keyCode >= 48 && $event.keyCode <= 57) || ($event.keyCode >= 96 && $event.keyCode <= 105)) && !_includes($event.keyCode)([8, 46, 17, 86])) {
        $event.preventDefault();
      }
    }
  }
}
