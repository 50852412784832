<div class="hse-user-info fx h-v-center"
     *ngIf="authService.session && loaded">
	<div class="menu-trigger__wrapper">
		<div [matMenuTriggerFor]="menu"
		     #t="matMenuTrigger"
		     class="menu-trigger"
		     (click)="eventsService.getEventsForPopup()"
		     [ngClass]="{ 'opened': t.menuOpen }">
			<hse-icon [name]="'bell'"></hse-icon>
			<!--<div *ngIf="eventsService.haveNewEvents" class="main__mark"></div>-->
			<div *ngIf="eventsService.counter" class="counter fx row h-v-center">
				<span>{{ eventsService.counter > 99 ? '∞' : eventsService.counter }}</span>
			</div>
		</div>
	</div>
	<mat-menu #menu="matMenu"
	          tabindex="-1"
	          class="custom-events-menu"
	          [overlapTrigger]="false">
		<div class="events-menu__title fx row h-v-center" (click)="$event.stopPropagation()">
			<span>Уведомления</span>
		</div>

		<div class="events-menu__empty" *ngIf="!eventsService.events.length" (click)="$event.stopPropagation()">
			<span>Новых уведомлений нет</span>
		</div>

		<div class="events-menu__list"
		     *ngIf="eventsService.events.length" (click)="$event.stopPropagation()">
			<div *ngFor="let event of eventsService.events"
			     [class.not-read]="!event.delivered"
			     (mouseover)="markNotification(event)"
			     class="event">
				<div class="event__info fx row space-between">
					<div class="event__name">
						<span>{{ event.theme_notification }}</span>
					</div>
					<div class="event__text fx row">
						<span class="time">{{ event.date }}</span>
						<span class="time">{{ event.time }}</span>
					</div>
				</div>
				<div class="event__text" [style.font-weight]="event.delivered ? 400 : 600">
					<span [innerHtml]="getEventText(event)"></span>
				</div>

				<div class="event__mark"
				     [ngClass]="getEventClass(event)"></div>
			</div>
		</div>

		<div class="show-more fx row h-v-center">
			<hse-button [color]="'primary'" (click)="showAll()">Показать все</hse-button>
		</div>
	</mat-menu>
</div>
