<div class="hse-radio-group" [class.as-line-row]="asLineRow" [id]="radioGroupId">
    <div class="hse-radio-group-btn"
         *ngFor="let item of radioGroupItems | slice: 0: isOpened ? radioGroupItems.length : visibleItemsCount">
        <input type="radio"
               [(ngModel)]="selectedValue"
               value="{{item[idField]}}"
               [disabled]="item.disabled"
               name="{{radioGroupName}}"
               id="{{radioGroupName}}_{{item[idField]}}"
               (change)="changeBtn(item[idField])">
        <label for="{{radioGroupName}}_{{item[idField]}}">{{item[nameField]}}</label>
    </div>
    <div *ngIf="visibleItemsCount" class="show-all-option-toggle">
        <span (click)="isOpened = !isOpened">{{ isOpened ? translations.HIDE : translations.SHOW }}</span>
    </div>
</div>
