export const menuItems = [
  {
    title: 'Образовательные стандарты',
    match: ['educational_standards'],
    translate: 'HSE-main.LIST.STANDARD',
    url: '/constructor/educational_standards/list',
    icon: 'menuEducationalStandards',
    active: false,
    allow: ['isUROP', 'isAdmin', 'isUAD'],
    disallow: [],
    visible: false,
    expandable: false,
    expanded: false
  },
  {
    title: 'Учебные планы',
    match: ['study_plan'],
    translate: 'HSE-main.LIST.PLAN',
    url: '',
    icon: 'menuLearnPlan',
    active: false,
    allow: [],
    disallow: [],
    visible: false,
    expandable: true,
    expanded: false,
    children: [
      {
        title: 'Учебные планы основных образовательных программ',
        match: ['study_plan/list'],
        translate: 'Учебные планы основных образовательных программ',
        url: '/constructor/study_plan/list',
        active: false,
        allow: ['isUROP', 'isAdmin', 'isManagerOP', 'isManagerASH', 'isUAD', 'isAcademicDeputy',
          'isAcademicDirectorASH', 'isFacultyDecan', 'isProrecror', 'isFilialDeputy', 'isDOOP', 'isReadOnly'],
        disallow: [],
        visible: false,
        expandable: false,
        expanded: false,
      },
      {
        title: 'Учебные планы общевузовских дисциплин',
        match: ['study_plan_common'],
        translate: 'Учебные планы общевузовских дисциплин',
        url: '/constructor/study_plan_common/list',
        active: false,
        allow: ['isUROP', 'isAdmin', 'isMezhvuzManager', 'isManagerOP', 'isAcademicDeputy', 'isFacultyDecan', 'isProrecror', 'isFilialDeputy', 'isDOOP', 'isReadOnly'],
        disallow: [],
        visible: false,
        expandable: false,
        expanded: false,
      },
      {
        title: 'Учебные планы факультетских дисциплин',
        match: ['study_plan_faculty'],
        translate: 'Учебные планы факультетских дисциплин',
        url: '/constructor/study_plan_faculty/list',
        active: false,
        allow: ['isUROP', 'isAdmin', 'isManagerOP', 'isAcademicDeputy', 'isFacultyDecan', 'isProrecror', 'isFilialDeputy', 'isDOOP', 'isReadOnly'],
        disallow: [],
        visible: false,
        expandable: false,
        expanded: false,
      },
      {
        title: 'Учебные планы дополнительных модулей',
        match: ['study_plan_additional'],
        translate: 'Учебные планы дополнительных модулей',
        url: '/constructor/study_plan_additional/list',
        active: false,
        allow: [],
        disallow: ['isMezhvuzManager', 'isManagerOP', 'isManagerASH', 'isUAD', 'isAcademicDirectorASH', 'isAcademicDeputy'],
        visible: false,
        expandable: false,
        expanded: false,
      }
    ]
  },
  {
    title: 'Дисциплины кафедры',
    match: ['department_discipline'],
    translate: 'HSE-main.LIST.DEPARTMENT-DISCIPLINE',
    url: '/constructor/department_discipline/list',
    icon: 'menuDepartmentDisciplines',
    active: false,
    allow: [],
    disallow: [],
    visible: false,
    expandable: false,
    expanded: false
  },
  {
    title: 'Справочники',
    match: ['directories'],
    translate: 'HSE-main.LIST.CATALOG',
    url: '',
    icon: 'menuCatalogue',
    active: false,
    allow: [],
    disallow: [],
    visible: false,
    expandable: true,
    expanded: false,
    children: [
      {
        title: 'Дисциплины ВУЗа',
        match: ['/directories/university_discipline/list'],
        translate: 'HSE-main.LIST.UNIVERSITY-DISCIPLINE',
        url: '/constructor/references/university_discipline/list',
        active: false,
        allow: [],
        disallow: ['isReadOnly'],
        visible: false,
      },
      {
        title: 'Ключевые образовательные результаты',
        match: ['/directories/education_results'],
        translate: 'HSE-main.LIST.EDUCATION-RESULTS',
        url: '/constructor/references/education_results',
        active: false,
        allow: [],
        disallow: ['isReadOnly', 'isMezhvuzManager', 'isDepartmentManager'],
        visible: false,
      },
      {
        title: 'Компетенции',
        match: ['/directories/competencies'],
        translate: 'HSE-main.LIST.COMPETENCIES',
        url: '/constructor/references/competencies',
        active: false,
        allow: [],
        disallow: ['isReadOnly', 'isMezhvuzManager', 'isDepartmentManager', 'isTeacher'],
        visible: false,
      },
      {
        title: 'Области профессиональной деятельности',
        match: ['/directories/professional_fields'],
        translate: 'HSE-main.LIST.PROFESSIONAL-FIELDS',
        url: '/constructor/translation_catalogues/professional_fields',
        active: false,
        allow: [],
        disallow: ['isReadOnly', 'isMezhvuzManager', 'isDepartmentManager', 'isTeacher'],
        visible: false,
      },
      {
        title: 'Типы задач профессиональной деятельности',
        match: ['/directories/professional_objectives'],
        translate: 'HSE-main.LIST.PROFESSIONAL-OBJECTIVES',
        url: '/constructor/references/professional_objectives',
        active: false,
        allow: [],
        disallow: ['isReadOnly', 'isMezhvuzManager', 'isDepartmentManager', 'isTeacher'],
        visible: false,
      },
      {
        title: 'Наименования разделов',
        match: ['/directories/directory_names'],
        translate: 'HSE-main.LIST.DIRECTORY-NAMES',
        url: '/constructor/references/directory_names',
        active: false,
        allow: ['isUROP', 'isAdmin', 'isUAD', 'isManagerOP', 'isManagerASH', 'isTeacher', 'isDOOP'],
        disallow: [],
        visible: false,
      },
      {
        title: 'Специализации',
        match: ['/directories/specializations'],
        translate: 'HSE-main.LIST.SPECIALIZATIONS',
        url: '/constructor/references/specializations/list',
        active: false,
        allow: [],
        disallow: [],
        visible: false,
      },
      {
        title: 'Образовательные программы',
        match: ['/directories/learn_programs'],
        translate: 'HSE-main.LIST.LEARN-PROGRAMS',
        url: '/constructor/references/learn_programs/list',
        active: false,
        allow: ['isAdmin'],
        disallow: [],
        visible: false,
      },
      {
        title: 'Дистанционные курсы',
        match: ['/directories/distance_courses'],
        translate: 'HSE-main.LIST.DISTANCE-COURSES',
        url: '/constructor/references/distance_courses/list',
        active: false,
        allow: ['isAdmin'],
        disallow: [],
        visible: false,
      },
      {
        title: 'Платформы дистанционных курсов',
        match: ['/directories/distance_courses_platforms'],
        translate: 'HSE-main.LIST.DISTANCE-COURSES-PLATFORMS',
        url: '/constructor/references/distance_courses_platforms',
        active: false,
        allow: ['isAdmin'],
        disallow: [],
        visible: false,
      }
    ]
  },
];
