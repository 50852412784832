<input class="hse-switch__input"
       type="checkbox"
       [disabled]="disabled"
       [id]="switchId"
       [(ngModel)]="model"
       (ngModelChange)="onChange($event)"
>
<label class="hse-switch__label" for="{{ switchId }}">
    <ng-content></ng-content>
</label>
