import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import moment from '@tools/moment.local';
import { Moment } from 'moment';

import {
  compact as _compact,
  times as _times,
  includes as _includes,
  isUndefined as _isUndefined,
  flow as _flow,
  forEach as _forEach,
  concat as _concat,
  noop as _noop
} from 'lodash/fp';
import { TranslateService } from '@ngx-translate/core';

export interface WeekDay {
  date: any;
  className?: string;
  isCurrentMonth: boolean;
  isToday: boolean;
  moment: Moment;
}

@Component({
  selector: 'hse-date-picker-calendar',
  templateUrl: './hse-date-picker-calendar.component.html',
  styleUrls: ['./hse-date-picker-calendar.component.scss']
})
export class HseDatePickerCalendarComponent implements OnInit, OnChanges {

  @Input() dateStart?: any;
  @Input() dateEnd?: any;
  @Input() minDate?: any;
  @Input() maxDate?: any;
  @Input() format?: string;
  @Input() weekMode?: boolean;
  @Input() withReset?: boolean;

  @Output() changeModel?: EventEmitter<any> = new EventEmitter<any>();

  public weekDays;

  public weeks: Array<Array<WeekDay>> = [];
  public now;
  public hoverStart: any;
  public hoverEnd: any;
  public isRange: boolean;
  private lastEditedDate: string;

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.weekDays = [
      {number: 1, name: this.translate.instant('UI.CALENDAR.MONDAY')},
      {number: 2, name: this.translate.instant('UI.CALENDAR.TUESDAY')},
      {number: 3, name: this.translate.instant('UI.CALENDAR.WEDNESDAY')},
      {number: 4, name: this.translate.instant('UI.CALENDAR.THURSDAY')},
      {number: 5, name: this.translate.instant('UI.CALENDAR.FRIDAY')},
      {number: 6, name: this.translate.instant('UI.CALENDAR.SATURDAY')},
      {number: 0, name: this.translate.instant('UI.CALENDAR.SUNDAY')}
    ];

    this.isRange = !_isUndefined(this.dateEnd);
    this.initMonth();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isAvailable = (changes.dateStart && !changes.dateStart.isFirstChange())
        || (changes.dateEnd && changes.dateEnd.isFirstChange());

    if (isAvailable) {
      this.isRange = !_isUndefined(this.dateEnd);
      this.initMonth();
    }
  }

  initMonth() {
    this.now = moment.isMoment(this.dateStart) ? this.dateStart.clone() : moment();
    this.makeMonth();
  }

  nextMonth() {
    if (this.maxDate && this.now.isSame(this.maxDate, 'month')) {
      return;
    }
    this.now.add(1, 'month');
    this.makeMonth();
  }

  prevMonth() {
    if (this.minDate && this.now.isSame(this.minDate, 'month')) {
      return;
    }
    this.now.subtract(1, 'month');
    this.makeMonth();
  }

  makeMonth() {
    const start = this.now.clone().startOf('month');
    const end = this.now.clone().endOf('month');
    const startDay = start.day();
    this.weeks = [];

    if (startDay > 1 || startDay === 0) {
      const sundayNumber = 6;
      const times = startDay === 0 ? sundayNumber : (startDay - 1);
      start.subtract(times, 'd');
    }

    while (start.isBefore(end, 'd')) {
      const week = [];
      const daysByWeek = 7;
      _times(() => {
        week.push({
          date: start.date(),
          isCurrentMonth: start.month() === this.now.month(),
          isToday: start.isSame(moment(), 'd'),
          moment: start.clone()
        });
        start.add(1, 'd');
      })(daysByWeek);
      this.weeks.push(week);
    }

    this.makeDayStyles();
  }

  makeDayStyles() {
    _forEach((week: WeekDay[]) => {
      _forEach((day: WeekDay) => {
        let active = false;
        if (this.dateStart && this.dateEnd) {
          active = day.moment.isBetween(this.dateStart, this.dateEnd, 'day', '[]');
        }

        const isStart = active && day.moment.isSame(this.dateStart, 'day');
        const isEnd = active && day.moment.isSame(this.dateEnd, 'day');
        const singleActive = day.moment.isSame(this.dateStart, 'day')
            && ((this.dateStart && !this.dateEnd) || (this.dateStart && this.dateEnd && this.dateStart.isSame(this.dateEnd, 'day')));
        const isDisabled = this.isDayDisabled(day);
        const isWeekEnd = this.isWeekend(day);

        const commonClasses = [
          'day',
          !day.isCurrentMonth && 'not-current-month',
          day.isToday && 'today',
          active && 'active',
          singleActive && 'single-active',
          isStart && 'start',
          isEnd && 'end',
          isDisabled && 'is-disabled-day',
          isWeekEnd && 'is-weekend'
        ];

        let hoverClasses = [];
        if (this.isRange) {
          const isHoverStart = day.moment.isSame(this.hoverStart, 'day');
          const isHoverEnd = day.moment.isSame(this.hoverEnd, 'day');
          const isInHover = day.moment.isBetween(
              this.hoverStart || this.dateEnd || (!this.dateEnd && this.dateStart),
              this.hoverEnd || this.dateStart
          );

          const isHoverRootRight = (isEnd || singleActive) && this.hoverEnd && !this.weekMode;
          const isHoverRootLeft = (isStart || singleActive) && this.hoverStart && !this.weekMode;

          hoverClasses = [
            isHoverStart && 'hover-start',
            isHoverEnd && 'hover-end',
            isInHover && 'hover-in',

            isHoverRootLeft && 'hover-root-left',
            isHoverRootRight && 'hover-root-right'
          ];
        }

        day.className = _flow(
            _concat(hoverClasses),
            _compact
        )(commonClasses).join(' ');
      })(week);
    })(this.weeks);
  }

  isDayDisabled(day: WeekDay): boolean {
    const minDate = this.minDate && this.weekMode ? this.minDate.clone().endOf('week') : this.minDate;
    const maxDate = this.maxDate && this.weekMode ? this.maxDate.clone().startOf('week').add(-1, 'day') : this.maxDate;

    return (minDate && day.moment.isBefore(minDate)) || (maxDate && day.moment.isAfter(maxDate));
  }

  isWeekend(day: WeekDay): boolean {
    return _includes(day.moment.format('d'))(['0', '6']);
  }

  selectDay(day: WeekDay) {
    if (this.isDayDisabled(day)) {
      return;
    }

    if (!this.isRange) {
      this.dateStart = day.moment.clone();
    } else if (this.weekMode) {
      this.dateStart = moment(day.moment).startOf('week');
      this.dateEnd = moment(day.moment).endOf('week');
    } else {
      // в режиме выбора периода
      // изначально выбирается дата начала периода
      if (!this.dateStart) {
        this.dateStart = day.moment.clone();
        this.lastEditedDate = 'start';
        this.makeDayStyles();

        return;
      }

      // если выбран день раньше dateStart начало периода смещается на этот день
      if (day.moment.isSameOrBefore(this.dateStart)) {

        // если дата конца периода отсутствует, устанавливаем в него предыдущее значение dateStart
        if (!this.dateEnd) {
          this.dateEnd = this.dateStart.clone();
        }

        this.dateStart = day.moment.clone();
        this.lastEditedDate = 'start';
      } else if (day.moment.isSameOrAfter(this.dateStart)) {
        // если выбран день между dateStart и dateEnd
        if (day.moment.isSameOrBefore(this.dateEnd)) {
          // устанавливаем dateStart или dateEnd в зависимости от типа последнего установленного значения lastEditedDate
          // для того чтобы была возможность задать любой диапазон в любой момент времени
          if (this.lastEditedDate === 'start') {
            this.dateEnd = day.moment.clone();
            this.lastEditedDate = 'end';
          } else {
            this.dateStart = day.moment.clone();
            this.lastEditedDate = 'start';
          }
        } else {
          // иначе выбран день позже dateEnd и конец периода смещается на этот день соответственно
          this.dateEnd = day.moment.clone();
          this.lastEditedDate = 'end';
        }
      }
    }

    this.changeModel.emit({
      dateStart: this.dateStart,
      dateEnd: this.dateEnd
    });

    this.clearHoverRange();
  }

  reset() {
    if (this.weekMode) {
      this.dateStart = moment().startOf('week');
      this.dateEnd = moment().endOf('week');
    } else if (!this.isRange) {
      this.dateStart = null;
      this.dateEnd = _noop();
    } else {
      this.dateStart = null;
      this.dateEnd = null;
    }

    this.initMonth();
    this.changeModel.emit({
      dateStart: this.dateStart,
      dateEnd: this.dateEnd
    });
  }

  hoverDay(day: WeekDay) {
    if (this.weekMode) {
      // устанавливаем период выделения в соответствии с началом и концом недели в которой находиться выделенный день
      // кроме случая если эта неделя уже выбрана
      if (!(this.dateStart && this.dateEnd) || !moment.range(this.dateStart, this.dateEnd).contains(day.moment)) {
        this.hoverStart = day.moment.clone().startOf('week');
        this.hoverEnd = day.moment.clone().endOf('week').startOf('day');
      }
    } else if (this.dateStart) {
      if (day.moment.isBefore(this.dateStart)) {
        this.hoverStart = day.moment;
      }

      if ((this.dateEnd && day.moment.isAfter(this.dateEnd)) || (!this.dateEnd && day.moment.isAfter(this.dateStart))) {
        this.hoverEnd = day.moment;
      }
    }
  }

  clearHoverRange() {
    this.hoverEnd = null;
    this.hoverStart = null;
  }
}
