import { Component, OnInit } from '@angular/core';
import { AuthService } from '@backend/auth/auth.service';
import { UserProfileService } from '@backend/user-profile/user-profile.service';

@Component({
  selector: 'hse-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {

  public roles;
  public loaded = false;

  constructor(public authService: AuthService,
              private userProfile: UserProfileService) { }

  ngOnInit() {
    this.init();
  }

  async init() {
    try {
      this.roles = await this.userProfile.getAvailableRoles();
      this.loaded = true;
    } catch (e) {
      console.error(e);
    }
  }

  selectedRole(role) {
    return role === localStorage.getItem('selectedRole');
  }

  async selectRole(role) {
    try {
      await this.userProfile.selectRole(role);

      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  }
}
