import { AfterViewInit, Directive, ElementRef, Input, NgZone, OnChanges, OnDestroy } from '@angular/core';
import { HseStickyService } from '@shared/modules/hse-sticky/hse-sticky.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import _guid from '@tools/fp/guid';
import { HseStickyEvent } from '@shared/modules/hse-sticky/hse-sticky-event.interface';


/**
 * Директива, позволяющая сделать елементы липкими.
 * stackName - имя стэка, в который необходимо доьавить элемент
 * offsetTop - размер отступа от верха экрана,
 *             в случае объединения элементов в стэк размер отступа от предыдущего элемента в стэке
 * zIndex    - zIndex для липкого элемента,
 *             в случае объединения в стэк учитывается zIndex только первого элемента,
 *             у остальных zIndex равен zIndex предыдущего элемента увеличенного на еденицу
 */
@Directive({
  selector: '[hseSticky]'
})
export class HseStickyDirective implements AfterViewInit, OnDestroy, OnChanges {
  private id: string;
  private mockElement: HTMLElement;
  private htmlElement: HTMLElement;
  private initialBounding: ClientRect | DOMRect;
  private initialStyles: Partial<CSSStyleDeclaration>;
  private scrollSubscription: Subscription = Subscription.EMPTY;

  @Input() stackName: string;
  @Input() offsetTop = 0;
  @Input() offsetLeft = 0;
  @Input() zIndex = 100;
  @Input() width;
  @Input() height;
  @Input() disabled;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private hseSticky: HseStickyService,
    private zone: NgZone
  ) { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.id = _guid();
      this.initialBounding = this.elementRef.nativeElement.getBoundingClientRect();
      this.initialStyles = this.getInitialStyles();
      this.mockElement = this.createMockElement();
      this.htmlElement = document.querySelector('html');

      this.hseSticky.addItem(this.elementRef.nativeElement, this.id, {
        stackName: this.stackName,
        offsetTop: this.offsetTop,
        zIndex: this.zIndex
      });

      this.scrollSubscription = this.hseSticky.events.pipe(
          filter((stickyEvent: any) => stickyEvent.itemId === this.id && !this.disabled)
      ).subscribe((e) => {
        this.setStyles(e);
      });
    });

  }

  ngOnChanges(changes): void {
    if (changes.width) {
      this.elementRef.nativeElement.style.width = this.width + 'px';

      if (this.mockElement) {
        this.mockElement.style.width = this.width + 'px';
      }
    }

    if (changes.height) {
      this.elementRef.nativeElement.style.height = this.height + 'px';
      if (this.mockElement) {
        this.mockElement.style.height = this.height + 'px';
      }
    }

    if (!this.elementRef.nativeElement.parentElement.contains(this.mockElement)) {
      return;
    }

    if (changes.offsetLeft) {
      if (this.mockElement) {
        this.elementRef.nativeElement.style.left = this.offsetLeft + 'px';
      }
    }
  }

  ngOnDestroy() {
    this.scrollSubscription.unsubscribe();
    this.hseSticky.removeItem(this.id, this.stackName);
  }

  // @ts-ignore
  /**
   * Создать объект начальных стилей элемента
   */
  private getInitialStyles(): Partial<CSSStyleDeclaration> {
    const computedStyles = window.getComputedStyle(this.elementRef.nativeElement);
    const left = this.offsetLeft ? `${this.offsetLeft}px` : computedStyles.left;
    const width = this.width ? `${this.width}px` : computedStyles.width;
    const height = this.height ? `${this.height}px` : computedStyles.height;

    return {
      top: computedStyles.top,
      left,
      width,
      height,
      zIndex: computedStyles.zIndex,
      position: computedStyles.position,
      // box-model
      boxSizing: computedStyles.boxSizing,
      paddingTop: computedStyles.paddingTop,
      paddingRight: computedStyles.paddingRight,
      paddingBottom: computedStyles.paddingBottom,
      paddingLeft: computedStyles.paddingLeft,
      borderTop: computedStyles.borderTop,
      borderRight: computedStyles.borderRight,
      borderBottom: computedStyles.borderBottom,
      borderLeft: computedStyles.borderLeft
    };
  }

  /**
   * Создать заглушку, подменяющую элемент
   */
  private createMockElement(): HTMLElement {
    const mockElement: HTMLElement = document.createElement('div');
    Object.assign(mockElement.style, this.initialStyles, {borderColor: 'transparent'});

    return mockElement;
  }

  /**
   * Установить/удалить заглушку и добавить/удалить стили для элемента
   */
  private setStyles(event: HseStickyEvent) {
    const elem: HTMLElement = this.elementRef.nativeElement;
    const {top, left} = this.initialBounding;
    const {y, zIndex, offsetTop} = event;
    const diff = top - y;
    const diffOffset = top + y;
    const condition = (offsetTop < top && diff < offsetTop)
      || (offsetTop >= top && diffOffset >= offsetTop);

    this.zone.runOutsideAngular(() => {
      requestAnimationFrame(() => {
        if (condition) {
          const width = this.width ? `${this.width}px` : this.initialStyles.width;
          const height = this.height ? `${this.height}px` : this.initialStyles.height;
          elem.style.top = `${offsetTop}px`;
          elem.style.left = `${this.offsetLeft || left}px`;
          elem.style.width = width;
          elem.style.height = height;
          elem.style.zIndex = `${zIndex}`;
          elem.style.position = 'fixed';
          elem.style.transition = '0.175s linear';

          if (!elem.parentElement.contains(this.mockElement)) {
            elem.parentElement.insertBefore(this.mockElement, elem);
          }
          // игнорируем изменения в pageYOffset когда на странице раскрыт select
        } else if (!this.htmlElement.classList.contains('cdk-global-scrollblock')) {
          elem.style.top = '';
          elem.style.left = '';
          elem.style.width = '';
          elem.style.height = '';
          elem.style.zIndex = '';
          elem.style.position = '';
          elem.style.transition = '';

          if (elem.parentElement.contains(this.mockElement)) {
            elem.parentElement.removeChild(this.mockElement);
          }
        }
      });
    });
  }
}
