<div class="menu-container">
  <div #menuTrigger
       [ngStyle]="{'z-index': menuOpened ? 5 : 3}"
       (click)="openMenu($event)"
       class="hse-menu-trigger">
    <ng-content select="[hse-menu-trigger]"></ng-content>
  </div>

  <div class="menu-body"
       (click)="bodyClicked($event)"
       [ngClass]="{'opened': menuOpened}"
       [style.left.px]="offsetX"
       [style.top.px]="offsetY"
       [style.width.px]="width || 200"
       [ngStyle]="customStyle">
    <div *ngIf="addTriangle"
         [style.left.px]="triangleOffset + 15"
         class="menu-triangle"></div>
    <ng-content select="[hse-menu-body]"></ng-content>
  </div>
</div>

<div class="hse-menu-overlay" *ngIf="menuOpened" (click)="clickOutside()"></div>
