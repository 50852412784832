import { Component, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'hse-options-popup',
  templateUrl: './options-popup.component.html',
  styleUrls: ['./options-popup.component.scss']
})
export class OptionsPopupComponent {

  @Input() left: number;
  @Input() top: number;
  @Input() params: any;
  @Input() config: {title: string; callback: string}[];
  @Input() width = 180;

  @Output() callback: EventEmitter<any> = new EventEmitter<any>();

  runCallback(callback: string) {
    const result = Object.assign(this.params || {}, {callback});
    this.callback.emit(result);
  }
}
