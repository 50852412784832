import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  BASE_URL = '/api';
  API_URL = '/bp/getcomment';
  SAVE_URL = '/bp/savecomment';
  UPDATE_URL = '/bp/updatecomment';
  DELETE_URL = '/bp/delcomment';

  constructor(private http: HttpClient) { }

  getComments(params?): Promise<Array<any>> {
    return this.http.get<Array<any>>(`${this.BASE_URL}${this.API_URL}` + this.makeParams(params)).toPromise();
  }

  saveComment(data): Promise<any> {
    return this.http.post(`${this.BASE_URL}${this.SAVE_URL}`, data).toPromise();
  }

  updateComment(data, id): Promise<any> {
    return this.http.post(`${this.BASE_URL}${this.UPDATE_URL}?id=${id}`, data).toPromise();
  }

  deleteComment(id): Promise<any> {
    return this.http.post(`${this.BASE_URL}${this.DELETE_URL}?id=${id}`, {}).toPromise();
  }

  private makeParams(params) {
    let result = '';
    if (params) {
      const data = [];
      for (const param in params) {
        if (params.hasOwnProperty(param)) {
          const paramToAppend = typeof params[param] === 'object' ? JSON.stringify(params[param]) : params[param];

          data.push(`${param}=${paramToAppend}`);
        }
      }
      result = `?${data.join('&')}`;
    }

    return result;
  }
}
