<div class="hse-range-date-picker">
    <hse-menu [offsetX]="offsetXMenu"
              [offsetY]="offsetYMenu"
              [width]="widthMenu"
              [addTriangle]="useTriangle"
              [triangleOffset]="triangleOffsetMenu"
              [customStyle]="customStyleMenu"
              [disabled]="disabled"
              (menuIsOpened)="calendarOpen($event)">
        <div hse-menu-trigger (click)="focusInput()">
            <div class="menu-trigger fx space-between v-center"
                 [class.is-open]="isOpenCalendar"
                 [class.is-disabled]="disabled"
                 [style.width.px]="isRange ? 321 : width ? width : 200">
                <span [class.hse-range-date-picker-placeholder]="!inputModel && placeholder" *ngIf="!enableManualInput || !showInput">
                    {{ inputModel || placeholder }}
                </span>
                <input [(ngModel)]="manualValue"
                       [maxLength]="10"
                       (ngModelChange)="checkMask($event)"
                       [class.hidden]="!enableManualInput || !showInput"
                       (blur)="validateManualValue()"
                       #manualInput>
                <hse-icon name="calendar" class="hse-range-date-picker-icon"></hse-icon>

                <hse-icon class="hse-input__lightning" name="lightning" *ngIf="valueChanged"></hse-icon>
            </div>
        </div>
        <div hse-menu-body>
            <hse-date-picker-calendar (changeModel)="changeDates($event)"
                                      [dateStart]="dateStartValue"
                                      [dateEnd]="dateEndValue"
                                      [withReset]="withReset"
                                      [minDate]="minDate"
                                      [maxDate]="maxDate"
                                      [weekMode]="weekMode"></hse-date-picker-calendar>
        </div>
    </hse-menu>
</div>
