import { Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatMenu } from '@angular/material/menu';
import { HseSelectId } from '@shared/components/common-ui/hse-select/hse-select-id.type';

import { compact as _compact, filter as _filter, find as _find, isNull as _isNull, noop as _noop } from 'lodash/fp';
import _int from '@tools/fp/int';
import { TranslateService } from '@ngx-translate/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';


@Component({
  selector: 'hse-select',
  templateUrl: './hse-select.component.html',
  styleUrls: ['./hse-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HseSelectComponent),
      multi: true
    }
  ]
})
export class HseSelectComponent implements OnInit, OnChanges, ControlValueAccessor {
  private defaultWidth = 377;
  private defaultHeight = 193;
  private showOptionsList = false;
  private modelValue: HseSelectId;

  public filteredCollection: any[];
  public searchString: string;
  public selected: any = null;
  public onChange: any = _noop;
  public onTouched: any = _noop;

  @Input() collection: any[];
  @Input() optionTpl?: any;
  @Input() idField?: string;
  @Input() nameField?: string;
  @Input() searchField?: string;
  @Input() nullText?: string;
  @Input() placeholder?: string;
  @Input() width?: string;
  @Input() disabledText?: string;
  @Input() disabled?: boolean;
  @Input() customStyle?: any;
  @Input() disablePlaceholderAnimation = false;
  @Input() searchPlaceholder?: string;
  @Input() useSearch = false;
  @Input() equalWidth = true;
  @Input() xPos = 'after';
  @Input() yPos = 'below';
  @Input() height: string;
  @Input() customInvalid = false;
  @Input() useTooltip = false;
  @Input() tooltipField = '';
  @Input() valueChanged = false;
  @Input() isBigList?: boolean;

  @Output() menuClosed?: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('menu', { static: false }) menu: MatMenu;
  @ViewChild('virtualScrollViewport', { static: false }) virtualScrollViewport: CdkVirtualScrollViewport;

  constructor(private elementRef: ElementRef, private translate: TranslateService) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (Boolean(changes.collection) && !changes.collection.isFirstChange()) {
      this.setSelectedItem(this.modelValue);
      this.filteredCollection = [...this.collection];
    }
  }

  ngOnInit() {
    this.idField = this.idField || 'id';
    this.nameField = this.nameField || 'name';
    if (!Boolean(this.searchField)) {
      this.searchField = this.nameField;
    }
    this.placeholder = this.placeholder || this.translate.instant('UI.SELECT.SELECT');
    this.searchPlaceholder = this.searchPlaceholder || this.translate.instant('UI.SELECT.FIND') + '...';

    this.filteredCollection = [...this.collection];
  }

  writeValue(value: HseSelectId) {
    this.changeValue(value, false);
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  /**
   * Изменить значение селекта
   */
  changeValue(value: HseSelectId, isEmitValue = true) {
    this.setSelectedItem(value);
    this.modelValue = Boolean(this.selected)
        ? this.selected[this.idField]
        : null;

    if (isEmitValue) {
      this.onChange(this.modelValue);
    }
  }

  /**
   * callback на открытие выпадающего списка
   */
  optionListOpened() {
    this.showOptionsList = true;

    // для корректной работы [hseValMessage]
    setTimeout(() => {
      this.menu['_elementRef'].nativeElement.focus();
      if (this.isBigList && this.virtualScrollViewport) {
        this.virtualScrollViewport.checkViewportSize();
      }
    });
  }

  /**
   * callback на закрытие выпадающего списка
   */
  optionListClosed() {
    this.showOptionsList = false;
    this.onTouched();
    this.menuClosed.emit(this.modelValue);
  }

  /**
   * Выбрать значение в селекте
   * value - idField выбранного элемента
   */
  setSelectedItem(value: HseSelectId) {
    this.selected = !_isNull(value)
        ? _find<any>((item) => item[this.idField] === value)(this.collection)
        : null;
  }

  /**
   * Получить отображаемое имя выбранного элемента
   */
  get selectedName() {
    return this.selected ? this.selected[this.nameField] : null;
  }

  /**
   * Получить ширину селекта и выпадающего списка
   */
  getWidth() {
    return _int(this.width) || this.defaultWidth;
  }

  /**
   * Получить высоту селекта
   */
  getHeight() {
    return _int(this.height) || this.defaultHeight;
  }

  /**
   * Получить css-классы для заголовка селекта
   * disabled - флаг активности селекта
   */
  getHeaderClasses(disabled) {
    return _compact([
      disabled ? 'hse-select-header-disabled' : 'hse-select-header',
      this.showOptionsList ? 'active' : '',
      this.selected || this.nullText ? 'has-value' : '',
      this.disablePlaceholderAnimation ? 'disabled-placeholder' : '',
      this.customInvalid ? 'custom-invalid' : ''
    ])
        .join(' ');
  }

  /**
   * Получить css-классы для плэйсхолдера селекта
   */
  getPlaceholderClass() {
    return _compact([
      'hse-select-label',
      this.selected || this.nullText ? 'active' : '',
      this.disablePlaceholderAnimation && (this.selected || this.nullText) ? 'disabled' : ''
    ])
        .join(' ');
  }

  /**
   * Получить css-классы для контейнера плэйсхолдера селекта
   */
  getPlaceholderContainerClass() {
    return _compact([
      'hse-select-label-container',
      this.disablePlaceholderAnimation && (this.selected || this.nullText) ? 'disabled' : ''
    ])
        .join(' ');
  }

  /**
   * Получить css-классы для элемента списка
   * item - элемент списка
   */
  getItemClass(item) {
    if (!item) {
      return _compact(['menu-row', this.modelValue === null && 'selected'])
          .join(' ');
    }

    return _compact(['menu-row', this.selected && item[this.idField] === this.selected[this.idField] && 'selected'])
        .join(' ');
  }

  stopPropagation($event: MouseEvent) {
    $event.stopPropagation();
  }

  filterOptions() {
    this.filteredCollection = this.searchString
        ? _filter((item: any) => item[this.searchField].toUpperCase().indexOf(this.searchString.toUpperCase()) > -1)(this.collection)
        : [...this.collection];
  }

  getTooltip(item) {
    if (this.tooltipField) {
      return item[this.tooltipField];
    }

    return this.useTooltip ? item[this.nameField] : '';
  }
}
