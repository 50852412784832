import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';

import { HseAlertComponent } from './component/hse-alert.component';
import { HseAlertService } from '@core/hse-alert/hse-alert.service';


@NgModule({
  declarations: [HseAlertComponent],
  imports: [CommonModule, SharedModule],
  providers: [HseAlertService],
  exports: [HseAlertComponent]
})
export class HseAlertModule { }
