import { Directive, ElementRef, HostListener, Output, EventEmitter, Input } from '@angular/core';

@Directive({
  selector: '[hseInfiniteScroll]'
})
export class InfiniteScrollDirective {

  @Output('hseInfiniteScroll') scrollCallback: EventEmitter<any> = new EventEmitter<any>();
  @Input() distance: number;

  @HostListener('window:scroll', ['$event.target']) onScroll(doc) {
    this.scroll(doc.defaultView);
  }

  constructor(private el: ElementRef) {}

  scroll(doc) {
    const windowHeight = doc.innerHeight;
    const elementBottom = this.el.nativeElement.offsetTop + this.el.nativeElement.offsetHeight;
    const windowBottom = windowHeight + (doc.scrollY || doc.pageYOffset);
    const remaining = elementBottom - windowBottom;

    // @ts-ignore
    if (remaining <= this.distance) {
      setTimeout(() => {
        this.scrollCallback.emit('emit');
      }, 0);
    }
  }
}
