import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface ChangeActionData {
  type: string,
  row?: any,
  data?: any,
  $id?: any,
  url?: string,
}

@Injectable({
  providedIn: 'root'
})
export class UnfinishedChangesIndicatorService {

  public unfinishedChangesEditByYear = false;
  public fire$: Subject<void> = new Subject<void>();
  public actionToDo: ChangeActionData;

  constructor() { }
}
