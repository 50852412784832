<div class="wrapper fx row h-v-center"
     [class.init-wrapper]="initAnimation"
     [class.finish-wrapper]="finishAnimation"
     *ngIf="showDialog">
    <div class="dialog fx column stretch"
         [class.init-dialog]="initAnimation"
         [class.finish-dialog]="finishAnimation">
        <div class="dialog-header fx row v-center space-between">
            <span>{{'SUPPORT-DIALOG.TITLE' | translate}}</span>
            <hse-icon [name]="'close'" (click)="base.closeDialog()"></hse-icon>
        </div>
        <div class="section fx row">
            <hse-select placeholder="{{'SUPPORT-DIALOG.MESSAGE-TYPE' | translate}}"
                        [collection]="base.messageTypes"
                        [width]="560"
                        [(ngModel)]="dialogForm.msg_type_id"></hse-select>
        </div>
        <div class="section fx column">
            <hse-input placeholder="{{'SUPPORT-DIALOG.FULL-NAME' | translate}}"
                       [width]="560"
                       [(ngModel)]="dialogForm.fio"></hse-input>
        </div>
        <div class="section common fx column">
            <hse-input placeholder="{{'SUPPORT-DIALOG.TOPIC' | translate}}"
                       [width]="560"
                       [(ngModel)]="dialogForm.msg_topic"></hse-input>
            <hse-textarea placeholder="{{'SUPPORT-DIALOG.CONTENT' | translate}}"
                          [width]="560"
                          [growOnFocus]="5"
                          [rowsCount]="5"
                          [(ngModel)]="dialogForm.msg_text"></hse-textarea>
        </div>
        <div class="file-section fx row v-center stretch">
            <div class="fx fx-1 row v-center" *ngIf="fileData.name">
                <span>{{fileData.name}}</span>
                <hse-icon class="remove-file" (click)="resetFileData()" [name]="'close'"></hse-icon>
            </div>
            <div class="fx fx-1 file-placeholder" *ngIf="!fileData.name">
                <span>{{'SUPPORT-DIALOG.FILE' | translate}}</span>
            </div>
            <div class="attachment fx row h-v-center">
                <hse-icon (click)="upload()"
                          [name]="'attachment'"></hse-icon>
            </div>
        </div>
        <div class="button fx row h-center">
            <hse-button [color]="'primary'"
                        [disabled]="disableButton()"
                        (click)="postMessage()">{{'SUPPORT-DIALOG.UPLOAD' | translate}}</hse-button>
        </div>
        <input type="file" class="file-upload" (change)="onUpload()" #fileUpload>
    </div>
</div>
