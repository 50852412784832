import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HseIconService } from '@core/hse-icon/hse-icon.service';

@NgModule({
  imports: [CommonModule],
  providers: [HseIconService]
})
export class HseIconModule {

}
