import { Component, Input, OnInit } from '@angular/core';
import { UserProfileService } from '@backend/user-profile/user-profile.service';
import { AuthService } from '@backend/auth/auth.service';
import { PrintFormsUnloadService } from '@backend/kosuip/print-forms-unload/print-forms-unload.service';
import * as moment from 'moment';

import {
  forEach as _forEach
} from 'lodash/fp';

@Component({
  selector: 'hse-print-forms-unload',
  templateUrl: './print-forms-unload.component.html',
  styleUrls: ['./print-forms-unload.component.scss']
})
export class PrintFormsUnloadComponent implements OnInit {

  public roles;
  public loaded = false;
  public items = [];
  public showYearSelector = false;
  public selectedPeriod;
  public selectedItem;

  @Input() id: number;
  @Input() planType?: string;
  @Input() type: string;
  @Input() config?: any[];
  @Input() periods?: any[];

  constructor(public authService: AuthService,
              private userProfile: UserProfileService,
              private unload: PrintFormsUnloadService) {
  }

  ngOnInit() {
    this.init();
  }

  async init() {
    try {
      this.roles = await this.userProfile.getCurrentRoles();
      this.loaded = true;
    } catch (e) {
      console.error(e);
    }
  }

  open() {
    if (!this.config) {
      this.unload.getAllFileType(this.type).subscribe(data => this.items = data);
    } else {
      this.items = [];

      _forEach((configItem: any) => {
        _forEach(($format: any) => {
          this.items.push({
            type: $format,
            caption: configItem.description + ' ' + $format,
            formType: configItem.form_type,
            isRup: configItem.description.indexOf('Печатная форма РУП') !== -1
          });
        })(configItem.available_formats);
      })(this.config);
    }
  }

  toBase64(fileType, file) {
    if (fileType === 'pdf') {

      return 'data:application/pdf;base64,' + file;
    }

    if (fileType === 'docx') {
      return 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,' + file;
    }

    return 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + file;
  }

  downloadFile(name, fileType, file) {
    const downloadLink = document.createElement('a');
    const fileName = name;

    downloadLink.href = file;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  async unloadFile(item: any, year = null) {
    try {
      if (item.isRup && !year) {
        this.showYearSelector = true;
        this.selectedItem = item;
        this.selectedPeriod = null;
      } else {
        this.showYearSelector = false;
        const date = moment().format('YYYYMMDD');
        const fileType = item.type;
        let unload;
        if (this.type === 'plan') {
          if (year) {
            unload = await this.unload.getFileLearnPlanByYear(item.formType, fileType, date, this.id, year);
          } else {
            unload = await this.unload.getFileLearnPlan(item.formType, fileType, date, this.id);
          }
        } else {
          unload = await this.unload.getFilePassport(fileType, this.id);
        }
        const origin = unload.fileData;
        const converted = origin.replace(/'\r\n'/g, '');
        const result = this.toBase64(fileType, converted);

        this.downloadFile(unload.filename, fileType, result);
      }
    } catch (e) {
      console.error('Ошибка выгрузки файла', e);
    }
  }

  selectPeriod($event) {
    this.selectedPeriod = $event;
  }
}
