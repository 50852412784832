/* tslint:disable */
import { Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { StringMaskService } from '@shared/directives/input-mask/string-mask.service';
import { Moment } from 'moment';
import moment from '@tools/moment.local';

import { forEach as _forEach, isNull as _isNull, isString as _isString, isUndefined as _isUndefined, noop as _noop } from 'lodash/fp';
import { DateModel } from '@shared/components/common-ui/hse-date-range-picker/date-model.interface';

@Component({
  selector: 'hse-date-range-picker',
  templateUrl: './hse-date-range-picker.component.html',
  styleUrls: ['./hse-date-range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HseDateRangePickerComponent),
      multi: true
    }
  ]
})
export class HseDateRangePickerComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() minDate?: any;
  @Input() maxDate?: any;
  @Input() format?: any;
  @Input() weekMode?: any;
  @Input() withReset?: boolean;
  @Input() placeholder?: string;
  @Input() openRight?: boolean;
  @Input() disabled?: boolean;
  @Input() enableManualInput?: boolean;
  @Input() enableDateMask?: boolean;
  @Input() offsetY?: string;
  @Input() useTriangle?: boolean;
  @Input() valueChanged = false;
  @Input() width?: number;

  @Output() blur?: EventEmitter<any> = new EventEmitter<any>();
  @Output() focus?: EventEmitter<any> = new EventEmitter<any>();
  @Output() modelChange?: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('manualInput', {static: false}) manualInput: ElementRef;

  constructor(private stringMaskService: StringMaskService) {}

  public onChange: any = _noop;
  public onTouched: any = _noop;

  public inputModel: string;
  public manualValue: string;
  public isOpenCalendar: boolean;
  public dateStartValue: any;
  public dateEndValue: any;
  public isRange: boolean;
  public customStyleMenu = {};
  public offsetXMenu = '0';
  public offsetYMenu = '0';
  public widthMenu = 338;
  public triangleOffsetMenu = 40;
  public showInput = false;

  ngOnInit() {
    this.customStyleMenu = this.openRight === true ? {right: 0} : {};
    this.offsetXMenu = this.openRight === true ? 'auto' : '0';
    this.offsetYMenu = this.offsetY ? this.offsetY : '50';
    this.triangleOffsetMenu = this.openRight === true ? this.widthMenu - 90 : 40;

    const tempMinDate = this.castDateToMoment(this.minDate, 'minDate');
    const tempMaxDate = this.castDateToMoment(this.maxDate, 'maxDate');

    if (Boolean(tempMinDate)) {
      this.minDate = tempMinDate;
    }

    if (Boolean(tempMaxDate)) {
      this.maxDate = tempMaxDate;
    }

    this.format = this.format || 'DD MMMM YYYY';
  }

  ngOnChanges(changes) {
    if (changes.minDate || changes.maxDate) {
      const fieldsToCheck = ['minDate', 'maxDate'];

      _forEach<string>((field) => {
        if (_isString(this[field])) {
          const val = moment(this[field]);

          if (val.isValid()) {
            this[field] = val;
          }
        }
      })(fieldsToCheck);
    }
  }

  writeValue(date: DateModel) {
    if (Boolean(date)) {
      this.isRange = !_isUndefined(date.dateEnd);
      this.dateStartValue = this.castDateToMoment(date.dateStart, 'dateStart');
      this.dateEndValue = this.castDateToMoment(date.dateEnd, 'dateEnd');

      this.createInputModel();
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  focusInput() {
    this.showInput = true;
    this.manualValue = '';
    setTimeout(() => this.manualInput.nativeElement.focus(), 0);
  }

  validateManualValue() {
    const value = moment(this.manualValue, 'DD.MM.YYYY');
    if (!value.isValid()) {
      this.manualValue = '';
      this.showInput = false;

      return;
    }
    this.dateStartValue = value;
    this.showInput = false;
    this.createInputModel();

    this.onChange({dateStart: this.dateStartValue, dateEnd: this.dateEndValue});
    this.modelChange.emit({dateStart: this.dateStartValue, dateEnd: this.dateEndValue})
  }

  checkMask(value: string) {
    if (!this.enableDateMask) {
      return;
    }
    this.manualValue = this.stringMaskService.apply(value, '00.00.0000');
  }

  createInputModel() {
    this.inputModel = '';

    if (this.dateStartValue) {
      this.inputModel = this.dateStartValue.format(this.format);
    }

    if (this.dateEndValue) {
      this.inputModel += ` - ${this.dateEndValue.format(this.format)}`;
    }
  }

  changeDates($event) {
    if ($event.dateStart && $event.dateEnd) {
      // TODO в текущей реализации ezd-menu нет функционала закрытия меню из внешнего компонента
      // this.trigger.closeMenu();
    }

    this.dateStartValue = $event.dateStart;
    this.dateEndValue = $event.dateEnd;
    this.createInputModel();

    this.onChange({dateStart: this.dateStartValue, dateEnd: this.dateEndValue});
    this.modelChange.emit({dateStart: this.dateStartValue, dateEnd: this.dateEndValue});
  }

  calendarOpen(isOpen) {
    if (this.disabled) {
      return false;
    }

    if (isOpen) {
      this.focus.emit();
    } else {
      this.blur.emit();
      this.onTouched();
    }

    this.isOpenCalendar = isOpen;
  }

  private castDateToMoment(date: Date | Moment, fieldName: string): Moment | void {
    if (_isNull(date) || _isUndefined(date)) {
      return date;
    }

    if (!this.checkDateMomentValue(date)) {
      return this.throwErrorMessage(fieldName);
    } else if (date instanceof Date) {
      return moment(date);
    }

    return date;
  }

  private checkDateMomentValue(date: any): boolean {
    return (date instanceof Date) || moment.isMoment(date);
  }

  private throwErrorMessage(fieldName: string) {
    const message = `Компонент <ezd-date-range-picker> ждет в параметре '${fieldName}' `
        + `либо объект Date либо Moment! Передано ${this[fieldName]}`;
    throw new Error(message);
  }
}
