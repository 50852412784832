import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'hse-create-education-result',
  templateUrl: './create-education-result.component.html',
  styleUrls: ['./create-education-result.component.scss']
})
export class CreateEducationResultComponent {

  @Output() close: EventEmitter<{description: string, eng_name: string}> = new EventEmitter();

  public description: string = null;
  public engName: string = null;
}
