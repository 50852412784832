import {
  AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { StringMaskService } from '../../../directives/input-mask/string-mask.service';
import _guid from '../../../../../tools/fp/guid';

@Component({
  selector: 'hse-input-diplom',
  templateUrl: './hse-input-diplom.component.html',
  styleUrls: ['./hse-input-diplom.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HseInputDiplomComponent),
      multi: true
    }
  ]
})
export class HseInputDiplomComponent implements OnInit, AfterViewInit, ControlValueAccessor, OnChanges {
  private innerValue: any;
  public inputId: string;
  @Input() type = 'text';
  @Input() width = 200;
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() min: number;
  @Input() max: number;
  @Input() disablePlaceholderAnimation = false;
  @Input() textMask: string;
  @Input() hideControls = false;

  @Output() focus = new EventEmitter<any>();
  @Output() blur = new EventEmitter<any>();

  @ViewChild('input', { static: true }) inputElem: ElementRef;

  onChange: any = () => {};
  onTouched: any = () => {};

  @Input()
  get model(): string {
    return this.innerValue;
  }

  set model(val) {
    this.innerValue = this.checkMask(val);
    this.onChange(this.innerValue);
  }

  constructor(private stringMaskService: StringMaskService) {

  }

  ngOnInit() {
    this.inputId = _guid();
  }

  ngAfterViewInit() {
    if (this.type === 'number') {
      this.inputElem.nativeElement.setAttribute('min', this.min);
      this.inputElem.nativeElement.setAttribute('max', this.max);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.textMask) {
      if (this.textMask && this.type === 'text') {
        const maxLength = this.textMask.length;
        this.inputElem.nativeElement.setAttribute('maxlength', maxLength);
      }
    }
  }

  writeValue(value): void {
    this.innerValue = this.checkMask(value);
    this.onChange(this.innerValue);
  }

  checkMask(value: string): string {
    let result: string = value;
    if (this.type && this.textMask) {
      result = this.stringMaskService.apply(value, this.textMask);
    }

    return result;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  emitFocusEvent() {
    this.focus.emit(this.innerValue);
  }

  emitBlurEvent() {
    this.blur.emit(this.innerValue);
    this.onTouched();
  }
}
