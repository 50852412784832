import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'hse-info-modal',
  templateUrl: './hse-info-modal.component.html',
  styleUrls: ['./hse-info-modal.component.scss']
})
export class HseInfoModalComponent {
  @Input() title!: string;
  @Input() template!: TemplateRef<void>;
  @Input() width = 550;
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
}
