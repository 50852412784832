<div class="table-filter-popup-wrapper fx fx-1"
     (click)="hidePopup()"
     [style.width.px]="bodyWidth"
     [style.height.px]="bodyHeight">
    <div class="table-filter-popup fx column"
         [style.left.px]="left"
         [style.top.px]="top"
         (click)="abortPropagation($event)">
        <hse-input [placeholder]="'Поиск'"
                   *ngIf="!withoutInput && !debounce"
                   [width]="width"
                   [disablePlaceholderAnimation]="true"
                   (ngModelChange)="setValue($event)"
                   [(ngModel)]="value"></hse-input>
        <hse-input [placeholder]="'Поиск'"
                   *ngIf="!withoutInput && debounce"
                   hseDebounce
                   [debounceTime]="debounce"
                   [width]="width"
                   [disablePlaceholderAnimation]="true"
                   (debounceCallback)="setValue($event)"
                   [(ngModel)]="value"></hse-input>
        <div class="order-item fx row v-center space-between"
             (click)="setOrder('asc')">
            <span>{{ 'UI.TABLE.ASC' | translate }}</span>
            <hse-icon [name]="'check'"
                      *ngIf="order === 'asc'"></hse-icon>
        </div>
        <div class="order-item fx row v-center space-between"
             (click)="setOrder('desc')">
            <span>{{ 'UI.TABLE.DESC' | translate }}</span>
            <hse-icon [name]="'check'"
                      *ngIf="order === 'desc'"></hse-icon>
        </div>
    </div>
</div>
