import {
    AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit,
    Output, SimpleChanges,
    ViewChild
} from '@angular/core';
import {
    flow as _flow,
    map as _map,
    sum as _sum,
    forEach as _forEach,
    reject as _reject,
    filter as _filter,
    remove as _remove,
    isEmpty as _isEmpty,
    findIndex as _findIndex,
    orderBy as _orderBy,
    cloneDeep as _cloneDeep,
    max as _max,
    isUndefined as _isUndefined,
    getOr as _getOr, find as _find
} from 'lodash/fp';
import { HseTableRow } from '@shared/components/common-ui/hse-table-new/hse-table-row.interface';
import { HseTableColumn, IHseTableConfig } from '@shared/components/common-ui/hse-table-new/hse-table-column.interface';
import ResizeObserver from 'resize-observer-polyfill';
import Debounce from 'debounce-decorator';
import { move } from '@tools/fp/move';
import { UserSettingsService } from '@backend/user-settings/user-settings.service';
import { UserSettings, UserTableColumnSettings } from '@backend/user-settings/user-settings.interface';

type BaseHseTableRow = HseTableRow<any>;

const SAVE_DEBOUNCE_DELAY = 1500;

export class HseTableConfig extends Array<HseTableColumn> implements IHseTableConfig {
    name: string;
    settingsSavingDisabled: boolean;

    [index: number]: HseTableColumn;

    saveSettings: () => void;

    constructor(...columnConfigs: Array<HseTableColumn>) {
        super(...columnConfigs);
    }

    resetFilters() {
        _forEach<HseTableColumn>((column) => {
            if (column.filter) {
                column.filter.selectedValue = column.filter.type === 'combo-box' ? [] : null;
                delete column.$settings.filterSelectedValue;
            }
        })(this);

        this.saveSettings();
    }

    setName(tableName: string) {
        this.name = tableName;

        return this;
    }

    getColumn(columnName: string): HseTableColumn {
        return _find<HseTableColumn>({name: columnName})(this);
    }

    setColumnFilterValue(columnName: string, filterValue: any) {
        const column = this.getColumn(columnName);
        if (column) {
            column.$settings.filterSelectedValue = filterValue;
            column.filter.selectedValue = filterValue;
            this.saveSettings();
        }
    }

    setColumns(columnConfigs: Array<HseTableColumn>) {
        for (let ix = 0; ix < columnConfigs.length; ix++) {
            this[ix] = columnConfigs[ix];
        }
    }

    disableSettingsSaving() {
        this.settingsSavingDisabled = true;

        return this;
    }
}

@Component({
    selector: 'hse-table-new',
    templateUrl: './hse-table-new.component.html',
    styleUrls: ['./hse-table-new.component.scss']
})
export class HseTableNewComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

    @Input() config: HseTableConfig;
    defaultConfig: HseTableConfig;

    @Input() optionTpl?: any;
    @Input() isDraggableColumns ? = true;
    @Input() rowsData?: HseTableRow<any>[];
    originRowsData?: HseTableRow<any>[];

    @Input() customStyles?: Partial<CSSStyleDeclaration>;
    @Output() rowClick?: EventEmitter<any> = new EventEmitter<any>();

    isRowClickable = false;

    @ViewChild('table', { static: true }) tableElem: ElementRef;
    @ViewChild('dynamicRowsWrapper', { static: true }) dynamicRowsWrapper: ElementRef;
    @ViewChild('fixedRowsWrapper', { static: true }) fixedRowsWrapper: ElementRef;

    fixedColumns: Array<HseTableColumn> = [];
    dynamicColumns: Array<HseTableColumn> = [];

    tableLeftOffset = 0;
    visibleColumnsWidth = 0;
    isOverflow = false;
    visibleColumns: Array<HseTableColumn> = [];
    tableWidth: number = null;
    fixedColumnsWidth: number = null;
    lastVisibleColumnIx: number = null;
    tableResizeObserver$: ResizeObserver;

    dragDynamicIndexFrom: number;
    dragFixedIndexFrom: number;

    oldWith: number;

    settings: UserSettings;

    allRows: Array<any>;

    constructor(private cdr: ChangeDetectorRef,
                private userSettings: UserSettingsService,
                private ngZone: NgZone) {
    }

    ngOnInit() {
        this.isRowClickable = Boolean(this.rowClick.observers.length);
        this.tableResizeObserver$ = new ResizeObserver((entries) => {
            this.ngZone.run(() => {
                const newWidth = parseFloat(getComputedStyle(entries[0].target).width);

                if (this.oldWith && this.oldWith === newWidth) {
                    this.cdr.detectChanges();
                    return;
                }

                this.oldWith = newWidth;
                this.onResize();
            });
        });

        this.setAllRows();
    }

    ngAfterViewInit() {
        this.ngZone.runOutsideAngular(() => {
            this.tableResizeObserver$.observe(this.tableElem.nativeElement.parentNode);
        });

        this.delayedResize();
    }

    // tslint:disable-next-line:no-magic-numbers
    @Debounce(100)
    delayedResize() {
        this.onResize();
    }

    onResize() {
        this.lastVisibleColumnIx = 0;
        this.tableLeftOffset = 0;
        this.resetWidths();
        this.buildTable();
        this.cdr.detectChanges();
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (!this.config) {
            return;
        }

        if (changes.rowsData) {
            this.originRowsData = _cloneDeep(this.rowsData);
            this.setAllRows();
        }

        this.sortTableByUserSettings();

        if (changes.config) {
            this.defaultConfig = _cloneDeep(this.config);
            this.settings = await this.getTableSettings();
            this.setSaveSettingsCallback();
            this.applySettings();
        } else {
            this.rebuildTable();
        }
    }

    async getTableSettings() {
        if (this.config.settingsSavingDisabled) {
            return {
                name: this.config.name,
                settings: []
            };
        }

        return this.userSettings.getSettings(this.config.name);
    }

    ngOnDestroy() {
        this.tableResizeObserver$.disconnect();
    }

    rebuildTable() {
        this.lastVisibleColumnIx = 0;
        this.tableLeftOffset = 0;
        this.dynamicColumns = _reject<HseTableColumn>((column) => column.fixed || column.hidden)(this.config);
        this.fixedColumns = _filter<HseTableColumn>((column) => column.fixed && !column.hidden)(this.config);
        this.fixedColumnsWidth = _flow(_map('width'), _sum)(this.fixedColumns);
        this.resetWidths();
        setTimeout(() => {
            this.buildTable();
        });
    }

    private buildTable() {
        this.visibleColumns = [];
        this.visibleColumnsWidth = 0;
        const columnWidths = _flow(_map('width'), _sum)(this.dynamicColumns);
        this.tableWidth = parseFloat(getComputedStyle(this.tableElem.nativeElement).width);
        this.isOverflow = this.tableWidth < columnWidths;
        this.setVisibleColumns();
        this.optimizeVisibleColumns();
    }

    private setVisibleColumns() {
        for (let ix = 0; ix < this.dynamicColumns.length; ix++) {
            if (this.visibleColumnsWidth + this.dynamicColumns[ix].width > this.tableWidth) {
                break;
            }
            this.lastVisibleColumnIx = ix;
            this.visibleColumnsWidth += this.dynamicColumns[ix].width;
            this.visibleColumns.push(this.dynamicColumns[ix]);
        }
    }

    private optimizeVisibleColumns() {
        this.visibleColumnsWidth = _flow(_map('optimizedWidth'), _sum)(this.visibleColumns);
        const delta = this.tableWidth - this.visibleColumnsWidth;
        const increment = delta / this.visibleColumns.length;
        _forEach<HseTableColumn>((column) => {
            column.optimizedWidth = column.width + increment;
        })(this.visibleColumns);
        this.cdr.detectChanges();
        this.calculateRowHeights();
    }

    private calculateRowHeights() {
        if (!this.fixedRowsWrapper) {
            return;
        }

        const dynamicRowsNodes = this.dynamicRowsWrapper.nativeElement.querySelectorAll('.hse-table-row__row');
        const fixedRowsNodes = this.fixedRowsWrapper.nativeElement.querySelectorAll('.hse-table-row__row');

        let rowIx = 0;
        _flow(
            _filter({visible: true}),
            _forEach<HseTableRow<any>>((row) => {
                const dCells = dynamicRowsNodes[rowIx].querySelectorAll('.hse-table-row__cell');
                const fCells = fixedRowsNodes[rowIx].querySelectorAll('.hse-table-row__cell');
                const dCellMaxHeight = _flow(_map((cell: Element) => parseFloat(getComputedStyle(cell).height)), _max)(dCells);
                const fCellMaxHeight = _flow(_map((cell: Element) => parseFloat(getComputedStyle(cell).height)), _max)(fCells);
                row.height = Math.max(dCellMaxHeight, fCellMaxHeight);
                rowIx += 1;
            })
        )(this.allRows);
    }

    shiftLeft() {
        this.resetWidths();
        const nextVisibleColumn = this.dynamicColumns[this.lastVisibleColumnIx - this.visibleColumns.length];
        this.lastVisibleColumnIx -= 1;
        this.tableLeftOffset -= nextVisibleColumn.width;
        this.visibleColumns.unshift(nextVisibleColumn);
        this.visibleColumns.pop();
        this.optimizeVisibleColumns();
    }

    shiftRight() {
        this.resetWidths();
        this.lastVisibleColumnIx += 1;
        const nextVisibleColumn = this.dynamicColumns[this.lastVisibleColumnIx];
        this.tableLeftOffset += this.visibleColumns[0].width;
        this.visibleColumns.shift();
        this.visibleColumns.push(nextVisibleColumn);
        this.optimizeVisibleColumns();
    }

    resetWidths() {
        _forEach<HseTableColumn>((column) => column.optimizedWidth = column.width)(this.dynamicColumns);
    }

    onRowClick(row: HseTableRow<any>) {
        this.rowClick.emit(row);
    }

    onDragDynamicColumn($event, index) {
        this.dragDynamicIndexFrom = index;
        this.dragFixedIndexFrom = null;
    }

    onDropToDynamicColumn($event, indexTo) {
        if (this.dragDynamicIndexFrom !== null) {
            this.dynamicColumns = move(this.dynamicColumns, this.dragDynamicIndexFrom, indexTo);
        } else {
            const column = this.fixedColumns[this.dragFixedIndexFrom];
            column.fixed = false;
            this.updateColumnSettings(column);
            const currentIndexColumn = this.dynamicColumns[indexTo];
            const visibleColumnIndex = _findIndex<HseTableColumn>(currentIndexColumn)(this.visibleColumns);
            this.dynamicColumns.splice(indexTo, 0, column);
            this.fixedColumns.splice(this.dragFixedIndexFrom, 1);
            this.visibleColumns.splice(visibleColumnIndex, 0, column);
            this.visibleColumnsWidth += column.width;
            this.lastVisibleColumnIx += 1;
            this.rebuildTableAfterColumnDrop();
        }

        this.updateConfigColumnsOrder();
        this.dragDynamicIndexFrom = null;
        this.dragFixedIndexFrom = null;
    }

    onDropToFixedColumn($event, indexTo) {
        if (this.dragFixedIndexFrom !== null) {
            this.fixedColumns = move(this.fixedColumns, this.dragFixedIndexFrom, indexTo);
        } else if (this.dragDynamicIndexFrom) {
            const column = this.dynamicColumns[this.dragDynamicIndexFrom];
            column.fixed = true;
            this.updateColumnSettings(column);
            this.fixedColumns.splice(indexTo, 0, this.dynamicColumns[this.dragDynamicIndexFrom]);
            this.dynamicColumns.splice(this.dragDynamicIndexFrom, 1);
            // @ts-ignore
            this.visibleColumns = _remove(column)(this.visibleColumns);
            this.visibleColumnsWidth -= column.width;
            this.lastVisibleColumnIx -= 1;
            this.rebuildTableAfterColumnDrop();
        }

        this.updateConfigColumnsOrder();
        this.dragFixedIndexFrom = null;
        this.dragDynamicIndexFrom = null;
    }

    updateConfigColumnsOrder() {
        const rightOrderColumns = this.dynamicColumns.concat(this.fixedColumns);
        this.config.sort((a, b) => {
            return _findIndex({name: a.name})(rightOrderColumns) - _findIndex({name: b.name})(rightOrderColumns);
        });
        this.saveSettings();
    }

    onDragFixedColumn($event, index) {
        this.dragFixedIndexFrom = index;
        this.dragDynamicIndexFrom = null;
    }

    onDragOver($event) {
        $event.preventDefault();
    }

    rebuildTableAfterColumnDrop() {
        this.fixedColumnsWidth = _flow(_map('width'), _sum)(this.fixedColumns);
        this.resetWidths();
        setTimeout(() => {
            const columnWidths = _flow(_map('width'), _sum)(this.dynamicColumns);
            this.tableWidth = parseFloat(getComputedStyle(this.tableElem.nativeElement).width);
            this.isOverflow = this.tableWidth < columnWidths;
            this.optimizeVisibleColumns();
        });
    }

    toggleColumnHidden(column) {
        column.hidden = !column.hidden;
        this.updateColumnSettings(column);
        this.saveSettings();
        this.rebuildTable();
    }

    columnChangeFixation(column) {
        this.updateColumnSettings(column);
        this.saveSettings();
        this.rebuildTable();
    }

    columnSort(column) {
        _forEach<HseTableColumn>((columnConfig) => {
            if (column !== columnConfig) {
                columnConfig.sortDir = null;
                columnConfig.$settings.sortDir = null;
            }
        })(this.config);

        column.$settings.sortDir = column.sortDir;
        this.saveSettings();

        if (column.sortDir) {
            const sortRowsDeep = (rows, columnData): any => {
                return _orderBy<BaseHseTableRow>((row) => {
                    if (!_isEmpty(row.rows)) {
                        row.rows = sortRowsDeep(row.rows, columnData);
                    }

                    return _getOr(row.title, columnData.name)(row);
                }, [columnData.sortDir])(rows);
            };

            this.rowsData = sortRowsDeep(this.rowsData, column);
        } else {
            this.rowsData = _cloneDeep(this.originRowsData);
        }

        setTimeout(() => {
            this.setAllRows();
            this.calculateRowHeights();
        });

        if (column.onSort) {
            column.onSort(column);
        }
    }

    filterColumn(column: HseTableColumn) {
        column.$settings.filterSelectedValue = column.filter.selectedValue;
        column.filter.callback(column);
        this.saveSettings();
    }

    moveColumn($event) {
        if ($event.from === $event.to) {
            return;
        }

        const columnFrom = this.config[$event.from];
        this.dragDynamicIndexFrom = !columnFrom.fixed && $event.from;
        this.dragFixedIndexFrom = columnFrom.fixed && $event.from;
        // console.log($event, $event.from, $event.to, this.config);
        this.config = move(this.config, $event.from, $event.to);
        this.setSaveSettingsCallback();
        this.saveSettings();
        this.rebuildTable();
    }

    setSaveSettingsCallback() {
        this.config.saveSettings = this.saveSettings.bind(this);
    }

    applySettings() {
        // move config columns by settings order
        this.config.sort((a, b) => {
            return _findIndex({name: a.name})(this.settings.settings) - _findIndex({name: b.name})(this.settings.settings);
        });

        this.setRowExpandedByUserSettings();
        this.setRowsIsEven();
        setTimeout(() => {
            _forEach<HseTableColumn>((column) => {
                let columnSettings = _find<UserTableColumnSettings>({name: column.name})(this.settings.settings);
                if (columnSettings) {
                    column.hidden = columnSettings.hidden;
                    column.fixed = columnSettings.fixed;
                    column.sortDir = columnSettings.sortDir;
                    if (column.filter) {
                        column.filter.selectedValue = columnSettings.filterSelectedValue;
                    }
                } else {
                    columnSettings = {
                        name: column.name,
                        hidden: Boolean(column.hidden),
                        fixed: Boolean(column.fixed)
                    };

                    if (column.filter) {
                        columnSettings.filterSelectedValue = column.filter.selectedValue;
                    }

                    if (column.sortDir) {
                        columnSettings.sortDir = column.sortDir;
                    }
                }

                column.$settings = columnSettings;
            })(this.config);
            this.sortTableByUserSettings();
            this.rebuildTable();
        });
    }

    setRowExpandedByUserSettings() {
        const expandedRowIds = _getOr<UserSettings, 'expandedRowIds', Array<string | number>>([], 'expandedRowIds')(this.settings);
        _forEach<HseTableRow<any>>((row) => {
            row.isExpanded = expandedRowIds.includes(row.id);
            if (row.isExpanded) {
                row.visible = true;
                _forEach<HseTableRow<any>>((subRow) => {
                    subRow.visible = true;
                })(row.rows);
            }
        })(this.allRows);
    }

    sortTableByUserSettings() {
        const columnToSort = _find<HseTableColumn>((column) => Boolean(column.sortDir))(this.config);
        if (columnToSort) {
            this.columnSort(columnToSort);
        }
    }

    updateColumnSettings(column: HseTableColumn) {
        column.$settings.hidden = column.hidden;
        column.$settings.fixed = column.fixed;
        column.$settings.sortDir = column.sortDir;
        if (column.filter) {
            column.$settings.filterSelectedValue = column.filter.selectedValue;
        }
    }

    saveSettings() {
        if (this.config.settingsSavingDisabled) {
            return;
        }

        const columnSettings = _map('$settings')(this.config);
        const expandedRowIds = this.getExpandedRowIds();
        this.userSettings.updateUserSettings(this.config.name, {name: this.config.name, settings: columnSettings, expandedRowIds});
    }

    getExpandedRowIds() {
        return _flow(
            _filter({isExpanded: true}),
            _map('id')
        )(this.allRows);
    }

    resetColumns() {
        this.config.sort((a, b) => {
            return _findIndex({name: a.name})(this.defaultConfig) - _findIndex({name: b.name})(this.defaultConfig);
        });

        let ix = 0;
        _forEach<HseTableColumn>((column) => {
            const defaultColumn = this.defaultConfig[ix];
            column.hidden = defaultColumn.hidden;
            column.fixed = defaultColumn.fixed;
            column.sortDir = defaultColumn.sortDir;
            this.updateColumnSettings(column);
            ix++;
        })(this.config);

        this.rowsData = _cloneDeep(this.originRowsData);
        this.setAllRows();

        this.rebuildTable();

        this.saveSettings();
    }

    onRowExpandChanged() {
        this.setRowsIsEven();
        this.setExpandInfoToOriginRowsData();

        setTimeout(() => {
            this.calculateRowHeights();
        });

        this.saveRowsExpandSettings();
    }


    @Debounce(SAVE_DEBOUNCE_DELAY)
    saveRowsExpandSettings() {
        this.saveSettings();
    }

    setExpandInfoToOriginRowsData() {
        const allOriginRows = [];
        const getWithSubrows = (row) => {
            allOriginRows.push(row);
            _forEach(getWithSubrows)(row.rows);
        };
        _forEach(getWithSubrows)(this.originRowsData);

        _forEach<HseTableRow<any>>((originRow) => {
            const actualRow = _find<HseTableRow<any>>({id: originRow.id})(this.allRows);
            originRow.visible = actualRow.visible;
            originRow.isExpanded = actualRow.isExpanded;
        })(allOriginRows);
    }

    setRowsIsEven() {
        let ix = 1;
        _flow(
            _filter({visible: true}),
            _forEach<HseTableRow<any>>((row) => {
                row.isEven = ix % 2 === 0;
                ix += 1;
            })
        )(this.allRows);
    }

    setAllRows() {
        this.allRows = [];

        const setRowWithSubrows = (row, isVisible) => {
            row.visible = isVisible;

            if (_isUndefined(row.isExpandable)) {
                row.isExpandable = true;
            }

            this.allRows.push(row);
            _forEach<HseTableRow<any>>((rw) => {
                setRowWithSubrows(rw, !row.isExpandable);
            })(row.rows);
        };

        _forEach<HseTableRow<any>>((rw) => {
            setRowWithSubrows(rw, !rw.isExpandable);
        })(this.rowsData);

        _forEach<HseTableRow<any>>((row) => {
            row.visible = true;
        })(this.rowsData);

        this.setRowsIsEven();
    }
}
