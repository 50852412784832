import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HseTableColumn } from '@shared/components/common-ui/hse-table-new/hse-table-column.interface';
import { HseTableComponent, HseTableConfig } from '@shared/components/common-ui/hse-table/hse-table.component';
import { Subject } from 'rxjs/index';

@Component({
  selector: 'hse-table-column',
  templateUrl: './hse-table-column.component.html',
  styleUrls: ['./hse-table-column.component.scss']
})
export class HseTableColumnComponent implements OnInit, AfterViewInit {

  @Input() column: HseTableColumn;
  @Input() i: number;
  @Input() table: HseTableComponent;
  @Input() configColumns: HseTableColumn[];
  @Input() resetCheck?: Subject<void> = null;

  @Output() dragStart: EventEmitter<any> = new EventEmitter<any>();
  @Output() dragEnd: EventEmitter<any> = new EventEmitter<any>();
  @Output() inputCallback: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkCallback: EventEmitter<any> = new EventEmitter<any>();

  public checkboxModel;

  constructor() {
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    if (Boolean(this.resetCheck)) {
      this.resetCheck
        .subscribe((value) => {
          this.checkboxModel = value;
        });
    }
  }

  onDragStart(i, columns) {
    this.dragStart.emit({i, columns});
  }

  onDrop(i, columns) {
    this.dragEnd.emit({i, columns});
  }

  onDragOver($event) {
    $event.preventDefault();
  }

  inputChanged(str) {
    this.inputCallback.emit({ str, column: this.column });
  }

  handleCheckbox(value) {
    this.checkCallback.emit({ value, column: this.column });
  }

}
