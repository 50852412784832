import {
  Component, AfterViewInit, Input, Output, EventEmitter, OnDestroy, NgZone, OnInit,
  ElementRef, ViewChild, OnChanges, SimpleChanges
} from '@angular/core';
import { CustomPlanItemExtended } from 'src/app/constructor/components/educational-standards/components/educational-standard-structure/educational-standard-structure.component';
import { findIndex as _findIndex, map as _map, find as _find } from 'lodash/fp';
import { Subject } from 'rxjs';
import ResizeObserver from 'resize-observer-polyfill';

@Component({
  selector: 'hse-tab-switch',
  templateUrl: './hse-tab-switch.component.html',
  styleUrls: ['./hse-tab-switch.component.scss']
})
export class HseTabSwitchComponent implements AfterViewInit, OnDestroy, OnInit, OnChanges {

  public sliderWidth: number;
  public sliderPosition: number;
  public dataCultureChanged = false;
  public editCredits = false;
  public creditsChanged = false;
  public tabClass;
  id: number = null;
  offset = 2;
  private widthsArray: number[] = null;
  tabsResizeObserver$: ResizeObserver;

  @Input() selectedId: number;
  @Input() tabs: CustomPlanItemExtended[];
  @Input() disableAddition?: boolean;
  @Input() addContextMenu?: string;
  @Input() showCredits = false;
  @Input() contextMenuData?: any[];
  @Input() numberOfCredits?: number;
  @Input() reset?: Subject<any> = null;
  @Output() switchCallback: EventEmitter<number> = new EventEmitter<number>();
  @Output() addTabCallback: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeCallback?: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('tabsRef', { static: true }) tabsElem: ElementRef;

  constructor(private ngZone: NgZone) {
    this.sliderWidth = 0;
    this.sliderPosition = this.offset;
    // tslint:disable:no-magic-numbers
    this.tabClass = this.makeId(10);
  }

  ngOnInit() {
    this.tabsResizeObserver$ = new ResizeObserver((entries) => {
      this.ngZone.run(() => {
        this.sliderWidth = this.setSliderWidth();
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedId && changes.selectedId.isFirstChange()) {
      const tab = _find<any>({id: this.selectedId})(this.tabs);
      this.switchTab(tab.id, false, tab.disabled);
    }
  }

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      this.tabsResizeObserver$.observe(this.tabsElem.nativeElement.parentNode);
    });

    setTimeout(() => {
      this.init();
    }, 0);

    if (Boolean(this.reset)) {
      this.reset
        .subscribe((id) => {
          this.resetTabs(id);
        });
    }
  }

  init() {
    this.id = this.tabs[0].id;
    setTimeout(() => {
      this.sliderWidth = this.setSliderWidth();
    }, 0);
  }

  getWidthsArray(tabs): number[] {
    return _map((tab: any) => {
      return parseFloat(getComputedStyle(tab).width);
    })(tabs);
  }

  setSliderWidth(): number {
    const tabs = document.getElementsByClassName(this.tabClass);
    const index = _findIndex({ id: this.id })(this.tabs);
    this.widthsArray = this.getWidthsArray(tabs);

    return this.widthsArray[index];
  }

  switchTab(id, resize?: boolean, tabDisabled = false) {
    if (tabDisabled) {
      return;
    }

    this.id = id;
    const index = _findIndex({ id })(this.tabs);
    setTimeout(() => {
      this.sliderWidth = this.setSliderWidth();
      this.sliderPosition = this.getSliderPosition(index);
      if (!resize) {
        this.switchCallback.emit(id);
      }
    }, 0);
  }

  getSliderPosition(index: number): number {
    let position = 0;
    for (let i = 0; i < index; i++) {
      position += this.widthsArray[i];
    }

    return position + this.offset;
  }

  resetTabs($id) {
    setTimeout(() => {
      const id = this.tabs[$id || 0].id;
      this.id = id;
      const index = _findIndex({ id })(this.tabs);

      this.sliderWidth = this.setSliderWidth();
      this.sliderPosition = this.getSliderPosition(index);
    }, 0);
  }

  addTab() {
  }

  onResize() {
    this.sliderWidth = this.setSliderWidth();
    this.switchTab(this.id, true);
  }

  menuClosed() {
    this.closeCallback.emit({
      contextMenuData: this.contextMenuData,
      dataCultureChanged: this.dataCultureChanged,
      numberOfCredits: this.numberOfCredits,
      creditsChanged: this.creditsChanged
    });

    this.editCredits = false;
    this.dataCultureChanged = false;
    this.creditsChanged = false;
  }

  ngOnDestroy(): void {
    if (Boolean(this.reset)) {
      this.reset = null;
    }
  }

  makeId(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
