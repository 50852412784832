import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RadioGroupItem } from './hse-radio-group.interface';
import { isUndefined as _isUndefined } from 'lodash/fp';
import _guid from '@tools/fp/guid';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'hse-radio-group',
    templateUrl: './hse-radio-group.component.html',
    styleUrls: ['./hse-radio-group.component.scss']
})

export class HseRadioGroupComponent implements OnInit {
    isOpened = true;
    radioGroupId: string;
    translations = {
        SHOW: '',
        HIDE: ''
    };

    @Input() public radioGroupItems: Array<RadioGroupItem>; // массив объектов для радио-группы (обязательны value и text)
    @Input() public selectedValue: string; // выбранное значение (value)
    @Input() public radioGroupName: string; // имя радио-группы
    @Input() asLineRow: boolean; // показ радио-группы в строку (по умолчанию в столбец)
    @Output() public changeRadio: EventEmitter<string> = new EventEmitter<string>(); // событие при изменении выбранного значения
    @Input() visibleItemsCount: number;
    @Input() nameField = 'text';
    @Input() idField = 'value';

    constructor(private translate: TranslateService) {}

    ngOnInit() {
        this.translations.SHOW = this.translate.instant('UI.COMBO-BOX.SHOW');
        this.translations.HIDE = this.translate.instant('UI.COMBO-BOX.HIDE');
        this.radioGroupId = _guid();

        if (!_isUndefined(this.visibleItemsCount)) {
          this.isOpened = this.visibleItemsCount > this.radioGroupItems.length;
        }
    }

    public changeBtn(event: string): void {
        this.changeRadio.emit(event);
    }
}
