/***
 *  Декоратор для кэширования значений
 * */

export function Cache() {
  return function(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = async function(...args: any[]) {
      try {
        // сохраняем кэшированное значение по имени метода и аргументам вызова
        const cacheKey = originalMethod.name + JSON.stringify(args);
        if (this.cache && this.cache[cacheKey]) {
          // console.log(`Returning cached value for ${name}`);
          return this.cache[cacheKey];
        }

        const result = await originalMethod.apply(this, args);

        if (this.cache) {
          // console.log(`Caching value for ${name}`);
          this.cache[cacheKey] = result;
        }

        return result;
      } catch (err) {
        console.error(err);
      }
    };

    return descriptor;
  };
}
