<div class="modal__wrapper fx row h-v-center">
	<div class="modal__container fx column" (click)="stopPropagation($event)">
		<div class="modal__text">
			<span>{{ 'STUDY-PLAN.STRUCTURE.EDUCATION-RESULTS-POPUP.MODULE-PROPERTIES' | translate }}</span>
		</div>

		<div class="modal__input">
			<hse-textarea [(ngModel)]="description"
			              [rowsCount]="3" [growOnFocus]="3" [width]="400"></hse-textarea>
		</div>

		<div class="modal__text">
			<span>{{ 'STUDY-PLAN.STRUCTURE.EDUCATION-RESULTS-POPUP.MIN-CREDITS' | translate }}</span>
		</div>

		<div class="modal__input">
			<hse-input (keypress)="numberOnly($event)"
			           [(ngModel)]="credits"
			           [disablePlaceholderAnimation]="true"
			           [width]="100"
			></hse-input>
		</div>

		<div class="modal__controls fx row space-around">
			<hse-button color="primary" [width]="160" (callback)="saveCallback()">{{ 'STUDY-PLAN.STRUCTURE.EDUCATION-RESULTS-POPUP.SAVE' | translate }}</hse-button>
			<hse-button color="secondary" [width]="160" (callback)="cancelCallback()">{{ 'STUDY-PLAN.STRUCTURE.EDUCATION-RESULTS-POPUP.CANCEL' | translate }}</hse-button>
		</div>
	</div>
</div>
