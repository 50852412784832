import {
  AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { StringMaskService } from '../../../directives/input-mask/string-mask.service';
import _guid from '../../../../../tools/fp/guid';
import { includes as _includes } from 'lodash/fp';

@Component({
  selector: 'hse-input',
  templateUrl: './hse-input.component.html',
  styleUrls: ['./hse-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HseInputComponent),
      multi: true
    }
  ]
})
export class HseInputComponent implements OnInit, AfterViewInit, ControlValueAccessor, OnChanges {
  private innerValue: any;
  public inputId: string;
  private numberRegExp = new RegExp('^[0-9]*$');
  @Input() type = 'text';
  @Input() width = 200;
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() customInvalid = false;
  @Input() min: number;
  @Input() max: number;
  @Input() disablePlaceholderAnimation = false;
  @Input() textMask: string;
  @Input() hideControls = false;
  @Input() infoState = false;
  @Input() infoMessage = '';
  @Input() maxLength = null;
  @Input() valueChanged = false;
  @Input() currencyMode = false;

  @Output() focus = new EventEmitter<any>();
  @Output() blur = new EventEmitter<any>();
  @Output() keypress = new EventEmitter<any>();
  @Output() keydownEvent = new EventEmitter<any>();

  @ViewChild('input', { static: true }) inputElem: ElementRef;

  onChange: any = () => {};
  onTouched: any = () => {};

  @Input()
  get model(): string {
    return this.innerValue;
  }

  set model(val) {
    this.innerValue = this.checkMask(val);
    this.onChange(this.innerValue);
  }

  constructor(private stringMaskService: StringMaskService) {

  }

  ngOnInit() {
    this.inputId = _guid();
  }

  ngAfterViewInit() {
    if (this.type === 'number') {
      this.inputElem.nativeElement.setAttribute('min', this.min);
      this.inputElem.nativeElement.setAttribute('max', this.max);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.textMask) {
      if (this.textMask && this.type === 'text') {
        const maxLength = this.textMask.length;
        this.inputElem.nativeElement.setAttribute('maxlength', maxLength);
      }
    }
  }

  writeValue(value): void {
    this.innerValue = this.checkMask(value);
    this.onChange(this.innerValue);
  }

  checkMask(value: string | null): string {
    let result: string = value;
    if (this.type && this.textMask) {
      result = this.stringMaskService.apply(value, this.textMask);
    }

    if (this.type === 'number') {
      return value === null
        ? null
        : this.numberRegExp.test(result)
          ? result
          : this.innerValue;
    }

    return result;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  emitFocusEvent() {
    this.focus.emit(this.innerValue);
  }

  emitBlurEvent() {
    this.blur.emit(this.innerValue);
    this.onTouched();
  }

  emitKeyPressEvent($event) {
    if (this.type === 'number') {
      // Allow: backspace, delete, tab, escape, enter and .
      if (_includes($event.keyCode)([46, 8, 9, 27, 13, 110, 190]) ||
        // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
        (($event.keyCode === 65 || $event.keyCode === 86 || $event.keyCode === 67) && ($event.ctrlKey === true || $event.metaKey === true)) ||
        // Allow: home, end, left, right, down, up
        ($event.keyCode >= 35 && $event.keyCode <= 40)) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if (($event.shiftKey || ($event.keyCode < 48 || $event.keyCode > 57)) && ($event.keyCode < 96 || $event.keyCode > 105)) {
        $event.preventDefault();
      }
    }

    this.keypress.emit($event);
  }

  emitKeyDownEvent($event) {
    if (this.type === 'number') {
      // Allow: backspace, delete, tab, escape, enter and .
      if (_includes($event.keyCode)([46, 8, 9, 27, 13, 110, 190]) ||
        // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
        (($event.keyCode === 65 || $event.keyCode === 86 || $event.keyCode === 67) && ($event.ctrlKey === true || $event.metaKey === true)) ||
        // Allow: home, end, left, right, down, up
        ($event.keyCode >= 35 && $event.keyCode <= 40)) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if (($event.shiftKey || ($event.keyCode < 48 || $event.keyCode > 57)) && ($event.keyCode < 96 || $event.keyCode > 105)) {
        $event.preventDefault();
      }
    }

    this.keydownEvent.emit($event);
  }

  checkModel() {
    return Boolean(this.model) || typeof this.model === 'number';
  }
}
