import {
  AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  flow as _flow,
  map as _map,
  sum as _sum,
  forEach as _forEach,
  reject as _reject,
  filter as _filter,
  remove as _remove,
  isEmpty as _isEmpty,
  findIndex as _findIndex,
  orderBy as _orderBy,
  cloneDeep as _cloneDeep,
  max as _max,
  compact as _compact,
  isUndefined as _isUndefined,
  getOr as _getOr, find as _find
} from 'lodash/fp';
import { HseTableRow } from '@shared/components/common-ui/hse-table-new/hse-table-row.interface';
import { HseTableColumn, IHseTableConfig } from '@shared/components/common-ui/hse-table-new/hse-table-column.interface';
import ResizeObserver from 'resize-observer-polyfill';
import Debounce from 'debounce-decorator';
import { move } from '@tools/fp/move';
import { UserSettingsService } from '@backend/user-settings/user-settings.service';
import { UserSettings, UserTableColumnSettings } from '@backend/user-settings/user-settings.interface';
import { from, Subject } from 'rxjs';

type BaseHseTableRow = HseTableRow<any>;

const SAVE_DEBOUNCE_DELAY = 1500;

export class HseTableConfig extends Array<HseTableColumn> implements IHseTableConfig {
  name: string;
  settingsSavingDisabled: boolean;

  [index: number]: HseTableColumn;

  saveSettings: () => void;

  constructor(...columnConfigs: Array<HseTableColumn>) {
    super(...columnConfigs);
  }

  resetFilters() {
    _forEach<HseTableColumn>((column) => {
      if (column.filter) {
        column.filter.selectedValue = column.filter.type === 'combo-box' ? [] : null;
        delete column.$settings.filterSelectedValue;
      }
    })(this);

    this.saveSettings();
  }

  setName(tableName: string) {
    this.name = tableName;

    return this;
  }

  getColumn(columnName: string): HseTableColumn {
    return _find<HseTableColumn>({name: columnName})(this);
  }

  setColumnFilterValue(columnName: string, filterValue: any) {
    const column = this.getColumn(columnName);
    if (column) {
      column.$settings.filterSelectedValue = filterValue;
      column.filter.selectedValue = filterValue;
      this.saveSettings();
    }
  }

  setColumns(columnConfigs: Array<HseTableColumn>) {
    for (let ix = 0; ix < columnConfigs.length; ix++) {
      this[ix] = columnConfigs[ix];
    }
  }

  disableSettingsSaving() {
    this.settingsSavingDisabled = true;

    return this;
  }
}

@Component({
  selector: 'hse-table',
  templateUrl: './hse-table.component.html',
  styleUrls: ['./hse-table.component.scss']
})
export class HseTableComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() config: HseTableConfig;
  defaultConfig: HseTableConfig;

  @Input() optionTpl?: any;
  @Input() isDraggableColumns ? = true;
  @Input() rowsData?: HseTableRow<any>[];
  @Input() stickyHeader = false;
  @Input() offsetTop?: number;
  @Input() stackName?: string;
  @Input() customSort?: boolean;
  @Input() resetCheck?: Subject<void> = null;
  @Input() emptyCell = '-';
  @Input() rebuild?: Subject<void> = null;
  @Input() toggleCol?: Subject<any> = null;
  @Output() inputCallback?: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkCallback?: EventEmitter<any> = new EventEmitter<any>();

  originRowsData?: HseTableRow<any>[];

  @Input() customStyles?: Partial<CSSStyleDeclaration>;
  @Output() rowClick?: EventEmitter<any> = new EventEmitter<any>();

  isRowClickable = false;

  @ViewChild('table', { static: false }) tableElem: ElementRef;
  @ViewChild('dynamicHeader', { static: false }) dynamicHeader: ElementRef;
  @ViewChild('fixedHeader', { static: false }) fixedHeader: ElementRef;
  @ViewChild('dynamicRowsWrapper', { static: false }) dynamicRowsWrapper: ElementRef;
  @ViewChild('fixedRowsWrapper', { static: false }) fixedRowsWrapper: ElementRef;

  tableHeaderHeight: number;

  fixedColumns: Array<HseTableColumn> = [];
  mainFixedColumns: Array<HseTableColumn> = [];
  visibleFixedColumns: Array<HseTableColumn> = [];

  dynamicColumns: Array<HseTableColumn> = [];
  mainDynamicColumns: Array<HseTableColumn> = [];
  visibleDynamicColumns: Array<HseTableColumn> = [];

  flattenColumns: Array<HseTableColumn> = [];

  tableLeftOffset = 0;
  tableLeftPositionOffset = 0;
  visibleColumnsWidth = 0;
  isOverflow = false;
  visibleColumns: Array<HseTableColumn> = [];
  tableWidth: number = null;
  fixedColumnsWidth: number = null;
  lastVisibleColumnIx: number = null;
  tableResizeObserver$: ResizeObserver;

  dragColumn: HseTableColumn = null;
  dragDynamicIndexFrom: number;
  dragFixedIndexFrom: number;

  oldWith: number;

  settings: UserSettings;

  allRows: Array<any>;

  self: HseTableComponent;

  dragColumns: HseTableColumn[];

  public resetCheckbox: Subject<any> = new Subject<any>();

  constructor(private cdr: ChangeDetectorRef,
              private userSettings: UserSettingsService,
              private ngZone: NgZone,
              private host: ElementRef) {
  }

  ngOnInit() {
    this.self = this;
    this.isRowClickable = Boolean(this.rowClick.observers.length);
    this.tableResizeObserver$ = new ResizeObserver((entries) => {
      this.ngZone.run(() => {
        const newWidth = parseFloat(getComputedStyle(entries[0].target).width);
        if (this.oldWith === newWidth) {
          this.cdr.detectChanges();
          return;
        }

        this.oldWith = newWidth;
        this.onResize();
      });
    });

    this.setAllRows();
  }

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      this.tableResizeObserver$.observe(this.tableElem.nativeElement.parentNode);
    });

    if (Boolean(this.resetCheck)) {
      this.resetCheck
        .subscribe((value) => {
          this.resetCheckbox.next(value);
        });
    }

    if (Boolean(this.rebuild)) {
      this.rebuild.subscribe(() => {
        this.rebuildTable();
      });
    }

    if (Boolean(this.toggleCol)) {
      this.toggleCol.subscribe(({ column, config }) => {
        this.toggleColumnHidden(column, config);
      });
    }
  }

  onResize() {
    this.lastVisibleColumnIx = 0;
    this.tableLeftOffset = 0;
    this.resetWidths();
    this.buildTable();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!this.config) {
      return;
    }

    if (changes.rowsData) {
      this.originRowsData = _cloneDeep(this.rowsData);
      setTimeout(() => {
        this.setAllRows();
        this.calculateRowHeights();
      }, 0);

    }

    if (!this.customSort) {
      this.sortTableByUserSettings();
    }

    if (changes.config) {
      this.defaultConfig = _cloneDeep(this.config);
      this.settings = await this.getTableSettings();
      this.setSaveSettingsCallback();
      this.applySettings();
    }
  }

  async getTableSettings() {
    if (this.config.settingsSavingDisabled) {
      return {
        name: this.config.name,
        settings: []
      };
    }

    return this.userSettings.getSettings(this.config.name);
  }

  ngOnDestroy() {
    this.tableResizeObserver$.disconnect();
    if (this.rebuild) {
      this.rebuild.unsubscribe();
    }
  }

  rebuildTable() {
    this.lastVisibleColumnIx = 0;
    this.tableLeftOffset = 0;
    this.mainDynamicColumns = _reject<HseTableColumn>((column) => column.fixed)(this.config);
    this.setDynamicColumns();

    this.mainFixedColumns = _filter<HseTableColumn>((column) => column.fixed)(this.config);
    this.setFixedColumns();

    this.fixedColumnsWidth = _flow(_map('width'), _sum)(this.fixedColumns);
    this.resetWidths();
    this.buildTable();
  }

  fixTableLeftOffset() {
    const invisibleColumns = this.dynamicColumns.slice(0, this.lastVisibleColumnIx - this.visibleColumns.length + 1);
    this.tableLeftOffset = _flow(_map('width'), _sum)(invisibleColumns);
  }

  getFlattenColumns(columns) {
    const lastDepthLevelColumns = [];
    const getSubColumns = (column: HseTableColumn, parentColumn) => {
      column.parentColumn = parentColumn;
      // @ts-ignore
      column.parentColumns = _getOr(null, 'parentColumn.columns')(column.parentColumn);

      if (column.columns && !column.hidden) {
        column.visibleColumns = _reject<HseTableColumn>({hidden: true})(column.columns);
        _forEach<HseTableColumn>((clmn) => getSubColumns(clmn, column))(column.visibleColumns);
      } else {
        lastDepthLevelColumns.push(column);
      }
    };
    _forEach<HseTableColumn>((clmn) => getSubColumns(clmn, null))(columns);

    return lastDepthLevelColumns;
  }

  setDynamicColumns() {
    this.visibleDynamicColumns = _reject<HseTableColumn>({hidden: true})(this.mainDynamicColumns);
    this.dynamicColumns = this.getFlattenColumns(this.visibleDynamicColumns);
  }

  setFixedColumns() {
    this.visibleFixedColumns = _reject<HseTableColumn>({hidden: true})(this.mainFixedColumns);
    this.fixedColumns = this.getFlattenColumns(this.visibleFixedColumns);
  }

  private buildTable() {
    const columnWidths = _flow(_map('width'), _sum)(this.dynamicColumns);
    this.optimizeVisibleColumns();
    this.setTableHeaderHeight();
    this.tableLeftPositionOffset = this.tableElem.nativeElement.getBoundingClientRect().x;
    this.tableWidth = parseFloat(getComputedStyle(this.tableElem.nativeElement).width);
    this.setVisibleColumns();
    this.isOverflow = this.tableWidth < columnWidths;
  }

  private setVisibleColumns() {
    this.visibleColumns = [];
    this.visibleColumnsWidth = 0;
    for (let ix = 0; ix < this.dynamicColumns.length; ix++) {
      if (this.visibleColumnsWidth + this.dynamicColumns[ix].width > this.tableWidth) {
        break;
      }
      this.lastVisibleColumnIx = ix;
      this.visibleColumnsWidth += this.dynamicColumns[ix].width;
      this.visibleColumns.push(this.dynamicColumns[ix]);
    }
  }

  private optimizeVisibleColumns() {
    this.visibleColumnsWidth = _flow(_map('optimizedWidth'), _sum)(this.visibleColumns);
    const delta = this.tableWidth - this.visibleColumnsWidth;
    const increment = delta / this.visibleColumns.length;

    _forEach<HseTableColumn>((column) => {
      column.optimizedWidth = column.width + increment;
    })(this.visibleColumns);
    this.calculateRowHeights();
    this.cdr.detectChanges();
  }

  private setTableHeaderHeight() {
    setTimeout(() => {
      const headerColumns = this.host.nativeElement.querySelectorAll('.hse-table__header > div > hse-table-column');
      const columnHeights = _map(getColumnHeight)(headerColumns);
      this.tableHeaderHeight = _max(columnHeights);

      function getColumnHeight(column) {
        const contentHeight = parseFloat(getComputedStyle(column.querySelector('.hse-table-column')).height);
        const nestedColumns = column.querySelectorAll(':scope > .hse-table-column-wrapper > div > hse-table-column');
        const nestedColumnHeight = nestedColumns.length > 0 ? _flow(_map(getColumnHeight), _max)(nestedColumns) + 1 : 0;

        return contentHeight + nestedColumnHeight;
      }
    });
  }

  private calculateRowHeights() {
    if (!this.fixedRowsWrapper) {
      return;
    }

    const dynamicRowsNodes = this.dynamicRowsWrapper.nativeElement.querySelectorAll('.hse-table-row__row');
    const fixedRowsNodes = this.fixedRowsWrapper.nativeElement.querySelectorAll('.hse-table-row__row');

    if (!Boolean(dynamicRowsNodes.length) && !Boolean(fixedRowsNodes.length)) {
      return;
    }

    let rowIx = 0;
    _flow(
      _filter({visible: true}),
      _forEach<HseTableRow<any>>((row) => {
        const dCells = dynamicRowsNodes[rowIx].querySelectorAll('.hse-table-row__cell');
        const fCells = fixedRowsNodes[rowIx].querySelectorAll('.hse-table-row__cell');
        const dCellMaxHeight = _flow(_map((cell: Element) => parseFloat(getComputedStyle(cell).height)), _max)(dCells);
        const fCellMaxHeight = _flow(_map((cell: Element) => parseFloat(getComputedStyle(cell).height)), _max)(fCells);
        row.height = Math.max(dCellMaxHeight, fCellMaxHeight);
        rowIx += 1;
      })
    )(this.allRows);
  }

  shiftLeft() {
    this.resetWidths();
    const nextVisibleColumn = this.dynamicColumns[this.lastVisibleColumnIx - this.visibleColumns.length];
    this.lastVisibleColumnIx -= 1;
    this.tableLeftOffset -= this.getColumnWidth(nextVisibleColumn);
    this.visibleColumns.unshift(nextVisibleColumn);
    this.visibleColumns.pop();
    this.optimizeVisibleColumns();
  }

  shiftRight() {
    this.resetWidths();
    this.lastVisibleColumnIx += 1;
    const nextVisibleColumn = this.dynamicColumns[this.lastVisibleColumnIx];
    this.tableLeftOffset += this.getColumnWidth(this.visibleColumns[0]);
    this.visibleColumns.shift();
    this.visibleColumns.push(nextVisibleColumn);
    this.optimizeVisibleColumns();
  }

  resetWidths() {
    _forEach<HseTableColumn>((column) => column.optimizedWidth = column.width)(this.dynamicColumns);
  }

  onRowClick(row: HseTableRow<any>) {
    this.rowClick.emit(row);
  }

  onDragDynamicColumn($event) {
    const {i, columns} = $event;

    this.dragColumns = columns;

    const visibleColumns = _reject<HseTableColumn>({hidden: true})(columns);
    const fromColumn = visibleColumns[i];
    const indexFrom = _findIndex({name: fromColumn.name})(columns);
    this.dragColumn = fromColumn;
    this.dragDynamicIndexFrom = indexFrom;
    this.dragFixedIndexFrom = null;
  }

  onDropToDynamicColumn($event) {
    const {i, columns} = $event;

    if (columns === this.dragColumns || (this.dragFixedIndexFrom !== null && !this.dragColumn.parentColumn)) {
      const visibleColumns = _reject<HseTableColumn>({hidden: true})(columns);
      const toColumn = visibleColumns[i];
      const indexTo = _findIndex(toColumn)(columns);
      let visibleColumnsLength = this.visibleColumns.length;
      if (this.dragFixedIndexFrom !== null) {
        const column = this.dragColumn;
        column.fixed = false;
        this.updateColumnSettings(column);
        const currentIndexColumn = this.mainDynamicColumns[indexTo];
        const visibleColumnIndex = _findIndex<HseTableColumn>(currentIndexColumn)(visibleColumns);
        this.mainDynamicColumns.splice(indexTo, 0, column);
        this.mainFixedColumns.splice(this.dragFixedIndexFrom, 1);
        visibleColumns.splice(visibleColumnIndex, 0, column);

        const addedColumnsCount = column.columns ? this.getFlattenColumns(column.columns).length : 1;
        this.visibleColumnsWidth += this.getColumnWidth(column);
        this.lastVisibleColumnIx += addedColumnsCount;
        visibleColumnsLength += addedColumnsCount;
        this.setFixedColumns();
      } else {
        move(this.dragColumns, this.dragDynamicIndexFrom, indexTo);
      }

      this.setDynamicColumns();
      this.visibleColumns = this.dynamicColumns.slice(this.lastVisibleColumnIx - (visibleColumnsLength - 1), this.lastVisibleColumnIx + 1);
      this.fixTableLeftOffset();
      this.rebuildTableAfterColumnDrop();
      this.updateConfigColumnsOrder();
    }

    this.dragDynamicIndexFrom = null;
    this.dragFixedIndexFrom = null;
    this.dragColumns = null;
  }

  getColumnWidth(column) {
    if (column.width) {
      return column.optimizedWidth || column.width;
    }

    const flattenSubColumns = this.getFlattenColumns(column.columns);

    return _flow(_map((subColumn: HseTableColumn) => {
      return subColumn.optimizedWidth || subColumn.width;
    }), _compact, _sum)(flattenSubColumns);
  }

  onDropToFixedColumn($event) {
    const {i, columns} = $event;

    if (columns === this.dragColumns || (this.dragDynamicIndexFrom !== null && !this.dragColumn.parentColumn)) {
      const visibleColumns = _reject({hidden: true})(columns);
      const toColumn = visibleColumns[i];
      const indexTo = _findIndex(toColumn)(columns);

      if (this.dragDynamicIndexFrom !== null) {
        const column = this.dragColumn;
        column.fixed = true;

        this.updateColumnSettings(column);
        this.mainFixedColumns.splice(indexTo, 0, column);
        this.mainDynamicColumns.splice(this.dragDynamicIndexFrom, 1);
        this.setDynamicColumns();

        const addedColumnsCount = column.columns ? this.getFlattenColumns(column.columns).length : 1;
        const visibleColumnsLength = this.visibleColumns.length - addedColumnsCount;

        const columnWidth = this.getColumnWidth(column);
        this.fixedColumnsWidth += columnWidth;
        this.visibleColumnsWidth -= columnWidth;
        this.tableWidth -= columnWidth;
        this.lastVisibleColumnIx -= addedColumnsCount;
        this.visibleColumns = this.dynamicColumns.slice(this.lastVisibleColumnIx - (visibleColumnsLength - 1), this.lastVisibleColumnIx + 1);
        this.fixTableLeftOffset();
      } else {
        move(this.dragColumns, this.dragFixedIndexFrom, indexTo);
      }

      this.setFixedColumns();
      this.updateConfigColumnsOrder();
    }

    this.dragFixedIndexFrom = null;
    this.dragDynamicIndexFrom = null;
    this.dragColumns = null;
  }

  updateConfigColumnsOrder() {
    const rightOrderColumns = this.mainDynamicColumns.concat(this.mainFixedColumns);
    this.applyColumnsOrder(this.config, rightOrderColumns);
    this.saveSettings();
  }

  applyColumnsOrder(columns, toOrderColumns) {
    columns.sort((a, b) => {
      const aIx = _findIndex({name: a.name})(toOrderColumns);
      const bIx = _findIndex({name: b.name})(toOrderColumns);

      return aIx !== -1 ? aIx - bIx : bIx - aIx;
    });
  }

  onDragFixedColumn($event) {
    const {i, columns} = $event;
    this.dragColumns = columns;

    const visibleColumns = _reject<HseTableColumn>({hidden: true})(columns);
    const fromColumn = visibleColumns[i];
    const indexFrom = _findIndex({name: fromColumn.name})(columns);
    this.dragColumn = fromColumn;
    this.dragFixedIndexFrom = indexFrom;
    this.dragDynamicIndexFrom = null;
  }

  rebuildTableAfterColumnDrop() {
    this.fixedColumnsWidth = _flow(_map('width'), _sum)(this.fixedColumns);
    this.resetWidths();
    setTimeout(() => {
      const columnWidths = _flow(_map('width'), _sum)(this.dynamicColumns);
      this.tableWidth = parseFloat(getComputedStyle(this.tableElem.nativeElement).width);
      this.isOverflow = this.tableWidth < columnWidths;
      this.optimizeVisibleColumns();
    });
  }

  toggleColumnHidden(column: HseTableColumn, config: HseTableColumn[]) {
    const isLastVisible = _reject({hidden: true})(config).length === 1;
    const isHidden = !column.hidden;
    if (!isLastVisible || !isHidden) {
      column.hidden = isHidden;
      this.updateColumnSettings(column);
      this.saveSettings();
      this.rebuildTable();
    } else if (column.parentColumn) {
      this.toggleColumnHidden(column.parentColumn, column.parentColumns);
    }
  }

  columnChangeFixation(column) {
    this.updateColumnSettings(column);
    this.saveSettings();
    this.rebuildTable();
  }

  columnSort(column) {
    console.log(column);
    _forEach<HseTableColumn>((columnConfig) => {
      if (column !== columnConfig) {
        columnConfig.sortDir = null;
        columnConfig.$settings.sortDir = null;
      }
    })(this.flattenColumns);

    column.$settings.sortDir = column.sortDir;

    if (!this.customSort) {
      this.saveSettings();

      if (column.sortDir) {
        const sortRowsDeep = (rows, columnData): any => {
          return _orderBy<BaseHseTableRow>((row) => {
            if (!_isEmpty(row.rows)) {
              row.rows = sortRowsDeep(row.rows, columnData);
            }

            return _getOr(row.title, columnData.calculatedName || columnData.name)(row);
          }, [columnData.sortDir])(rows);
        };

        this.rowsData = sortRowsDeep(this.rowsData, column);
      } else {
        this.rowsData = _cloneDeep(this.originRowsData);
      }
    } else {
      this.saveSettings();
    }

    setTimeout(() => {
      this.setAllRows();
      this.calculateRowHeights();
    });

    if (column.onSort) {
      column.onSort(column);
    }
  }

  filterColumn(column: HseTableColumn) {
    column.$settings.filterSelectedValue = column.filter.selectedValue;
    column.filter.callback(column);
    this.saveSettings();
  }

  moveColumn($event) {
    if ($event.from === $event.to) {
      return;
    }

    const columnFrom = $event.columns[$event.from];
    this.dragDynamicIndexFrom = !columnFrom.fixed && $event.from;
    this.dragFixedIndexFrom = columnFrom.fixed && $event.from;
    move($event.columns, $event.from, $event.to);

    this.updateConfigColumnsOrder();
    this.rebuildTable();
  }

  setSaveSettingsCallback() {
    this.config.saveSettings = this.saveSettings.bind(this);
  }

  applySettings() {
    this.setRowExpandedByUserSettings();
    this.setRowsIsEven();
    this.flattenColumns = [];
    this.applyColumnsBySettings(this.config, this.settings.settings);
    if (!this.customSort) {
      this.sortTableByUserSettings();
    }
    this.rebuildTable();
  }

  applyColumnsBySettings(columns, settings) {
    this.applyColumnsOrder(columns, settings);
    const setColumnSettings = (column: HseTableColumn, parentColumn: HseTableColumn, parentColumnsSettings: UserTableColumnSettings[]) => {
      if (!parentColumn) {
        column.calculatedName = column.name;
      }

      column.calculatedName = (parentColumn ? parentColumn.calculatedName : '') + column.name;
      let columnSettings = _find<UserTableColumnSettings>({name: column.name})(parentColumnsSettings);
      if (columnSettings) {
        column.hidden = columnSettings.hidden;
        column.fixed = columnSettings.fixed;
        if (!this.customSort) {
          column.sortDir = columnSettings.sortDir;
        }
        if (column.filter && !_isEmpty(columnSettings.filterSelectedValue)) {
          column.filter.selectedValue = columnSettings.filterSelectedValue;
        }
      } else {
        columnSettings = {
          name: column.name,
          hidden: Boolean(column.hidden),
          fixed: Boolean(column.fixed),
          columns: []
        };

        if (column.filter) {
          columnSettings.filterSelectedValue = column.filter.selectedValue;
        }

        if (column.sortDir) {
          columnSettings.sortDir = column.sortDir;
        }
      }

      column.$settings = columnSettings;
      this.flattenColumns.push(column);


      if (column.columns) {
        this.applyColumnsOrder(column.columns, columnSettings.columns);
        _forEach<HseTableColumn>((subColumn) => {
          setColumnSettings(subColumn, column, columnSettings.columns);
        })(column.columns);
      }
    };

    _forEach<HseTableColumn>((column) => {
      setColumnSettings(column, null, settings);
    })(columns);
  }

  setRowExpandedByUserSettings() {
    const expandedRowIds = _getOr<UserSettings, 'expandedRowIds', Array<string | number>>([], 'expandedRowIds')(this.settings);
    _forEach<HseTableRow<any>>((row) => {
      row.isExpanded = expandedRowIds.includes(row.id);
      if (row.isExpanded) {
        row.visible = true;
        _forEach<HseTableRow<any>>((subRow) => {
          subRow.visible = true;
        })(row.rows);
      }
    })(this.allRows);
  }

  sortTableByUserSettings() {
    const columnToSort = _find<HseTableColumn>((column) => Boolean(column.sortDir))(this.config);
    if (columnToSort) {
      this.columnSort(columnToSort);
    }
  }

  updateColumnSettings(column: HseTableColumn) {
    column.$settings.hidden = column.hidden;
    column.$settings.fixed = column.fixed;
    column.$settings.sortDir = column.sortDir;
    if (column.filter) {
      column.$settings.filterSelectedValue = column.filter.selectedValue;
    }
  }

  saveSettings() {
    if (this.config.settingsSavingDisabled) {
      return;
    }

    const getColumnsSettings = (column) => {
      column.$settings.columns = _map(getColumnsSettings)(column.columns);
      return column.$settings;
    };

    const settingsToSave = _map((column) => {
      return getColumnsSettings(column);
    })(this.config);

    const expandedRowIds = this.getExpandedRowIds();
    this.userSettings.updateUserSettings(this.config.name, {
      name: this.config.name,
      settings: settingsToSave,
      expandedRowIds
    });
  }

  getExpandedRowIds() {
    return _flow(
      _filter({isExpanded: true}),
      _map('id')
    )(this.allRows);
  }

  resetColumns() {

    const resetColumn = (column: HseTableColumn, parentDefaultConfig) => {
      const defaultColumn = _find<HseTableColumn>({name: column.name})(parentDefaultConfig);
      column.hidden = defaultColumn.hidden;
      column.fixed = defaultColumn.fixed;
      column.sortDir = defaultColumn.sortDir;
      this.updateColumnSettings(column);
      if (column.columns) {
        this.applyColumnsOrder(column.columns, defaultColumn.columns);
        _forEach<HseTableColumn>((subColumn) => {
          resetColumn(subColumn, defaultColumn.columns);
        })(column.columns);
      }
    };

    this.applyColumnsOrder(this.config, this.defaultConfig);
    _forEach<HseTableColumn>((column) => {
      resetColumn(column, this.defaultConfig);
    })(this.config);

    this.rowsData = _cloneDeep(this.originRowsData);
    this.setAllRows();
    this.rebuildTable();
    this.saveSettings();
  }

  onRowExpandChanged() {
    this.setRowsIsEven();
    this.setExpandInfoToOriginRowsData();

    setTimeout(() => {
      this.calculateRowHeights();
    });

    this.saveRowsExpandSettings();
  }


  @Debounce(SAVE_DEBOUNCE_DELAY)
  saveRowsExpandSettings() {
    this.saveSettings();
  }

  setExpandInfoToOriginRowsData() {
    const allOriginRows = [];
    const getWithSubrows = (row) => {
      allOriginRows.push(row);
      _forEach(getWithSubrows)(row.rows);
    };
    _forEach(getWithSubrows)(this.originRowsData);

    _forEach<HseTableRow<any>>((originRow) => {
      const actualRow = _find<HseTableRow<any>>({id: originRow.id})(this.allRows);
      originRow.visible = actualRow.visible;
      originRow.isExpanded = actualRow.isExpanded;
    })(allOriginRows);
  }

  setRowsIsEven() {
    let ix = 1;
    _flow(
      _filter({visible: true}),
      _forEach<HseTableRow<any>>((row) => {
        row.isEven = ix % 2 === 0;
        ix += 1;
      })
    )(this.allRows);
  }

  setAllRows() {
    this.allRows = [];

    const setRowWithSubrows = (row, isVisible) => {
      row.visible = isVisible;

      if (_isUndefined(row.isExpandable)) {
        row.isExpandable = true;
      }

      this.allRows.push(row);
      _forEach<HseTableRow<any>>((rw) => {
        setRowWithSubrows(rw, !row.isExpandable);
      })(row.rows);
    };

    _forEach<HseTableRow<any>>((rw) => {
      setRowWithSubrows(rw, !rw.isExpandable);
    })(this.rowsData);

    _forEach<HseTableRow<any>>((row) => {
      row.visible = true;
    })(this.rowsData);

    this.setRowsIsEven();
  }

  inputChanged({ str, column }) {
    this.inputCallback.emit({ str, column });
  }

  checkChanged({ value, column }) {
    this.checkCallback.emit({ value, column });
  }
}
