<div class="checkbox label-position-{{labelPosition}}" (click)="onClick($event)">
    <hse-icon *ngIf="model"
              (click)="onChangeValue()"
              [class.disable-checked]="model && disabled"
              class="check-icon" [name]="'check'"
    ></hse-icon>
    <input id="checkbox-{{ checkboxId }}"
           type="checkbox"
           (change)="onChangeValue(true)"
           [(ngModel)]="model"
           [class.error]="customInvalid"
           [disabled]="disabled">
    <label for="checkbox-{{ checkboxId }}">
        <ng-content></ng-content>
    </label>
</div>
