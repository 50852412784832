import { Component } from '@angular/core';
import { HseLoaderService } from '../services/hse-loader.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'hse-loader',
  templateUrl: './hse-loader.component.html',
  styleUrls: ['./hse-loader.component.scss']
})
export class HseLoaderComponent {

  /**
   * Открытие/скрытие лоадера
   */
  visible$: Observable<boolean>;
  background$: Observable<boolean>;

  constructor(private hseLoaderService: HseLoaderService) {
    this.visible$ = this.hseLoaderService.loadingStatus;
    this.background$ = this.hseLoaderService.backgroundLoadingStatus;
  }
}
