<section class="calendar" (click)="$event.stopPropagation()">
    <header>
        <div class="month fx row space-between v-center">
            <div class="chevron fx h-v-center" (click)="prevMonth()">
                <hse-icon name="chevronLeft" aria-label="chevronLeft" class="calendar-arrow"></hse-icon>
            </div>

            <span>{{now.format("MMMM YYYY")}}</span>

            <div class="chevron fx h-v-center" (click)="nextMonth()">
                <hse-icon name="chevronRight" aria-label="chevronRight" class="calendar-arrow"></hse-icon>
            </div>
        </div>
        <div class="week-days fx row space-around v-center">
            <div *ngFor="let dayOfWeek of weekDays" class="fx row h-v-center">{{dayOfWeek.name}}</div>
        </div>
    </header>

    <main>
        <div *ngFor="let week of weeks" class="week fx row space-around v-center">
            <div *ngFor="let day of week"
                 [ngClass]="day.className"
                 (click)="selectDay(day)"
                 (mouseover)="hoverDay(day)"
                 (mouseleave)="clearHoverRange()"
                 class="fx row h-v-center">
                <div class="fx h-center v-start" [style.padding-top.px]="8">{{ day.date }}</div>
            </div>
        </div>

        <div class="fx wor h-end v-center" *ngIf="withReset">
            <span class="reset" (click)="reset()">{{ 'UI.CALENDAR.RESET' | translate }}</span>
        </div>
    </main>
</section>
