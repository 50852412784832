import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { compact as _compact, filter as _filter, flow as _flow, forEach as _forEach, includes as _includes, map as _map, sortBy as _sortBy, } from 'lodash/fp';
import _int from '@tools/fp/int';
import { HseComboBoxItem } from '@shared/components/common-ui/hse-combo-box/hse-combo-box.interface';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

/**
 * Это комбо-бокс (селект с множественным выбором).
 *
 * Пример использования:
 * <hse-combo-box [collection]="selectCollection"
 *   (change)="comboChange($event)"
 *   [(model)]="comboModel"
 *   [nameField]="'name'"
 *   [selectedField]="'selected'"
 *   [width]="300"
 *   ></hse-combo-box>
 *
 *   где collection - исходная коллекция
 *   change - коллбэк на изменение модели
 *   model - коллекция (!) из выбранных значений
 *   nameField - ключ отображаемого поля
 *   selectedField - ключ поля значения выбрано/не выбрано
 *   width - ширина
 *   chipsContainerWidth - ширина контейнера с чипами. может пригодиться, если сам комбо-бокс слишком маленького размера
 */
@Component({
  selector: 'hse-combo-box',
  templateUrl: './hse-combo-box.component.html',
  styleUrls: ['./hse-combo-box.component.scss']
})
export class HseComboBoxComponent implements OnInit, OnChanges, AfterViewInit {

  modelValue = [];
  resultCollection: HseComboBoxItem[] = [];
  trueWidth;
  trueChipsContainerWidth;
  hiddenChipsCount = 0;
  searchString: string;
  containerElement;
  containerHeight;

  @Input() collection: HseComboBoxItem[];
  filteredCollection: HseComboBoxItem[];
  @Input() nameField ? = 'name';
  @Input() searchField ? = null;
  @Input() selectedField ? = 'selected';
  @Input() idField ? = 'id';
  @Input() placeholder ? = '';
  @Input() maxHeight ? = 40;
  @Input() minHeight ? = 40;
  @Input() width?: string;
  @Input() chipsContainerWidth ?: string;
  @Input() useSearch: boolean;
  @Input() searchPlaceholder: string;
  @Input() instantDisplayCollection ? = false;
  @Input() disabled = false;
  @Input() maxSelectedItems ?: number;
  @Input() updateOnClose ? = false;
  @Input() sortBySelected ? = false;
  @Input() valueChanged ? = false;
  @Input() customInvalid = false;
  @Input() disablePlaceholderAnimation = false;

  @Input()
  get model() {
    return this.modelValue;
  }

  set model(val) {
    this.modelValue = val;
    this.modelChange.emit(this.modelValue);
  }

  @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() change?: EventEmitter<any> = new EventEmitter<any>();
  @Output() openCallback ?: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('optionsList', { static: false }) optionsList: ElementRef;
  @ViewChild('viewPort', { static: true }) virtualScroll: CdkVirtualScrollViewport;

  ngOnInit() {
    if (!Boolean(this.searchField)) {
      this.searchField = this.nameField;
    }
    this.filteredCollection = [...this.collection];
    this.trueWidth = _int(this.width) || 377;
    this.trueChipsContainerWidth = _int(this.chipsContainerWidth) || 85;
    if (this.model) {
      _forEach((item: HseComboBoxItem) => {
        if (_includes(item[this.idField])(this.model)) {
          item.selected = true;
          this.resultCollection.push(item);
        } else {
          item.selected = false;
        }
      })(this.collection);

      if (this.sortBySelected) {
        this.collection = _flow(
          _sortBy<any>(this.nameField),
          _sortBy(($item: any) => !$item.selected)
        )(this.collection);
      }

      this.filterOptions();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (Boolean(changes.collection) && !changes.collection.isFirstChange()
      || Boolean(changes.model) && !changes.model.isFirstChange()) {
      this.resultCollection = [];

      if (this.model) {
        _forEach((item: HseComboBoxItem) => {
          if (_includes(item[this.idField])(this.model)) {
            item.selected = true;
            this.resultCollection.push(item);
          } else {
            item.selected = false;
          }
        })(this.collection);

        if (this.sortBySelected) {
          this.collection = _flow(
            _sortBy<any>(this.nameField),
            _sortBy(($item: any) => !$item.selected)
          )(this.collection);
        }

        this.filterOptions();
        setTimeout(() => {
          this.getHiddenChipsCount();
        }, 0);
      }
    }
  }

  ngAfterViewInit(): void {
    this.getHiddenChipsCount();
  }

  itemClicked(item: HseComboBoxItem, event: Event) {
    event.stopPropagation();
    event.preventDefault();

    if (item.disabled || this.checkItemForDisable(item)) {
      return;
    }

    item.selected = !Boolean(item.selected);
    this.updateModel();
    window.dispatchEvent(new Event('resize'));

    if (!this.updateOnClose) {
      this.change.emit(this.model);
    }
  }

  getMenuRowClass(item: HseComboBoxItem) {
    return _compact(['menu-row', item[this.selectedField] && 'selected', (this.checkItemForDisable(item) || item.disabled) && 'disabled'])
      .join(' ');
  }

  removeAllItems() {
    _forEach((item) => {
      item[this.selectedField] = false;
    })(this.collection);
    this.updateModel();
    this.change.emit(this.model);
  }

  updateModel() {
    this.resultCollection = _filter<HseComboBoxItem>({selected: true})(this.collection);
    if (this.sortBySelected) {
      this.collection = _flow(
        _sortBy<any>(this.nameField),
        _sortBy(($item: any) => !$item.selected)
      )(this.collection);
    }
    this.model = _map(this.idField)(this.resultCollection);
    setTimeout(() => {
      this.getHiddenChipsCount();
    }, 0);
  }

  getHiddenChipsCount() {
    this.hiddenChipsCount = 0;
    const selectedItemsElems = this.optionsList.nativeElement.querySelectorAll('.chip-item');
    const wrapWidth = parseFloat(getComputedStyle(this.optionsList.nativeElement).width);
    let itemsWidth = 0;
    const itemMargin = 8;
    let ix = 0;
    while (itemsWidth < wrapWidth && ix < this.resultCollection.length) {
      itemsWidth += parseFloat(getComputedStyle(selectedItemsElems[ix]).width) + itemMargin;
      ix += 1;
    }

    this.hiddenChipsCount = itemsWidth > wrapWidth ? this.resultCollection.length - ix + 1 : 0;
  }

  stopPropagation($event: MouseEvent) {
    $event.stopPropagation();
  }

  filterOptions() {
    this.filteredCollection = this.searchString
      ? _filter((item: any) => item[this.searchField].toUpperCase().search(this.searchString.toUpperCase()) > -1)(this.collection)
      : [...this.collection];
  }

  checkItemForDisable(item) {
    if (!this.maxSelectedItems) {
      return false;
    }

    return (this.maxSelectedItems === this.resultCollection.length) && !item.selected;
  }

  closeMenu() {
    if (this.updateOnClose) {
      this.change.emit(this.model);
    }
  }

  openMenu() {
    setTimeout(() => {
      this.containerElement = document.getElementsByClassName('cdk-virtual-scroll-content-wrapper')[0];
      // tslint:disable-next-line:no-magic-numbers
      this.containerHeight = this.containerElement.offsetHeight <= 300 ? this.containerElement.offsetHeight : 300;
      this.virtualScroll.checkViewportSize();
      this.filteredCollection = [...this.filteredCollection];
    }, 0);
    this.openCallback.emit();
  }

  /**
   * Получить css-классы для плэйсхолдера комбобокса
   */
  getPlaceholderClass() {
    return _compact([
      'hse-combo-box-label',
      this.modelValue.length ? 'active' : '',
      this.disablePlaceholderAnimation && this.modelValue.length ? 'disabled' : ''
    ])
      .join(' ');
  }

  /**
   * Получить css-классы для контейнера плэйсхолдера комбобокса
   */
  getPlaceholderContainerClass() {
    return _compact([
      'hse-combo-box-label-container',
      this.disablePlaceholderAnimation && this.modelValue.length ? 'disabled' : ''
    ])
      .join(' ');
  }

  /**
   * Получить css-классы для заголовка селекта
   * disabled - флаг активности селекта
   */
  getHeaderClasses(disabled) {
    return _compact([
      disabled ? 'hse-combo-box-trigger-disabled' : 'hse-combo-box-trigger',
      this.modelValue.length ? 'has-value' : '',
      this.disablePlaceholderAnimation ? 'disabled-placeholder' : '',
      this.customInvalid ? 'custom-invalid' : ''
    ])
      .join(' ');
  }
}
