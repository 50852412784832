export interface BreadcrumbItem {
  title: string;
  url: string;
}

export interface Breadcrumbs {
  url: string;
  items: BreadcrumbItem[];
}

export const breadcrumbsCollection: Breadcrumbs[] = [
  {
    url: '/constructor/study_plan/edit/',
    items: [
      {
        title: 'Учебные планы',
        url: '/constructor/study_plan/list'
      },
      {
        title: 'Базовый учебный план',
        url: ''
      }
    ]
  },
  {
    url: '/constructor/study_plan/edit_by_year/',
    items: [
      {
        title: 'Учебные планы',
        url: '/constructor/study_plan/list'
      },
      {
        title: 'Базовый учебный план',
        url: '/constructor/study_plan/edit/:id'
      },
      {
        title: 'Детализация по годам',
        url: ''
      }
    ]
  },
  {
    url: '/constructor/study_plan/edit_structure/',
    items: [
      {
        title: 'Учебные планы',
        url: '/constructor/study_plan/list'
      },
      {
        title: 'Базовый учебный план',
        url: '/constructor/study_plan/edit/:id'
      },
      {
        title: 'Структура базового учебного плана',
        url: ''
      }
    ]
  },
  {
    url: '/constructor/study_plan/create',
    items: [
      {
        title: 'Учебные планы',
        url: '/constructor/study_plan/list'
      },
      {
        title: 'Создание учебного плана',
        url: ''
      }
    ]
  },


  {
    url: '/constructor/department_discipline/create',
    items: [
      {
        title: 'Дисциплины кафедры',
        url: '/constructor/department_discipline/list'
      },
      {
        title: 'Создать новую дисциплину',
        url: ''
      }
    ]
  },

  {
    url: '/constructor/educational_standards/edit/',
    items: [
      {
        title: 'Образовательные стандарты',
        url: '/constructor/educational_standards/list'
      },
      {
        title: 'Паспорт направления подготовки',
        url: ''
      }
    ]
  },
  {
    url: '/constructor/educational_standards/edit_description/',
    items: [
      {
        title: 'Образовательные стандарты',
        url: '/constructor/educational_standards/list'
      },
      {
        title: 'Паспорт направления подготовки',
        url: '/constructor/educational_standards/edit/:id'
      },
      {
        title: 'Редактирование паспорта направления подготовки',
        url: ''
      }
    ]
  },
  {
    url: '/constructor/educational_standards/edit_structure/',
    items: [
      {
        title: 'Образовательные стандарты',
        url: '/constructor/educational_standards/list'
      },
      {
        title: 'Паспорт направления подготовки',
        url: '/constructor/educational_standards/edit/:id'
      },
      {
        title: 'Структура паспорта направления подготовки',
        url: ''
      }
    ]
  },
  {
    url: '/constructor/educational_standards/create',
    items: [
      {
        title: 'Образовательные стандарты',
        url: '/constructor/educational_standards/list'
      },
      {
        title: 'Создание паспорта направления подготовки',
        url: ''
      }
    ]
  },
  {
    url: '/constructor/study_plan_common/create',
    items: [
      {
        title: 'Учебные планы общевузовских дисциплин',
        url: '/constructor/study_plan_common/list'
      },
      {
        title: 'Создание учебного плана общевузовских дисциплин',
        url: ''
      }
    ]
  },
  {
    url: '/constructor/study_plan_common/edit',
    items: [
      {
        title: 'Учебные планы общевузовских дисциплин',
        url: '/constructor/study_plan_common/list'
      },
      {
        title: 'Учебный план общевузовских дисциплин',
        url: ''
      }
    ]
  },
  {
    url: '/constructor/study_plan_faculty/create',
    items: [
      {
        title: 'Учебные планы факультетских дисциплин',
        url: '/constructor/study_plan_faculty/list'
      },
      {
        title: 'Учебный план факультетских дисциплин',
        url: ''
      }
    ]
  },
  {
    url: '/constructor/study_plan_faculty/edit',
    items: [
      {
        title: 'Учебные планы факультетских дисциплин',
        url: '/constructor/study_plan_faculty/list'
      },
      {
        title: 'Учебный план факультетских дисциплин',
        url: ''
      }
    ]
  },
  {
    url: '/constructor/study_plan_additional/create',
    items: [
      {
        title: 'Учебные планы дополнительных модулей',
        url: '/constructor/study_plan_additional/list'
      },
      {
        title: 'Учебный план дополнительных модулей',
        url: ''
      }
    ]
  },
  {
    url: '/constructor/study_plan_additional/detail',
    items: [
      {
        title: 'Учебные планы дополнительных модулей',
        url: '/constructor/study_plan_additional/list'
      },
      {
        title: 'Учебный план дополнительных модулей',
        url: ''
      }
    ]
  },
  {
    url: '/constructor/study_plan_additional/edit',
    items: [
      {
        title: 'Учебные планы дополнительных модулей',
        url: '/constructor/study_plan_additional/list'
      },
      {
        title: 'Учебный план дополнительных модулей',
        url: ''
      }
    ]
  },
];
