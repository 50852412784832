<div class="hse-table" [ngStyle]="customStyles">
    <div class="hse-table__content_wrapper" #table [style.paddingLeft.px]="fixedColumnsWidth" >
        <div class="hse-table__header"
             *ngIf="dynamicColumns.length"
             #dynamicHeader
             hseSticky [offsetTop]="offsetTop" [offsetLeft]="tableLeftPositionOffset + fixedColumnsWidth" [stackName]="stackName"
             [disabled]="!stickyHeader"
             [zIndex]="100"
             [width]="tableWidth"
             [height]="tableHeaderHeight">
            <div class="fx shifter"
                 [style.left.px]="-tableLeftOffset"
                 [style.height.px]="tableHeaderHeight">
                <hse-table-column *ngFor="let column of visibleDynamicColumns; index as i"
                                  [column]="column"
                                  [configColumns]="mainDynamicColumns"
                                  [table]="self"
                                  [i]="i"
                                  [resetCheck]="resetCheckbox"
                                  (dragStart)="onDragDynamicColumn($event)"
                                  (dragEnd)="onDropToDynamicColumn($event)"
                                  (inputCallback)="inputChanged($event)"
                                  (checkCallback)="checkChanged($event)"
                ></hse-table-column>
            </div>

            <div class="hse-table__header__shift-left"
                 (click)="shiftLeft()"
                 *ngIf="isOverflow && lastVisibleColumnIx > visibleColumns.length - 1">
                <hse-icon [name]="'arrowTriangleRight'" ></hse-icon>
            </div>
            <div class="hse-table__header__shift-right"
                 (click)="shiftRight()"
                 *ngIf="isOverflow && lastVisibleColumnIx < dynamicColumns.length - 1">
                <hse-icon [name]="'arrowTriangleRight'" ></hse-icon>
            </div>
        </div>
        <div class="hse-table__body shifter" #dynamicRowsWrapper [style.left.px]="-tableLeftOffset">
            <hse-table-row  [row]="row" *ngFor="let row of rowsData"
                            [extendControls]="row.isExpandable"
                            (rowClick)="onRowClick($event)"
                            [emptyCell]="emptyCell"
                            (rowExpandChanged)="onRowExpandChanged()"
                            [columns]="dynamicColumns"></hse-table-row>
        </div>
    </div>
    <div class="hse-table__content_wrapper fixed-content" *ngIf="fixedColumns.length">
        <div class="hse-table__header"
             #fixedHeader
             [zIndex]="100"
             hseSticky [offsetTop]="offsetTop" [offsetLeft]="tableLeftPositionOffset" [stackName]="stackName + '_fixed'"
             [disabled]="!stickyHeader"
             [width]="fixedColumnsWidth" [height]="tableHeaderHeight">
            <div class="fx" [style.height.px]="tableHeaderHeight">
                <hse-table-column *ngFor="let column of visibleFixedColumns; index as i"
                                  [column]="column"
                                  [configColumns]="mainFixedColumns"
                                  [table]="self"
                                  [i]="i"
                                  [resetCheck]="resetCheckbox"
                                  (dragStart)="onDragFixedColumn($event)"
                                  (dragEnd)="onDropToFixedColumn($event)"
                                  (inputCallback)="inputChanged($event)"
                                  (checkCallback)="checkChanged($event)"
                ></hse-table-column>
            </div>
        </div>
        <div class="hse-table__body" #fixedRowsWrapper>
            <hse-table-row  [row]="row" *ngFor="let row of rowsData"
                            [columns]="fixedColumns"
                            [emptyCell]="emptyCell"
                            (rowClick)="onRowClick($event)"></hse-table-row>
        </div>
    </div>
    <!--TODO SCROLLBAR-->
</div>
