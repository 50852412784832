import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

export interface HseStepItem {
  label: string;
  value: number;
  icon?: string;
  disabled?: boolean;
}

@Component({
  selector: 'hse-stepper',
  templateUrl: './hse-stepper.component.html',
  styleUrls: ['./hse-stepper.component.scss']
})
export class HseStepperComponent implements OnInit, OnChanges {
  @HostBinding('class') get classes() {
    return `fx row`;
  }

  @Input() separateIcon = 'arrowRight';
  @Input() addSeparateIcon = false;
  @Input() steps: HseStepItem[] = [];
  @Input() model: number | null = null;
  @Output() modelChange: EventEmitter<number> = new EventEmitter<number>();

  public selected: number | null;

  constructor() { }

  ngOnInit() {
    this.selected = this.model || this.steps[0].value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { model }: SimpleChanges = changes;

    if (model && !model.isFirstChange()) {
      this.selected = model.currentValue;
    }
  }

  change(newValue) {
    this.selected = newValue;
    this.modelChange.emit(this.selected);
  }
}
