 <div class="hse-table-row__row-group"
      [class.divider]="row.withDivider">
     <div class="fx hse-table-row__row"
          [class.is-even]="row.isEven"
          [style.height.px]="row.height"
          [ngStyle]="row.$style"
          (mouseenter)="row.isHovered = true"
          (mouseleave)="row.isHovered = false"
          (click)="row.onClick ? row.onClick(row) : rowClick.emit(row)"
          [class.clickable]="row.onClick || isRowClickable"
          [class.hovered]="row.isHovered">
         <div class="hse-table-row__cell fx"
              *ngFor="let column of columns; index as ix"
              [style.minWidth.px]="column.optimizedWidth || column.width"
              [style.width.px]="column.optimizedWidth || column.width">
             <div *ngIf="ix == 0 " [style.marginLeft.px]="(extendControls && row.isExpandable) ? depth * arrowOffset + ((!row?.rows?.length) ? arrowOffset*3 : 0): 0">
                 <hse-icon class="arrow-icon"
                           *ngIf="extendControls && row?.rows?.length"
                           [style.marginLeft.px]="depth * arrowOffset"
                           [class.rotated]="row.isExpanded"
                           (click)="toggleRowExpanded(row, $event)"
                           [name]="'arrowTriangleRight'"></hse-icon>
             </div>
             <div class="wrap" *ngIf="!column.template">{{row[column.calculatedName || column.name] || emptyCell}}</div>
             <ng-template *ngTemplateOutlet="column.template; context: {$implicit: {cell: row[column.calculatedName || column.name], row: row }}"></ng-template>
         </div>
     </div>

     <div *ngIf="row?.rows?.length && (row.isExpanded || !row.isExpandable)">
         <hse-table-row  [row]="subRow" *ngFor="let subRow of row.rows"
                         [extendControls]="extendControls"
                         [isRowClickable]="isRowClickable"
                         (rowExpandChanged)="rowExpandChanged.emit()"
                         (rowClick)="rowClick.emit(row)"
                         [depth]="depth + 1"
                         [columns]="columns"></hse-table-row>
     </div>
 </div>
