import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hse-footer',
  templateUrl: './hse-footer.component.html',
  styleUrls: ['./hse-footer.component.scss']
})
export class HseFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
