<div class="hse-input__container" [style.width.px]="width">
    <input id="{{inputId}}"
           #input
           class="hse-input__input"
           [class.hse-input__input--not-empty]="!!model && !disablePlaceholderAnimation"
           [class.border-without-label]="disablePlaceholderAnimation"
           [class.hide-controls]="hideControls"
           [(ngModel)]="model"
           [type]="type"
           (focus)="emitFocusEvent()"
           (blur)="emitBlurEvent()"
           [disabled]="disabled"
           [placeholder]="placeholder">
    <span class="hse-input__label-container" *ngIf="!disablePlaceholderAnimation">
    <label for="{{inputId}}" class="hse-input__label">
      {{placeholder}}
    </label>
  </span>
</div>
