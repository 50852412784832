import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'hse-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
  @Input() template: TemplateRef<void> | null = null;
  @Input() text = '';
  @Input() width = 450;
  @Input() confirmBtn = '';
  @Input() cancelBtn = '';
  @Output() callback: EventEmitter<boolean> = new EventEmitter<boolean>();
}
