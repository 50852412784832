import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { filter as _filter, forEach as _forEach } from 'lodash/fp';
import { EducationResult } from '@backend/kosuip/catalogue/interfaces/education-result.interface';
import { CatalogueService } from '@backend/kosuip/catalogue/catalogue.service';

@Component({
  selector: 'hse-add-education-results',
  templateUrl: './add-education-results.component.html',
  styleUrls: ['./add-education-results.component.scss']
})
export class AddEducationResultsComponent implements OnInit {
  @Input() isMagistracy = false;
  @Output() close: EventEmitter<EducationResult | null> = new EventEmitter();

  private readonly magistracyEducationLevelId = '5';
  public searchString: string = null;
  public fullMatch = false;
  public selectedKOR: EducationResult = null;
  public educationResults: EducationResult[] = [];
  public specialities: any;
  public filteredSpecialities: any;
  public educationLevels: any;
  public selectedSpecialities: any = [];
  public selectedEducationLevel: any;
  public loaded = false;
  public translations = {
    INPUT_PLACEHOLDER: ''
  };

  constructor(private translate: TranslateService,
              private catalogue: CatalogueService) { }

  ngOnInit(): void {
    this.translations.INPUT_PLACEHOLDER = this.translate.instant('STUDY-PLAN.ADD-EDUCATION-RESULTS-POPUP.INPUT-PLACEHOLDER');
    this.init();
  }

  async init(): Promise<void> {
    try {
      await this.getEducationResults();
      await this.getSpecialities();
      await this.getEducationLevels();

      if (this.isMagistracy) {
        await this.setEducationLevel(this.magistracyEducationLevelId);
      }

      this.loaded = true;
    } catch (e) {
      console.error(e);
    }
  }

  async getEducationResults(fullMatch = false): Promise<void> {
    try {
      this.fullMatch = fullMatch;

      this.submitFilters();
    } catch (e) {
      console.error(e);
    }
  }

  async getSpecialities(): Promise<void> {
    try {
      this.specialities = this.filteredSpecialities = await this.catalogue.getSpecialities();

      _forEach((speciality: any) => {
        speciality.fullName = `${speciality.description} (${speciality.code})`;
      })(this.specialities);
    } catch (e) {
      console.error(e);
    }
  }

  async getEducationLevels(): Promise<void> {
    try {
      const response: any = await this.catalogue.getEducationLevels();

      this.educationLevels = _filter((level: any) => level.id !== '1' && level.id !== '2')(response);
    } catch (e) {
      console.error(e);
    }
  }

  async setEducationLevel(event) {
    try {
      this.selectedEducationLevel = event;
      this.selectedSpecialities = [];
      this.filteredSpecialities = _filter<any>({ ed_level_id: this.selectedEducationLevel })(this.specialities);

      this.submitFilters();
    } catch (e) {
      console.error(e);
    }
  }

  async setSpecialities(event) {
    try {
      this.selectedSpecialities = event;

      this.submitFilters();
    } catch (e) {
      console.error(e);
    }
  }

  async submitFilters() {
    try {
      const filters: any = {};

      if (Boolean(this.searchString)) {
        filters.description = this.searchString;
      }

      if (Boolean(this.fullMatch)) {
        filters.complete_match = true;
      }

      if (this.selectedEducationLevel) {
        filters.education_level_id = this.selectedEducationLevel;
      }

      if (this.selectedSpecialities.length) {
        filters.speciality_id = this.selectedSpecialities.join(',');
      }

      const response = await this.catalogue.getEducationResultsTranslations({params : {detail_info: true, ...filters}});
      this.educationResults = response.body;
    } catch (e) {
      console.error(e);
    }
  }

  onClose(): void {
    this.close.emit(null);
  }

  addKOR() {
    this.close.emit(this.selectedKOR);
  }
}
