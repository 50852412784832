<div [matMenuTriggerFor]="menu" class="column-settings">
    <div class="fx v-center icon-wrapper" (click)="menuOpened()">
        <hse-icon [name]="'moreInfo'"></hse-icon>
    </div>
</div>
<mat-menu class="hse-table-column-menu" #menu tabindex="-1" [overlapTrigger]="false">
    <div>
        <div *ngIf="column.enableInput" (click)="$event.stopPropagation()">
            <div class="fx v-center h-center" style="margin: 14px 0;">
                <hse-input [(ngModel)]="inputStr"
                           (keydownEvent)="checkInput($event)"
                           placeholder="Поиск"
                           [width]="160"></hse-input>
            </div>
        </div>
        <div *ngIf="column.filter" class="divider" mat-menu-item [matMenuTriggerFor]="filterMenu">
            <div class="fx v-center space-between">
                <span>{{ 'UI.TABLE.FAST-FILTER' | translate }}</span>
                <hse-icon class="hse-table-column-menu__triangle-icon" [name]="'arrowTriangleRight'" ></hse-icon>
            </div>
        </div>
        <div *ngIf="orderAvailable" [ngClass]="{ 'divider': column.enableInput }" mat-menu-item (click)="sortColumn($event, 'asc')">
            <div class="fx v-center space-between">
                <span>{{ 'UI.TABLE.ASC' | translate }}</span>
                <hse-icon class="sorted-icon" *ngIf="column.sortDir === 'asc'" [name]="'check'"></hse-icon>
            </div>
        </div>
        <div *ngIf="orderAvailable" mat-menu-item (click)="sortColumn($event, 'desc')">
            <div class="fx v-center space-between">
                <span>{{ 'UI.TABLE.DESC' | translate }}</span>
                <hse-icon class="sorted-icon" *ngIf="column.sortDir === 'desc'" [name]="'check'" ></hse-icon>
            </div>
        </div>
        <div mat-menu-item [matMenuTriggerFor]="columnsMenu" [class.divider]="orderAvailable">
            <div class="fx v-center space-between">
                <span>{{ 'UI.TABLE.MANAGE' | translate }}</span>
                <hse-icon class="hse-table-column-menu__triangle-icon" [name]="'arrowTriangleRight'"></hse-icon>
            </div>
        </div>
    </div>
</mat-menu>
<mat-menu class="hse-table-column-menu" #columnsMenu tabindex="-1" [overlapTrigger]="false">
    <div class="menu-description">{{ 'UI.TABLE.SORT-COLUMNS' | translate }}</div>
    <div [class.hidden-column]="column.hidden" mat-menu-item [class.divider]="ix != 0" class="settings-column-item"
         (click)="$event.stopPropagation()"
         *ngFor="let column of config; index as ix">
        <div class="fx v-center space-between drag-wrapper"
             [class.drag]="ix === dragColumnFromIx"
             (mousedown)="onDragColumn($event, ix)"
             (mouseup)="onDropColumn($event, ix)">
            <hse-icon class="drag-column-icon" [name]="'drag'"></hse-icon>
            <span>{{ix+1}}.{{column.title}}</span>
            <div class="fx v-center">
                <hse-icon class="hide-column-icon" (click)="toggleColumnHidden($event, column)" [name]="'eye'"></hse-icon>
                <hse-icon class="fixed-column-icon" (click)="toggleColumnFixed($event, column)" *ngIf="column.fixed && fixationAvailable" [name]="'lock'"></hse-icon>
                <hse-icon class="dynamic-column-icon" (click)="toggleColumnFixed($event, column)"  *ngIf="!column.fixed && fixationAvailable" [name]="'lockOpened'"></hse-icon>
            </div>
        </div>
    </div>

    <div class="reset-settings-button" (click)="resetColumnsSettings()">{{ 'UI.TABLE.DEFAULT' | translate }}</div>
</mat-menu>

<mat-menu class="hse-table-column-menu" #filterMenu tabindex="-1" [overlapTrigger]="false">
    <div class="menu-description">{{ 'UI.TABLE.SHOW' | translate }}</div>
    <div class="filter-wrapper" *ngIf="column.filter">
<!--        <div  mat-menu-item (click)="filterByColumn($event, filterItem)" class="divider filter-menu-item fx v-center"
              *ngFor="let filterItem of column.filter.collection; index as ix">
            <div >
                <hse-checkbox [stopClickPropagation]="true"
                              [(model)]="filterItem.selected"
                              (modelChange)="filterByColumn($event, filterItem)">
                    {{filterItem[column.filter.displayField || 'name']}}
                </hse-checkbox>
            </div>
        </div>-->
    <!--(click)="$event.stopPropagation()"-->

        <hse-radio-group *ngIf="column.filter.type === 'radio'"
                         [radioGroupItems]="column.filter.collection"
                         [selectedValue]="column.filter.selectedValue"
                         [radioGroupName]="column.name"
                         (changeRadio)="filterByColumn($event)"
        ></hse-radio-group>

        <hse-select *ngIf="column.filter.type === 'select'"
                    (click)="$event.stopPropagation()"
                    [collection]="column.filter.collection"
                    [(ngModel)]="column.filter.selectedValue"
                    (ngModelChange)="filterByColumn()"
                    [useSearch]="false"
                    [width]="column.filter.width"
                    [disablePlaceholderAnimation]="true"
                    [idField]="column.filter.filterByField || 'id'"
                    [nullText]="translations.CHOOSE"
        ></hse-select>

        <hse-ya-combo-box
                *ngIf="column.filter.type === 'combo-box'"
                [name]="column.name"
                [collection]="column.filter.collection"
                [placeholder]="'Выбрать'"
                [maxHeight]="40"
                [width]="column.filter.width"
                [(model)]="column.filter.selectedValue"
                [nameField]="column.filter.displayField || 'name'"
                [idField]="column.filter.filterByField || 'id'"
                [style.position]="'relative'"
                (changeSelected)="filterByColumn()"
        ></hse-ya-combo-box>
    </div>
</mat-menu>
