import { Component, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'hse-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopupComponent {

  @Input() message: string;
  @Input() yesNo = false;
  @Output() callback: EventEmitter<boolean> = new EventEmitter<boolean>();

}
