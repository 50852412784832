<div class="hse-pagination fx v-center">
    <div class="per-page-select fx v-center" *ngIf="!withoutPerPage">
        <hse-select [collection]="perPageData"
                    [(ngModel)]="perPage"
                    [idField]="'perPage'"
                    [nameField]="'perPage'"
                    [width]="70"
                    [disablePlaceholderAnimation]="true"
                    [customStyle]="{height: '40px'}"
                    (ngModelChange)="switchPerPage($event)"></hse-select>
    </div>
    <div class="page-switch fx fx-1 h-v-center" [style.margin-left]="withoutPerPage ? '' : '-80px'">
        <!--<hse-button [filters]="true" (click)="firstPage()" matTooltip="{{ 'UI.PAGINATION.FIRST-HINT' | translate }}">{{ 'UI.PAGINATION.FIRST' | translate }}</hse-button>-->
        <hse-button [filters]="true" (click)="prevPage()">
            <hse-icon name="leftArrow"
                      matTooltip="{{ 'UI.PAGINATION.PREV-HINT' | translate }}"></hse-icon>
        </hse-button>

        <!--НОМЕРА СТРАНИЦ-->
        <div class="fx">
            <button *ngFor="let sheet of pagesToShow"
                    (click)="setPage(sheet)"
                    class="pages fx h-center v-center"
                    [ngClass]="{
                'selected': page === sheet,
                'dots': sheet === '...'
            }">{{sheet}}</button>
        </div>
        <!--НОМЕРА СТРАНИЦ КОНЕЦ-->

        <hse-button [filters]="true" (click)="nextPage()">
            <hse-icon name="rightArrow"
                      matTooltip="{{ 'UI.PAGINATION.NEXT-HINT' | translate }}"></hse-icon>
        </hse-button>
        <!--<hse-button [filters]="true" (click)="lastPage()" matTooltip="{{ 'UI.PAGINATION.LAST-HINT' | translate }}">{{ 'UI.PAGINATION.LAST' | translate }}</hse-button>-->
    </div>
</div>
