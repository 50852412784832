import { Component, Input, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { some as _some, find as _find } from 'lodash/fp';

export interface EducationTrajectory {
  id: string;
  description: string;
  specialization_id: string;
  edu_trajectory_type_description: string;
  disabled?: boolean;
}

@Component({
  selector: 'hse-education-trajectory-select',
  templateUrl: './hse-education-trajectory-select.component.html',
  styleUrls: ['./hse-education-trajectory-select.component.scss']
})
export class HseEducationTrajectorySelectComponent implements OnInit, OnDestroy {

  @Input() educationTrajectories: EducationTrajectory[] = [];
  @Input() showAllPlanTrajectory = true;
  @Input() showCommonPart = true;
  @Input() reset?: Subject<string> = null;
  @Output() callback: EventEmitter<string> = new EventEmitter<string>();

  selectedTrajectoryId: string = null;
  resetSubscription: Subscription = null;

  ngOnInit(): void {
    if (Boolean(this.reset)) {
      this.resetSubscription = this.reset.subscribe((specializationId: string) => {
        this.selectedTrajectoryId = specializationId;
      });
    }

    if (!this.showAllPlanTrajectory) {
      this.selectedTrajectoryId = this.showCommonPart ? 'common' : this.educationTrajectories[0].specialization_id;
    }
  }

  selectEducationTrajectory(id: string): void {
    let trajectory;

    if (id && id !== 'common') {
      trajectory = _find<any>({ specialization_id: id })(this.educationTrajectories);

      if (trajectory.disabled) {
        return;
      }
    } else {
      if (this.disableCommonPart()) {
        return;
      }
    }

    this.selectedTrajectoryId = id;
    this.callback.emit(id);
  }

  disableCommonPart() {
    return _some<any>({ disabled: true })(this.educationTrajectories);
  }

  ngOnDestroy(): void {
    if (Boolean(this.reset)) {
      this.resetSubscription.unsubscribe();
    }
  }
}
