import { Pipe, PipeTransform } from '@angular/core';
import { reject as _reject } from 'lodash/fp';

@Pipe({name: 'reject'})
export class RejectPipe implements PipeTransform {

    transform(items: any[], target: any): any[] {
        return _reject(target)(items);
    }
}
