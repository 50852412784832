import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@backend/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateCustomService } from '@shared/services/translate-custom.service';

// import { SessionStoreService } from '@core/services/session-store/session-store.service';
// import { UpdateApiWrapperService } from '@core/services/wrapper-update/update-api-wrapper.service';
// import { DefaultPageService } from '@core/services/default-page/default-page.service';
//
// import { Session } from '@backend/lms/session/session.interface';
// import { TeacherProfileExtended } from '@backend/core/teacher-profile/teacher-profile-extended.interface';
//
// import { forkJoin, Observable } from 'rxjs';
// import { concatMap, tap } from 'rxjs/operators';
// import _uniqPluck from '@tools/fp/uniqPluck';
//
// import { includes as _includes } from 'lodash/fp';
//
@Injectable({
  providedIn: 'root'
})
export class AppLoadService {
  private router: Router;

  constructor(private authService: AuthService, private injector: Injector, private translate: TranslateService, private translateCustom: TranslateCustomService) {
  }

  async initApp(): Promise<any> {
    const locale = localStorage.getItem('locale');
    console.log('App initializing...');
    if (!locale || locale === 'ru') {
      await this.translate.setDefaultLang('ru');
      await this.translate.use('ru');
      localStorage.setItem('locale', 'ru');
    } else {
      await this.translate.setDefaultLang('en');
      await this.translate.use('en');
    }
    this.authService.initSession();
    this.translateCustom.initExtensions();
  }

  // /**
  //  * Инициализировать сессию
  //  * @param body: { auth_token: string } | { login: string, password: string } - тело запроса
  //  */
  // initSession(body): Observable<any> {
  //   return this.sessionStore.getSession(body).pipe(
  //     concatMap((session: Session) => {
  //       const userIds: number[] = _uniqPluck('user_id')(session.profiles);
  //       console.log(session);
  //
  //       this.sessionStore.setSession(session);
  //       this.sessionStore.castTypesToRoles();
  //       this.sessionStore.beautifySessionProfiles();
  //       this.sessionStore.setDefaultSessionProfile();
  //       this.sessionStore.setCookies();
  //       this.updateApiWrapper.updateHeadersAndParams();
  //
  //       return forkJoin(this.sessionStore.loadUsers(userIds), this.sessionStore.loadRights());
  //     }),
  //     concatMap(([users, rights]) => {
  //       this.sessionStore.getRightsForCurrentProfile(rights);
  //       this.sessionStore.setSessionUsers(users);
  //       this.sessionStore.setCurrentUser();
  //       this.sessionStore.setSupportCookies();
  //
  //       return this.sessionStore.getTeacherProfile();
  //     }),
  //     tap((teacherProfile: TeacherProfileExtended) => {
  //       console.log('ROLES: ', this.sessionStore.currentProfile.roles);
  //       this.sessionStore.setTeacherProfile(teacherProfile);
  //
  //       const path = location.pathname;
  //       if (_includes(path)(['/', '/new', '/new/', '/new/auth'])) {
  //         const defaultPage = this.defaultPageService.getDefaultPage();
  //         this.router.navigateByUrl(defaultPage, {replaceUrl: true});
  //       }
  //     })
  //   );
  // }
}
