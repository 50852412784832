export enum ElementTypes {
  // "Раздел"
  SECTION = '00000000001',
  // "Раздел с указанием предметной области"
  SECTION_WITH_SUBJECT_AREA = '00000000002',
  // "Раздел c указанием вида практики"
  SECTION_WITH_PRACTICE_TYPE = '00000000003',
  // "Научно-исследовательский семинар"
  RESEARCH_SEMINAR = '00000000004',
  // "Дисциплина"
  DISCIPLINE = '00000000005',
  // "Раздел \"Major\""
  SECTION_MAJOR = '00000000006',
  // "Раздел \"Практика\""
  SECTION_PRACTICE = '00000000007',
  // "Раздел \"ДОЦ\""
  SECTION_DOTS = '00000000008',
  // "Раздел \"Minor\""
  PARTITION_MINOR = '00000000009',
  // "Раздел \"Английский язык\""
  SECTION_ENGLISH = '00000000010',
  // "Раздел \"Data Culture\""
  SECTION_DATA_CULTURE = '00000000011',
  // "Раздел \"ГИА\""
  SECTION_GIA = '00000000012',
  // "Выпускная квалификационная работа"
  GRADUATION_WORK = '00000000013',
  // "Итоговый госэкзамен"
  FINAL_STATE_EXAM = '00000000014',
  // "Практика"
  PRACTICE = '00000000015',
  // "Дисциплина других планов"
  DISCIPLINE_OTHER_PLANES = '00000000016',
  // "Курсовая работа"
  COURSEWORK = '00000000017',
  // "Проект студентов"
  STUDENT_PROJECT = '00000000018',
  // "Раздел \"Государственные экзамены\""
  SECTION_STATE_EXAMS = '00000000019',
  // "Зачетно-экзаменационные недели"
  EXAM_WEEKS = '00000000020',
  // "Научные исследования аспирантов"
  POSTGRADUATE_RESEARCH = '00000000021',
  // "Кандидатский экзамен"
  CANDIDATE_EXAM = '00000000022',
  // "Практика аспирантов"
  POSTGRADUATE_PRACTICE = '00000000023',
  // "Итоговый госэкзамен аспирантов"
  POSTGRADUATE_FINAL_STATE_EXAM = '00000000024',
  // "Защита научного доклада аспирантов"
  GRADUATE_STUDENTS_SCIENTIFIC_REPORT = '00000000025',
  // "Базовая часть"
  BASE_PART = '00000000026',
  // "Вариативная часть"
  VARIABLE_PART = '00000000027',
  // "Раздел \"Факультативы\""
  SECTION_ELECTIVES = '00000000028',
  // "Проект школьников"
  SCHOOLCHILDREN_PROJECT = '00000000029',
  // Раздел образовательной траектории
  EDUCATIONAL_TRAJECTORY_SECTION = '00000000030',
  // "Оценка диссертации на предмет её соответствия критериям"
  THESIS_ASSESSMENT = '00000000032',
  // "Образовательный компонент"
  EDUCATIONAL_COMPONENT = '00000000031',
  // "Научный компонент "
  SCIENTIFIC_COMPONENT = '00000000033',
}
