import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HseLoaderService {
  private loaderIsVisible = false;
  private loadingStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private backgroundLoadingStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Получить статус загрузки
   */
  get loadingStatus(): Observable<boolean> {
    return this.loadingStatus$.asObservable();
  }

  /**
   * Получить статус загрузки
   */
  get backgroundLoadingStatus(): Observable<boolean> {
    return this.backgroundLoadingStatus$.asObservable();
  }

  /**
   * Показать лоадер
   */
  show(isBackground = false) {
    if (isBackground) {
      this.backgroundLoading = true;

      return;
    }

    this.loading = true;
  }

  /**
   * Скрыть лоадер
   */
  hide(isBackground = false) {
    if (isBackground) {
      this.backgroundLoading = false;

      return;
    }

    this.loading = false;
  }

  /**
   * Установить видимость лоадера
   */
  private set loading(value: boolean) {
    this.loaderIsVisible = value;
    this.loadingStatus$.next(value);
  }

  /**
   * Установить видимость лоадера
   */
  private set backgroundLoading(value: boolean) {
    this.loaderIsVisible = value;
    this.backgroundLoadingStatus$.next(value);
  }
}
