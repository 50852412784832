<div class="add-element-background fx column stretch" [style.min-height.px]="bodyHeight" (window:resize)="setBodyHeight()">
    <div class="add-element-wrapper fx column fx-1">
        <div class="title fx row stretch" *ngIf="path && path.length">
            <div class="fx fx-4 row h-start">
                <h1>{{ edit ? translations.EDIT : translations.ADD }}</h1>
            </div>

            <div class="center fx-3 column h-start" *ngIf="path[iterationIndex] !== 'final'">
                <div *ngIf="!edit">
                    <div class="passed-item fx row" *ngFor="let item of passedItems">
                        <span class="title">{{item.title}}</span>
                        <span class="value">{{item.value}}</span>
                    </div>
                </div>


                <div class="fx row"
                     [style.margin-top.px]="margin"
                     *ngIf="path[iterationIndex] === 'directoryFromCatalogue'">
                    <div class="from-catalogue fx fx-1 column">
                        <span class="iteration-title">{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-CATALOGUE.DIRECTORY-FROM-CATALOGUE' | translate }}</span>
                        <div class="search-str fx row v-center">
                            <hse-input [(ngModel)]="directoryFilters.full_description"
                                       (debounceCallback)="loadDirectories()"
                                       [disablePlaceholderAnimation]="true"
                                       hseDebounce
                                       [debounceTime]="300"
                                       [width]="560"
                                       placeholder="{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.SEARCH' | translate }}"></hse-input>
                            <hse-checkbox [(model)]="directoryFilters.complete_match"
                                          (modelChange)="loadDirectories()">{{ 'STUDY-PLAN.WORK-EDIT.FULL-MATCH' | translate }}</hse-checkbox>
                        </div>

                        <div class="fx column h-center no-directories" *ngIf="!directories.length">
                            <span>{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-CATALOGUE.MESSAGE' | translate }}</span>
                            <hse-button (callback)="close.emit({create_flag: true})">{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-CATALOGUE.CREATE-DIRECTORY' | translate }}</hse-button>
                        </div>

                        <div class="fx column"
                             *ngIf="directories.length"
                             (hseInfiniteScroll)="loadDirectories(true)"
                             [distance]="-10">

                            <div class="table-header"
                                 hseSticky
                                 [stackName]="'topBar'">

                                <div>

                                    <div class="iteration__7-header fx row">

                                        <div class="iteration__7-header-cell first-col fx row space-between v-center"></div>
                                        <div class="iteration__7-header-cell fx row space-between v-center"
                                             [style.width.px]="i === directoryTable.length - 1 ? column.width + 2 : column.width"
                                             *ngFor="let column of directoryTable; index as i">
                                            <span>{{column.title}}</span>
                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div>

                                <div class="height">

                                    <div class="radio-item fx row v-center"
                                         (click)="selectDirectory(option)"
                                         *ngFor="let option of directories">
                                        <div class="iteration__7-table-cell fx row v-center">
                                            <div class="circle"
                                                 [class.selected]="option.id === progress.directoryFromCatalogue.value"></div>
                                        </div>


                                        <div class="iteration__7-table-cell fx row v-center"
                                             [style.width.px]="column.width"
                                             *ngFor="let column of directoryTable">
                                            <p matTooltip="{{option[column.name] || '-'}}">{{option[column.name] || '-'}}</p>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>

                    <div class="next-button">
                        <hse-button [disabled]="!progress.directoryFromCatalogue.value" (callback)="setDirectory()">{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.NEXT' | translate }}</hse-button>
                    </div>
                </div>


                <div class="fx row"
                     *ngIf="path[iterationIndex] === 'subjectField'"
                     [style.margin-top.px]="margin">
                    <div class="departments fx fx-1 column">
                        <span class="iteration-title">{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-CATALOGUE.AREAS' | translate }}</span>
                        <div class="search-str">
                            <hse-input [(ngModel)]="searchString"
                                       (ngModelChange)="filterFields($event)"
                                       [disablePlaceholderAnimation]="true"
                                       [width]="500"
                                       placeholder="{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-CATALOGUE.SEARCH' | translate }}"></hse-input>
                        </div>
                        <div class="radio fx column">
                            <div class="radio-item practice fx row v-center"
                                 *ngFor="let item of filteredSubjectFields"
                                 (click)="selectSubjectField({title: item.name, value: item.id})">
                                <div class="circle"
                                     [class.selected]="progress.subjectField.value === item.id"></div>
                                <span>{{item.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="duration fx fx-1 row"
                     *ngIf="path[iterationIndex] === 'duration'"
                     [style.margin-top.px]="margin">
                    <div class="fx column">
                        <span class="iteration-title">{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-CATALOGUE.MIN-CREDITS' | translate }}</span>
                        <div class="fx row inputs">
                            <hse-input [(ngModel)]="progress.duration.value"
                                       [width]="100"
                                       [type]="'number'"
                                       [hideControls]="true"
                                       [disabled]="optional"
                                       [disablePlaceholderAnimation]="true"></hse-input>


                        </div>
                        <div class="fx row v-center">
                            <div class="skip">
                                <hse-checkbox [(model)]="optional"
                                              (modelChange)="checkOptionality($event)">{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-CATALOGUE.SKIP' | translate }}</hse-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="button">
                        <hse-button [color]="'primary'"
                                    (callback)="setValue()"
                                    [disabled]="disableCredits()">{{edit ? 'Применить' : 'Далее'}}</hse-button>
                    </div>
                </div>


                <div class="duration fx fx-1 row"
                     *ngIf="path[iterationIndex] === 'name'"
                     [style.margin-top.px]="margin">
                    <div class="fx column">
                        <span class="iteration-title">{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-CATALOGUE.NAME' | translate }}</span>
                        <div class="fx row inputs">
                            <hse-input [(ngModel)]="progress.name.value"
                                       [width]="400"
                                       [disablePlaceholderAnimation]="true"></hse-input>
                        </div>
                    </div>
                    <div class="button">
                        <hse-button [color]="'primary'"
                                    (callback)="setValue()"
                                    [disabled]="!progress.name.value">{{ edit ? translations.ACCEPT : translations.NEXT }}</hse-button>
                    </div>
                </div>

                <div class="duration fx fx-1 row"
                     *ngIf="path[iterationIndex] === 'englishName'"
                     [style.margin-top.px]="margin">
                    <div class="fx column">
                        <span class="iteration-title">{{ 'EDUCATIONAL-STANDARD.STRUCTURE.CATALOGUE-PROGRESS.ENG-NAME' | translate }}</span>
                        <div class="fx row inputs">
                            <hse-input [(ngModel)]="progress.englishName.value"
                                       [width]="400"
                                       [disablePlaceholderAnimation]="true"></hse-input>
                        </div>
                    </div>
                    <div class="button">
                        <hse-button [color]="'primary'"
                                    (callback)="setValue()">{{ edit ? translations.ACCEPT : translations.NEXT }}</hse-button>
                    </div>
                </div>


                <div class="fx row"
                     *ngIf="path[iterationIndex] === 'elementType'"
                     [style.margin-top.px]="margin">
                    <div class="filials fx fx-1 column">
                        <span class="iteration-title">{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-CATALOGUE.TYPES-OF-ELEMENTS' | translate }}</span>
                        <div class="checkbox fx column">
                            <div class="fx column" *ngFor="let item of filteredElementTypes">
                                <div class="checkbox-item fx row v-center"
                                     [class.disabled]="item.disabled">
                                    <hse-checkbox [(model)]="item.selected"
                                                  [disabled]="item.disabled">{{item.name}}</hse-checkbox>
                                </div>
                                <div *ngIf="item.selected" class="checkbox-options fx column">
                                    <span class="sub-title">{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-CATALOGUE.RESTRICTIONS' | translate }}</span>
                                    <div class="fx row space-between">
                                        <div class="fx fx-1 row sub-radio"
                                             *ngFor="let option of item.required; index as i" (click)="selectOption(item, i)">
                                            <div class="fx fx-1 row v-center" [class.h-end]="i === 1">
                                                <div class="circle" [class.selected]="option.selected"></div>
                                                <span>{{option.text}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="fx row space-between">
                                        <hse-input [(ngModel)]="item.min"
                                                   [width]="100"
                                                   [placeholder]="translations.FROM"
                                                   [type]="'number'"
                                                   [hideControls]="true"
                                                   (ngModelChange)="validate(item)"
                                                   [disabled]="item.required[1].selected"
                                                   [disablePlaceholderAnimation]="true"></hse-input>
                                        <hse-input [(ngModel)]="item.max"
                                                   [width]="100"
                                                   [customInvalid]="item.invalid"
                                                   [type]="'number'"
                                                   [hideControls]="true"
                                                   (ngModelChange)="validate(item)"
                                                   (blur)="checkMaxOfType(item)"
                                                   [placeholder]="translations.TO"
                                                   [disablePlaceholderAnimation]="true"></hse-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hse-button [color]="'primary'"
                                    (callback)="setElementTypes()"
                                    [disabled]="checkTypesDisable()">{{ edit ? translations.ACCEPT : translations.NEXT }}</hse-button>
                    </div>
                </div>


                <div class="fx row"
                     *ngIf="path[iterationIndex] === 'additional'"
                     [style.margin-top.px]="margin">
                    <div class="filials fx fx-1 column">
                        <span class="iteration-title">{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-CATALOGUE.ADDITIONAL-PROPERTIES' | translate }}</span>
                        <div class="checkbox fx column">
                            <div class="fx column">
                                <div class="checkbox-item fx row v-center"
                                     [class.disabled]="!config.checkboxAvailable">
                                    <hse-checkbox [(model)]="progress.additional.optional"
                                                  [disabled]="!config.checkboxAvailable">{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-CATALOGUE.OPTIONAL-DIRECTORY' | translate }}</hse-checkbox>
                                </div>
                                <div class="checkbox-item fx row v-center"
                                     [class.disabled]="config.disableSum">
                                    <hse-checkbox [(model)]="progress.additional.check"
                                                  [disabled]="config.disableSum">{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-CATALOGUE.COUNT-AS-SUM' | translate }}</hse-checkbox>
                                </div>
                                <div class="checkbox-options fx column">
                                    <span class="sub-title">{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-CATALOGUE.NUMBER-OF-ELEMENTS' | translate }}</span>
                                    <div class="fx row space-between">
                                        <hse-input [(ngModel)]="progress.additional.min"
                                                   [width]="100"
                                                   [placeholder]="translations.FROM"
                                                   [type]="'number'"
                                                   (ngModelChange)="validateAdds()"
                                                   [hideControls]="true"
                                                   [disablePlaceholderAnimation]="true"></hse-input>
                                        <hse-input [(ngModel)]="progress.additional.max"
                                                   [width]="100"
                                                   [type]="'number'"
                                                   (ngModelChange)="validateAdds()"
                                                   [customInvalid]="disableAdds"
                                                   [hideControls]="true"
                                                   [placeholder]="translations.TO"
                                                   [disablePlaceholderAnimation]="true"></hse-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hse-button [color]="'primary'"
                                class="margin-button"
                                [disabled]="disableAdds"
                                (callback)="setAdditionalInfo()">{{ edit ? translations.ACCEPT : translations.NEXT }}</hse-button>
                </div>

            </div>



            <div class="center result fx-3 column h-start"
                 *ngIf="path[iterationIndex] === 'final'">

                <div class="passed-item fx fx-1 column" *ngFor="let item of passedItems">
                    <span class="title">{{item.title}}</span>
                    <div class="fx row v-center">
                        <p class="value">{{item.value}}</p>
                        <hse-icon [name]="'editBig'" *ngIf="item.path !== 'engSubjectField'" (click)="goToIteration(item.path)"></hse-icon>
                    </div>

                </div>
               <hse-button *ngIf="path[iterationIndex] === 'final'"
                            class="fx fx-1"
                            [color]="'primary'"
                            (callback)="nextIteration('confirm')">{{ edit ? translations.ACCEPT : translations.NEXT }}</hse-button>

            </div>



            <div class="controls fx fx-4 column v-start">
                <div class="fx row h-end">
                    <hse-stage-shift *ngIf="!edit"
                                     [isAvailableToBack]="isAvailableToBack"
                                     [isAvailableToForward]="isAvailableToForward"
                                     (backwardCallback)="goBack()"
                                     (forwardCallback)="goForward()">
                    </hse-stage-shift>
                    <hse-icon [name]="'close'" class="close" (click)="onClose()"></hse-icon>
                </div>

            </div>
        </div>
    </div>
</div>
