import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'constructor',
    pathMatch: 'full'
  },
  {
    path: 'constructor',
    loadChildren: () => import('./constructor/constructor.module').then((m) => m.ConstructorModule)
  },
  {
    path: 'callback',
    redirectTo: 'constructor/callback'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
