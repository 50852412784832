<div class="hse-combo-box">
  <!--<hse-menu [customStyle]="optionsListStyle" [width]="trueWidth">-->
    <div class="fx row space-between"
         *ngIf="!disabled"
         [style.width.px]="trueWidth"
         [matMenuTriggerFor]="menu"
         (menuOpened)="openMenu()"
         (menuClosed)="closeMenu()"
         [ngStyle]="{ 'max-height': maxHeight + 'px', 'min-height': minHeight + 'px' }"
         [ngClass]="getHeaderClasses(false)"
    >
      <div [style.width.px]="trueWidth" [ngClass]="getPlaceholderContainerClass()">
        <span [ngClass]="getPlaceholderClass()">
          {{ placeholder }}
        </span>
      </div>
      <div class="chips-container fx row wrap" #optionsList [style.width.%]="trueChipsContainerWidth">
        <div *ngFor="let item of resultCollection" class="chip-item fx row v-center">
          <span>{{ item[nameField] }}</span>
        </div>
      </div>
      <div class="icon-container fx v-center">
        <hse-icon class="hse-input__lightning" *ngIf="valueChanged" [name]="'lightning'"></hse-icon>
        <div class="fx h-v-center hidden-chips-count" *ngIf="hiddenChipsCount">+{{hiddenChipsCount}}</div>
        <hse-icon class="arrow-icon" *ngIf="!resultCollection.length" [name]="'arrowDown'" ></hse-icon>
        <hse-icon class="close-icon" *ngIf="resultCollection.length" [name]="'close'" (click)="removeAllItems()"></hse-icon>
      </div>
    </div>

    <div class="greyscale fx row space-between"
         [matMenuTriggerFor]="disabledMenu"
         *ngIf="disabled"
         [style.width.px]="trueWidth"
         [ngStyle]="{ 'max-height': maxHeight + 'px', 'min-height': minHeight + 'px' }"
         [ngClass]="getHeaderClasses(true)">
      <div [style.width.px]="trueWidth" [ngClass]="getPlaceholderContainerClass()">
        <span [ngClass]="getPlaceholderClass()">
          {{ placeholder }}
        </span>
      </div>
      <div class="chips-container fx row wrap" #optionsList [style.width.%]="trueChipsContainerWidth">
        <div *ngFor="let item of resultCollection" class="chip-item fx row v-center">
          <span>{{ item[nameField] }}</span>
        </div>
      </div>
      <div class="icon-container fx v-center">
        <div class="fx h-v-center hidden-chips-count" *ngIf="hiddenChipsCount">+{{hiddenChipsCount}}</div>
        <hse-icon class="arrow-icon" *ngIf="!resultCollection.length" [name]="'arrowDown'" ></hse-icon>
      </div>
    </div>

    <mat-menu #menu="matMenu"
              tabindex="-1"
              [overlapTrigger]="false">

      <div (click)="$event.stopPropagation()">
        <div class="hse-select-search" *ngIf="useSearch">
          <input [(ngModel)]="searchString"
                 (input)="filterOptions()"
                 (click)="stopPropagation($event)"
                 placeholder="{{searchPlaceholder}}">
          <hse-icon name="search" class="search-icon"></hse-icon>
        </div>

        <div class="hse-combo-box-options-list-2" [style.width.px]="trueWidth">
          <cdk-virtual-scroll-viewport style="overflow-x: hidden"
                                       [itemSize]="10"
                                       #viewPort
                                       [style.height.px]="containerHeight">
            <ng-container *cdkVirtualFor="let item of filteredCollection">
              <div (click)="itemClicked(item, $event)"
                   [ngClass]="getMenuRowClass(item)">
                <div class="fx">
                  <hse-checkbox [model]="item.selected" [disabled]="item.disabled || checkItemForDisable(item)"></hse-checkbox>
                  <span class="hse-combo-box-span">{{ item[nameField] }}</span>
                </div>
              </div>
            </ng-container>
          </cdk-virtual-scroll-viewport>
        </div>
      </div>
    </mat-menu>

  <mat-menu #disabledMenu="matMenu" tabindex="-1" [overlapTrigger]="false">
    <div class="hse-combo-box-options-list-2 disabled" [style.width.px]="trueWidth">
      <div class="item" *ngFor="let item of resultCollection">
        <div class="fx">
          <hse-checkbox [model]="true" [disabled]="true"></hse-checkbox>
          <span class="hse-combo-box-span">{{ item[nameField] }}</span>
        </div>
      </div>
    </div>
  </mat-menu>
  <!--</hse-menu>-->
</div>
