import { EducationResultItem } from '@backend/kosuip/catalogue/interfaces/education-result-item.interface';

const EDUCATION_RESULTS_ITEMS: EducationResultItem[] = [
  {
    code: 'КОР-1',
    is_default: true,
    description: 'Владеет английским языком не ниже требований уровня',
    sub_text: 'по Общеевропейской шкале уровней владения иностранным языком (CEFR) и способен учиться на уровне' +
      ' магистратуры или вести профессиональное общение на английском языке',
    options: [
      {
        id: '00000000001',
        code: 'B2',
        selected: true
      },
      {
        id: '00000000002',
        code: 'C1',
        selected: false
      },
      {
        id: '00000000003',
        code: 'C2',
        selected: false
      }
    ]
  },
  {
    code: 'КОР-2',
    is_default: true,
    description: 'Знает основные принципы работы с данными, владеет современными инструментами анализа данных на ',
    sub_text: 'уровне, в т.ч. навыками программирования, алгоритмизации и математическими методами при решении задач анализа данных.',
    options: [
      {
        id: '00000000004',
        code: 'начальном',
        selected: true
      },
      {
        id: '00000000005',
        code: 'базовом',
        selected: false
      },
      {
        id: '00000000006',
        code: 'продвинутом',
        selected: false
      },
      // {
      //     id: '00000000007',
      //     code: 'профессиональном',
      //     selected: false
      // },
    ]
  }
];

export default EDUCATION_RESULTS_ITEMS;
