import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HseIconService } from '../../../../core/hse-icon/hse-icon.service';

/**
 * hse-icon
 * Пример использования:
 *
 * <hse-icon name="search"></hse-icon>
 * search - название иконки, устанавливаемое при добавлении иконки в коллекцию
 */
@Component({
  selector: 'hse-icon',
  templateUrl: './hse-icon.component.html',
  styleUrls: ['./hse-icon.component.scss']
})
export class HseIconComponent implements OnInit {

  @Input() name: string;

  constructor(
    private iconService: HseIconService,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.iconService.getIconByName(this.name).subscribe(
      (icon) => {
        const svgWrapper = this.renderer.createElement('div');
        svgWrapper.innerHTML = icon;

        this.renderer.addClass(svgWrapper, 'hse-icon');
        this.elementRef.nativeElement.appendChild(svgWrapper);
      },
      (error) => console.error(error)
    );
  }
}
