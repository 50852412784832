import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class MtkVerificationService {

    BASE_URL = '/api';

    constructor(private http: HttpClient) {
    }


    public getVerification(id: string, learnPlanId: number): Promise<Array<any>> {
        return this.http.post<Array<any>>(`${this.BASE_URL}/mcp/runmcp?id=${id}&learn_plan_id=${learnPlanId}`, {}).toPromise();
    }
}
