<div class="fx column v-center hse-table-column-wrapper"
     [class.no-border]="column.parentColumn ? i === column.parentColumn.visibleColumns.length - 1 : false"
     [class.no-top-border]="column.name === column.calculatedName"
     [style.minWidth.px]="column.optimizedWidth || column.width"
     [style.width.px]="column.optimizedWidth || column.width"
     style=" overflow: hidden;" *ngIf="!column.hidden">
	<div class="fx column h-center space-between v-center hse-table-column"
	     [class.sortable]="column.onSort"
	     [draggable]="table.isDraggableColumns"
	     (dragstart)="onDragStart(i, configColumns)"
	     (drop)="onDrop(i, configColumns)"
	     (dragover)="onDragOver($event)">
		<div class="fx row v-center">
			<hse-checkbox *ngIf="column.enableCheck"
			              (modelChange)="handleCheckbox($event)"
			              [(model)]="checkboxModel"></hse-checkbox>
			<span>{{column.title}}</span>
		</div>
		<hse-table-column-settings class="hse-table-column__column-settings"
		                           *ngIf="!column.disableDots"
		                           [column]="column"
		                           [config]="configColumns"
		                           [fixationAvailable]="column.name === column.calculatedName"
		                           [orderAvailable]="!column.disableSort && !column.columns"
		                           (columnSort)="table.columnSort(column)"
		                           (columnFilter)="table.filterColumn(column)"
		                           (columnHide)="table.toggleColumnHidden($event, configColumns)"
		                           (columnMove)="table.moveColumn($event)"
		                           (columnFixationChanged)="table.columnChangeFixation($event)"
		                           (inputCallback)="inputChanged($event)"
		                           (columnsReset)="table.resetColumns()">
		</hse-table-column-settings>
	</div>
	<div class="fx fx-1" *ngIf="column.columns">
		<hse-table-column *ngFor="let subColumn of column.visibleColumns; index as j;"
		                  [i]="j"
		                  [table]="table"
		                  [configColumns]="column.columns"
		                  [column]="subColumn"
		                  (dragStart)="dragStart.emit($event)"
		                  (dragEnd)="dragEnd.emit($event)">
		</hse-table-column>
	</div>
</div>
