<div class="hse-table" [ngStyle]="customStyles">
    <div class="hse-table__content_wrapper" #table [style.paddingLeft.px]="fixedColumnsWidth">
        <div class="hse-table__header">
            <div class="fx shifter" [style.left.px]="-tableLeftOffset">
                <div class="fx space-between v-center hse-table__header__cell"
                     *ngFor="let column of dynamicColumns; index as i"
                     [class.sortable]="column.onSort"
                     [style.minWidth.px]="column.optimizedWidth"
                     [style.width.px]="column.optimizedWidth"
                     [draggable]="isDraggableColumns"
                     (dragstart)="onDragDynamicColumn($event, i)"
                     (drop)="onDropToDynamicColumn($event, i)"
                     (dragover)="onDragOver($event)">
                    <span>{{column.title}}</span>
                    <hse-table-column-settings class="hse-table__header__cell__column-settings"
                                               [column]="column"
                                               [config]="config"
                                               (columnSort)="columnSort(column)"
                                               (columnFilter)="filterColumn(column)"
                                               (columnHide)="toggleColumnHidden($event)"
                                               (columnMove)="moveColumn($event)"
                                               (columnFixationChanged)="columnChangeFixation($event)"
                                               (columnsReset)="resetColumns()">
                    </hse-table-column-settings>
                </div>
            </div>
            <div class="hse-table__header__shift-left"
                 *ngIf="isOverflow && lastVisibleColumnIx > visibleColumns.length - 1">
                <hse-icon [name]="'arrowTriangleRight'" (click)="shiftLeft()"></hse-icon>
            </div>
            <div class="hse-table__header__shift-right"
                 *ngIf="isOverflow && lastVisibleColumnIx < dynamicColumns.length - 1">
                <hse-icon [name]="'arrowTriangleRight'" (click)="shiftRight()"></hse-icon>
            </div>
        </div>
        <div class="hse-table__body shifter" #dynamicRowsWrapper [style.left.px]="-tableLeftOffset">
            <hse-table-row  [row]="row" *ngFor="let row of rowsData"
                            [extendControls]="row.isExpandable"
                            (rowClick)="onRowClick($event)"
                            (rowExpandChanged)="onRowExpandChanged()"
                            [columns]="dynamicColumns"></hse-table-row>
        </div>
    </div>
    <div class="hse-table__content_wrapper fixed-content" *ngIf="fixedColumns.length">
        <div class="hse-table__header">
            <div class="fx">
                <div class="fx space-between v-center hse-table__header__cell"
                     *ngFor="let column of fixedColumns; index as i"
                     [draggable]="isDraggableColumns"
                     (dragstart)="onDragFixedColumn($event, i)"
                     (drop)="onDropToFixedColumn($event, i)"
                     (dragover)="onDragOver($event)"
                     [class.sortable]="column.onSort"
                     [style.minWidth.px]="column.width"
                     [style.width.px]="column.width">
                    <span>{{column.title}}</span>
                    <!--<hse-icon *ngIf="column.onSort" [name]="'shortArrowDown'"></hse-icon>-->
                    <hse-table-column-settings class="hse-table__header__cell__column-settings"
                                               [column]="column"
                                               [config]="config"
                                               (columnSort)="columnSort(column)"
                                               (columnFilter)="filterColumn(column)"
                                               (columnHide)="toggleColumnHidden($event)"
                                               (columnFixationChanged)="columnChangeFixation($event)"
                                               (columnMove)="moveColumn($event)"
                                               (columnsReset)="resetColumns()">
                    </hse-table-column-settings>
                </div>
            </div>
        </div>
        <div class="hse-table__body" #fixedRowsWrapper>
            <hse-table-row  [row]="row" *ngFor="let row of rowsData"
                            [columns]="fixedColumns"
                            (rowClick)="onRowClick($event)"></hse-table-row>
        </div>
    </div>
    <!--TODO SCROLLBAR-->
</div>
