import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop as _noop } from 'lodash/fp';

@Component({
  selector: 'hse-textarea',
  templateUrl: './hse-textarea.component.html',
  styleUrls: ['./hse-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HseTextareaComponent),
      multi: true
    }
  ]
})
export class HseTextareaComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  private modelValue: string;
  public rows: number;
  public onChange: any = _noop;
  public onTouched: any = _noop;

  @Input() growOnFocus = 1;
  @Input() rowsCount = 1;
  @Input() autoHeight = false;
  @Input() width = 200;
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() maxLength = null;

  set model(value: string) {
    if (this.autoHeight) {
      this.adjustHeight();
    }
    this.modelValue = value;
    this.onChange(this.modelValue);
  }

  get model(): string {
    return this.modelValue;
  }

  @ViewChild('hseTextarea', { static: true }) element: ElementRef;

  @Output() focus?: EventEmitter<any> = new EventEmitter<any>();
  @Output() blur?: EventEmitter<any> = new EventEmitter<any>();
  @Output() onKeyDownEvent?: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

  constructor(
    private readonly _elRef: ElementRef,
    private readonly _renderer: Renderer2
  ) { }

  ngOnInit() {
    this.rows = this.growOnFocus;

    if (this.maxLength) {
      this._renderer.setAttribute(this.element.nativeElement, 'maxlength', this.maxLength);
    }
  }

  ngAfterViewInit(): void {
    if (this.autoHeight) {
      this.element.nativeElement.style.overflow = 'hidden';
      this.adjustHeight();

      return;
    }
  }

  adjustHeight() {
    setTimeout(() => {
      this.element.nativeElement.style.height = 'auto';
      this.element.nativeElement.style.height = `${this.element.nativeElement.scrollHeight + 2}px`;
    }, 0);
  }

  writeValue(value: string) {
    this.model = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  grow() {
    this.focus.emit();
    if (this.autoHeight) {
      this.adjustHeight();

      return;
    }
    this.rows = this.growOnFocus;
  }

  decrease() {
    this.blur.emit();
    this.onTouched();

    if (!this.growOnFocus) {
      return;
    }

    if (this.autoHeight) {
      this.adjustHeight();

      return;
    }

    if (this.model) {
      return;
    }

    this.rows = this.rowsCount;
  }

  onKeydownHandle(event: KeyboardEvent) {
    this.onKeyDownEvent.emit(event);
  }
}
