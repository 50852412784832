<div class="hse-user-info fx h-v-center" *ngIf="authService.session && loaded">
	<div [matMenuTriggerFor]="menu">
		<hse-icon [name]="'person'"></hse-icon>
	</div>
	<mat-menu #menu="matMenu"
	          tabindex="-1"
	          [overlapTrigger]="false">
		<div class="user-container">
			<div class="user-name" (click)="$event.stopPropagation()">
				<span>{{ authService.session.commonname }}</span>
			</div>

			<div class="user-title" (click)="$event.stopPropagation()">
				<span>Личный кабинет</span>
			</div>

			<div *ngFor="let role of roles"
			     class="fx row v-center role-item"
			     (click)="selectRole(role)"
			     [matTooltip]="role"
			     [matTooltipPosition]="'above'">
				<span style="width: 264px;">{{ role }}</span>

				<div class="user-icon">
					<hse-icon [name]="'check'" *ngIf="selectedRole(role)"></hse-icon>
				</div>
			</div>

			<div (click)="authService.logout()" class="fx row v-center space-between user-logout">
				<span>{{ 'COMMON.LOGOUT' | translate }}</span>

				<div class="user-icon">
					<hse-icon [name]="'logout'"></hse-icon>
				</div>
			</div>
		</div>
	</mat-menu>
</div>
