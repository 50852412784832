import { HseIconStore } from './hse-icon.interface';

/**
 * Коллекция svg-иконок
 */
const icons: HseIconStore = new Map([
  ['add', {url: 'assets/images/icons/add.svg', data: null}],
  ['approved', {url: 'assets/images/icons/approved.svg', data: null}],
  ['error', {url: 'assets/images/icons/error.svg', data: null}],
  ['arrowTriangleRight', {url: 'assets/images/icons/arrow-triangle-right.svg', data: null}],
  ['arrowDown', {url: 'assets/images/icons/arrow-down.svg', data: null}],
  ['arrowLeft', {url: 'assets/images/icons/arrow-left.svg', data: null}],
  ['arrowRight', {url: 'assets/images/icons/arrow-right.svg', data: null}],
  ['attachment', {url: 'assets/images/icons/attachment.svg', data: null}],
  ['blueArrow', {url: 'assets/images/icons/blue-arrow.svg', data: null}],
  ['calendar', {url: 'assets/images/icons/calendar.svg', data: null}],
  ['chevronLeft', {url: 'assets/images/icons/chevron_left.svg', data: null}],
  ['chevronRight', {url: 'assets/images/icons/chevron_right.svg', data: null}],
  ['close', {url: 'assets/images/icons/close.svg', data: null}],
  ['computer', {url: 'assets/images/icons/computer.svg', data: null}],
  ['courses', {url: 'assets/images/icons/courses.svg', data: null}],
  ['dots', {url: 'assets/images/icons/dots.svg', data: null}],
  ['drag', {url: 'assets/images/icons/drag.svg', data: null}],
  ['info', {url: 'assets/images/icons/info.svg', data: null}],
  ['infoNew', {url: 'assets/images/icons/info-2.svg', data: null}],
  ['edit', {url: 'assets/images/icons/edit.svg', data: null}],
  ['editBig', {url: 'assets/images/icons/edit-big.svg', data: null}],
  ['expandMore', {url: 'assets/images/icons/expand_more.svg', data: null}],
  ['filter', {url: 'assets/images/icons/filter.svg', data: null}],
  ['filters', {url: 'assets/images/icons/filters.svg', data: null}],
  ['leftArrow', {url: 'assets/images/icons/i-arrow-left.svg', data: null}],
  ['logo', {url: 'assets/images/icons/logo.svg', data: null}],
  ['logo2', {url: 'assets/images/icons/logo-2.svg', data: null}],
  ['logo3', {url: 'assets/images/icons/logo-3.svg', data: null}],
  ['check', {url: 'assets/images/icons/check.svg', data: null}],
  ['openTab', {url: 'assets/images/icons/open-tab.svg', data: null}],
  ['search', {url: 'assets/images/icons/search.svg', data: null}],
  ['person', {url: 'assets/images/icons/person.svg', data: null}],
  ['rightArrow', {url: 'assets/images/icons/i-arrow-right.svg', data: null}],
  ['shortArrowDown', {url: 'assets/images/icons/arrow-down-short.svg', data: null}],
  ['shortArrowRight', {url: 'assets/images/icons/arrow-right-short.svg', data: null}],
  ['enter', {url: 'assets/images/icons/enter.svg', data: null}],
  ['moreInfo', {url: 'assets/images/icons/more-info.svg', data: null}],
  ['eye', {url: 'assets/images/icons/eye.svg', data: null}],
  ['lock', {url: 'assets/images/icons/lock.svg', data: null}],
  ['lockOpened', {url: 'assets/images/icons/lock-opened.svg', data: null}],
  ['specializations', {url: 'assets/images/icons/specializations.svg', data: null}],
  ['inputInfo', {url: 'assets/images/icons/input-info.svg', data: null}],
  ['bell', {url: 'assets/images/icons/bell.svg', data: null}],
  ['download', {url: 'assets/images/icons/download.svg', data: null}],
  ['xls', {url: 'assets/images/icons/xls.svg', data: null}],
  ['xlsx', {url: 'assets/images/icons/xls.svg', data: null}],
  ['pdf', {url: 'assets/images/icons/pdf.svg', data: null}],
  ['comment', {url: 'assets/images/icons/comment.svg', data: null}],
  ['delete', {url: 'assets/images/icons/delete.svg', data: null}],
  ['editSmall', {url: 'assets/images/icons/edit-small.svg', data: null}],
  ['verify', {url: 'assets/images/icons/verify.svg', data: null}],
  ['docx', {url: 'assets/images/icons/doc.svg', data: null}],
  ['reload', {url: 'assets/images/icons/reload.svg', data: null}],
  ['scale', {url: 'assets/images/icons/scale.svg', data: null}],
  ['copy', {url: 'assets/images/icons/copy.svg', data: null}],
  ['system', {url: 'assets/images/icons/system.svg', data: null}],
  ['trash', {url: 'assets/images/icons/trash.svg', data: null}],
  ['doubleArrows', {url: 'assets/images/icons/double-arrows.svg', data: null}],
  ['annotation', {url: 'assets/images/icons/annotation.svg', data: null}],
  ['unite', {url: 'assets/images/icons/unite.svg', data: null}],
  ['arrowSmallDown', {url: 'assets/images/icons/arrow-small-down.svg', data: null}],
  ['arrowSmallUp', {url: 'assets/images/icons/arrow-small-up.svg', data: null}],
  ['upload', {url: 'assets/images/icons/upload.svg', data: null}],
  ['menuCatalogue', {url: 'assets/images/icons/menu-catalogue.svg', data: null}],
  ['menuEducationalStandards', {url: 'assets/images/icons/menu-educational-standards.svg', data: null}],
  ['menuDepartmentDisciplines', {url: 'assets/images/icons/menu-department-disciplines.svg', data: null}],
  ['menuLearnPlan', {url: 'assets/images/icons/menu-learn-plan.svg', data: null}],
  ['withdraw', {url: 'assets/images/icons/withdraw.svg', data: null}],
  ['logout', {url: 'assets/images/icons/logout.svg', data: null}],
  ['lightning', {url: 'assets/images/icons/lightning.svg', data: null}],
  ['subjectareas', {url: 'assets/images/icons/subjectareas.svg', data: null}],
  ['kor', {url: 'assets/images/icons/kor.svg', data: null}],
  ['actionLink', {url: 'assets/images/icons/action-link.svg', data: null}],
  ['ruble', {url: 'assets/images/icons/ruble.svg', data: null}],
  ['network', {url: 'assets/images/icons/network.svg', data: null}],
  ['heirs', {url: 'assets/images/icons/heirs.svg', data: null}],
  ['inProgress', {url: 'assets/images/icons/in_progress.svg', data: null}],
  ['checkCircle', {url: 'assets/images/icons/check_circle.svg', data: null}],
  ['crow', {url: 'assets/images/icons/crow.svg', data: null}],
]);

export default icons;
