import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

export interface UnloadConfig {
  file_format: 'xlsx' | 'pdf' | 'docx';
}

@Component({
  selector: 'hse-print-form-modal',
  templateUrl: './print-form-modal.component.html',
  styleUrls: ['./print-form-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintFormModalComponent {
  public readonly fileFormats = [
    { id: 'xlsx', name: 'xlsx' },
    { id: 'pdf', name: 'pdf' },
    { id: 'docx', name: 'docx' },
  ];

  public fileFormat: any = '';

  @Output() onUnload: EventEmitter<UnloadConfig> = new EventEmitter<UnloadConfig>();
  @Output() onCancel: EventEmitter<undefined> = new EventEmitter<undefined>();


  unload() {
    this.onUnload.emit({ file_format: this.fileFormat });
  }

  cancel() {
    this.onCancel.emit();
  }
}
