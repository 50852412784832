<div class="add-element-background fx column stretch">
    <div class="add-element-wrapper fx column fx-1" #wrapper>
        <div class="title fx column stretch h-center">

            <div class="fx header-wrapper space-between">
                <div class="fx fx-4 row h-start">
                    <h1>
                        {{ ('EDUCATIONAL-STANDARD.STRUCTURE.' + (edit ? 'EDIT' : 'ADD') + '-ELEMENT.TITLE') | translate }}
                    </h1>
                </div>

                <div class="fx column passed-items">
                    <div class="passed-item fx row" *ngFor="let item of passedItems">
                        <span class="title">{{item.title}}</span>
                        <p class="value">{{item.value}}</p>
                    </div>
                </div>

                <div class="controls fx fx-4 column v-start">
                    <div class="fx row h-end">
                        <hse-stage-shift [isAvailableToBack]="isAvailableToBack"
                                         *ngIf="!edit"
                                         [isAvailableToForward]="isAvailableToForward"
                                         (backwardCallback)="goBack()"
                                         (forwardCallback)="goForward()">
                        </hse-stage-shift>
                        <hse-icon [name]="'close'" class="close" (click)="onClose()"></hse-icon>
                    </div>
                </div>
            </div>

            <div class="center column h-start" *ngIf="path[iterationIndex] !== 'final'"><!--fx-3-->

                <div class="from-catalogue fx fx-1 column"
                     [style.margin-top.px]="margin"
                     *ngIf="path[iterationIndex] === 'type'">
                    <span class="iteration-title">{{ progress[path[iterationIndex]].title }}</span>
                    <div class="radio fx column">
                        <div class="radio-item fx row v-center"
                             *ngFor="let item of elementTypes"
                             [class.disabled]="change"
                             (click)="startFilling(item)">
                            <div class="circle"
                                 [class.selected]="progress.type.value === item.id"></div>
                            <span>{{item.name}}</span>
                        </div>
                    </div>
                </div>

                <div class="from-catalogue fx fx-1 column"
                     [style.margin-top.px]="margin"
                     *ngIf="path[iterationIndex] === 'searchById'">
                    <span class="iteration-title">{{ progress[path[iterationIndex]].title }}</span>
                    <div class="search-str fx column">
                        <hse-input [(ngModel)]="progress.searchById.value"
                                   [disablePlaceholderAnimation]="true"
                                   [width]="500"
                                   placeholder="{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.SEARCH-BY-ID-PLACEHOLDER' | translate }}"></hse-input>
                    </div>
                    <div class="fx row h-start" [style.margin-top.px]="12">
                        <hse-button [color]="'primary'" [disabled]="!progress.searchById.value" (callback)="searchById()">{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.NEXT' | translate }}</hse-button>
                        <hse-button [color]="'secondary'" (callback)="skipSearchById()">{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.SKIP' | translate }}</hse-button>
                    </div>
                </div>

                <div class="from-to fx fx-1 row"
                     [style.margin-top.px]="margin"
                     *ngIf="path[iterationIndex] === 'period'">
                    <div class="fx column">
                        <span class="iteration-title">{{ progress[path[iterationIndex]].title }}</span>
                        <div class="years fx fx-1 row space-between">
                            <div class="fx fx-1 column">
                                <div class="radio-item fx row v-center"
                                     *ngFor="let year of beginYears"
                                     (click)="selectLearnPeriod(year, true)"
                                     [class.disabled]="year.disabled">
                                    <div class="circle"
                                         [class.selected]="year.id === progress.period.start"></div>
                                    <span>{{year.description}}</span>
                                </div>
                            </div>
                            <div class="fx fx-1 column">
                                <div class="radio-item fx row v-center"
                                     *ngFor="let year of endYears"
                                     (click)="selectLearnPeriod(year)"
                                     [class.disabled]="year.disabled">
                                    <div class="circle"
                                         [class.selected]="year.id === progress.period.end"></div>
                                    <span>{{year.description}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="from-catalogue fx fx-1 column"
                     [style.margin-top.px]="margin"
                     *ngIf="path[iterationIndex] === 'fromCatalogue'">
                    <span class="iteration-title">{{ progress[path[iterationIndex]].title }}</span>
                    <div class="radio fx column">
                        <div class="radio-item fx row v-center"
                             *ngFor="let item of fromCatalogue"
                             [class.disabled]="change || !studyPlan"
                             (click)="selectValue({title: item.name, value: item.value})">
                            <div class="circle"
                                 [class.selected]="progress.fromCatalogue.passed && (!progress.fromCatalogue.value && !item.value || progress.fromCatalogue.value && item.value)"></div>
                            <span>{{item.name}}</span>
                        </div>
                    </div>
                </div>

                <div class="from-catalogue fx fx-1 column"
                     [style.margin-top.px]="margin"
                     *ngIf="path[iterationIndex] === 'specialization'">
                    <span class="iteration-title">{{ progress[path[iterationIndex]].title }}</span>
                    <div class="radio fx column">
                        <div class="radio-item fx row v-center"
                             (click)="selectValue({title: translations.NOT_SET, value: null})">
                            <div class="circle"
                                 [class.selected]="!progress.specialization.value"></div>
                            <span>{{ translations.NOT_SET }}</span>
                        </div>
                        <div class="radio-item fx row v-center"
                             *ngFor="let item of config.specializations"
                             (click)="selectValue({title: item.description, value: item.specialization_id})">
                            <div class="circle"
                                 [class.selected]="item.specialization_id === progress.specialization.value"></div>
                            <span>{{item.description}}</span>
                        </div>
                    </div>
                </div>




                <div class="fx row"
                     [style.margin-top.px]="margin"
                     *ngIf="path[iterationIndex] === 'practiceType'">
                    <div class="from-catalogue fx fx-1 column">
                        <span class="iteration-title">{{ progress[path[iterationIndex]].title }}</span>
                        <div class="search-str">
                            <hse-input [(ngModel)]="disciplineFilter.search_string"
                                       (ngModelChange)="filterPracticeTypes($event)"
                                       [disablePlaceholderAnimation]="true"
                                       [width]="500"
                                       placeholder="{{ progress[path[iterationIndex]].title }}"></hse-input>
                        </div>

                        <div class="fx column">

                            <div class="table-header"
                                 hseSticky
                                 [stackName]="'practice'"
                                 [offsetTop]="50">
                                <div>

                                    <div class="iteration__7-header fx row">

                                        <div class="iteration__7-header-cell fx row space-between v-center"
                                             [style.width.px]="i === practiceTypeTable.length - 1 ? column.width + 2 : column.width"
                                             *ngFor="let column of practiceTypeTable; index as i">
                                            <span>{{column.title}}</span>
                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div>

                                <div class="height">

                                    <div class="checkbox-item fx row v-center"
                                         (click)="selectPracticeType({title: option.description, value: option.id, formId: option.practice_form_id, eng: option.eng_description})"
                                         *ngFor="let option of filteredPracticeTypes">


                                        <div class="iteration__7-table-cell fx row v-center"
                                             [style.width.px]="column.width"
                                             *ngFor="let column of practiceTypeTable">
                                            <p matTooltip="{{option[column.name] || '-'}}">{{option[column.name] || '-'}}</p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <hse-textarea [(ngModel)]="practiceTypeDescription"
                                          *ngIf="progress.practiceType.value === '89'"
                                          [width]="500"
                                          [placeholder]="'Название'"
                                          [rowsCount]="3"
                                          [growOnFocus]="3"></hse-textarea>

                        </div>
                    </div>

                    <div class="next-button" *ngIf="progress.practiceType.value === '89'">
                        <hse-button (callback)="setProjectDescription()">{{ progress[path[iterationIndex]].title }}</hse-button>
                    </div>
                </div>


                <div class="from-catalogue fx fx-1 column"
                     [style.margin-top.px]="margin"
                     *ngIf="path[iterationIndex] === 'practicePlaceType'">
                    <span class="iteration-title">{{ progress[path[iterationIndex]].title }}</span>
                    <div class="radio fx column">
                        <div class="radio-item fx row v-center"
                             *ngFor="let item of practicePlaceTypes"
                             (click)="selectValue({title: item.name, value: item.id})">
                            <div class="circle"
                                 [class.selected]="progress.practicePlaceType.value === item.id"></div>
                            <span>{{item.name}}</span>
                        </div>
                    </div>
                </div>

                <div class="from-catalogue fx fx-1 column"
                     [style.margin-top.px]="margin"
                     *ngIf="path[iterationIndex] === 'subjectType'">
                    <span class="iteration-title">{{ progress.subjectType.title.split(':')[0] }}</span>
                    <div class="radio fx column">
                        <div class="radio-item fx row v-center"
                             *ngFor="let item of subjectTypes"
                             (click)="selectValue({title: item.name, value: item.id})">
                            <div class="circle"
                                 [class.selected]="progress.subjectType.value === item.id"></div>
                            <span>{{item.name}}</span>
                        </div>
                    </div>
                </div>

                <div class="from-catalogue fx fx-1 column"
                     [style.margin-top.px]="margin"
                     *ngIf="path[iterationIndex] === 'practiceForm'">
                    <span class="iteration-title">{{ progress[path[iterationIndex]].title }}</span>
                    <div class="radio fx column">
                        <div class="radio-item fx row v-center"
                             *ngFor="let item of practiceForms"
                             (click)="selectValue({title: item.name, value: item.value})">
                            <div class="circle"
                                 [class.selected]="progress.practiceForm.value === item.value"></div>
                            <span>{{item.name}}</span>
                        </div>
                    </div>
                </div>


                <div class="fx row"
                     *ngIf="path[iterationIndex] === 'subjectAreas'"
                     [style.margin-top.px]="margin">
                    <div class="filials fx fx-1 column">
                        <span class="iteration-title">{{ progress[path[iterationIndex]].title }}</span>
                        <div class="checkbox fx column">
                            <div class="checkbox-item fx row v-center"
                                 *ngFor="let item of subjectAreas">
                                <hse-checkbox [(model)]="item.selected"
                                              [stopClickPropagation]="true">{{item.description}}</hse-checkbox>
                            </div>
                        </div>
                    </div>

                    <div class="button">
                        <hse-button [color]="'primary'"
                                    (callback)="setSubjectAreas()"
                                    [disabled]="checkSubjectAreasDisable()">{{ edit ? translations.ACCEPT : translations.NEXT }}</hse-button>
                    </div>

                </div>


                <div class="fx row"
                     *ngIf="path[iterationIndex] === 'filial'"
                     [style.margin-top.px]="margin">
                    <div class="filials fx fx-1 column">
                        <span class="iteration-title">{{ progress[path[iterationIndex]].title }}</span>
                        <div class="checkbox fx column">
                            <div class="checkbox-item fx row v-center"
                                 *ngFor="let item of filials" (click)="select(item, 'filials')">
                                <hse-checkbox [(model)]="item.selected"
                                              (click)="abortPropagation($event)"
                                              (modelChange)="select(item, 'filials')">{{item.name}}</hse-checkbox>
                            </div>
                        </div>
                    </div>

                    <div class="button">
                        <hse-button [color]="'primary'"
                                    (callback)="setFilial()"
                                    [disabled]="checkFilialsDisable()">{{ edit ? translations.ACCEPT : translations.NEXT }}</hse-button>
                    </div>

                </div>




                <div class="departments fx fx-1 column"
                     *ngIf="path[iterationIndex] === 'department'"
                     (hseInfiniteScroll)="loadMoreDepartments()"
                     [distance]="-10"
                     [style.margin-top.px]="margin">

                    <span class="iteration-title">{{ progress[path[iterationIndex]].title }}</span>
                    <div class="search-str">
                        <hse-input [(ngModel)]="departmentFilter.search_string"
                                   (ngModelChange)="filterDepartments($event)"
                                   [disablePlaceholderAnimation]="true"
                                   [width]="750"
                                   placeholder="{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.SEARCH' | translate }}"></hse-input>
                    </div>

                    <div class="fx row">

                        <div class="fx column">

                            <div class="table-window narrow-dep table-header"
                                 hseSticky
                                 [stackName]="'left'"
                                 [offsetTop]="50">
                                <div class="table">

                                    <div class="iteration__7-header fx row">

                                        <div class="iteration__7-header-cell fx row space-between v-center"
                                             [style.width.px]="departmentTableFirstColumn[0].width">
                                            <span>{{departmentTableFirstColumn[0].title}}</span>
                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div class="table-window narrow-dep" [style.height.px]="departmentTableHeight">

                                <div class="table height">

                                    <div class="checkbox-item sample disable-hover fx row v-center"
                                         *ngFor="let option of departments"
                                         [class.hovered]="option.hovered"
                                         [style.height.px]="option.height"
                                         (click)="selectValue({title: option.description, value: option.id})"
                                         (mouseenter)="hoverItem(option)"
                                         (mouseleave)="resetDepartmentHover()">


                                        <div class="iteration__7-table-cell fx row v-center"
                                             [style.width.px]="departmentTableFirstColumn[0].width">
                                            <div class="circle"
                                                 [class.selected]="progress.department.value === option.id"></div>
                                            <p matTooltip="{{option[departmentTableFirstColumn[0].name] || '-'}}">{{option[departmentTableFirstColumn[0].name] || '-'}}</p>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div class="fx column">

                            <div class="fx table-window-dep table-header"
                                 hseSticky
                                 [stackName]="'right'"
                                 [offsetTop]="50"
                                 (scroll)="slideDepartmentTable($event)">
                                <div class="table">
                                    <div class="iteration__7-header fx row">
                                        <div class="iteration__7-header-cell fx row space-between v-center"
                                             [style.width.px]="i === departmentTableColumns.length - 1 ? column.width + 2 : column.width"
                                             *ngFor="let column of departmentTableColumns; index as i">
                                            <span>{{column.title}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="fx table-window-dep not-scrollable"
                                 [style.height.px]="departmentTableHeight">
                                <div class="table" [style.left.px]="departmentTablePosition">

                                    <div class="checkbox-item remaining disable-hover fx row v-center"
                                         [style.height.px]="option.height"
                                         [class.hovered]="option.hovered"
                                         (click)="selectValue({title: option.description, value: option.id})"
                                         (mouseenter)="hoverItem(option)"
                                         (mouseleave)="resetDepartmentHover()"
                                         *ngFor="let option of departments">


                                        <div class="iteration__7-table-cell fx row v-center"
                                             [style.width.px]="column.width"
                                             *ngFor="let column of departmentTableColumns">
                                            <p matTooltip="{{option[column.name] || '-'}}">{{option[column.name] || '-'}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="fx row"
                     *ngIf="path[iterationIndex] === 'foundDiscipline'"
                     [style.margin-top.px]="margin">
                    <div class="departments fx fx-1 column h-center">
                        <div class="fx column student-count">
                            <span class="iteration-title">{{ progress[path[iterationIndex]].title }}</span>

                            <hse-input [disablePlaceholderAnimation]="true"
                                       [type]="'number'"
                                       [hideControls]="true"
                                       [(ngModel)]="studCount"
                                       [width]="652"
                                       (ngModelChange)="validateDisciplineById()"
                                       [customInvalid]="disciplineById.disabled"
                                       placeholder="{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.STUDENT-COUNT-HINT' | translate }}"></hse-input>

                            <span class="error-label" *ngIf="disciplineById.exceed">Данная численность обучающихся превышает максимальную численность обучающихся на дисциплине</span>
                        </div>

                        <div class="fx column">
                            <div class="fx table-window"
                                 [style.height.px]="setTableHeight(sample)"
                                 [style.min-width.px]="fullTableWidth - 60">
                                <div class="table">
                                    <div class="iteration__7-header fx row">
                                        <div class="iteration__7-header-cell fx row space-between v-center"
                                             [style.width.px]="disciplineTableFirstColumn[0].width">
                                            <span>{{disciplineTableFirstColumn[0].title}}</span>
                                        </div>
                                        <div class="iteration__7-header-cell fx row space-between v-center"
                                             [style.width.px]="i === disciplineTableColumns.length - 1 ? column.width + 2 : column.width"
                                             *ngFor="let column of disciplineTableColumns; index as i">
                                            <span>{{column.title}}</span>
                                        </div>
                                    </div>
                                    <div [style.left.px]="disciplineTablePosition">

                                        <div class="checkbox-item disable-hover fx row v-center" #sample>


                                            <div class="iteration__7-table-cell fx row v-center"
                                                 [style.width.px]="disciplineTableFirstColumn[0].width">
                                                <p>{{disciplineById[disciplineTableFirstColumn[0].name] || '-'}}</p>
                                            </div>

                                            <div class="iteration__7-table-cell fx row v-center"
                                                 [style.width.px]="column.width"
                                                 *ngFor="let column of disciplineTableColumns">
                                                <p>{{disciplineById[column.name] || '-'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div class="fx row">
                            <hse-button [color]="'primary'"
                                        (callback)="addElements()"
                                        [disabled]="disciplineById.disabled">{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.NEXT' | translate }}</hse-button>
                        </div>
                    </div>

                </div>


                <div class="fx row"
                     *ngIf="path[iterationIndex] === 'planDiscipline'"
                     [style.margin-top.px]="margin">
                    <div class="departments h-center fx fx-1 column">
                        <div>
                            <div class="fx column student-count">
                                <span class="iteration-title">{{ 'EDUCATIONAL-STANDARD.STRUCTURE.PROGRESS.STUDENT-COUNT-2' | translate }}</span>

                                <hse-input [disablePlaceholderAnimation]="true"
                                           (ngModelChange)="mapPlanDisciplines($event)"
                                           [type]="'number'"
                                           [hideControls]="true"
                                           [(ngModel)]="studCount"
                                           [width]="652"
                                           placeholder="{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.STUDENT-COUNT-HINT' | translate }}"></hse-input>
                            </div>

                            <span class="iteration-title">{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.SELECT-FROM-EXISTENT' | translate }}</span>

                            <div class="fx row v-center plan-filters">
                                <hse-input [(ngModel)]="planDisciplineSearch"
                                           (ngModelChange)="filterPlanDisciplines($event)"
                                           [disablePlaceholderAnimation]="true"
                                           [width]="652"
                                           placeholder="{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.SEARCH' | translate }}"></hse-input>
                                <hse-checkbox [(model)]="planDisciplineFullMatch"
                                              (modelChange)="filterPlanDisciplines(planDisciplineSearch)">{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.SELECT-PRECISE' | translate }}</hse-checkbox>
                            </div>

                            <div class="chips fx column h-start">
                                <div class="fx row v-center"
                                     *ngFor="let chip of chips"
                                     matTooltip="{{chip.name}} ({{chip.basic_credits}})"
                                     (click)="checkDiscipline(chip)">
                                    <span>{{chip.name}} ({{chip.basic_credits}})</span>
                                    <hse-icon [name]="'close'"></hse-icon>
                                </div>
                            </div>
                        </div>

                        <div class="fx row">

                            <div class="fx column">
                                <div class="table-window narrow table-header"
                                     hseSticky
                                     [stackName]="'left'"
                                     [offsetTop]="50">
                                    <div class="table">

                                        <div class="iteration__7-header fx row">

                                            <div class="iteration__7-header-cell fx row space-between v-center"
                                                 [style.width.px]="disciplineTableFirstColumn[0].width">
                                                <span>{{disciplineTableFirstColumn[0].title}}</span>
                                                <!--<hse-icon [name]="'expandMore'"-->
                                                          <!--*ngIf="disciplineTableFirstColumn[0].name === tableSortConfig.sortColumn"-->
                                                          <!--[class.rotated]="tableSortConfig.sortDirection === 'desc'"></hse-icon>-->
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div class="table-window narrow"  [style.height.px]="disciplineTableHeight">

                                    <div class="table height">

                                        <div class="checkbox-item sample disable-hover fx row v-center"
                                             *ngFor="let option of filteredPlanDisciplines"
                                             [class.hovered]="!option.disabled && option.hovered"
                                             [class.disabled]="option.disabled"
                                             [style.height.px]="option.height"
                                             (mouseenter)="hoverItem(option)"
                                             (mouseleave)="resetDisciplineHover()"
                                             (click)="checkDiscipline(option)">

                                            <div class="iteration__7-table-cell fx row v-center"
                                                 [style.width.px]="disciplineTableFirstColumn[0].width">
                                                <hse-checkbox [(model)]="option.selected"
                                                              [disabled]="option.disabled"
                                                              matTooltip="{{ (option.disabled ? (option.excess ? 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.STUDENT-COUNT-MESSAGE-1' : 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.STUDENT-COUNT-MESSAGE-2') : 'COMMON.VOID') | translate }}"
                                                              *ngIf="disciplineTableFirstColumn[0].name === 'name'"
                                                              (click)="abortPropagation($event)"
                                                              (modelChange)="checkDiscipline(option, true)"></hse-checkbox>
                                                <p matTooltip="{{disciplineTableFirstColumn[0].name === 'name' || disciplineTableFirstColumn[0].name === 'custom_plan' ? option[disciplineTableFirstColumn[0].name] || '-' : ''}}">{{option[disciplineTableFirstColumn[0].name] || '-'}}</p>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div class="fx column">

                                <div class="fx table-window table-header"
                                     hseSticky
                                     [style.min-width.px]="fullTableWidth - 360"
                                     [stackName]="'right'"
                                     [offsetTop]="50"
                                     (scroll)="slideDisciplineTable($event)">
                                    <div class="table">
                                        <div class="iteration__7-header fx row">
                                            <div class="iteration__7-header-cell fx row space-between v-center"
                                                 [style.width.px]="i === disciplineTableColumns.length - 1 ? column.width + 2 : column.width"
                                                 *ngFor="let column of disciplineTableColumns; index as i">
                                                <span>{{column.title}}</span>
                                                <!--<hse-icon [name]="'expandMore'"-->
                                                          <!--*ngIf="column.name === tableSortConfig.sortColumn"-->
                                                          <!--[class.rotated]="tableSortConfig.sortDirection === 'desc'"></hse-icon>-->
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="fx table-window not-scrollable"
                                     [style.height.px]="disciplineTableHeight">
                                    <div class="table" [style.left.px]="disciplineTablePosition">

                                        <div class="checkbox-item remaining disable-hover fx row v-center"
                                             [style.height.px]="option.height"
                                             [class.hovered]="!option.disabled && option.hovered"
                                             [class.disabled]="option.disabled"
                                             (mouseenter)="hoverItem(option)"
                                             (mouseleave)="resetDisciplineHover()"
                                             *ngFor="let option of filteredPlanDisciplines"
                                             (click)="checkDiscipline(option)">


                                            <div class="iteration__7-table-cell fx row v-center"
                                                 [style.width.px]="column.width"
                                                 *ngFor="let column of disciplineTableColumns">
                                                <p [class.red]="column.name === 'displayStudCount' && option.disabled" matTooltip="{{column.name === 'name' || column.name === 'custom_plan' ? option[column.name] || '-' : ''}}">{{option[column.name] || '-'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div class="fx row">
                            <hse-button [color]="'primary'"
                                        [disabled]="disablePlanDisciplines()"
                                        (callback)="addPlanDisciplines()">{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.ADD' | translate }} {{progress.planDiscipline.buttonLabel}}</hse-button>
                        </div>
                    </div>

                </div>

                <div class="fx column"
                     *ngIf="path[iterationIndex] === 'courseDescription'"
                     [style.margin-top.px]="margin" (hseInfiniteScroll)="loadMoreDisciplines()" [distance]="-10">
                    <div class="exam fx fx-1 column">
                        <span class="iteration-title">
                          {{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.COURSE_DESCRIPTION' | translate }}
                        </span>
                        <div class="fx fx-1 row">
                            <hse-input [(ngModel)]="progress.courseDescription.label"
                                       [width]="530"
                                       style="margin-bottom: 0;"
                                       [maxLength]="128"
                                       [disablePlaceholderAnimation]="true"
                                       [placeholder]="translations.ADD_NAME"></hse-input>
                            <hse-button (callback)="setCourse()" [disabled]="!progress.courseDescription.label">{{ edit ? translations.ACCEPT : translations.NEXT }}</hse-button>
                        </div>
                        <span class="length-message" style="margin-bottom: 36px;">{{ translations.INPUT }} {{ progress.courseDescription.label ? progress.courseDescription.label.length : '0' }} {{ translations.FROM }} 128 {{ translations.SYMBOLS }}</span>
                    </div>
                    <div class="exam fx fx-1 column">
                        <span class="iteration-title">
                          {{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.COURSE_DESCRIPTION_ENG' | translate }}
                        </span>
                        <div class="fx fx-1 row">
                            <hse-input [(ngModel)]="progress.engName.value"
                                       [width]="530"
                                       style="margin-bottom: 0;"
                                       [maxLength]="1024"
                                       [disablePlaceholderAnimation]="true"
                                       [placeholder]="translations.ADD_NAME"></hse-input>
                        </div>
                        <span class="length-message" style="margin-bottom: 36px;">{{ translations.INPUT }} {{ progress.engName.value ? progress.engName.value.length : '0' }} {{ translations.FROM }} 1024 {{ translations.SYMBOLS }}</span>
                    </div>
                    <div class="departments fx fx-1 column">
                        <span class="iteration-title">
                          {{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.DISCIPLINES_FROM_DIRECTORY' | translate }}
                        </span>
                        <div class="search-str">
                            <hse-input [(ngModel)]="disciplineFilter.search_string"
                                       (ngModelChange)="filterDisciplines($event)"
                                       [disablePlaceholderAnimation]="true"
                                       [width]="550"
                                       placeholder="{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.SEARCH' | translate }}"></hse-input>
                        </div>
                        <div class="checkbox fx column">
                            <div class="radio-item discipline-item fx row v-center"
                                 (click)="selectCourse({title: item.name, value: item.id, engName: item.eng_description})"
                                 *ngFor="let item of disciplines">
                                <div class="circle"
                                     [class.selected]="progress.courseDescription.value === item.id"></div>
                                <span>{{item.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="fx row"
                     *ngIf="path[iterationIndex] === 'catalogueDiscipline'"
                     [style.margin-top.px]="margin" (hseInfiniteScroll)="loadMoreDisciplines()" [distance]="-10">
                    <div class="departments fx fx-1 column">
                        <span class="iteration-title">{{ progress[path[iterationIndex]].title }}</span>
                        <div class="search-str">
                            <hse-input [(ngModel)]="disciplineFilter.search_string"
                                       (ngModelChange)="filterDisciplines($event)"
                                       [disablePlaceholderAnimation]="true"
                                       [width]="550"
                                       placeholder="{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.SEARCH' | translate }}"></hse-input>
                        </div>
                        <div class="fx column" [style.width.px]="550">
                            <div class="iteration__7-header fx row">
                                <div class="iteration__7-header-cell fx row space-between v-center" [style.width.px]="50">
                                </div>
                                <div class="iteration__7-header-cell fx row space-between v-center" [style.width.px]="250">
                                    <span>Наименование</span>
                                </div>
                                <div class="iteration__7-header-cell fx row space-between v-center" [style.width.px]="250">
                                    <span>Наименование английское</span>
                                </div>
                            </div>
                            <div>
                                <div class="checkbox-item fx row v-center" *ngFor="let item of disciplines" (click)="selectValue({title: item.name + ' (' + item.eng_description + ')', value: item.id})">
                                    <div class="iteration__7-table-cell fx row space-between v-center" [style.width.px]="50">
                                        <div class="circle"
                                             [class.selected]="progress.courseDescription.value === item.id"></div>
                                    </div>
                                    <div class="iteration__7-table-cell fx row v-center" [style.width.px]="250">
                                        <p>{{ item.name || '-' }}</p>
                                    </div>

                                    <div class="iteration__7-table-cell fx row v-center" [style.width.px]="250">
                                        <p>{{ item.eng_description || '-' }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="language fx fx-1 column"
                     *ngIf="path[iterationIndex] === 'language'"
                     [style.margin-top.px]="margin">
                    <span class="iteration-title">{{ progress[path[iterationIndex]].title }}</span>
                    <div class="radio fx column">
                        <div class="radio-item fx row v-center"
                             *ngFor="let item of languages" (click)="selectLanguage($event, item)">
                              <hse-checkbox [(model)]="item.selected" (modelChange)="selectLanguage($event, item)"></hse-checkbox>
                            <span>{{item.name}}</span>
                        </div>
                        <hse-button [color]="'primary'"
                                    [disabled]="progress[path[iterationIndex]].value.length === 0"
                                    (click)="setValue()">{{ "COMMON.ADD" | translate }}</hse-button>
                    </div>
                </div>

                <div class="exam fx fx-1 column"
                     [style.margin-top.px]="margin"
                     *ngIf="path[iterationIndex] === 'name'">
                    <span class="iteration-title">{{ progress[path[iterationIndex]].title }}</span>
                    <hse-input [(ngModel)]="progress.name.value"
                               [width]="530"
                               [maxLength]="128"
                               style="margin-bottom: 0;"
                               [disablePlaceholderAnimation]="true"
                               [placeholder]="'Введите название'"></hse-input>
                    <span class="length-message" style="margin-bottom: 36px;">{{ translations.INPUT }} {{ progress.name.value ? progress.name.value.length : '0' }} {{ translations.FROM }} 128 {{ translations.SYMBOLS }}</span>
                    <hse-button (callback)="setName()" [disabled]="!progress.name.value">{{ edit ? translations.ACCEPT : translations.NEXT }}</hse-button>
                </div>

                <div class="exam fx fx-1 column"
                     [style.margin-top.px]="margin"
                     *ngIf="path[iterationIndex] === 'engName'">
                    <span class="iteration-title">{{ progress[path[iterationIndex]].title }}</span>
                    <hse-input [(ngModel)]="progress.engName.value"
                               [width]="530"
                               [maxLength]="1024"
                               style="margin-bottom: 0;"
                               [disablePlaceholderAnimation]="true"
                               [placeholder]="'Введите название'"></hse-input>
                    <span class="length-message" style="margin-bottom: 36px;">{{ translations.INPUT }} {{ progress.engName.value ? progress.engName.value.length : '0' }} {{ translations.FROM }} 1024 {{ translations.SYMBOLS }}</span>
                    <hse-button (callback)="setEngName()" [disabled]="!progress.engName.value">{{ edit ? translations.ACCEPT : translations.NEXT }}</hse-button>
                </div>

                <div class="duration fx fx-1 row"
                     *ngIf="path[iterationIndex] === 'duration' && !creditsByYears"
                     [style.margin-top.px]="margin">
                    <div class="fx column">
                        <span class="iteration-title">{{ progress[path[iterationIndex]].title }}</span>
                        <div class="fx row inputs">
                            <hse-input [(ngModel)]="progress.duration.value"
                                       [width]="100"
                                       (keypress)="checkInputParam($event, progress.duration.value)"
                                       [hideControls]="true"
                                       [disablePlaceholderAnimation]="true"
                            ></hse-input>
                        </div>
                    </div>
                    <div class="button">
                        <hse-button [color]="'primary'"
                                    (callback)="setDuration()"
                                    [disabled]="!optionalDuration && !progress.duration.value">
                          {{ edit ? translations.ACCEPT : translations.NEXT }}
                        </hse-button>
                    </div>
                </div>

                <div class="duration fx fx-1 row"
                     *ngIf="path[iterationIndex] === 'duration' && creditsByYears"
                     [style.margin-top.px]="margin">
                    <div class="fx column">
                        <span class="iteration-title">{{ progress[path[iterationIndex]].title }}</span>

                        <div class="fx row">
                            <div class="fx column">
                                <div class="fx row v-center year-label">
                                    <span>{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.ALL-CREDITS' | translate }}:</span>
                                </div>
                                <div class="fx row v-center year-label" *ngFor="let year of progress.duration.yearsForCredits">
                                    <span>{{year.label}}</span>
                                </div>
                            </div>
                            <div class="fx column year-inputs">
                                <hse-input [(ngModel)]="progress.duration.value"
                                           [width]="100"
                                           (keypress)="checkInputParam($event, progress.duration.value)"
                                           [type]="'text'"
                                           (ngModelChange)="validateCredits()"
                                           [hideControls]="true"
                                           [disablePlaceholderAnimation]="true"></hse-input>
                                <hse-input *ngFor="let year of progress.duration.yearsForCredits"
                                           [(ngModel)]="year.credits"
                                           (keypress)="checkInputParam($event, year.credits)"
                                           [width]="100"
                                           [customInvalid]="year.invalid"
                                           (ngModelChange)="validateCredits()"
                                           [type]="'text'"
                                           [hideControls]="true"
                                           [disablePlaceholderAnimation]="true"></hse-input>
                            </div>
                            <div class="fx column">
                                <div class="button">
                                    <hse-button [color]="'primary'"
                                                (callback)="setYearsForCredits()"
                                                [disabled]="disableCreditsButton">{{ edit ? translations.ACCEPT : translations.NEXT }}</hse-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>



            <div class="center result fx-3 column h-start"
                 *ngIf="path[iterationIndex] === 'final' && !edit">
                <div class="passed-item fx fx-1 column" *ngFor="let item of passedItems">
                    <span class="title">{{item.title}}</span>
                    <div class="fx row v-center">
                        <p class="value">{{item.value}}</p>
                        <hse-icon [name]="'editBig'"
                                  (click)="goToIteration(item.key)"
                                  *ngIf="(!edit && item.key !== 'fromCatalogue') || (item.key !== 'type' && item.key !== 'fromCatalogue')"></hse-icon>
                    </div>

                </div>

                <hse-button *ngIf="path[iterationIndex] === 'final'"
                            class="fx fx-1"
                            [color]="'primary'"
                            (callback)="addElements()">{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.CONFIRM' | translate }}</hse-button>

            </div>

            <div class="center result fx-3 column h-start"
                 *ngIf="path[iterationIndex] === 'final' && edit">
                <div class="passed-item fx fx-1 column" *ngFor="let item of passedItems">
                    <span class="title">{{item.title}}</span>
                    <div class="fx row v-center">
                        <p class="value">{{item.value}}</p>
                        <hse-icon [name]="'editBig'" (click)="goToIteration(item.key)" *ngIf="ableToEditPaths.indexOf(item.key) > -1"></hse-icon>
                    </div>

                </div>

                <hse-button *ngIf="path[iterationIndex] === 'final'"
                            class="fx fx-1"
                            [color]="'primary'"
                            (callback)="addElements()">{{ 'EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.CONFIRM' | translate }}</hse-button>

            </div>
        </div>
    </div>
</div>
