import { Injectable } from '@angular/core';
import { CatalogueService } from '@backend/kosuip/catalogue/catalogue.service';
import {
  CustomPlanItemExtended
} from '../educational-standards/components/educational-standard-structure/educational-standard-structure.component';
import { map as _map, cloneDeep as _cloneDeep, sortBy as _sortBy } from 'lodash/fp';
import { StudyPlanFile } from '@backend/kosuip/catalogue/interfaces/study-plan-file.interface';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StudyPlansBase {

  public studyPlan;
  public loadPromise;
  public loadPlan: Subject<void> = new Subject<void>();
  public loadData: Subject<void> = new Subject<void>();
  public reset: Subject<void> = new Subject<void>();

  constructor(private catalogueService: CatalogueService) { }

  async loadStudyPlan(id) {
    try {
      this.loadPromise = this.catalogueService.getStudyPlans({ id, detail_info: true });
      const studyPlan = await this.loadPromise;

      this.studyPlan = _cloneDeep(studyPlan);

      this.loadPlan.next(this.studyPlan);

      return studyPlan;
    } catch (err) {
      console.error(err);
    }
  }

  loadAttachedFiles(id: string): Promise<StudyPlanFile[]> {
    return this.catalogueService.getAttachedFiles({learn_plan_id: id});
  }

  async loadDataForPassportEdit() {
    try {
      const learnPeriods = await this.catalogueService.getLearnPeriods({ from_learn_year: 2017 });
      const specialities = await this.catalogueService.getSpecialities();
      const standardStatuses = await this.catalogueService.getPlanStatuses();

      const $learnPeriods: any = _cloneDeep(learnPeriods);
      this.loadData.next($learnPeriods);

      return {
        learnPeriods,
        specialities,
        standardStatuses
      };
    } catch (e) {
      console.error(e);
    }
  }

  async loadDataForDetailedPlan() {
    try {
      const [
        learnPeriods,
        specialities,
        standardStatuses,
        languages,
        dataCultureLevels,
        subjectType,
        departments,
        studyFormats,
        controlTypes,
        filials
      ] = await Promise.all([
        this.catalogueService.getLearnPeriods({ from_learn_year: 2018, detail_info: true }),
        this.catalogueService.getSpecialities(),
        this.catalogueService.getPlanStatuses(),
        this.catalogueService.getLanguages(),
        this.catalogueService.getDataCulture(),
        this.catalogueService.getSubjectType(),
        this.catalogueService.getDepartments({ remove_archived: true }),
        this.catalogueService.getStudyFormat(),
        this.catalogueService.getControlTypes(),
        this.catalogueService.getFilials({ remove_archived: true })
      ]);

      const [
        practicePlaceTypes,
        studyAries,
        distantSubjects,
        loadRatio,
        faculties,
        audienceReach,
        planTypes
      ] = await Promise.all([
        this.catalogueService.getPracticePlaceType(),
        this.catalogueService.getStudyAries(),
        this.catalogueService.getDistantSubject(),
        this.catalogueService.getLoadRatio(),
        this.catalogueService.getFaculties({ remove_archived: true }),
        this.catalogueService.getAudienceReach(),
        this.catalogueService.getPlanTypes()
      ]);

      const $learnPeriods: any = _cloneDeep(learnPeriods);
      this.loadData.next($learnPeriods);

      return {
        learnPeriods,
        specialities,
        standardStatuses,
        languages,
        dataCultureLevels: _sortBy('description')(dataCultureLevels),
        subjectType,
        departments,
        studyFormats,
        controlTypes,
        filials,
        practicePlaceTypes,
        studyAries,
        distantSubjects: distantSubjects.body,
        loadRatio,
        faculties,
        audienceReach,
        planTypes
      };
    } catch (e) {
      console.error(e);
    }
  }

  async loadCurrentStructure(id: string = null) {
    try {
      return await this.catalogueService.getPassportStructure({ learn_plan_id: id });
    } catch (err) {
      console.error(err);
    }
  }

  prepareToSave(structure: any): any {
    structure.structure_id = structure.structure_id || structure.id;

    delete structure.level;
    delete structure.max;
    delete structure.min;
    delete structure.id;

    structure.custom_plan_item = _map((planItem: CustomPlanItemExtended) => {
      delete planItem.ableToEdit;
      delete planItem.children;
      delete planItem.selected;
      delete planItem.isParent;
      delete planItem.end;
      delete planItem.elementTypes;
      delete planItem.max;
      delete planItem.min;
      delete planItem.level;
      delete planItem.expanded;

      // for (const key of Object.keys(planItem)) {
      //   if (!Boolean(planItem[key]) && ['basic_credits', 'discipline_description', 'dict_university_subject_id', 'specialization_id', 'language_id', 'subject_type_id'].indexOf(key) === -1) {
      //     delete planItem[key];
      //   }
      // }

      return planItem;
    })(structure.custom_plan_item);

    return structure;
  }
}
