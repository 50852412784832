<div class="fx row h-v-center confirm-modal__wrapper">
    <div class="fx column confirm-modal__modal" [style.width.px]="width">
        <div class="confirm-modal__title">
            <ng-container [ngTemplateOutlet]="template || textTmpl"></ng-container>
        </div>
        <div class="fx row h-end confirm-modal__buttons">
            <hse-button color="primary" (callback)="callback.emit(true)">
                {{ confirmBtn || ('COMMON.DELETE' | translate) }}
            </hse-button>
            <hse-button color="secondary" (callback)="callback.emit(false)">
                {{ cancelBtn || ('COMMON.CANCEL' | translate) }}
            </hse-button>
        </div>
    </div>
</div>

<ng-template #textTmpl>
    {{ text }}
</ng-template>
