/* tslint:disable */
import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, HostBinding, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { random as _random } from 'lodash/fp';

@Component({
  selector: 'hse-switch',
  templateUrl: './hse-switch.component.html',
  styleUrls: ['./hse-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HseSwitchComponent),
      multi: true
    }
  ]
})
export class HseSwitchComponent implements ControlValueAccessor {
  @HostBinding('class.hse-switch') readonly baseCss: boolean = true;

  @Input() disabled = false;
  @Input()
  set model(value: boolean) {
    this._value = value;
  }

  get model(): boolean {
    return this._value;
  }

  get switchId(): string {
    return this._switchId;
  }

  @Output() modelChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private readonly _switchId: string;
  private _value = false;
  private _onChange = (value: boolean) => {};
  private _onTouched = () => {};

  constructor() {
    this._switchId = `${_random(1000000, 9999999)}-hse-switch-${Date.now()}`;
  }

  writeValue(value: boolean): void {
    this._value = value;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange(value: boolean): void {
    this._value = value;

    this._onChange(this._value);
    this._onTouched();
    this.modelChange.emit(this._value);
  }

}
