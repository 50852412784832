import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  map as _map,
  flow as _flow,
  sortBy as _sortBy,
  reverse as _reverse,
  forEach as _forEach
} from 'lodash/fp';
import moment from '@tools/moment.local';
import { HseAlertService } from '@core/hse-alert/hse-alert.service';
import { UserProfileService } from '@backend/user-profile/user-profile.service';
import { interval, startWith, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  BASE_URL = '/api';
  API_URL = '/bp/getnotification';
  COUNTER_URL = '/bp/getnotificationcounter';
  MARK_NOTIFICATION_URL = '/bp/marknotification';
  DELETE_NOTIFICATION_URL = '/bp/delnotification';
  baseIntervalTime = 30000;
  baseIntervalCount = 10;
  startBaseInterval;
  increasedInterval;
  // increasedIntervalTime = 600000;
  increasedIntervalTime = 30000;
  events: any = [];
  removedEvents = [];
  baseInterval;
  haveNewEvents;
  counter: any = 0;
  eventsForAlert = [];

  constructor(private http: HttpClient, private alert: HseAlertService, private userProfile: UserProfileService) {
    this.baseInterval = interval(this.baseIntervalTime);
    this.increasedInterval = interval(this.increasedIntervalTime);
    this.startBaseInterval = this.baseInterval.pipe(
      startWith(0),
      take(this.baseIntervalCount)
    );

    this.alert.removeCallback.subscribe((data: any) => {
      if (data && !data.delivered) {
        this.removeEvent(data);

        data.delivered = true;
      }
    });
  }

  startPinging() {
    const subscr = this.startBaseInterval.subscribe((iterator: any) => {
      // this.getEvents();
      this.getCounter();

      if (iterator === 8) {
        subscr.unsubscribe();
        this.increasedInterval.subscribe(() => {
          // this.getEvents();
          this.getCounter();
        });
      }
    });
  }

  getEvents(params?) {
     return this.http.get(`${this.BASE_URL}${this.API_URL}${this.makeParams(params)}`, { observe: 'response' }).toPromise();
  }

  async getEventsForPopup() {
    try {
      const rawEvents: any = await this.getEvents({
        show_notification: false,
        page_num: 1,
        page_limit: 10
      });
      this.events = _flow(
        _map((event: any) => {
          const dateTime = moment(event.time_created);
          event.time = dateTime.format('HH:mm');
          event.date = dateTime.format('DD.MM.YY');

          return event;
        }),
        _sortBy<any>('time'),
        _sortBy<any>('date'),
        _reverse
      )(rawEvents.body);

      this.haveNewEvents = Boolean(this.events.length);
    } catch (e) {
      console.error(e);
    }
  }

  async getEventsForAlerts() {
    try {
      if (!this.eventsForAlert.length) {
        const rawEvents: any = await this.getEvents({
          show_notification: true
        });

        this.eventsForAlert = rawEvents.body;

        _forEach(($evt: any) => {
          if (!$evt.delivered) {
            switch ($evt.style_notification) {
              case '000000001':
                this.alert.error($evt.text_notification, false, $evt);
                break;
              case '000000002':
                this.alert.info($evt.text_notification, false, $evt);
                break;
              case '000000003':
                this.alert.warn($evt.text_notification, false, $evt);
                break;
              case '000000004':
                this.alert.success($evt.text_notification, false, $evt);
                break;
              default:
                return 'yellow';
                break;
            }
          }
        })(this.eventsForAlert);
      }
    } catch (e) {
      console.error(e);
    }
  }

  async getCounter() {
    try {
      const options: any = {};
      const updatedHeaders = {
        headers: new HttpHeaders()
      };
      const additionalEmail = this.userProfile.getSelectedChangedUser();

      if (additionalEmail) {
        updatedHeaders.headers = updatedHeaders.headers.set('HSE-Auth-Replacement', btoa(additionalEmail));
        options.headers = updatedHeaders.headers;
      }

      const counter: any = await this.http.get(`${this.BASE_URL}${this.COUNTER_URL}`, options).toPromise();

      this.counter = counter[0].counter;

      if (counter[0].show_notification) {
        this.getEventsForAlerts();
      }
    } catch (e) {
      console.error(e);
    }
  }

  removeEvent(event) {
    return this.http.post(`${this.BASE_URL}${this.MARK_NOTIFICATION_URL}?id=${event.id}`, {}).toPromise();
  }

  markAllEvents(params) {
    return this.http.post(`${this.BASE_URL}${this.MARK_NOTIFICATION_URL}` + this.makeParams(params), {}).toPromise();
  }

  deleteEvent(event) {
    this.http.post(`${this.BASE_URL}${this.DELETE_NOTIFICATION_URL}?id=${event.id}`, {}).toPromise();
  }

  private makeParams(params) {
    let result = '';
    if (params) {
      const data = [];
      for (const param in params) {
        if (params.hasOwnProperty(param)) {
          const paramToAppend = typeof params[param] === 'object' ? JSON.stringify(params[param]) : params[param];

          data.push(`${param}=${paramToAppend}`);
        }
      }
      result = `?${data.join('&')}`;
    }

    return result;
  }
}
