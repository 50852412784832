/* tslint:disable */
import { Injectable } from '@angular/core';
import { find as _find, findIndex as _findIndex } from 'lodash/fp';
import _guid from '@tools/fp/guid';
import { Subject } from 'rxjs';

export class HseAlertItem {
  id: string;
  isFadeOut: boolean;

  constructor(
      public type = 'info',
      public msg,
      public isHtml = false,
      public additionalData = null
  ) {
    this.id = _guid();
  }
}

@Injectable({
  providedIn: 'root'
})
export class HseAlertService {
  alerts: Array<HseAlertItem> = [];
  alertTimeout = 50000000;
  timeout = 1000;
  removeCallback: Subject<void> = new Subject<void>();

  add(type, msg, isHtml, additionalData = null) {
    let newAlert;

    try {
      newAlert = new HseAlertItem(type, msg, isHtml, additionalData);
      this.alerts.unshift(newAlert);
    } catch (err) {
      console.error(err);
    }

    this.autoRemoveAlert(newAlert);
  }

  success(msg, isHtml?, additionalData?) {
    this.add('success', msg, isHtml, additionalData);
  }

  error(msg, isHtml?, additionalData?) {
    this.add('error', msg, isHtml, additionalData);
  }

  info(msg, isHtml?, additionalData?) {
    this.add('info', msg, isHtml, additionalData);
  }

  warn(msg, isHtml?, additionalData?) {
    this.add('warn', msg, isHtml, additionalData);
  }

  handled(msg, isHTML?) {
    msg += ' Повторите попытку позже.';
    this.error(msg, isHTML);
  }

  fatal() {
    this.handled('Не удалось загрузить необходимые данные.');
  }

  /**
   * Проверка есть такое сообщение в очереди сообщений
   */
  exists(msg, type) {
    const isExists = _find((item: HseAlertItem) => {
      return item.type === type && item.msg === msg;
    })(this.alerts);

    return Boolean(isExists);
  }

  autoRemoveAlert(newAlert) {
    setTimeout(() => {
      this.removeAlert(newAlert.id);
    }, this.alertTimeout);
  }

  removeAlert(id) {
    if (!id) {
      return;
    }

    const alert: HseAlertItem = _find({id})(this.alerts) as HseAlertItem;

    if (alert) {
      alert.isFadeOut = true;

      this.removeCallback.next(alert.additionalData);

      setTimeout(() => {
        const ix = _findIndex({id})(this.alerts);
        this.alerts.splice(ix, 1);
      }, this.timeout);
    }
  }
}
