<div class="add-education-results-background fx column stretch" *ngIf="loaded">
    <div class="add-education-results-wrapper fx column fx-1 stretch">
        <div class="title fx row stretch">
            <div class="fx fx-1 row">
                <h2>{{ 'STUDY-PLAN.ADD-EDUCATION-RESULTS-POPUP.TITLE' | translate }}</h2>
            </div>
            <div class="controls fx column">
                <hse-icon [name]="'close'" (click)="onClose()"></hse-icon>
            </div>
        </div>
        <div class="fx column">
            <h1>{{ 'STUDY-PLAN.ADD-EDUCATION-RESULTS-POPUP.CHOOSE' | translate }}</h1>
        </div>
        <div class="title-controls fx row v-center space-between">
            <div class="search-string fx row v-center h-start">
                <hse-input [(ngModel)]="searchString"
                           [width]="600"
                           hseDebounce
                           [debounceTime]="300"
                           (debounceCallback)="getEducationResults()"
                           [placeholder]="translations.INPUT_PLACEHOLDER"></hse-input>
                <hse-checkbox [(model)]="fullMatch" (modelChange)="getEducationResults($event)">{{ 'STUDY-PLAN.ADD-EDUCATION-RESULTS-POPUP.FULL-MATCH' | translate }}</hse-checkbox>
            </div>
            <div class="fx row v-center">
                <hse-button (callback)="addKOR()" [disabled]="!selectedKOR">{{ 'STUDY-PLAN.ADD-EDUCATION-RESULTS-POPUP.ADD' | translate }}</hse-button>
            </div>
        </div>

        <div class="title-controls fx row v-center">
            <div class="fx row v-center" style="margin-right: 16px;">
                <span style="margin-right: 8px;">Уровень образования</span>
                <hse-select [collection]="educationLevels"
                            [placeholder]="'Выбрать'"
                            [(ngModel)]="selectedEducationLevel"
                            (ngModelChange)="setEducationLevel($event)"
                            [disablePlaceholderAnimation]="true"
                            [nameField]="'description'"></hse-select>
            </div>

            <div class="fx row v-center">
                <span style="margin-right: 8px;">Направление подготовки</span>
                <hse-combo-box [collection]="filteredSpecialities"
                               (change)="setSpecialities($event)"
                               [placeholder]="'Выбрать'"
                               [maxHeight]="100"
                               [(model)]="selectedSpecialities"
                               [useSearch]="true"
                               [nameField]="'fullName'"
                               [sortBySelected]="true"
                               [style.position]="'relative'"></hse-combo-box>
            </div>
        </div>
        <div class="content fx fx-1 column">
            <div class="table-title fx row stretch">
                <div class="first-cell fx row v-center"></div>
                <div class="fx fx-1 row v-center">{{ 'STUDY-PLAN.ADD-EDUCATION-RESULTS-POPUP.TABLE.NAME' | translate }}</div>
                <div class="fx fx-1 row v-center">{{ 'STUDY-PLAN.ADD-EDUCATION-RESULTS-POPUP.TABLE.ENG-NAME' | translate }}</div>
            </div>
            <div class="table-window">
                <div class="fx column table-content">
                    <div class="fx row stretch v-center table-row"
                         (click)="selectedKOR = item"
                         *ngFor="let item of educationResults">
                        <div class="first-cell fx row v-center">
                            <div class="circle"
                                 [class.selected]="selectedKOR && item.id === selectedKOR.id"></div>
                        </div>
                        <div class="fx fx-1 row v-center">{{ item.description }}</div>
                        <div class="fx fx-1 row v-center">{{ item.eng_name }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
