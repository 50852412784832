import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { CatalogueService } from '@backend/kosuip/catalogue/catalogue.service';
import { map as _map, find as _find, filter as _filter, flow as _flow } from 'lodash/fp';
import { PreRequisite } from '@backend/kosuip/catalogue/interfaces/post-pre-requisite.interface';
import { CustomPlanItemExtended } from '../../../constructor/components/educational-standards/components/educational-standard-structure/educational-standard-structure.component';
import { ElementType } from '@backend/kosuip/catalogue/interfaces/element-type.interface';

export interface PostPreRequisiteExtended extends PreRequisite {
  subject_type_description: string;
  selected: boolean;
}

@Component({
  selector: 'hse-add-requisites',
  templateUrl: './add-requisites.component.html',
  styleUrls: ['../add-element/add-element.component.scss']
})
export class AddRequisitesComponent implements OnInit {

  @Input() item: CustomPlanItemExtended;
  @Output() close: EventEmitter<boolean> = new EventEmitter();

  public bodyHeight = 0;
  private body: any;
  public mode: 'default' | 'pre' | 'post' = 'default';
  public prerequisitesFiltered: PostPreRequisiteExtended[] = [];
  public postrequisitesFiltered: PostPreRequisiteExtended[] = [];
  public prerequisites: PostPreRequisiteExtended[] = [];
  public postrequisites: PostPreRequisiteExtended[] = [];
  private elementTypes: ElementType[] = [];
  private listChanged = false;

  constructor(private catalogue: CatalogueService) { }

  ngOnInit() {
    this.body = document.getElementsByTagName('body')[0];
    this.setBodyHeight();
    this.init();
  }

  async init(): Promise<void> {
    try {
      this.elementTypes = await this.catalogue.getElementTypes();
      await this.loadRequisites();
    } catch (e) {
      console.error(e);
    }
  }

  async loadRequisites(): Promise<void> {
    try {
      const response = await this.catalogue.getPrerequisites({cpi_id: this.item.id});

      this.prerequisites = _map<PreRequisite, PostPreRequisiteExtended>((requisite) => {
        return {...requisite, selected: requisite.is_post_prerequisites, subject_type_description: _find<ElementType>({subject_class_id: requisite.subject_class_id})(this.elementTypes).description};
      })(response.pre_prerequisites);

      this.postrequisites = _map<PreRequisite, PostPreRequisiteExtended>((requisite) => {
        return {...requisite, selected: requisite.is_post_prerequisites, subject_type_description: _find<ElementType>({subject_class_id: requisite.subject_class_id})(this.elementTypes).description};
      })(response.post_prerequisites);

      this.prerequisitesFiltered = _filter<PostPreRequisiteExtended>('selected')(this.prerequisites);
      this.postrequisitesFiltered = _filter<PostPreRequisiteExtended>('selected')(this.postrequisites);
    } catch (e) {
      console.error(e);
    }
  }

  async addRequisites(): Promise<void> {
    try {
      this.listChanged = true;
      switch (this.mode) {
        case 'pre':
          await this.catalogue.savePreRequisites({
            post_cpi_id: this.item.id,
            pre_cpi_id: _flow([
              _filter('selected'),
              _map('id')
            ])(this.prerequisites)
          });
          break;
        case 'post':
          await this.catalogue.savePostRequisites({
            pre_cpi_id: this.item.id,
            post_cpi_id: _flow([
              _filter('selected'),
              _map('id')
            ])(this.postrequisites)
          });
          break;
      }

      await this.loadRequisites();

      this.mode = 'default';
    } catch (e) {
      console.error(e);
    }
  }

  setBodyHeight(): void {
    this.bodyHeight = this.body.scrollHeight;
  }

  onClose(): void {
    if (this.mode !== 'default') {
      this.mode = 'default';

      return;
    }
    this.close.emit(this.listChanged);
  }
}
