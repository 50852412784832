import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  forEach as _forEach,
  toNumber as _toNumber,
  find as _find
} from 'lodash/fp';
import moment from '@tools/moment.local';
import { StudyPlansBase } from '../../../../constructor/components/study-plans/study-plans.base.service';
import { UserProfileService } from '@backend/user-profile/user-profile.service';
import { CommentsService } from '@backend/kosuip/comments/comments.service';
import { SupportDialogService } from '@core/support-dialog/support-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { CatalogueService } from '@backend/kosuip/catalogue/catalogue.service';
import { UnfinishedChangesIndicatorService } from '@core/unfinished-changes-indicator/unfinished-changes-indicator.service';
import { AdditionalStudyPlanService } from '../../../../constructor/components/additional-study-plan/additional-study-plan.service';
import { filter, fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'hse-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss']
})
export class BaseLayoutComponent implements OnInit, OnDestroy, AfterViewInit {

  public isUROP = false;
  public isManagerOP = false;
  public isManagerUP = false;
  public isAcademicDeputy = false;
  public isFacultyDecan = false;
  public isProrecror = false;
  public isFilialDeputy = false;
  public isAdmin = false;
  public isReadOnly = false;

  url: string;
  params: number;
  ready = false;

  public comments;
  public login;
  public studyPlan;
  public learnPeriods;
  public approveDate;
  public loaded = false;
  public currentLocale = 'ru';
  public isProd = false;
  public barWidth;

  public additionalStudyPlanSubscription: Subscription = Subscription.EMPTY;
  public studyPlanSubscription: Subscription = Subscription.EMPTY;

  @ViewChild('mainContent', { static: true }) mainContent: ElementRef;

  constructor(
    private readonly router: Router,
    private readonly userProfile: UserProfileService,
    private readonly changesIndicator: UnfinishedChangesIndicatorService,
    private readonly supportDialog: SupportDialogService,
    private readonly commentsService: CommentsService,
    private readonly translate: TranslateService,
    private readonly catalogue: CatalogueService,
    private readonly studyPlanBase: StudyPlansBase,
    private readonly additionalStudyPlanService: AdditionalStudyPlanService
  ) {
    this.isProd = Boolean(environment.production);

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        this.url = event.urlAfterRedirects;
        this.loaded = false;
        this.ready = false;
        this.checkUrl();
      });
    this.studyPlanSubscription = this.studyPlanBase.loadPlan.subscribe((studyPlan: any) => {
      this.setDataBeforeCommentsLoading(studyPlan);
    });

    this.additionalStudyPlanSubscription = this.additionalStudyPlanService.loadPlan.subscribe((studyPlan: any) => {
      this.setDataBeforeCommentsLoading(studyPlan);
    });

    this.studyPlanBase.loadData.subscribe((learnPeriods) => {
      this.learnPeriods = learnPeriods;
    });
  }

  ngOnInit() {
     this.currentLocale = localStorage.getItem('locale');
  }

  ngAfterViewInit() {
    this.setHeaderWidth();
    fromEvent(window, 'resize').subscribe(() => {
      this.setHeaderWidth();
    });
  }

  ngOnDestroy(): void {
    this.studyPlanSubscription.unsubscribe();
    this.additionalStudyPlanSubscription.unsubscribe();
  }

  setHeaderWidth() {
    setTimeout(() => {
      this.barWidth = parseFloat(getComputedStyle(this.mainContent.nativeElement).width);
    }, 0);
  }

  async init() {
    try {
      this.isManagerOP = await this.userProfile.isManagerOP();
      this.isUROP = await this.userProfile.isUROP() || await this.userProfile.isUAD();
      this.isManagerUP = await this.userProfile.isManagerUP();
      this.isAcademicDeputy = await this.userProfile.isAcademicDeputy() || await this.userProfile.isAcademicMaster();
      this.isFacultyDecan = await this.userProfile.isFacultyDecan();
      this.isProrecror = await this.userProfile.isProrecror();
      this.isFilialDeputy = await this.userProfile.isFilialDeputy();
      this.isAdmin = await this.userProfile.isAdmin();
      this.isReadOnly = await this.userProfile.isReadOnly();
      this.login = await this.userProfile.getLogin();
    } catch (e) {
      console.error(e);
    }
  }

  private checkUrl() {
    const URL_BY_YEAR = '/constructor/study_plan/edit_by_year/';
    const URL_STRUCTURE = '/constructor/study_plan/edit_structure/';
    const URL_BY_EDIT = '/constructor/study_plan/edit/';
    const URL_BY_ADDITIONAL = '/constructor/study_plan_additional/detail/';

    this.ready = (this.url.indexOf(URL_BY_EDIT) !== -1)
      || (this.url.indexOf(URL_BY_YEAR) !== -1)
      || (this.url.indexOf(URL_STRUCTURE) !== -1)
      || (this.url.indexOf(URL_BY_ADDITIONAL) !== -1);

    if (this.ready) {
      this.params = this.getIdFromURL(this.url);
      this.init();
    }
  }

  async loadComments() {
    try {

      this.comments = await this.catalogue.getComment3({
        learn_plan_id: this.studyPlan.id,
        is_arhive: true
      });

      _forEach(($comment: any) => {
        $comment.$date = moment($comment.Period, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY HH:mm');
        $comment.ableToEdit = $comment.user === this.login && this.ableApprove();
        $comment.ableToMark = $comment.ableToEdit && ($comment.parent_id === '0' || !$comment.parent_id) || this.isUROP || this.isAdmin;
      })(this.comments);

      this.loaded = true;
    } catch (e) {
      console.error(e);
    }
  }

  goToStartScreen() {
    if (this.changesIndicator.unfinishedChangesEditByYear) {
      this.changesIndicator.fire$.next();

      return;
    }

    this.router.navigateByUrl('/constructor');
  }

  private getIdFromURL(url: string) {
    const id = _toNumber(url.split('/').pop());
    if (Number(id)) {

      return id;
    }

    return -1;
  }


  async sendComment(comment) {
    try {
      const { text, parent_id } = comment;
      const parentComment: any = parent_id ? this.comments.find(($comment: any) => $comment.id === parent_id) : null;

      await this.commentsService.saveComment({
        type_object: '1',
        object: this.studyPlan.id,
        comment: text,
        parent_id
      });

      /**
       * при добавлении нового коммента у родительского сбрасываем флаг solved
       */
      if (parentComment && parentComment.solved) {
        await this.commentsService.updateComment({
          solved: false,
          type_object: parentComment.type_object.toString(),
          object: parentComment.object_id,
          comment: parentComment.Comment,
          is_important: parentComment.is_important
        }, parentComment.id);
      }

      this.comments = await this.catalogue.getComment3({
        learn_plan_id: this.studyPlan.id,
        is_arhive: true
      });

      _forEach(($comment: any) => {
        $comment.$date = moment($comment.Period, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY HH:mm');
        $comment.ableToEdit = $comment.user === this.login && this.ableApprove()
          && (!this.approveDate || moment(this.approveDate, 'YYYY-MM-DDTHH:mm:ss').isBefore(moment($comment.Period, 'YYYY-MM-DDTHH:mm:ss')));
        $comment.ableToMark = $comment.ableToEdit && ($comment.parent_id === '0' || !$comment.parent_id) || this.isUROP || this.isAdmin;
      })(this.comments);
    } catch (e) {
      console.error(e);
    }
  }

  async deleteComment(comment) {
    try {
      await this.commentsService.deleteComment(comment.id);

      this.comments = await this.catalogue.getComment3({
        learn_plan_id: this.studyPlan.id,
        is_arhive: true
      });

      _forEach(($comment: any) => {
        $comment.$date = moment($comment.Period, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY HH:mm');
        $comment.ableToEdit = $comment.user === this.login && this.ableApprove();
        $comment.ableToMark = $comment.ableToEdit && ($comment.parent_id === '0' || !$comment.parent_id) || this.isUROP || this.isAdmin;
      })(this.comments);
    } catch (e) {
      console.error(e);
    }
  }

  async updateComment(comment) {
    try {
      await this.commentsService.updateComment({
        solved: comment.solved,
        type_object: comment.type_object.toString(),
        object: comment.Object,
        comment: comment.Comment,
        is_important: comment.is_important
      }, comment.id);
    } catch (e) {
      console.error(e);
    }
  }

  ableApprove() {
    const lastStep = this.studyPlan.plan_status_id === '27';
    const noRole = !(this.isManagerOP || this.isUROP || this.isAcademicDeputy || this.isFacultyDecan || this.isProrecror);

    if (this.isManagerOP && (this.studyPlan.plan_status_id === '15' || this.studyPlan.plan_status_id === '16')) {
      return true;
    } else if (this.isUROP
      && ((this.studyPlan.filial_id !== '4' && this.studyPlan.plan_status_id === '8')
        || (this.studyPlan.filial_id === '4' && this.studyPlan.plan_status_id === '10')
        || this.studyPlan.plan_status_id === '100')) {
      return true;
    } else if (this.isAcademicDeputy && this.studyPlan.plan_status_id === '99') {
      return true;
    } else if (this.isFilialDeputy && this.studyPlan.filial_id !== '4' && this.studyPlan.plan_status_id === '10') {
      return true;
    } else if (this.isFacultyDecan && this.studyPlan.plan_status_id === '2') {
      return true;
    } else if (this.isProrecror && this.studyPlan.plan_status_id === '22') {
      return true;
    } else if (noRole) {
      return false;
    } else if (lastStep) {
      return false;
    }

    return false;
  }

  disableComments() {
    return !(this.ableApprove() || this.isUROP || this.isAdmin);
  }

  async markAsSolved($event) {
    try {
      const {
        event, comment
      } = $event;

      await this.commentsService.updateComment({
        solved: event,
        type_object: comment.type_object.toString(),
        object: comment.object_id,
        comment: comment.Comment,
        is_important: comment.is_important
      }, comment.id);

      const $comment = _find<any>({ id: comment.id })(this.comments);

      $comment.solved = event;
    } catch (e) {
      console.error(e);
    }
  }

  async markAsImportant($event) {
    try {
      const {
        event, comment
      } = $event;

      await this.commentsService.updateComment({
        is_important: event,
        type_object: comment.type_object.toString(),
        object: comment.object_id,
        comment: comment.Comment,
        solved: comment.solved
      }, comment.id);

      const $comment = _find<any>({ id: comment.id })(this.comments);

      $comment.is_important = event;
    } catch (e) {
      console.error(e);
    }
  }

  openSupportDialog(): void {
    this.supportDialog.openDialog();
  }

  changeLocale(locale = 'ru') {
    this.currentLocale = locale;
    this.translate.setDefaultLang(locale);
    this.translate.use(locale);
    localStorage.setItem('locale', locale);
    window.location.reload();
  }

  private setDataBeforeCommentsLoading(studyPlan: any) {
    this.studyPlan = studyPlan.body[0];
    this.approveDate = this.studyPlan.plan_status_update_date;

    this.loadComments();
  }
}
