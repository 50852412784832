<div class="wrapper">
    <div class="fadeInDown"
         [class.fadeOutDown]="alert.isFadeOut"
         *ngFor="let alert of alerts">
        <div class="hse-alert hse-alert-{{alert.type}}">
            <div class="fx row space-between hse-alert-item-wrapper">
                <div *ngIf="!alert.isHtml">{{alert.msg}}</div>
                <div *ngIf="alert.isHtml" [innerHTML]="alert.msg"></div>
                <hse-icon name="close" (click)="removeAlert(alert)"></hse-icon>
            </div>
        </div>
    </div>
</div>
