import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class StringMaskService {

  options: {reverse?: boolean, usedefaults?: boolean} = {};
  pattern: string = null;
  recursive = [];
  inRecursiveMode = false;
  formatted = '';
  valuePos = 0;
  optionalNumbersToUse = 0;
  i = 0;
  steps: {start: number, end: number, inc: number} = {start: 0, end: 0, inc: 0};
  value = '';

  tokens = {
      0: {pattern: /\d/, _default: '0'},
      9: {pattern: /\d/, optional: true},
      '#': {pattern: /\d/, optional: true, recursive: true},
      S: {pattern: /[a-zA-Z]/},
      $: {escape: true}
  };

  isEscaped(pattern, pos) {
    let count = 0;
    let i = pos - 1;
    let token = {escape: true};
    while (i >= 0 && token && token.escape) {
      token = this.tokens[pattern.charAt(i)];
      count += token && token.escape ? 1 : 0;
      i--;
    }

    return count > 0 && count % 2 === 1;
  }

  calcOptionalNumbersToUse(pattern, value) {
    const numbersInP = pattern.replace(/[^0]/g, '').length;
    const numbersInV = value.replace(/[^\d]/g, '').length;

    return numbersInV - numbersInP;
  }

  concatChar(text, character, options) {
    if (options.reverse) {
      return character + text;
    }

    return text + character;
  }

  hasMoreTokens(pattern, pos, inc) {
    const pc = pattern.charAt(pos);
    const token = this.tokens[pc];
    if (pc === '') {
      return false;
    }

    return token && !token.escape ? true : this.hasMoreTokens(pattern, pos + inc, inc);
  }

  insertChar(text, char, position) {
    const t = text.split('');
    t.splice(position >= 0 ? position : 0, 0, char);

    return t.join('');
  }

  continueCondition() {
    if (!this.inRecursiveMode && this.hasMoreTokens(this.pattern, this.i, this.steps.inc)) {
      return true;
    } else if (!this.inRecursiveMode) {
      this.inRecursiveMode = this.recursive.length > 0;
    }

    if (this.inRecursiveMode) {
      const pc = this.recursive.shift();
      this.recursive.push(pc);
      if (this.options.reverse && this.valuePos >= 0) {
        this.i++;
        this.pattern = this.insertChar(this.pattern, pc, this.i);

        return true;
      } else if (!this.options.reverse && this.valuePos < this.value.length) {
        this.pattern = this.insertChar(this.pattern, pc, this.i);

        return true;
      }
    }

    return this.i < this.pattern.length && this.i >= 0;
  }

  proccess(value, pattern, options?): {result: string, valid: boolean} {
    this.options = options || {};
    this.options.reverse = this.options.reverse || false;
    this.options.usedefaults = this.options.usedefaults || this.options.reverse;
    this.pattern = pattern;
    let valid = true;
    if (!value) {
      return {result: '', valid};
    }
    if (this.pattern.search(/[+.]/im) > -1) {
      this.value = value.replace(/[^0-9]/gim, '');
    } else {
      this.value = value;
    }
    this.formatted = '';
    this.valuePos = this.options.reverse ? value.length - 1 : 0;
    this.optionalNumbersToUse = this.calcOptionalNumbersToUse(this.pattern, value);
    let escapeNext = false;
    this.recursive = [];
    this.inRecursiveMode = false;

    this.steps = {
      start: this.options.reverse ? this.pattern.length - 1 : 0,
      end: this.options.reverse ? -1 : this.pattern.length,
      inc: this.options.reverse ? -1 : 1
    };

    for (this.i = this.steps.start;
         this.continueCondition();
         this.i = this.i + this.steps.inc) {
      const pc = this.pattern.charAt(this.i);
      const vc = this.value.charAt(this.valuePos);
      const token = this.tokens[pc];
      if (!this.inRecursiveMode || vc) {
        if (this.options.reverse && this.isEscaped(this.pattern, this.i)) {
          this.formatted = this.concatChar(this.formatted, pc, this.options);
          this.i = this.i + this.steps.inc;
          continue;
        } else if (!this.options.reverse && escapeNext) {
          this.formatted = this.concatChar(this.formatted, pc, this.options);
          escapeNext = false;
          continue;
        } else if (!this.options.reverse && token && token.escape) {
          escapeNext = true;
          continue;
        }
      }

      if (!this.inRecursiveMode && token && token.recursive) {
        this.recursive.push(pc);
      } else if (this.inRecursiveMode && !vc) {
        if (!token || !token.recursive) {
          this.formatted = this.concatChar(this.formatted, pc, this.options);
        }
        continue;
      } else if (this.recursive.length > 0 && token && !token.recursive) {
        // Recursive tokens most be the last tokens of the pattern
        valid = false;
        continue;
      } else if (!this.inRecursiveMode && this.recursive.length > 0 && !vc) {
        continue;
      }

      if (!token) {
        this.formatted = this.concatChar(this.formatted, pc, this.options);
        if (!this.inRecursiveMode && this.recursive.length) {
          this.recursive.push(pc);
        }
      } else if (token.optional) {
        if (token.pattern.test(vc) && this.optionalNumbersToUse) {
          this.formatted = this.concatChar(this.formatted, vc, this.options);
          this.valuePos = this.valuePos + this.steps.inc;
          this.optionalNumbersToUse--;
        } else if (this.recursive.length > 0 && vc) {
          valid = false;
          break;
        }
      } else if (token.pattern.test(vc)) {
        this.formatted = this.concatChar(this.formatted, vc, this.options);
        this.valuePos = this.valuePos + this.steps.inc;
      } else if (!vc && token._default && this.options.usedefaults) {
        this.formatted = this.concatChar(this.formatted, token._default, this.options);
      } else {
        valid = false;
        break;
      }
    }

    return {result: this.formatted, valid};
  }

  apply(value, pattern, options?): string {
    return this.proccess(value, pattern, options).result;
  }

  validate(value, pattern, options?): boolean {
    return this.proccess(value, pattern, options).valid;
  }
}
