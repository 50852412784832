import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'hse-stage-shift',
  templateUrl: './hse-stage-shift.component.html',
  styleUrls: ['./hse-stage-shift.component.scss']
})
export class HseStageShiftComponent {

  @Input() isAvailableToBack: boolean;
  @Input() isAvailableToForward: boolean;

  @Output() backwardCallback: EventEmitter<any> = new EventEmitter<any>();
  @Output() forwardCallback: EventEmitter<any> = new EventEmitter<any>();
}
