<div class="edit-translation fx column" [style.height.px]="bodyHeight">
    <div class="title fx row v-center space-between">
        <h1 *ngIf="!edit">{{'DIRECTORY-NAMES.CREATE.TITLE' | translate}}</h1>
        <h1 *ngIf="edit">{{'DIRECTORY-NAMES.CREATE.EDIT-TITLE' | translate}}</h1>
        <div class="controls fx row v-center">
            <hse-button [color]="'secondary'" (callback)="cancel()">{{'EDUCATION-RESULTS.EDIT.CANCEL' | translate}}</hse-button>
            <hse-button (callback)="save()" [disabled]="!progress.description.passed || !progress.engName.passed">{{'EDUCATION-RESULTS.EDIT.CONFIRM' | translate}}</hse-button>
        </div>
    </div>
    <div class="main-content fx row stretch">
        <div class="gap fx fx-2"></div>



        <div class="final fx fx-3 column" *ngIf="iterations[iterationIndex] === 'final'">
            <div class="final-item fx column" *ngFor="let item of passedItems">
                <div class="item-title fx row">
                    <span class="label">{{item.title}}</span>
                </div>
                <div class="item-value fx row">
                    <p>{{item.value}}</p>
                    <hse-icon [name]="'editBig'"
                              (click)="goToIteration(item.key, true)"></hse-icon>
                </div>
            </div>
        </div>




        <div class="iteration fx fx-2 column" *ngIf="iterations[iterationIndex] !== 'final'">
            <div class="passed-items fx column" [style.margin-bottom.px]="margin">
                <div class="passed-item fx row" *ngFor="let item of passedItems">
                    <div>
                        <p><b>{{item.title}}: </b>{{item.value}}</p>
                    </div>
                </div>
            </div>

            <div class="editing fx column" *ngIf="iterations[iterationIndex] === 'description'">
                <div class="edit-title fx row">
                    <span>{{progress.description.title}}</span>
                </div>
                <div class="edit-form fx row v-center">
                    <hse-input [(ngModel)]="progress.description.value"
                               [disablePlaceholderAnimation]="true"
                               [maxLength]="128"
                               [width]="560">
                    </hse-input>
                    <hse-button (callback)="saveField()" [disabled]="!progress.description.value">{{'DIRECTORY-NAMES.CREATE.NEXT' | translate}}</hse-button>
                    <div class="accept-label">
                        <span>нажмите Enter</span>
                        <hse-icon [name]="'enter'" class="enter-icon"></hse-icon>
                    </div>
                </div>
                <span class="length-message">{{ translations.INPUT }} {{ progress.description.value ? progress.description.value.length : '0' }} {{ translations.FROM }} 128 {{ translations.SYMBOLS }}</span>
            </div>

            <div class="editing fx column" *ngIf="iterations[iterationIndex] === 'engName'">
                <div class="edit-title fx row">
                    <span>{{progress.engName.title}}</span>
                </div>
                <div class="edit-form fx row v-center">
                    <hse-input [(ngModel)]="progress.engName.value"
                               [disablePlaceholderAnimation]="true"
                               [maxLength]="1024"
                               [width]="560">
                    </hse-input>
                    <hse-button (callback)="saveField()" [disabled]="!progress.engName.value">{{'DIRECTORY-NAMES.CREATE.NEXT' | translate}}</hse-button>
                </div>
                <span class="length-message">{{ translations.INPUT }} {{ progress.engName.value ? progress.engName.value.length : '0' }} {{ translations.FROM }} 1024 {{ translations.SYMBOLS }}</span>
            </div>
        </div>




        <div class="gap fx fx-2 h-end">
            <hse-stage-shift [isAvailableToBack]="isAvailableToBack"
                             *ngIf="!edit"
                             [isAvailableToForward]="isAvailableToForward"
                             (backwardCallback)="goBack()"
                             (forwardCallback)="goForward()">
            </hse-stage-shift>
        </div>
    </div>
    <hse-confirm-popup *ngIf="showConfirmPopup"
                       (callback)="confirmPopupCallback($event)"
                       [message]="confirmMessage"
                       [yesNo]="true"></hse-confirm-popup>
</div>
