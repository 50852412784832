<div class="edit-translation fx column" [style.height.px]="bodyHeight">
    <div class="title fx row v-center space-between">
        <h1>{{title}}</h1>
        <div class="controls fx row v-center">
            <hse-button [color]="'secondary'" (callback)="editCallback.emit(null)">
                {{'EDUCATION-RESULTS.EDIT.CANCEL' | translate}}
            </hse-button>
            <hse-button (callback)="save()" [disabled]="!hasChanges">
                {{'EDUCATION-RESULTS.EDIT.CONFIRM' | translate}}
            </hse-button>
        </div>
    </div>
    <div class="main-content fx row stretch">
        <div class="gap fx fx-2"></div>



        <div class="final fx fx-3 column" *ngIf="iterations[iterationIndex] === 'final'">
            <div class="final-item fx column" *ngFor="let field of config">
                <div class="item-title fx row">
                    <span>{{field.index}}.</span>
                    <span class="label">{{field.title}}</span>
                </div>
                <div class="item-value fx row">
                    <p>{{field.value}}</p>
                    <hse-icon [name]="'editBig'"
                              (click)="goToIteration(field.key)"
                              *ngIf="field.ableToEdit"></hse-icon>
                </div>
            </div>
        </div>




        <div class="iteration fx fx-2 column" *ngIf="iterations[iterationIndex] !== 'final'">
            <div class="passed-items fx column">
                <div class="passed-item fx row" *ngFor="let item of config">
                    <div>
                        <span>{{item.index}}.</span>
                    </div>
                    <div>
                        <p><b>{{item.title}}: </b>{{item.value || '-'}}</p>
                    </div>
                </div>
            </div>



            <div class="editing fx column" *ngIf="iterations[iterationIndex] === 'description'">
                <div class="edit-title fx row">
                    <span>{{descriptionIndex + 1}}.</span>
                    <span class="label">{{config[descriptionIndex].title}}</span>
                </div>
                <div class="edit-form fx row v-center">
                    <hse-textarea [ngModel]="description"
                                  (ngModelChange)="checkChanges($event, 'description')"
                                  [width]="560"
                                  [maxLength]="1024"
                                  [rowsCount]="5"
                                  [growOnFocus]="5">
                    </hse-textarea>
                    <hse-button (callback)="saveField('description')" [disabled]="!hasChanges">{{'EDUCATION-RESULTS.EDIT.SAVE' | translate}}</hse-button>
                    <div class="accept-label">
                        <span>{{'EDUCATION-RESULTS.EDIT.PRESS' | translate}} Enter</span>
                        <hse-icon [name]="'enter'" class="enter-icon"></hse-icon>
                    </div>
                </div>
                <span class="length-message">{{ translations.INPUT }} {{ description ? description.length : '0' }} {{ translations.FROM }} 1024 {{ translations.SYMBOLS }}</span>
            </div>

            <div class="editing fx column" *ngIf="iterations[iterationIndex] === 'engName'">
                <div class="edit-title fx row">
                    <span>{{engNameIndex + 1}}.</span>
                    <span>{{config[engNameIndex].title}}</span>
                </div>
                <div class="edit-form fx row v-center">
                    <hse-textarea [ngModel]="engName"
                                  (ngModelChange)="checkChanges($event, 'engName')"
                                  [rowsCount]="3"
                                  [maxLength]="1024"
                                  [width]="560"
                                  [growOnFocus]="3">
                    </hse-textarea>
                    <hse-button (callback)="saveField('engName')" [disabled]="!hasChanges">{{'EDUCATION-RESULTS.EDIT.SAVE' | translate}}</hse-button>
                </div>
                <span class="length-message">{{ translations.INPUT }} {{ engName ? engName.length : '0' }} {{ translations.FROM }} 1024 {{ translations.SYMBOLS }}</span>
            </div>
        </div>




        <div class="gap fx fx-2"></div>
    </div>
</div>
