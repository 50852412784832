export enum UserRole {
  UROP = 'УРОП',
  Admin = 'Администраторы',
  UAD = 'УАД',
  ManagerOP = 'Менеджер ОП',
  ManagerUP = 'Менеджер УП',
  ManagerASH = 'Менеджер АШ',
  AcademicDeputy = 'Академический руководитель УП',
  AcademicDirectorASH = 'Академический директор АШ',
  FacultyDecan = 'Декан факультета УП',
  Prorector = 'Проректор',
  FilialDeputy = 'Заместитель директора филиала',
  DOOP = 'ДООП/Траектория',
  ReadOnly = 'Просмотр',
  MezhvuzManager = 'Менеджер общевузовских планов',
  Teacher = 'Преподаватель',
  AcademicMaster = 'Академический наставник',
  DepartmentManager = 'Менеджер кафедры',
  DOO = 'ДОО'
}
