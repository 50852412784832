import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'hse-table-filter-popup',
  templateUrl: './table-filter-popup.component.html',
  styleUrls: ['./table-filter-popup.component.scss']
})
export class TableFilterPopupComponent implements OnInit {

  @Input() field: string;
  @Input() order: boolean | 'asc' | 'desc';
  @Input() value: string;
  @Input() left: number;
  @Input() shifted = false;
  @Input() top: number;
  @Input() debounce: number = null;
  @Input() withoutInput = false;
  @Input() width = 200;
  @Output() callback: EventEmitter<{field: string, order: boolean | 'asc' | 'desc', value: string, hide?: boolean}> = new EventEmitter<{field: string, order: boolean | 'asc' | 'desc', value: string, hide?: boolean}>();

  resizeCallback: Subscription;
  bodyHeight: number;
  bodyWidth: number;
  body: any;

  constructor() { }

  ngOnInit() {
    if (this.shifted) {
      // @ts-ignore
      this.left = this.left - this.width + 70;
    }
    this.body = document.getElementsByTagName('body')[0];
    this.setWrapper();
    this.resizeCallback = fromEvent(window, 'resize')
      .subscribe(() => {
        this.setWrapper();
      });
  }

  setWrapper(): void {
    // @ts-ignore
    this.bodyHeight = this.body.scrollHeight - 92;
    // @ts-ignore
    this.bodyWidth = this.body.scrollWidth - 25;
  }

  abortPropagation(event): void {
    event.stopPropagation();
  }

  hidePopup(): void {
    this.callback.emit(null);
  }

  submit(hide = false): void {
    if (hide) {
      this.callback.emit({field: this.field, order: this.order, value: this.value, hide: true});

      return;
    }
    this.callback.emit({field: this.field, order: this.order, value: this.value});
  }

  setOrder(order: boolean | 'asc' | 'desc'): void {
    this.order = order;
    this.submit();
  }

  setValue(value: string): void {
    if (typeof value === 'string') {
      this.value = value;
    }
    this.submit(Boolean(this.debounce));
  }
}
