<div class="fx row h-v-center print-form-modal__wrapper">
    <div class="fx column print-form-modal">
        <div class="print-form-modal__title">Выбор параметров для выгрузки печатной формы</div>
        <div class="fx column print-form-modal__content">
            <hse-select [collection]="fileFormats"
                        [(ngModel)]="fileFormat"
                        [placeholder]="'Выберите формат'"
            ></hse-select>
        </div>
        <div class="fx row v-center h-end print-form-modal__actions">
            <hse-button color="primary" [disabled]="!fileFormat" (callback)="unload()">Выгрузить</hse-button>
            <hse-button color="secondary" (callback)="cancel()">Отмена</hse-button>
        </div>
    </div>
</div>
