<div class="hse-combo-box">
  <section  [hidden]="!isOpened" *ngIf="useSearch || completeMatchCheckbox">
    <div *ngIf="useSearch" class="hse-combo-box__search-wrapper fx v-center">
      <hse-input #searchInput
                 (input)="filterOptions()"
                 [(ngModel)]="searchString"
                 [disablePlaceholderAnimation]="true"
                 [width]="null"
                 placeholder="{{searchPlaceholder}}"></hse-input>
      <hse-icon name="search" class="hse-combo-box__search-wrapper__search-icon"></hse-icon>
    </div>
    <hse-checkbox [(model)]="completeMatch"
                  *ngIf="completeMatchCheckbox"
                  (modelChange)="completeMatchChange.emit($event)">{{ 'COMMON.COMPLETE-MATCH' | translate }}</hse-checkbox>
  </section>

  <div [class.is-opened]="isOpened">
    <div class="hse-combo-box__options-list" *ngIf="(collection.length < alphabetModeCount ? true : !isOpened) || searchString">
      <div *ngFor="let item of filteredCollection
       | sortBy: 'desc': 'selected'
       | slice: 0:
       isOpened ? filteredCollection.length
                     : model && model.length > visibleItemsCount
                          ? model.length
                          : visibleItemsCount">
        <div (click)="itemClicked(item, $event)" class="menu-row" [class.selected]="item[selectedField]">
          <div class="fx v-center">
            <hse-checkbox [model]="item.selected"></hse-checkbox>
            <span class="hse-combo-box-span" *ngIf="!useTooltip">{{ item[nameField] }}</span>
            <span class="hse-combo-box-span"
                  *ngIf="useTooltip"
                  matTooltip="{{ item[nameField] }}"
                  [matTooltipPosition]="'above'">{{ item[nameField] }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="hse-combo-box__options-list"
         [hidden]="!isOpened"
         *ngIf="collection.length > alphabetModeCount">
      <div *ngFor="let letterOptions of alphCollection">
        <div class="option-group-letter">{{letterOptions.letter}}</div>
        <div *ngFor="let item of letterOptions.options">
          <div (click)="itemClicked(item, $event)" class="menu-row" [class.selected]="item[selectedField]">
            <div class="fx v-center">
              <hse-checkbox [model]="item.selected"></hse-checkbox>
              <span class="hse-combo-box-span" *ngIf="!useTooltip">{{ item[nameField] }}</span>
              <span class="hse-combo-box-span"
                    *ngIf="useTooltip"
                    matTooltip="{{ item[nameField] }}"
                    [matTooltipPosition]="'above'">{{ item[nameField] }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="show-all-option-toggle"
       *ngIf="filteredCollection.length > visibleItemsCount">
    <span (click)="toggleIsOpened($event)">{{ isOpened ? translations.HIDE : translations.SHOW }}</span>
  </div>
</div>
