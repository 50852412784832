<div class="create-education-results-background fx column stretch">
    <div class="create-education-results-wrapper fx column fx-1 stretch">
        <div class="title fx row stretch">
            <div class="fx fx-1 row">
                <h1>{{ 'STUDY-PLAN.CREATE-EDUCATION-RESULTS-POPUP.TITLE' | translate }}</h1>
            </div>
            <div class="controls fx column">
                <hse-icon [name]="'close'"
                          (click)="close.emit(null)"></hse-icon>
            </div>
        </div>
        <div class="content fx fx-1 row h-v-center">
            <div class="form fx column">
                <div class="form-item fx column">
                    <span>{{ 'STUDY-PLAN.CREATE-EDUCATION-RESULTS-POPUP.NAME' | translate }}</span>
                    <hse-textarea [rowsCount]="3" [growOnFocus]="3" [width]="600" [(ngModel)]="description"></hse-textarea>
                </div>
                <div class="form-item fx column">
                    <span>{{ 'STUDY-PLAN.CREATE-EDUCATION-RESULTS-POPUP.ENG-NAME' | translate }}</span>
                    <hse-textarea [rowsCount]="3" [growOnFocus]="3" [width]="600" [(ngModel)]="engName"></hse-textarea>
                </div>
            </div>
            <div class="confirm fx column">
                <hse-button (callback)="close.emit({description: description, eng_name: engName})"
                            [disabled]="!description">{{ 'STUDY-PLAN.CREATE-EDUCATION-RESULTS-POPUP.CREATE' | translate }}</hse-button>
            </div>
        </div>
    </div>
</div>
