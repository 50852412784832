import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExpandableLeftMenuService {

  public menuWidth = 72;
  public menuExpanded = false;
  toggleCallback: Subject<void> = new Subject<void>();

  constructor() { }

  toggleExpand() {
    this.menuExpanded = !this.menuExpanded;

    this.menuWidth = this.menuExpanded ? 344 : 72;

    this.toggleCallback.next();
  }
}
