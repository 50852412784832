<textarea class="hse-textarea"
          #hseTextarea
          [(ngModel)]="model"
          [style.width.px]="width"
          [rows]="rows"
          [placeholder]="placeholder"
          [disabled]="disabled"
          (focus)="grow()"
          (blur)="decrease()"
          (keydown)="onKeydownHandle($event)"
></textarea>
