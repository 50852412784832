<div class="fx row main-layout">
	<div class="left_bar-wrapper">
		<router-outlet name="left_bar"></router-outlet>
	</div>
	<div class="content-wrapper" [ngStyle]="{ 'min-width': 'calc(100% - ' + rightPanelWidth + 'px - ' + leftMenuService.menuWidth + 'px)' }">
		<router-outlet></router-outlet>
		<hse-footer></hse-footer>
	</div>
	<div class="right_bar-wrapper">
		<router-outlet name="right_bar"></router-outlet>
	</div>
</div>
