import { Component } from '@angular/core';
import { HseAlertItem, HseAlertService } from '@core/hse-alert/hse-alert.service';

@Component({
  selector: 'hse-alert',
  templateUrl: './hse-alert.component.html',
  styleUrls: ['./hse-alert.component.scss']
})
export class HseAlertComponent {
  alerts: Array<HseAlertItem>;

  constructor(private hseAlertService: HseAlertService) {
    this.alerts = hseAlertService.alerts;
  }

  removeAlert(alert) {
    this.hseAlertService.removeAlert(alert.id);
  }
}
