import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
@Directive({
  selector: '[hseDebounce]'
})
export class HseDebounceDirective implements OnInit, OnDestroy {
  @Input() debounceTime = 1000;
  @Output() debounceCallback = new EventEmitter();

  private subscription: Subscription;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.subscription = fromEvent(this.elementRef.nativeElement, 'input')
        .pipe(
            debounceTime(this.debounceTime),
            distinctUntilChanged()
        )
        .subscribe((event) => this.debounceCallback.emit(event));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
