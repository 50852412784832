import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { find as _find, findIndex as _findIndex } from 'lodash/fp';
import { Subscription, fromEvent } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export interface TranslationCallbackResponse {
  description: string;
  eng_name: string;
}

export interface ConfigItem {
  key: string;
  index: number;
  title: string;
  ableToEdit: boolean;
  value: any;
}

@Component({
  selector: 'hse-edit-translation',
  templateUrl: './edit-translation.component.html',
  styleUrls: ['./edit-translation.component.scss']
})
export class EditTranslationComponent implements OnInit, OnDestroy {

  @Input() config: ConfigItem[];
  @Input() title: string;
  @Output() editCallback: EventEmitter<TranslationCallbackResponse> = new EventEmitter<TranslationCallbackResponse>();

  private body: HTMLBodyElement;
  public bodyHeight: number;
  public iterationIndex = 2;
  public hasChanges = false;
  public defaultValues = {
    description: '',
    engName: ''
  };
  public description: string;
  public engName: string;
  public iterations = ['description', 'engName', 'final'];
  public enterSubscription: Subscription;
  translations = {
    INPUT: '',
    FROM: '',
    SYMBOLS: '',
  };
  public descriptionIndex: number;
  public engNameIndex: number;

  constructor(private translate: TranslateService) {
    this.translations.INPUT = this.translate.instant('UNIVERSITY-DISCIPLINE.INPUT');
    this.translations.FROM = this.translate.instant('UNIVERSITY-DISCIPLINE.FROM');
    this.translations.SYMBOLS = this.translate.instant('UNIVERSITY-DISCIPLINE.SYMBOLS');
  }

  ngOnInit() {
    this.body = document.getElementsByTagName('body')[0];
    this.setBodyHeight();
    this.enterSubscription = fromEvent(window, 'keyup')
      .subscribe((event: KeyboardEvent) => {
        if (event.code !== 'Enter') {
          return;
        }
        if (this.iterations[this.iterationIndex] === 'final') {
          return;
        }

        this.saveField(this.iterations[this.iterationIndex]);
      });
    this.descriptionIndex = _findIndex({key: 'description'})(this.config);
    this.engNameIndex = _findIndex({key: 'engName'})(this.config);
    this.description = this.defaultValues.description = this.config[this.descriptionIndex].value;
    this.engName = this.defaultValues.engName = this.config[this.engNameIndex].value;
  }

  goToIteration(key: string): void {
    if (key !== 'final') {
      this[key] = _find<ConfigItem>({key})(this.config).value;
    }
    this.iterationIndex = this.iterations.indexOf(key);
  }

  setBodyHeight(): void {
    this.bodyHeight = this.body.scrollHeight;
    window.scrollTo(0, 0);
  }

  saveField(key: string): void {
    _find<ConfigItem>({key})(this.config).value = this[key];
    this.goToIteration('final');
  }

  save(): void {
    this.editCallback.emit({description: this.description, eng_name: this.engName});
  }

  ngOnDestroy(): void {
    this.enterSubscription.unsubscribe();
  }

  checkChanges(value: string, fieldName: string) {
    this[fieldName] = value;
    this.hasChanges = value !== this.defaultValues[fieldName];
  }
}
