<div class="add-element-background fx column stretch" [style.min-height.px]="bodyHeight" (window:resize)="setBodyHeight()">

    <div class="add-element-wrapper fx column fx-1">

        <div class="title fx row stretch">
            <div class="fx fx-1 row h-start">
                <h1>{{ 'STUDY-PLAN.REQUISITES.TITLE' | translate }}</h1>
            </div>

            <div class="center fx-4 column h-start" [ngSwitch]="mode">


                <div class="passed-item fx row">
                    <span class="title">{{ 'STUDY-PLAN.REQUISITES.DISCIPLINE' | translate }}:</span>
                    <p class="value">{{ item.description }}</p>
                </div>


                <div class="pre-post-requisites fx fx-1 column" *ngSwitchCase="'default'">
                    <span class="iteration-title">{{ 'STUDY-PLAN.REQUISITES.PREREQUISITES' | translate }}</span>
                    <div class="requisite-block fx row stretch">
                        <div class="fx fx-1 column">
                            <span *ngIf="!prerequisitesFiltered.length">{{ 'STUDY-PLAN.REQUISITES.PRE-EMPTY' | translate }}</span>
                            <div class="radio-item disable-hover fx row v-center"
                                 *ngFor="let preCpi of prerequisitesFiltered">
                                <span>{{ preCpi.description }}</span>
                            </div>
                        </div>
                        <div class="fx v-center">
                            <hse-button (callback)="mode = 'pre'">{{ 'STUDY-PLAN.REQUISITES.EDIT' | translate }}</hse-button>
                        </div>
                    </div>
                    <span class="iteration-title">{{ 'STUDY-PLAN.REQUISITES.POSTREQUISITES' | translate }}</span>
                    <div class="requisite-block fx row stretch">
                        <div class="fx fx-1 column">
                            <span *ngIf="!postrequisitesFiltered.length">{{ 'STUDY-PLAN.REQUISITES.POST-EMPTY' | translate }}</span>
                            <div class="radio-item disable-hover fx row v-center"
                                 *ngFor="let postCpi of postrequisitesFiltered">
                                <span>{{ postCpi.description }}</span>
                            </div>
                        </div>
                        <div class="fx v-center">
                            <hse-button (callback)="mode = 'post'">{{ 'STUDY-PLAN.REQUISITES.EDIT' | translate }}</hse-button>
                        </div>
                    </div>
                </div>

                <div class="pre-post-requisites fx column" *ngSwitchCase="'pre'">
                    <span class="iteration-title">{{ 'STUDY-PLAN.REQUISITES.PREREQUISITES' | translate }}</span>
                    <div class="fx row stretch">
                        <div class="fx fx-1 column">
                            <div class="fx row stretch iteration__7-header">
                                <div class="iteration__7-header-cell fx fx-3 row v-center">
                                    <span>{{ 'STUDY-PLAN.REQUISITES.NAME' | translate }}</span>
                                </div>
                                <div class="iteration__7-header-cell fx fx-4 row v-center">
                                    <span>{{ 'STUDY-PLAN.REQUISITES.TYPE' | translate }}</span>
                                </div>
                            </div>
                            <div class="table-window fx column">
                                <div class="requisite-item checkbox-item fx row stretch v-center"
                                     (click)="requisite.selected = !requisite.selected"
                                     *ngFor="let requisite of prerequisites">
                                    <div class="iteration__7-table-cell fx fx-3 row v-center">
                                        <hse-checkbox [(model)]="requisite.selected"
                                                      [stopClickPropagation]="true"></hse-checkbox>
                                        <span>{{ requisite.description }}</span>
                                    </div>
                                    <div class="iteration__7-table-cell fx fx-4 row v-center">
                                        <span>{{ requisite.subject_type_description }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="fx row v-center">
                            <hse-button (callback)="addRequisites()">{{ 'STUDY-PLAN.REQUISITES.ADD' | translate }}</hse-button>
                        </div>
                    </div>
                </div>

                <div class="pre-post-requisites fx column" *ngSwitchCase="'post'">
                    <span class="iteration-title">{{ 'STUDY-PLAN.REQUISITES.POSTREQUISITES' | translate }}</span>
                    <div class="fx row stretch">
                        <div class="fx fx-1 column">
                            <div class="fx row stretch iteration__7-header">
                                <div class="iteration__7-header-cell fx fx-3 row v-center">
                                    <span>{{ 'STUDY-PLAN.REQUISITES.NAME' | translate }}</span>
                                </div>
                                <div class="iteration__7-header-cell fx fx-4 row v-center">
                                    <span>{{ 'STUDY-PLAN.REQUISITES.TYPE' | translate }}</span>
                                </div>
                            </div>
                            <div class="table-window fx column">
                                <div class="checkbox-item fx fx-1 row stretch"
                                     (click)="requisite.selected = !requisite.selected"
                                     *ngFor="let requisite of postrequisites">
                                    <div class="iteration__7-table-cell fx fx-3 row v-center">
                                        <hse-checkbox [(model)]="requisite.selected"
                                                      [stopClickPropagation]="true">{{ requisite.description }}</hse-checkbox>
                                    </div>
                                    <div class="iteration__7-table-cell fx fx-4 row v-center">
                                        <span>{{ requisite.subject_type_description }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="fx row v-center">
                            <hse-button (callback)="addRequisites()">{{ 'STUDY-PLAN.REQUISITES.ADD' | translate }}</hse-button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="controls fx fx-1 column v-start">
                <div class="fx row h-end">
                    <hse-icon [name]="'close'" class="close" (click)="onClose()"></hse-icon>
                </div>

            </div>


        </div>



    </div>

</div>
