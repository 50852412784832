import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

import { BackendModule } from '@backend/backend.module';

import { HseAlertModule } from '@core/hse-alert/hse-alert.module';
import { HseIconModule } from '@core/hse-icon/hse-icon.module';
import { SupportDialogModule } from '@core/support-dialog/support-dialog.module';

const exports = [
    BackendModule,
    HseAlertModule,
    HseIconModule,
    SupportDialogModule
];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        ...exports
    ],
    exports,
    providers: [CookieService]
})
export class CoreModule {
}
