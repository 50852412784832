import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { HseAlertService } from 'src/app/core/hse-alert/hse-alert.service';
import {
  cloneDeep as _cloneDeep,
  compact as _compact,
  filter as _filter,
  find as _find,
  flatten as _flatten,
  flow as _flow,
  forEach as _forEach,
  getOr as _getOr,
  includes as _includes,
  map as _map,
  max as _max,
  reject as _reject,
  sum as _sum,
  uniq as _uniq
} from 'lodash/fp';
import _getNumEndings from '@tools/fp/getNumEnding';
import { CatalogueService } from '@backend/kosuip/catalogue/catalogue.service';
import { Column, ColumnProps, CustomPlanItemExtended } from 'src/app/constructor/components/educational-standards/components/educational-standard-structure/educational-standard-structure.component';
import { PracticePlaceType } from '@backend/kosuip/catalogue/interfaces/practice-place-type.interface';
import { PracticeType } from '@backend/kosuip/catalogue/interfaces/practice-type.interface';
import { SubjectType } from '@backend/kosuip/catalogue/interfaces/subject-type.interface';
import { StudyPlanSpecialization } from '@backend/kosuip/catalogue/interfaces/study-plan.interface';
import { TranslateService } from '@ngx-translate/core';
import moment from '@tools/moment.local';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomPlanItemBackend, EducationStandardStructureBackend } from '@backend/kosuip/catalogue/interfaces/education-standard-structure.interface';
import { PlanDiscipline } from '@backend/kosuip/catalogue/interfaces/plan-discipline.interface';
import { Department } from '@backend/kosuip/catalogue/interfaces/department.interface';
import { Filial } from '@backend/kosuip/catalogue/interfaces/filials.interface';
import { Language } from '@backend/kosuip/catalogue/interfaces/language.interface';
import { ElementType } from '@backend/kosuip/catalogue/interfaces/element-type.interface';
import { Discipline } from '@backend/kosuip/catalogue/interfaces/discipline.interface';

export interface AddConfig {
  directoriesRequired?: boolean;
  baseDirectory?: boolean;
  startYearId?: string;
  endYearId?: string;
  previousPeriods?: boolean;
  disableSum?: boolean;
  subject_area_id?: string;
  module_structure?: EducationStandardStructureBackend;
  module?: CustomPlanItemExtended;
  directory?: any;
  type?: any;
  plan_status_id?: string;
  current_approval_year_id?: string;
  structure?: EducationStandardStructureBackend;
  properties: ColumnProps;
  structure_id?: string;
  standart_id?: string;
  item?: CustomPlanItemExtended;
  column?: Column;
  element_type_id?: string;
  children_structures?: EducationStandardStructureBackend[];
  checkboxAvailable?: boolean;
  structures?: EducationStandardStructureBackend[];
  specializations?: StudyPlanSpecialization[];
  is_comercial?: boolean;
  parent?: CustomPlanItemExtended;
  practice_profile_id?: string;
  education_level_id?: string;
  lp_begin_year_id?: string;
  rstplan_type_id?: string;
  learn_programm_id?: string;
  learn_period_type_id?: string;
  period_count?: number;
  $studyPlan?: any;
}

export interface PlanDisciplineExtended extends PlanDiscipline {
  disabled?: boolean;
  displayStudCount?: string;
  excess?: boolean;
  zeroStudCount?: boolean;
  selected?: boolean;
  periodNumList?: string;
  hovered?: boolean;
  height?: number;
  invalid?: boolean;
  audienceReachName?: string;
  custom_item_source?: string;
}

export interface DepartmentExtended extends Department {
  hovered?: boolean;
  height?: number;
  fullDescription?: string;
}

export interface FilialItem extends Filial {
  selected?: boolean;
}

export interface LanguageItem extends Language {
  selected?: boolean;
}

@Component({
  selector: 'hse-add-element',
  templateUrl: './add-element.component.html',
  styleUrls: ['./add-element.component.scss']
})
export class AddElementComponent implements OnInit, OnDestroy {

  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Input() config?: AddConfig;
  @Input() edit = false;
  @Input() change = false;
  @Input() studyPlan = false;
  @Input() subjectAreas: any[] = [];

  margin = 200;
  disciplineTableHeight = 0;
  disciplineTablePosition = 0;
  departmentTableHeight = 0;
  departmentTablePosition = 0;
  optionalDuration = false;

  studCount: string = null;

  beginYears: any[];
  endYears: any[];

  creditsByYears = false;
  disableCreditsButton = true;

  practiceTypeDescription = '';

  endings;
  translations = {
    NEXT: '',
    ACCEPT: '',
    ADD_NAME: '',
    INPUT: '',
    FROM: '',
    SYMBOLS: '',
    NOT_SET: ''
  };

  ableToEditPaths: string[] = [];

  public progress;
  public practiceForms;
  public path: string[] = ['type'];
  public passedItems: { title: string, value: string, key: string }[] = [];
  public disciplineTableColumns;
  public disciplineTableFirstColumn;
  public departmentTableColumns;
  public practiceTypeTable;
  public departmentTableFirstColumn;
  public disciplineById: any = null;

  // public tableSortConfig = {
  //   sortColumn: '',
  //   sortDirection: 'asc'
  // };
  public elementTypes: ElementType[] = [];
  public fromCatalogue;
  public practiceTypes: PracticeType[] = [];
  public filteredPracticeTypes: PracticeType[] = [];
  public practicePlaceTypes: PracticePlaceType[] = [];
  public subjectTypes: SubjectType[] = [];

  public iterationIndex = 0;
  public isAvailableToBack = false;
  public isAvailableToForward = false;

  public filials: FilialItem[];
  public languages: LanguageItem[] = [];
  public chips: PlanDisciplineExtended[] = [];
  public planDisciplines: PlanDisciplineExtended[] = [];
  public filteredPlanDisciplines: PlanDisciplineExtended[] = [];
  public departments: DepartmentExtended[] = [];
  public disciplines: Discipline[] = [];
  public disciplineFilter = {
    page_num: 1,
    page_limit: 50,
    search_string: ''
  };
  public departmentFilter = {
    page_num: 1,
    page_limit: 50,
    search_string: '',
    filial_id: ''
  };
  public disciplineSearch: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public departmentSearch: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public planDisciplineSearch = '';
  public planDisciplineFullMatch = false;
  public totalDisciplinesPages: number = null;
  public totalDepartmentsPages: number = null;
  updateDepartments = false;
  private callbacks: string[] = [null];
  enterSubscription: Subscription;
  resizeSubscription: Subscription;

  public learnPeriods: any;

  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;

  fullTableWidth = 0;

  constructor(private catalogueService: CatalogueService,
              private translate: TranslateService,
              private alert: HseAlertService) {
  }

  ngOnInit(): void {
    this.translations.NEXT = this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.NEXT');
    this.translations.ACCEPT = this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.ACCEPT');
    this.translations.ADD_NAME = this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.ADD-NAME');
    this.translations.INPUT = this.translate.instant('UNIVERSITY-DISCIPLINE.INPUT');
    this.translations.FROM = this.translate.instant('UNIVERSITY-DISCIPLINE.FROM');
    this.translations.SYMBOLS = this.translate.instant('UNIVERSITY-DISCIPLINE.SYMBOLS');
    this.translations.NOT_SET = this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.NOT-SET-2');
    this.endings = [
      this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.ELEMENT-COUNTING.EL-1'),
      this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.ELEMENT-COUNTING.EL-2'),
      this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.ELEMENT-COUNTING.EL-3')
    ];

    this.progress = {
      type: {
        value: null,
        passed: false,
        label: '',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.PROGRESS.TYPE') + ':'
      },
      courseDescription: {
        value: null,
        passed: false,
        label: '',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.PROGRESS.NAME') + ':'
      },
      name: {
        value: null,
        passed: false,
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.PROGRESS.NAME') + ':',
        label: ''
      },
      engName: {
        value: null,
        passed: false,
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.PROGRESS.ENG-NAME') + ':',
        label: ''
      },
      period: {
        start: null,
        end: null,
        passed: false,
        label: '',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.PROGRESS.PERIOD') + ':'
      },
      fromCatalogue: {
        value: false,
        passed: false,
        label: '',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.PROGRESS.ADD-DISCIPLINE') + ':'
      },
      searchById: {
        value: null,
        passed: false,
        label: '',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.PROGRESS.SEARCH-BY-ID') + ':'
      },
      foundDiscipline: {
        value: null,
        passed: false,
        label: '',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.PROGRESS.STUDENT-COUNT-2') + ':'
      },
      filial: {
        value: null,
        label: '',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.PROGRESS.FILIAL') + ':',
        passed: false
      },
      department: {
        value: null,
        label: '',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.PROGRESS.DEPARTMENT') + ':',
        passed: false
      },
      planDiscipline: {
        value: null,
        passed: false,
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.PROGRESS.DISCIPLINE') + ':',
        label: '',
        buttonLabel: ''
      },
      catalogueDiscipline: {
        value: null,
        passed: false,
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.PROGRESS.DISCIPLINE') + ':',
        label: ''
      },
      disciplineToAdd: {
        value: null,
        passed: false,
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.PROGRESS.DISCIPLINE') + ':',
        label: ''
      },
      language: {
        value: [],
        label: '',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.PROGRESS.LANGUAGE') + ':',
        passed: false
      },
      duration: {
        value: null,
        passed: false,
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.PROGRESS.CREDITS') + ':',
        label: '',
        yearsForCredits: []
      },
      disciplineOfCycle: {
        value: null,
        passed: false,
        label: '',
        buttonLabel: '',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.PROGRESS.COMMON-DISCIPLINES') + ':'
      },
      practiceType: {
        value: null,
        passed: false,
        description: '',
        eng_description: '',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.PROGRESS.PRACTICE-TYPE') + ':',
        label: ''
      },
      practicePlaceType: {
        value: null,
        passed: false,
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.PROGRESS.PRACTICE-METHOD') + ':',
        label: ''
      },
      subjectType: {
        value: null,
        passed: false,
        title: null,
        label: ''
      },
      subjectAreas: {
        value: [],
        passed: false,
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.ADD-ELEMENT.SUBJECT-AREAS') + ':',
        label: ''
      },
      practiceForm: {
        value: null,
        passed: false,
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.PROGRESS.PRACTICE-FORM') + ':',
        label: ''
      },
      specialization: {
        value: null,
        passed: false,
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.PROGRESS.SPECIALIZATION') + ':',
        label: ''
      }
    };

    this.practiceForms = [
      {
        name: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.PRACTICE-FORMS.WITH'),
        value: true
      },
      {
        name: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.PRACTICE-FORMS.WITHOUT'),
        value: false
      }
    ];

    this.disciplineTableColumns = [
      {
        name: 'id',
        title: 'ID',
        width: 100
      },
      // {
      //   name: 'custom_plan',
      //   title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.DISCIPLINE-TABLE-COLUMNS.PLAN'),
      //   width: 150
      // },
      {
        name: 'study_format',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.DISCIPLINE-TABLE-COLUMNS.STUDY-FORMAT'),
        width: 112
      },
      {
        name: 'language',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.DISCIPLINE-TABLE-COLUMNS.LANGUAGE'),
        width: 116
      },
      {
        name: 'audienceReachName',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.DISCIPLINE-TABLE-COLUMNS.AUDIENCE-REACH'),
        width: 108
      },
      {
        name: 'basic_credits',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.DISCIPLINE-TABLE-COLUMNS.CREDITS'),
        width: 108
      },
      {
        name: 'aud_hours',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.DISCIPLINE-TABLE-COLUMNS.CONTACT-HOURS'),
        width: 100
      },
      {
        name: 'lec_hours',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.DISCIPLINE-TABLE-COLUMNS.LECTION-HOURS'),
        width: 120
      },
      {
        name: 'sem_hours',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.DISCIPLINE-TABLE-COLUMNS.SEMINAR-HOURS'),
        width: 126
      },
      {
        name: 'periodNumList',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.DISCIPLINE-TABLE-COLUMNS.MODULES'),
        width: 128
      },
      {
        name: 'data_culture_level_description',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.DISCIPLINE-TABLE-COLUMNS.DATA-CULTURE'),
        width: 250
      },
      {
        name: 'basic_entire_hours',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.DISCIPLINE-TABLE-COLUMNS.HOURS'),
        width: 88
      },
      {
        name: 'displayStudCount',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.DISCIPLINE-TABLE-COLUMNS.STUD-COUNT'),
        width: 160
      },
      {
        name: 'custom_item_source',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.DISCIPLINE-TABLE-COLUMNS.FINANCE-SOURCE'),
        width: 166
      },
      {
        name: 'item_status_description',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.DISCIPLINE-TABLE-COLUMNS.STATUS'),
        width: 130
      }
    ];

    this.disciplineTableFirstColumn = [
      {
        name: 'name',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.DISCIPLINE-TABLE-COLUMNS.DISCIPLINE'),
        width: 300
      }
    ];

    this.departmentTableColumns = [
      {
        name: 'department_category',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.DEPARTMENT-TABLE-COLUMNS.CATEGORY'),
        width: 250
      },
      {
        name: 'faculty_name',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.DEPARTMENT-TABLE-COLUMNS.FACULTY'),
        width: 500
      }
    ];

    this.departmentTableFirstColumn = [
      {
        name: 'fullDescription',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.DEPARTMENT-TABLE-COLUMNS.NAME'),
        width: 300
      }
    ];

    this.practiceTypeTable = [
      {
        name: 'description',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.PROGRESS.PRACTICE-TYPE'),
        width: 250
      },
      {
        name: 'practice_form',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.PROGRESS.PRACTICE-FORM-SHORT'),
        width: 250
      },
      {
        name: 'eng_description',
        title: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.PROGRESS.ENG-NAME-2'),
        width: 250
      }
    ];

    this.fromCatalogue = [
      {
        name: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.FROM-CATALOGUE.CATALOGUE'),
        value: true
      },
      {
        name: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.FROM-CATALOGUE.DEPARTMENT'),
        value: false
      }
    ];

    this.enterSubscription = fromEvent(window, 'keyup')
      .subscribe((event: KeyboardEvent) => {
        if (event.code === 'Enter' && Boolean(this.callbacks[this.iterationIndex])) {
          this[this.callbacks[this.iterationIndex]]();
        }

        return;
      });
    this.loadData();

    this.resizeSubscription = fromEvent(window, 'resize').subscribe(() => {
      this.setFullTableWidth();
    });

    setTimeout(() => {
      this.setFullTableWidth();
    });
  }

  setFullTableWidth() {
    this.fullTableWidth = this.wrapper.nativeElement.getBoundingClientRect().width;
  }

  fillType() {
    const type = _find<ElementType>({ id: this.config.item.elementTypeId })(this.elementTypes);

    this.progress.type.value = this.config.item.elementTypeId;
    this.progress.type.label = type.name;
    this.progress.type.passed = true;
  }

  setPlanHeights() {
    const sampleTemplates = document.getElementsByClassName('sample');
    const remainingTemplates = document.getElementsByClassName('remaining');

    for (let i = 0; i < this.planDisciplines.length; i++) {
      this.planDisciplines[i].height = sampleTemplates[i].clientHeight >= remainingTemplates[i].clientHeight
        ? sampleTemplates[i].clientHeight
        : remainingTemplates[i].clientHeight;
    }
  }

  setDepartmentHeights() {
    const sampleTemplates = document.getElementsByClassName('sample');
    const remainingTemplates = document.getElementsByClassName('remaining');

    for (let i = 0; i < this.departments.length; i++) {
      this.departments[i].height = sampleTemplates[i].clientHeight >= remainingTemplates[i].clientHeight
        ? sampleTemplates[i].clientHeight
        : remainingTemplates[i].clientHeight;
    }
  }

  async searchById(): Promise<void> {
    try {
      if (!Boolean(this.progress.searchById.value)) {
        return;
      }

      const studyPlan = this.config.$studyPlan;
      const elementType = _find<ElementType>({ id: this.progress.type.value })(this.elementTypes);

      const params = {
        id: Number(this.progress.searchById.value),
        begin_year_id: this.progress.period.start, // идентификатор года начала реализации дисциплины;
        end_year_id: this.progress.period.end, // идентификатор года начала окончания реализации дисциплины;
        is_archive: false, // признак удаления (архивации);
        lp_begin_year_id: studyPlan.start_learn_year_id, // идентификатор учебного года начала реализации УП, чтобы вычислить курс обучения;
        rstplan_type_id: this.config.rstplan_type_id, // идентификатор типа учебного плана, в который добавляется дисциплина;
        learn_programm_id: studyPlan.learn_program_id,
        learn_program_version_id: studyPlan.learn_program_version_id,
        period_count: studyPlan.period_count, // количество периодов учебного плана (в соответсвии с параметром УП);
        learn_period_type_id: studyPlan.learn_period_type_id, // идентификатор параметра "реализуется по периодам" (в соответсвии с параметром УП);
        plan_type_id: 2, // искать только дисциплины ППК;
        subject_class_id: elementType.subject_class_id, // идентификатор вида записи плана (в соответсвии с типом добавляемой записи) — Дисциплина (id=1) или НИС (id=9);
        item_status_id: [3, 4].join(','), // идентификатор статуса дисциплины "Утверждена", "Принята кафедрой";
        custom_item_source_id: await this.getCustomItemSourceIds() // идентификатор источника финансирования, который определяется по логике из задачи STDCONST-2850
      };

      const disciplineByIdResponse = await this.catalogueService.getDisciplinePlan(params);

      if (!Boolean(disciplineByIdResponse.length)) {
        this.alert.warn('Дисциплина найдена, но не может быть добавлена в данный Учебный План');

        return;
      }

      const audienceReach = await this.catalogueService.getAudienceReach();
      const disciplineById: PlanDisciplineExtended = disciplineByIdResponse[0];

      disciplineById.periodNumList = _flow([
        _filter((itemByYear: any) => Boolean(itemByYear.learn_year)),
        _map<CustomPlanItemBackend, string>((itemByYear: any) => {
          return `${itemByYear.learn_year}: модули ${itemByYear.period_num_list}`;
        })
      ])(disciplineById.custom_plan_item_by_year).join('\n');
      disciplineById.displayStudCount = `${disciplineById.custom_plan_item_by_year[0].stud_count}/${disciplineById.custom_plan_item_by_year[0].max_count}`;
      disciplineById.language = _map('language')(disciplineById.sbj_languages).join(', ');
      disciplineById.custom_item_source = _map('source')(disciplineById.sbj_sources).join(', ');
      disciplineById.audienceReachName = _flow(_find({ id: Number(disciplineById.audience_reach_id) }), _getOr('', 'description'))(audienceReach);

      this.disciplineById = disciplineById;
      this.path = ['type', 'period', 'fromCatalogue', 'subjectType', 'searchById', 'foundDiscipline'];
      this.callbacks = [null, null, null, null, 'searchById', 'setFilial', null, null];

      this.nextIteration();
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        if (e.status === 400) {
          this.alert.warn('Дисциплин по данному ID не найдено');
        } else {
          this.alert.warn('Дисциплина найдена, но не может быть добавлена в данный Учебный План');
        }
      }

      console.error(e);
    }
  }

  skipSearchById(): void {
    this.progress.searchById.value = null;
    this.path = ['type', 'period', 'fromCatalogue', 'subjectType', 'searchById', 'filial', 'department', 'planDiscipline'];
    this.callbacks = [null, null, null, null, 'searchById', 'setFilial', null, 'addPlanDisciplines'];

    this.nextIteration();
  }

  fillCourseDescription() {
    this.progress.courseDescription.value = this.config.item.dict_university_subject_id;
    this.progress.courseDescription.label = this.config.item.description;
    this.progress.courseDescription.passed = true;
  }

  fillSubjectType() {
    const subjectType = _find<SubjectType>({ id: this.config.item.subject_type_id })(this.subjectTypes);

    this.progress.subjectType.title = this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.ELEMENT-TYPE');
    this.progress.subjectType.value = Boolean(subjectType) ? subjectType.id : null;
    this.progress.subjectType.label = Boolean(subjectType) ? subjectType.name : '-';
    this.progress.subjectType.passed = true;
  }

  fillName() {
    this.progress.name.value = this.config.item.description;
    this.progress.name.label = this.config.item.description;
    this.progress.name.passed = true;
  }

  fillEngName() {
    const engName = this.config.item.eng_description || this.config.item.eng_name;
    this.progress.engName.value = engName;
    this.progress.engName.label = engName;
    this.progress.engName.passed = true;
  }

  fillPracticeType() {
    const practiceType = _find<PracticeType>({ id: this.config.item.practice_type_id })(this.practiceTypes);

    this.progress.practiceType.value = practiceType ? practiceType.id : null;
    this.progress.practiceType.label = !practiceType
      ? '-'
      : practiceType.id === '89'
        ? `Проект. ${this.config.item.description}`
        : practiceType.description;
    this.progress.practiceType.description = practiceType ? practiceType.description : '';
    this.progress.practiceType.passed = true;
  }

  fillPracticePlaceType() {
    const practicePlaceType = _find<PracticePlaceType>({ id: this.config.item.practice_place_type_id })(this.practicePlaceTypes);

    this.progress.practicePlaceType.value = Boolean(practicePlaceType) ? practicePlaceType.id : null;
    this.progress.practicePlaceType.label = Boolean(practicePlaceType) ? practicePlaceType.name : '-';
    this.progress.practicePlaceType.passed = true;
  }

  fillPracticeForm() {
    const practiceForm = _find<any>({ value: this.config.item.practice_form })(this.practiceForms);

    this.progress.practiceForm.value = Boolean(practiceForm) ? practiceForm.value : null;
    this.progress.practiceForm.label = Boolean(practiceForm) ? practiceForm.name : '-';
    this.progress.practiceForm.passed = true;
  }

  fillSubjectAreas() {
    this.progress.subjectAreas.value = _map('subject_area_id')(this.config.item.subject_areas);
    this.subjectAreas = _map((area: any) => {
      area.selected = this.progress.subjectAreas.value.indexOf(area.subject_area_id) > -1;

      return area;
    })(this.subjectAreas);
    this.progress.subjectAreas.label = Boolean(this.progress.subjectAreas.value.length) ? _map('description')(this.config.item.subject_areas).join(', ') : this.translations.NOT_SET;
    this.progress.subjectAreas.passed = true;
  }

  fillFromCatalogue() {
    if (this.change) {
      this.progress.fromCatalogue.value = false;
    } else {
      this.progress.fromCatalogue.value = !Boolean(this.config.item.source_id);
    }
    this.progress.fromCatalogue.passed = true;
    this.progress.fromCatalogue.label = this.progress.fromCatalogue.value
      ? this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.FROM-CATALOGUE.CATALOGUE')
      : this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.FROM-CATALOGUE.DEPARTMENT');
  }

  fillCatalogueDiscipline() {
    this.progress.catalogueDiscipline.value = this.config.item.dict_university_subject_id;
    this.progress.catalogueDiscipline.label = this.config.item.description;
    this.progress.catalogueDiscipline.passed = true;
  }

  fillPlanDiscipline() {
    this.progress.planDiscipline.value = this.config.item.source_id;
    this.progress.planDiscipline.label = this.config.item.description;
    this.progress.planDiscipline.passed = true;
  }

  fillLanguage() {
    const {language_id, sbj_languages} = this.config.item;
    if (language_id) {
      const language = _find<Language>({id: this.config.item.language_id})(this.languages);
      this.progress.language.value = language && language.id;
      this.progress.language.label = language ? language.name : this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.NOT-SET-1');
    } else if (sbj_languages) {
      this.progress.language.value = _map('language_id')(sbj_languages);
      this.progress.language.label = sbj_languages.length
        ? _map('language')(sbj_languages).join(', ')
        : this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.NOT-SET-1');

      _forEach<LanguageItem>((language) => {
        language.selected = Boolean(_find({language_id: language.id})(sbj_languages));
      })(this.languages);
    }
    this.progress.language.passed = true;
  }

  fillSpecialization() {
    if (!Boolean(this.config.item.specialization_id)) {
      this.progress.specialization.value = '';
      this.progress.specialization.label = this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.NOT-SET-2');
      this.progress.specialization.passed = true;
    } else {
      const specialization = _find<any>({ specialization_id: this.config.item.specialization_id })(this.config.specializations);

      this.progress.specialization.value = Boolean(specialization) ? specialization.specialization_id : null;
      this.progress.specialization.label = Boolean(specialization) ? specialization.description : null;
      this.progress.specialization.passed = true;
    }
  }

  fillPeriod() {
    this.progress.period.start = this.config.item.start_learn_year_id;
    this.progress.period.end = this.config.item.end_learn_year_id;

    if (Boolean(this.progress.period.start) && Boolean(this.progress.period.end)) {
      this.progress.period.label = this.progress.period.start === this.progress.period.end
        ? _find<any>({ id: this.progress.period.start })(this.beginYears).description
        : [
            `${this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.YEARS')}`,
            `${_find<any>({ id: this.progress.period.start })(this.beginYears).description.split(' ')[0]}`,
            '-',
            `${_find<any>({ id: this.progress.period.end })(this.endYears).description.split(' ')[0]}`
          ].join(' ');

      this.creditsByYears = this.studyPlan && (this.progress.period.start !== this.progress.period.end);
      this.setProperDurationCallback();
    } else {
      this.progress.period.label = '-';
    }
    this.progress.period.passed = true;
  }

  fillDuration() {
    this.progress.duration.value = this.config.item.basic_credits;
    this.progress.duration.passed = true;
    const start = _find<any>({ id: this.progress.period.start })(this.beginYears);
    const end = _find<any>({ id: this.progress.period.end })(this.beginYears);
    const years = Boolean(this.progress.period.start) && Boolean(this.progress.period.end)
      ? _flow([
        _filter((year: any) => {
          return year.learn_year >= start.learn_year && year.learn_year <= end.learn_year;
        }),
        _map('id')
      ])(this.beginYears)
      : null;

    const condition = this.creditsByYears
      && Boolean(this.progress.period.start) && Boolean(this.progress.period.end)
      && this.config.item.custom_plan_item_by_year.length === years.length
      && !Boolean(_find((item: CustomPlanItemExtended) => {
        return !_includes(item.item_learn_year_id)(years);
      })(this.config.item.custom_plan_item_by_year));

    if (this.studyPlan && (this.config.item.custom_plan_item_by_year.length > 1)) {
      if (condition) {
        this.disableCreditsButton = false;
        this.progress.duration.yearsForCredits = _flow([
          _map((item: CustomPlanItemExtended) => {
            const resultYear = _find<any>({ id: item.item_learn_year_id })(this.beginYears);
            resultYear.credits = item.basic_credits;
            resultYear.label = resultYear.name.split(' ')[0];
            resultYear.invalid = false;

            return resultYear;
          })
        ])(this.config.item.custom_plan_item_by_year);
        this.progress.duration.label = `Всего кредитов - ${this.progress.duration.value}\n${_map((year: any) => {
          return `${year.label} - ${year.credits}`;
        })(this.progress.duration.yearsForCredits).join('\n')}`;
        this.validateCredits();
      } else {
        this.prepareCreditsByYears();
      }
    } else {
      this.disableCreditsButton = false;
      this.progress.duration.label = this.config.item.basic_credits.toString();
    }
  }

  async fillToChange() {
    try {
      this.path = [...this.path, 'period', 'fromCatalogue', 'subjectType', 'searchById', 'foundDiscipline', 'filial', 'department', 'planDiscipline'];
      this.callbacks = [...this.callbacks, null, null, null, 'searchById', 'setFilial', 'setFilial', null, 'addPlanDisciplines'];
      this.setProperDurationCallback();

      await this.getFilials();

      this.fillType();
      this.fillPeriod();
      this.fillFromCatalogue();


      this.updatePassedItems();
      this.goToIteration('searchById');
      this.checkAvailabilityToShift();
    } catch (e) {
      console.error(e);
    }
  }

  async fillToEdit() {
    try {
      await this.getSubjectTypes();
      await this.getLanguages();

      this.fillType();

      switch (this.config.item.elementTypeId) {
        case '00000000017':
          this.disciplineSearch
            .pipe(debounceTime(500))
            .pipe(distinctUntilChanged())
            .subscribe((resp) => {
              this.disciplineFilter.search_string = resp;
              this.getDisciplines(this.disciplineFilter);
            });
          if (this.studyPlan) {
            switch (this.config.education_level_id) {
              case '3':
              case '4':
              case '5':
              case '6':
                this.path = [...this.path, 'period', 'courseDescription', 'subjectType', 'language', 'duration', 'final'];
                this.callbacks = [...this.callbacks, null, 'setCourse', null, null, 'setDuration', 'addElements'];
                this.ableToEditPaths = [...this.path];
                this.fillPeriod();
                this.fillCourseDescription();
                this.fillSubjectType();
                this.fillLanguage();
                this.fillDuration();
                this.fillEngName();

                break;
            }
          } else {
            switch (this.config.education_level_id) {
              case '3':
              case '4':
                this.path = [...this.path, 'name', 'subjectType', 'final'];
                this.callbacks = [...this.callbacks, 'setName', null, 'addElements'];
                this.ableToEditPaths = ['name', this.config.item.is_parent_blocked ? null : 'subjectType'];
                this.fillName();
                this.fillSubjectType();

                break;
              default:
                this.path = [...this.path, 'name', 'subjectType', 'duration', 'final'];
                this.optionalDuration = true;
                this.callbacks = [...this.callbacks, 'setName', null, 'setDuration', 'addElements'];
                this.ableToEditPaths = ['name', this.config.item.is_parent_blocked ? null : 'subjectType', 'duration'];
                this.fillName();
                this.fillSubjectType();
                this.fillDuration();

                break;
            }
          }

          break;
        case '00000000016':
          if (this.studyPlan) {
            switch (this.config.education_level_id) {
              case '3':
              case '4':
              case '5':
              case '6':
                this.path = [...this.path, 'name', 'engName', 'period', 'subjectType', 'language', 'duration', 'final'];
                this.callbacks = [...this.callbacks, 'setName', 'setEngName', null, null, null, 'setDuration', 'addElements'];
                this.ableToEditPaths = [...this.path];
                this.fillName();
                this.fillEngName();
                this.fillPeriod();
                this.fillSubjectType();
                this.fillLanguage();
                this.fillDuration();

                break;
            }
          } else {
            switch (this.config.education_level_id) {
              case '3':
              case '4':
                this.path = [...this.path, 'name', 'engName', 'subjectType', 'language', 'final'];
                this.callbacks = [...this.callbacks, 'setName', 'setEngName', null, null, 'addElements'];
                this.ableToEditPaths = _compact(['name', this.config.item.is_parent_blocked ? null : 'subjectType', 'language']);
                this.fillName();
                this.fillEngName();
                this.fillSubjectType();
                this.fillLanguage();

                break;
              default:
                this.path = [...this.path, 'name', 'engName', 'subjectType', 'language', 'duration', 'final'];
                this.callbacks = [...this.callbacks, 'setName', 'setEngName', null, null, 'setDuration', 'addElements'];
                this.ableToEditPaths = _compact(['name', this.config.item.is_parent_blocked ? null : 'subjectType', 'language']);
                this.fillName();
                this.fillEngName();
                this.fillSubjectType();
                this.fillLanguage();
                this.fillDuration();

                break;
            }
          }

          break;
        case '00000000013':
        case '00000000018':
          if (this.studyPlan) {
            switch (this.config.education_level_id) {
              case '3':
              case '4':
              case '5':
              case '6':
                this.path = [...this.path, 'period', 'name', 'engName', 'subjectType', 'language', 'duration', 'final'];
                this.callbacks = [...this.callbacks, null, 'setName', 'setEngName', null, null, 'setDuration', 'addElements'];
                this.ableToEditPaths = [...this.path];
                this.fillPeriod();
                this.fillName();
                this.fillEngName();
                this.fillSubjectType();
                this.fillLanguage();
                this.fillDuration();

                break;
            }
          } else {
            switch (this.config.education_level_id) {
              case '3':
              case '4':
                this.path = [...this.path, 'name', 'engName', 'subjectType', 'final'];
                this.callbacks = [...this.callbacks, 'setName', 'setEngName', null, 'addElements'];
                this.fillName();
                this.fillEngName();
                this.fillSubjectType();
                this.ableToEditPaths = _compact(['name', 'engName', this.config.item.is_parent_blocked ? null : 'subjectType']);

                break;
              default:
                this.path = [...this.path, 'name', 'engName', 'subjectType', 'duration', 'final'];
                this.optionalDuration = true;
                this.callbacks = [...this.callbacks, 'setName', 'setEngName', null, 'setDuration', 'addElements'];
                this.fillName();
                this.fillEngName();
                this.fillSubjectType();
                this.fillDuration();
                this.ableToEditPaths = _compact(['name', 'duration', 'subjectType']);

                break;
            }
          }

          break;
        case '00000000015':
          await this.getPracticeTypes();
          await this.getPracticePlaceTypes();
          this.fillPracticeType();
          const practiceType = _find<any>({ id: this.progress.practiceType.value })(this.practiceTypes);

          if (this.studyPlan) {
            switch (this.config.education_level_id) {
              case '6':
                this.fillPeriod();
                if (practiceType.practice_form_id === '1') {
                  this.path = [...this.path, 'period', 'practiceType', 'practicePlaceType', 'subjectType', 'practiceForm', 'language', 'final'];
                  this.callbacks = [...this.callbacks, null, null, null, null, null, null, 'addElements'];
                  this.ableToEditPaths = [...this.path];
                  this.fillPracticePlaceType();
                } else {
                  this.path = [...this.path, 'period', 'practiceType', 'subjectType', 'practiceForm', 'language', 'final'];
                  this.callbacks = [...this.callbacks, null, null, null, null, null, 'addElements'];
                  this.ableToEditPaths = [...this.path];
                }
                this.fillSubjectType();
                this.fillPracticeForm();
                this.fillLanguage();

                break;
              case '3':
              case '4':
              case '5':
                this.fillPeriod();
                this.fillDuration();
                if (practiceType && practiceType.practice_form_id === '1') {
                  this.path = [...this.path, 'period', 'duration', 'practiceType', 'practicePlaceType', 'subjectType', 'practiceForm', 'language', 'final'];
                  this.callbacks = [...this.callbacks, null, 'setDuration', null, null, null, null, null, 'addElements'];
                  this.ableToEditPaths = [...this.path];
                  this.fillPracticePlaceType();
                } else {
                  this.path = [...this.path, 'period', 'duration', 'practiceType', 'subjectType', 'practiceForm', 'language', 'final'];
                  this.callbacks = [...this.callbacks, null, 'setDuration', null, null, null, null, 'addElements'];
                  this.ableToEditPaths = [...this.path];
                }
                this.fillSubjectType();
                this.fillPracticeForm();
                this.fillLanguage();

                break;
            }
          } else {
            switch (this.config.education_level_id) {
              case '6':
                this.fillPeriod();
                this.fillDuration();
                if (practiceType.practice_form_id === '1') {
                  this.path = [...this.path, 'period', 'practiceType', 'practicePlaceType', 'subjectType', 'practiceForm', 'language', 'final'];
                  this.callbacks = [...this.callbacks, null, null, null, null, null, null, 'addElements'];
                  this.ableToEditPaths = [...this.path];
                  this.fillPracticePlaceType();
                } else {
                  this.path = [...this.path, 'period', 'practiceType', 'subjectType', 'practiceForm', 'language', 'final'];
                  this.callbacks = [...this.callbacks, null, null, null, null, null, 'addElements'];
                  this.ableToEditPaths = [...this.path];
                }
                this.fillSubjectType();
                this.fillPracticeForm();
                this.fillLanguage();

                break;
              case '3':
              case '4':
                this.path = [...this.path, 'practiceType', 'subjectType', 'final'];
                this.callbacks = [...this.callbacks, null, null, 'addElements'];
                this.ableToEditPaths = _compact(['practiceType', this.config.item.is_parent_blocked ? null : 'subjectType']);
                this.fillSubjectType();

                break;
              default:
                this.path = [...this.path, 'practiceType', 'subjectType', 'final'];
                this.optionalDuration = true;
                this.callbacks = [...this.callbacks, null, null, 'addElements'];
                this.ableToEditPaths = _compact(['practiceType', this.config.item.is_parent_blocked ? null : 'subjectType']);
                this.fillPracticeType();
                this.fillSubjectType();

                break;
            }
          }

          break;
        case '00000000005':
          if (!Boolean(this.config.item.source_id)) {
            this.disciplineSearch
              .pipe(debounceTime(500))
              .pipe(distinctUntilChanged())
              .subscribe((resp) => {
                this.disciplineFilter.search_string = resp;
                this.getDisciplines(this.disciplineFilter);
              });
            this.path = [...this.path, 'period', 'fromCatalogue', 'catalogueDiscipline', 'language', 'duration', 'subjectType'];
            this.callbacks = [...this.callbacks, null, null, null, null, 'setDuration', null];
            if (this.studyPlan && Boolean(this.subjectAreas.length)) {
              this.path = [...this.path, 'subjectAreas'];
              this.callbacks = [...this.callbacks, 'setSubjectAreas'];

              this.fillSubjectAreas();
            }
            if (this.studyPlan && Boolean(this.config.specializations.length)) {
              this.path = [...this.path, 'specialization', 'final'];
              this.callbacks = [...this.callbacks, null, 'addElements'];
              this.fillSpecialization();
            } else {
              this.path.push('final');
              this.callbacks.push('addElements');
            }

            this.fillPeriod();
            this.fillFromCatalogue();
            this.fillCatalogueDiscipline();
            this.fillLanguage();
            this.fillDuration();
            this.fillSubjectType();

            this.ableToEditPaths = [...this.path];
          } else {
            this.path = [...this.path, 'period', 'fromCatalogue', 'subjectType', 'planDiscipline', 'language', 'duration', 'subjectType'];
            this.callbacks = [...this.callbacks, null, null, null, 'addPlanDisciplines', null, 'setDuration', null];
            if (this.studyPlan && Boolean(this.subjectAreas.length)) {
              this.path = [...this.path, 'subjectAreas'];
              this.callbacks = [...this.callbacks, 'setSubjectAreas'];
              this.ableToEditPaths = ['subjectAreas'];

              this.fillSubjectAreas();
            }
            if (this.studyPlan && Boolean(this.config.specializations.length)) {
              this.path = [...this.path, 'specialization', 'final'];
              this.callbacks = [...this.callbacks, null, 'addElements'];
              this.ableToEditPaths = [...this.ableToEditPaths, 'specialization'];
              this.fillSpecialization();
            } else {
              this.path.push('final');
              this.callbacks.push('addElements');
            }

            this.fillPeriod();
            this.fillFromCatalogue();
            this.fillPlanDiscipline();
            this.fillLanguage();
            this.fillDuration();
            this.fillSubjectType();

            this.ableToEditPaths = [...this.ableToEditPaths, 'subjectType'];
          }

          break;
        case '00000000004':
          if (!Boolean(this.config.item.source_id)) {
            this.disciplineSearch
              .pipe(debounceTime(500))
              .pipe(distinctUntilChanged())
              .subscribe((resp) => {
                this.disciplineFilter.search_string = resp;
                this.getDisciplines(this.disciplineFilter);
              });
            this.path = [...this.path, 'period', 'fromCatalogue', 'catalogueDiscipline', 'language', 'duration', 'subjectType'];
            this.callbacks = [...this.callbacks, null, null, null, null, 'setDuration', null];

            if (this.studyPlan && Boolean(this.subjectAreas.length)) {
              this.path = [...this.path, 'subjectAreas'];
              this.callbacks = [...this.callbacks, 'setSubjectAreas'];

              this.fillSubjectAreas();
            }

            if (this.studyPlan && Boolean(this.config.specializations.length)) {
              this.path = [...this.path, 'specialization', 'final'];
              this.callbacks = [...this.callbacks, null, 'addElements'];
              this.fillSpecialization();
            } else {
              this.path.push('final');
              this.callbacks.push('addElements');
            }

            this.fillPeriod();
            this.fillFromCatalogue();
            this.fillCatalogueDiscipline();
            this.fillLanguage();
            this.fillDuration();
            this.fillSubjectType();

            this.ableToEditPaths = [...this.path];
          } else {
            this.path = [...this.path, 'period', 'fromCatalogue', 'subjectType', 'planDiscipline', 'language', 'duration', 'subjectType'];
            this.callbacks = [...this.callbacks, null, null, null, 'addPlanDisciplines', null, 'setDuration', null];

            if (this.studyPlan && Boolean(this.subjectAreas.length)) {
              this.path = [...this.path, 'subjectAreas'];
              this.callbacks = [...this.callbacks, 'setSubjectAreas'];
              this.ableToEditPaths = ['subjectAreas'];

              this.fillSubjectAreas();
            }

            if (this.studyPlan && Boolean(this.config.specializations.length)) {
              this.path = [...this.path, 'specialization', 'final'];
              this.callbacks = [...this.callbacks, null, 'addElements'];
              this.ableToEditPaths = ['specialization'];
              this.fillSpecialization();
            } else {
              this.path.push('final');
              this.callbacks.push('addElements');
            }

            this.fillPeriod();
            this.fillFromCatalogue();
            this.fillPlanDiscipline();
            this.fillLanguage();
            this.fillDuration();
            this.fillSubjectType();

            this.ableToEditPaths = [...this.ableToEditPaths, 'subjectType'];
          }

          break;
        case '00000000014':
          if (this.studyPlan) {
            switch (this.config.education_level_id) {
              case '3':
              case '4':
              case '5':
              case '6':
                this.path = [...this.path, 'period', 'subjectType', 'language', 'name', 'engName', 'duration', 'final'];
                this.callbacks = [...this.callbacks, null, null, null, 'setName', 'setEngName', 'setDuration', 'addElements'];
                this.ableToEditPaths = [...this.path];
                this.fillLanguage();
                this.fillPeriod();
                this.fillSubjectType();
                this.fillName();
                this.fillEngName();
                this.fillDuration();

                break;
            }
          } else {
            switch (this.config.education_level_id) {
              case '3':
              case '4':
                this.path = [...this.path, 'subjectType', 'name', 'engName', 'language', 'final'];
                this.callbacks = [...this.callbacks, null, 'setName', 'setEngName', null, 'addElements'];
                this.ableToEditPaths = _compact(['name', 'engName', 'language', this.config.item.is_parent_blocked ? null : 'subjectType']);

                this.fillName();
                this.fillEngName();
                this.fillLanguage();
                this.fillSubjectType();

                break;
              default:
                this.path = [...this.path, 'subjectType', 'name', 'engName', 'language', 'duration', 'final'];
                this.optionalDuration = true;
                this.callbacks = [...this.callbacks, null, 'setName', 'setEngName', null, 'setDuration', 'addElements'];
                this.ableToEditPaths = [...this.path];

                this.fillName();
                this.fillEngName();
                this.fillLanguage();
                this.fillDuration();
                this.fillSubjectType();

                break;
            }

          }

          break;
        case '00000000025':
          if (this.studyPlan) {
            switch (this.config.education_level_id) {
              default:
                this.path = [...this.path, 'name', 'engName', 'period', 'duration', 'subjectType', 'final'];
                this.callbacks = [...this.callbacks, 'setName', 'setEngName', null, 'setDuration', null, 'addElements'];
                this.ableToEditPaths = [...this.path];

                this.fillName();
                this.fillEngName();
                this.fillPeriod();
                this.fillDuration();
                this.fillSubjectType();

                break;
            }
          } else {
            switch (this.config.education_level_id) {
              default:
                this.path = [...this.path, 'name', 'engName', 'duration', 'language', 'subjectType', 'final'];
                this.callbacks = [...this.callbacks, 'setName', 'setEngName', 'setDuration', null, null, 'addElements'];
                this.ableToEditPaths = [...this.path];

                this.fillName();
                this.fillEngName();
                this.fillDuration();
                this.fillLanguage();
                this.fillSubjectType();

                break;
            }
          }
          break;
        case '00000000023':
          await this.getPracticeTypes();
          await this.getPracticePlaceTypes();
          if (this.studyPlan) {
            switch (this.config.education_level_id) {
              case '3':
              case '4':

                break;
              default:
                this.path = [...this.path, 'subjectType', 'period', 'name', 'practiceForm', 'practicePlaceType', 'duration', 'final'];
                this.callbacks = [...this.callbacks, null, null, 'setName', null, 'setDuration', 'addElements'];
                this.ableToEditPaths = [...this.path];

                this.fillName();
                this.fillPracticeForm();
                this.fillDuration();
                this.fillPeriod();
                this.fillSubjectType();

                break;
            }
          } else {
            switch (this.config.education_level_id) {
              case '3':
              case '4':

                break;
              default:
                this.path = [...this.path, 'subjectType', 'name', 'practiceForm', 'duration', 'final'];
                this.callbacks = [...this.callbacks, null, 'setName', null, 'setDuration', 'addElements'];
                this.ableToEditPaths = [...this.path];

                this.fillName();
                this.fillPracticeForm();
                this.fillDuration();
                this.fillSubjectType();

                break;
            }
          }

          break;
        case '00000000024':
        case '00000000029':
          if (this.studyPlan) {
            switch (this.config.education_level_id) {
              case '3':
              case '4':

                break;
              default:
                this.path = [...this.path, 'name', 'period', 'duration', 'subjectType', 'final'];
                this.callbacks = [...this.callbacks, 'setName', null, 'setDuration', null, 'addElements'];
                this.ableToEditPaths = [...this.path];

                this.fillName();
                this.fillPeriod();
                this.fillDuration();
                this.fillSubjectType();

                break;
            }
          } else {
            switch (this.config.education_level_id) {
              case '3':
              case '4':

                break;
              default:
                this.path = [...this.path, 'name', 'duration', 'final'];
                this.callbacks = [...this.callbacks, 'setName', 'setDuration', 'addElements'];
                this.ableToEditPaths = [...this.path];

                this.fillName();
                this.fillDuration();

                break;
            }
          }

          break;
        case '00000000021':
          if (this.studyPlan) {
            switch (this.config.education_level_id) {
              case '6':
                this.path = [...this.path, 'name', 'engName', 'period', 'subjectType', 'final'];
                this.callbacks = [...this.callbacks, 'setName', 'setEngName', null, null, 'addElements'];
                this.ableToEditPaths = [...this.path];

                this.fillName();
                this.fillEngName();
                this.fillPeriod();
                this.fillSubjectType();

                break;
              case '3':
              case '4':

                break;
              default:
                this.path = [...this.path, 'name', 'engName', 'period', 'duration', 'subjectType', 'final'];
                this.callbacks = [...this.callbacks, 'setName', 'setEngName', null, 'setDuration', null, 'addElements'];
                this.ableToEditPaths = [...this.path];

                this.fillName();
                this.fillEngName();
                this.fillPeriod();
                this.fillDuration();
                this.fillSubjectType();

                break;
            }
          } else {
            switch (this.config.education_level_id) {
              case '6':
                this.path = [...this.path, 'name', 'engName', 'period', 'subjectType', 'final'];
                this.callbacks = [...this.callbacks, 'setName', 'setEngName', null, null, 'addElements'];
                this.ableToEditPaths = [...this.path];

                this.fillName();
                this.fillEngName();
                this.fillPeriod();
                this.fillDuration();
                this.fillSubjectType();

                break;
              case '3':
              case '4':

                break;
              default:
                this.path = [...this.path, 'name', 'engName', 'duration', 'final'];
                this.callbacks = [...this.callbacks, 'setName', 'setEngName', 'setDuration', 'addElements'];
                this.ableToEditPaths = [...this.path];

                this.fillName();
                this.fillEngName();
                this.fillDuration();

                break;
            }
          }

          break;
        case '00000000020':
          if (this.studyPlan) {
            switch (this.config.education_level_id) {
              case '6':
                this.path = [...this.path, 'name', 'engName', 'period', 'subjectType', 'final'];
                this.callbacks = [...this.callbacks, 'setName', 'setEngName', null, null, 'addElements'];
                this.ableToEditPaths = [...this.path];

                this.fillName();
                this.fillEngName();
                this.fillPeriod();
                this.fillDuration();
                this.fillSubjectType();

                break;
              case '3':
              case '4':

                break;
              default:
                this.path = [...this.path, 'name', 'engName', 'period', 'duration', 'subjectType', 'final'];
                this.callbacks = [...this.callbacks, 'setName', 'setEngName', null, 'setDuration', null, 'addElements'];
                this.ableToEditPaths = [...this.path];

                this.fillName();
                this.fillEngName();
                this.fillPeriod();
                this.fillDuration();
                this.fillSubjectType();

                break;
            }
          } else {
            switch (this.config.education_level_id) {
              case '6':
                this.path = [...this.path, 'name', 'engName', 'period', 'subjectType', 'final'];
                this.callbacks = [...this.callbacks, 'setName', 'setEngName', null, null, 'addElements'];
                this.ableToEditPaths = [...this.path];

                this.fillName();
                this.fillEngName();
                this.fillPeriod();
                this.fillDuration();
                this.fillSubjectType();

                break;
              case '3':
              case '4':

                break;
              default:
                this.path = [...this.path, 'name', 'engName', 'duration', 'final'];
                this.callbacks = [...this.callbacks, 'setName', 'setEngName', 'setDuration', 'addElements'];
                this.ableToEditPaths = [...this.path];

                this.fillName();
                this.fillEngName();
                this.fillDuration();

                break;
            }
          }

          break;
        case '00000000022':
          if (this.studyPlan) {
            switch (this.config.education_level_id) {
              case '3':
              case '4':

                break;
              default:
                this.path = [...this.path, 'name', 'engName', 'period', 'duration', 'subjectType', 'final'];
                this.callbacks = [...this.callbacks, 'setName', 'setEngName', null, 'setDuration', null, 'addElements'];
                this.ableToEditPaths = [...this.path];

                this.fillName();
                this.fillEngName();
                this.fillPeriod();
                this.fillDuration();
                this.fillSubjectType();

                break;
            }
          } else {
            switch (this.config.education_level_id) {
              case '3':
              case '4':

                break;
              default:
                this.path = [...this.path, 'name', 'engName', 'duration', 'final'];
                this.callbacks = [...this.callbacks, 'setName', 'setEngName', 'setDuration', 'addElements'];
                this.ableToEditPaths = [...this.path];

                this.fillName();
                this.fillEngName();
                this.fillDuration();

                break;
            }
          }

          break;
        default:
          if (this.studyPlan) {
            switch (this.config.education_level_id) {
              case '3':
              case '4':

                break;
              default:
                this.path = [...this.path, 'name', 'period', 'duration', 'subjectType', 'final'];
                this.callbacks = [...this.callbacks, 'setName', null, 'setDuration', null, 'addElements'];
                this.ableToEditPaths = [...this.path];

                this.fillName();
                this.fillPeriod();
                this.fillDuration();
                this.fillSubjectType();

                break;
            }
          } else {
            switch (this.config.education_level_id) {
              case '3':
              case '4':

                break;
              default:
                this.path = [...this.path, 'name', 'duration', 'final'];
                this.callbacks = [...this.callbacks, 'setName', 'setDuration', 'addElements'];
                this.ableToEditPaths = [...this.path];

                this.fillName();
                this.fillDuration();

                break;
            }
          }

          break;
      }

      this.ableToEditPaths = _reject((item: string) => _includes(item)(['type', 'fromCatalogue']))(this.ableToEditPaths);
      this.setProperDurationCallback();

      this.updatePassedItems();
      this.goToIteration('final');
    } catch (e) {
      console.error(e);
    }
  }

  startFilling({ name, id }) {
    if (this.change) {
      return;
    }
    this.progress.type.value = id;
    this.progress.type.label = name;
    this.progress.type.passed = true;
    switch (this.progress.type.value) {
      case '00000000005':
      case '00000000004':
        if (this.studyPlan) {
          switch (this.config.education_level_id) {
            case '3':
            case '4':
            case '6':
            case '5':
              this.path = [...this.path, 'period', 'fromCatalogue'];
              this.callbacks = [...this.callbacks, null, null];

              break;
          }
        } else {
          switch (this.config.education_level_id) {
            case '3':
            case '4':
              this.path = [...this.path, 'fromCatalogue', 'catalogueDiscipline', 'language', 'duration', 'subjectType', 'final'];
              this.callbacks = [...this.callbacks, null, null, null, 'setDuration', null, 'addElements'];

              break;
            default:
              this.path = [...this.path, 'fromCatalogue', 'catalogueDiscipline', 'language', 'duration', 'subjectType', 'final'];
              this.callbacks = [...this.callbacks, null, null, null, 'setDuration', null, 'addElements'];

              break;
          }

          this.progress.fromCatalogue.value = true;
          this.progress.fromCatalogue.label = this.fromCatalogue[0].name;
          this.progress.fromCatalogue.passed = true;

          this.iterationIndex = 1;
        }

        break;
      case '00000000016':
        if (this.studyPlan) {
          switch (this.config.education_level_id) {
            case '3':
            case '4':
            case '5':
            case '6':
              this.path = [...this.path, 'name', 'engName', 'period', 'subjectType', 'language', 'duration', 'final'];
              this.callbacks = [...this.callbacks, 'setName', 'setEngName', null, null, null, 'setDuration', 'addElements'];

              break;
          }
        } else {
          switch (this.config.education_level_id) {
            case '3':
            case '4':
              this.path = [...this.path, 'name', 'engName', 'subjectType', 'language', 'final'];
              this.callbacks = [...this.callbacks, 'setName', 'setEngName', null, null, 'addElements'];

              break;
            default:
              this.path = [...this.path, 'name', 'engName', 'subjectType', 'language', 'duration', 'final'];
              this.callbacks = [...this.callbacks, 'setName', 'setEngName', null, null, 'setDuration', 'addElements'];

              break;
          }
        }

        break;
      case '00000000017':
        if (this.studyPlan) {
          switch (this.config.education_level_id) {
            case '3':
            case '4':
            case '5':
            case '6':
              this.path = [...this.path, 'period', 'courseDescription', 'subjectType', 'language', 'duration', 'final'];
              this.callbacks = [...this.callbacks, null, 'setCourse', null, null, 'setDuration', 'addElements'];

              break;
          }
        } else {
          switch (this.config.education_level_id) {
            case '3':
            case '4':
              this.path = [...this.path, 'name', 'subjectType', 'final'];
              this.callbacks = [...this.callbacks, 'setName', null, 'addElements'];

              break;
            default:
              this.path = [...this.path, 'name', 'subjectType', 'duration', 'final'];
              this.callbacks = [...this.callbacks, 'setName', null, 'setDuration', 'addElements'];
              this.optionalDuration = true;

              break;
          }
        }

        break;
      case '00000000013':
      case '00000000018':
        if (this.studyPlan) {
          switch (this.config.education_level_id) {
            case '3':
            case '4':
            case '5':
            case '6':
              this.path = [...this.path, 'period', 'name', 'engName', 'subjectType', 'language', 'duration', 'final'];
              this.callbacks = [...this.callbacks, null, 'setName', 'setEngName', null, null, 'setDuration', 'addElements'];

              break;
          }
        } else {
          switch (this.config.education_level_id) {
            case '3':
            case '4':
              this.path = [...this.path, 'name', 'engName', 'subjectType', 'final'];
              this.callbacks = [...this.callbacks, 'setName', 'setEngName', null, 'addElements'];

              break;
            default:
              this.path = [...this.path, 'name', 'engName', 'subjectType', 'duration', 'final'];
              this.callbacks = [...this.callbacks, 'setName', 'setEngName', null, 'setDuration', 'addElements'];
              this.optionalDuration = true;

              break;
          }
        }

        break;
      case '00000000015':
        if (this.studyPlan) {
          switch (this.config.education_level_id) {
            case '6':
              this.path = [...this.path, 'period', 'practiceType', 'practicePlaceType', 'subjectType', 'practiceForm', 'language', 'final'];
              this.callbacks = [...this.callbacks, null, null, null, null, null, null, 'addElements'];

              break;
            case '3':
            case '4':
            case '5':
              this.path = [...this.path, 'period', 'duration', 'practiceType', 'practicePlaceType', 'subjectType', 'practiceForm', 'language', 'final'];
              this.callbacks = [...this.callbacks, null, 'setDuration', null, null, null, null, null, 'addElements'];

              break;
          }
        } else {
          switch (this.config.education_level_id) {
            case '3':
            case '4':
              this.path = [...this.path, 'practiceType', 'subjectType', 'final'];
              this.callbacks = [...this.callbacks, null, null, 'addElements'];

              break;
            default:
              this.path = [...this.path, 'practiceType', 'subjectType', 'final'];
              this.callbacks = [...this.callbacks, null, null, 'addElements'];

              break;
          }
        }

        break;
      case '00000000014':
        if (this.studyPlan) {
          switch (this.config.education_level_id) {
            case '3':
            case '4':
            case '5':
            case '6':
              this.path = [...this.path, 'period', 'language', 'subjectType', 'name', 'engName', 'duration', 'final'];
              this.callbacks = [...this.callbacks, null, null, null, 'setName', 'setEngName', 'setDuration', 'addElements'];

              break;
          }
        } else {
          switch (this.config.education_level_id) {
            case '3':
            case '4':
              this.path = [...this.path, 'language', 'subjectType', 'name', 'engName', 'final'];
              this.callbacks = [...this.callbacks, null, null, 'setName', 'addElements'];

              break;
            default:
              this.path = [...this.path, 'language', 'subjectType', 'name', 'engName', 'duration', 'final'];
              this.callbacks = [...this.callbacks, null, null, 'setName', 'setDuration', 'addElements'];
              this.optionalDuration = true;

              break;
          }
        }
        this.progress.type.passed = false;

        break;
      case '00000000020':
        if (this.studyPlan) {
          switch (this.config.education_level_id) {
            default:
              this.path = [...this.path, 'name', 'engName', 'period', 'duration', 'final'];
              this.callbacks = [...this.callbacks, 'setName', 'setEngName', null, 'setDuration', 'addElements'];

              break;
          }
        } else {
          switch (this.config.education_level_id) {
            default:
              this.path = [...this.path, 'name', 'engName', 'duration', 'final'];
              this.callbacks = [...this.callbacks, 'setName', 'setEngName', 'setDuration', 'addElements'];

              break;
          }
        }

        break;
      case '00000000025':
        if (this.studyPlan) {
          switch (this.config.education_level_id) {
            default:
              this.path = [...this.path, 'name', 'engName', 'period', 'duration', 'subjectType', 'final'];
              this.callbacks = [...this.callbacks, 'setName', 'setEngName', null, 'setDuration', null, 'addElements'];

              break;
          }
        } else {
          switch (this.config.education_level_id) {
            default:
              this.path = [...this.path, 'name', 'engName', 'duration', 'language', 'subjectType', 'final'];
              this.callbacks = [...this.callbacks, 'setName', 'setEngName', 'setDuration', null, null, 'addElements'];

              break;
          }
        }
        break;
      case '00000000022':
      case '00000000021':
        if (this.studyPlan) {
          switch (this.config.education_level_id) {
            case '6':
              this.path = [...this.path, 'name', 'engName', 'period', 'subjectType', 'final'];
              this.callbacks = [...this.callbacks, 'setName', 'setEngName', null, null, 'addElements'];

              break;
            default:
              this.path = [...this.path, 'name', 'engName', 'period', 'duration', 'subjectType', 'final'];
              this.callbacks = [...this.callbacks, 'setName', 'setEngName', null, 'setDuration', null, 'addElements'];

              break;
          }
        } else {
          switch (this.config.education_level_id) {
            default:
              this.path = [...this.path, 'name', 'engName', 'duration', 'final'];
              this.callbacks = [...this.callbacks, 'setName', 'setEngName', 'setDuration', 'addElements'];

              break;
          }
        }

        break;
      case '00000000024':
      case '00000000029':
        if (this.studyPlan) {
          switch (this.config.education_level_id) {
            default:
              this.path = [...this.path, 'name', 'period', 'duration', 'subjectType', 'final'];
              this.callbacks = [...this.callbacks, 'setName', null, 'setDuration', null, 'addElements'];

              break;
          }
        } else {
          switch (this.config.education_level_id) {
            default:
              this.path = [...this.path, 'name', 'duration', 'final'];
              this.callbacks = [...this.callbacks, 'setName', 'setDuration', 'addElements'];

              break;
          }
        }

        break;
      case '00000000023':
        if (this.studyPlan) {
          switch (this.config.education_level_id) {
            default:
              this.path = [...this.path, 'name', 'period', 'subjectType', 'practiceType', 'duration', 'final'];
              this.callbacks = [...this.callbacks, 'setName', null, null, null, 'setDuration', 'addElements'];

              break;
          }
        } else {
          switch (this.config.education_level_id) {
            default:
              this.path = [...this.path, 'name', 'subjectType', 'practiceType', 'duration', 'final'];
              this.callbacks = [...this.callbacks, 'setName', null, null, 'setDuration', 'addElements'];

              break;
          }
        }

        break;
      default:
        if (this.studyPlan) {
          switch (this.config.education_level_id) {
            default:
              this.path = [...this.path, 'name', 'period', 'duration', 'final'];
              this.callbacks = [...this.callbacks, 'setName', null, 'setDuration', 'addElements'];

              break;
          }
        } else {
          switch (this.config.education_level_id) {
            default:
              this.path = [...this.path, 'name', 'duration', 'final'];
              this.callbacks = [...this.callbacks, 'setName', 'setDuration', 'addElements'];

              break;
          }
        }

        break;
    }

    this.updatePassedItems();
    this.nextIteration();
  }

  async loadData() {
    try {
      this.learnPeriods = await this.catalogueService.getLearnPeriods({ from_learn_year: 2020 });
      _forEach((area: any) => {
        area.selected = false;
      })(this.subjectAreas);
      this.elementTypes = await this.catalogueService.getElementTypes();
      const elementTypeIds = Boolean(this.config.properties.elementTypes.length) ? _map('id')(this.config.properties.elementTypes) : [this.config.item.elementTypeId];
      this.elementTypes = _filter<ElementType>((type: ElementType) => _includes(type.id)(elementTypeIds) && type.subject_class_id !== '6')(this.elementTypes);
      await this.setYears();

      if (this.change) {
        await this.fillToChange();

        return;
      }

      if (!this.edit) {
        if (this.elementTypes.length === 1) {
          this.startFilling({ name: this.elementTypes[0].name, id: this.elementTypes[0].id });
        }
      } else {
        await this.fillToEdit();
      }
    } catch (e) {
      console.error(e);
    }
  }

  async getSubjectTypes() {
    try {
      this.subjectTypes = await this.catalogueService.getSubjectType();
    } catch (e) {
      console.error(e);
    }
  }

  async getPracticePlaceTypes() {
    try {
      this.practicePlaceTypes = await this.catalogueService.getPracticePlaceType();
    } catch (e) {
      console.error(e);
    }
  }

  async getPracticeTypes() {
    try {
      const params: any = {};
      if (['3', '4'].indexOf(this.config.education_level_id) > -1 && this.config.practice_profile_id) {
        params.practice_profile_id = this.config.practice_profile_id;
      }
      this.practiceTypes = await this.catalogueService.getPracticeType(params);
      this.filterPracticeTypes('');
    } catch (e) {
      console.error(e);
    }
  }

  async getLanguages() {
    try {
      this.languages = await this.catalogueService.getLanguages();
      // if (Boolean(this.languages[0].id)) {
      //   this.languages.unshift({
      //     name: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.NOT-SET-1'),
      //     asav_uid: null,
      //     created_at: null,
      //     deleted_at: null,
      //     description: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.NOT-SET-1'),
      //     id: null,
      //     is_archive: false,
      //     selected: false,
      //     updated_at: null
      //   });
      // }
    } catch (e) {
      console.error(e);
    }
  }

  async loadMoreDisciplines() {
    try {
      this.disciplineFilter.page_num++;

      if (this.disciplineFilter.page_num > this.totalDisciplinesPages) {
        return;
      }

      await this.getDisciplines(this.disciplineFilter, true);
    } catch (e) {
      console.error(e);
    }
  }

  async loadMoreDepartments() {
    try {
      this.departmentFilter.page_num++;

      if (this.departmentFilter.page_num > this.totalDepartmentsPages) {
        return;
      }

      await this.getDepartments(this.departmentFilter, true);
    } catch (e) {
      console.error(e);
    }
  }

  async getDisciplines(params, more = false) {
    try {
      const response = await this.catalogueService.getDisciplines(params);

      this.totalDisciplinesPages = Number(response.headers.get('totalpages'));

      if (!more) {
        this.disciplines = response.body;
        if (this.progress.type.value === '00000000017') {
          this.disciplines.unshift({
            id: null,
            description: null,
            asav_uid: null,
            code: null,
            full_name: null,
            is_archive: null,
            name: this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.NOT-SET-2'),
            subject_category: null,
            time_created: null,
            time_modified: null,
            time_deleted: null
          });
        }
      } else {
        this.disciplines = [...this.disciplines, ...response.body];
      }
    } catch (e) {
      console.error(e);
    }
  }

  async getDepartments(params, more = false) {
    try {
      const response = await this.catalogueService.getDepartments(params);

      this.totalDepartmentsPages = Number(response.headers.get('totalpages'));

      const departments = _map(($department: any) => {
        if (!$department.department_unit_creation_date && !$department.department_unit_closing_date) {
          $department.fullDescription = $department.description;
        } else if (!$department.department_unit_creation_date && $department.department_unit_closing_date) {
          $department.fullDescription = `${$department.description} (нет - ${moment($department.department_unit_closing_date, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY')})`;
        } else if ($department.department_unit_creation_date && !$department.department_unit_closing_date) {
          $department.fullDescription = `${$department.description} (${moment($department.department_unit_creation_date, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY')})`;
        } else if ($department.department_unit_creation_date && $department.department_unit_closing_date) {
          const descriptionParts = [
            `${$department.description}`,
            '(',
            `${moment($department.department_unit_creation_date, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY')}`,
            '-',
            `${moment($department.department_unit_closing_date, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY')}`,
            ')'
          ];
          $department.fullDescription = descriptionParts.join(' ');
        }

        return $department;
      })(response.body);

      if (!more) {
        this.departments = departments;
      } else {
        this.departments = [...this.departments, ...departments];
      }

      this.departments = _map<DepartmentExtended, DepartmentExtended>((item: DepartmentExtended) => {
        item.hovered = false;

        return item;
      })(this.departments);
      setTimeout(() => {
        this.setDepartmentHeights();
      }, 0);
      setTimeout(() => {
        this.setDepartmentTableHeight();
      }, 0);
    } catch (e) {
      console.error(e);
    }
  }

  async getDisciplinePlan() {
    try {
      let params: any = {};
      const commonParams: any = {
        begin_year_id: this.progress.period.start,
        end_year_id: this.progress.period.end,
        is_archive: false
      };

      if (this.studyPlan) {
        commonParams.lp_begin_year_id = this.config.lp_begin_year_id;
        commonParams.rstplan_type_id = this.config.rstplan_type_id;
        commonParams.learn_programm_id = this.config.$studyPlan.learn_program_id;
        commonParams.learn_program_version_id = this.config.$studyPlan.learn_program_version_id;
        commonParams.learn_period_type_id = this.config.learn_period_type_id;
        commonParams.period_count = this.config.period_count;
      }

      switch (this.config.module.name) {
        case 'Major':
        case 'ДОЦ':
        case 'Английский язык':
          params = {
            ...commonParams,
            plan_type_id: 2,
            filial_id: this.progress.filial.value,
            department_id: this.progress.department.value,
            subject_class_id: _find<ElementType>({ id: this.progress.type.value })(this.elementTypes).subject_class_id,
            item_status_id: '3,4'
          };

          break;
        case 'Data Culture':
          params = {
            ...commonParams,
            plan_type_id: 2,
            filial_id: this.progress.filial.value,
            department_id: this.progress.department.value,
            subject_class_id: _find<ElementType>({ id: this.progress.type.value })(this.elementTypes).subject_class_id,
            item_status_id: '3,4'
          };

          if (!this.studyPlan) {
            params.data_culture_level_id = _map('data_culture_level_id')(this.config.module_structure.datacultures).join(',');
          }

          break;
        default:
          params = {
            ...commonParams,
            plan_type_id: 2,
            filial_id: this.progress.filial.value,
            department_id: this.progress.department.value,
            subject_class_id: _find<ElementType>({ id: this.progress.type.value })(this.elementTypes).subject_class_id,
            item_status_id: '3,4'
          };

          break;
      }

      params.custom_item_source_id = await this.getCustomItemSourceIds();

      const [planDisciplines, audienceReach] = await Promise.all([
        this.catalogueService.getDisciplinePlan(params),
        this.catalogueService.getAudienceReach()
      ]);

      this.planDisciplines = _flow([
        _filter((item: PlanDisciplineExtended) => Boolean(item.custom_plan_item_by_year.length)),
        _map<PlanDisciplineExtended, PlanDisciplineExtended>((item: PlanDisciplineExtended) => {
          const beginYear = _find<any>({ id: item.begin_year_id })(this.beginYears);
          const endYear = _find<any>({ id: item.end_year_id })(this.beginYears);

          item.audienceReachName = _flow(_find({ id: Number(item.audience_reach_id) }), _getOr('', 'description'))(audienceReach);
          item.invalid = Boolean(_find((itemByYear: any) => {
            return !Boolean(itemByYear.item_learn_year_id)
              || itemByYear.learn_year < beginYear.learn_year
              || itemByYear.learn_year > endYear.learn_year;
          })(item.custom_plan_item_by_year));

          item.selected = false;
          item.hovered = false;
          item.disabled = !item.custom_plan_item_by_year[0].is_excess;
          item.periodNumList = _flow([
            _filter((itemByYear: any) => Boolean(itemByYear.learn_year)),
            _map<CustomPlanItemBackend, string>((itemByYear: any) => {
              return `${itemByYear.learn_year}: модули ${itemByYear.period_num_list}`;
            })
          ])(item.custom_plan_item_by_year).join('\n');
          item.displayStudCount = `${item.custom_plan_item_by_year[0].stud_count}/${item.custom_plan_item_by_year[0].max_count}`;
          item.language = _map('language')(item.sbj_languages).join(', ');
          item.custom_item_source = _map('source')(item.sbj_sources).join(', ');

          return item;
        })
      ])(planDisciplines);
      this.filterPlanDisciplines(this.planDisciplineSearch, true);
    } catch (e) {
      console.error(e);
    }
  }

  async getCustomItemSourceIds() {
    const lpVersionResponse = await this.catalogueService.getLearnProgram4({
      id: this.config.$studyPlan.learn_program_version_id,
      detail_info: true
    });
    const lpVersion: any[] = _flow(_map('learn_programs'), _flatten)(lpVersionResponse);

    const customItemSourceIds = _flow(
      _map((lp: any) => {
        const sourceIds = [];

        switch (lp.cost_type_id) {
          case 1:
            sourceIds.push(1);
            break;
          case 2:
            sourceIds.push(3);
            break;
          case 3:
            sourceIds.push(1);
            sourceIds.push(3);
            break;
          default:
            sourceIds.push(null);
        }

        if (lp.is_network && !lp.is_base_org) {
          sourceIds.push(4);
        }

        return sourceIds;
      }),
      _flatten,
      _compact,
      _uniq
    )(lpVersion);

    return customItemSourceIds.join(',');
  }

  setDisciplineTableHeight() {
    this.disciplineTableHeight = _flow([
      _map((item: PlanDisciplineExtended) => item.height + 4),
      _sum
      // @ts-ignore
    ])(this.filteredPlanDisciplines) + 20;
  }

  setTableHeight(sample: HTMLElement): number {
    return Boolean(sample) ? sample.clientHeight + 50 : 50;
  }

  setDepartmentTableHeight() {
    this.departmentTableHeight = _flow([
      _map((item: PlanDisciplineExtended) => item.height + 4),
      _sum
      // @ts-ignore
    ])(this.departments) + 20;
  }

  validateDisciplineById(): void {
    const excess = (this.disciplineById.custom_plan_item_by_year[0].stud_count + Number(this.studCount || '0')) > this.disciplineById.custom_plan_item_by_year[0].max_count;
    const zeroStudCount = !Boolean(Number(this.studCount || '0'));
    this.disciplineById.disabled = !this.disciplineById.custom_plan_item_by_year[0].is_excess && (excess || zeroStudCount);
    this.disciplineById.exceed = !this.disciplineById.custom_plan_item_by_year[0].is_excess && excess;
  }

  mapPlanDisciplines(studCount: number): void {
    this.filteredPlanDisciplines = _map<PlanDisciplineExtended, PlanDisciplineExtended>((item: PlanDisciplineExtended) => {
      item.excess = (item.custom_plan_item_by_year[0].stud_count + Number(studCount || '0')) > item.custom_plan_item_by_year[0].max_count;
      item.zeroStudCount = !Boolean(Number(studCount || '0'));
      item.disabled = !item.custom_plan_item_by_year[0].is_excess && (item.excess || item.zeroStudCount);

      return item;
    })(this.filteredPlanDisciplines);
  }

  filterPlanDisciplines(search: string, first = false) {
    this.filteredPlanDisciplines = _filter<PlanDisciplineExtended>((item: PlanDisciplineExtended) => {
      if (this.planDisciplineFullMatch) {
        return item.name.toLowerCase() === search.toLowerCase();
      }

      return item.name.toLowerCase().includes(search.toLowerCase());
    })(this.planDisciplines);

    if (first) {
      setTimeout(() => {
        this.setPlanHeights();
      }, 0);
    }

    setTimeout(() => {
      this.setDisciplineTableHeight();
    }, 0);
  }

  async getFilials() {
    try {
      this.filials = await this.catalogueService.getFilials();
    } catch (e) {
      console.error(e);
    }
  }

  async setYears() {
    try {
      let params: any = {};

      if (!this.studyPlan) {
        params = { from_learn_year: 2020 };
      }

      let learnPeriods = await this.catalogueService.getLearnPeriods(params);

      if (this.studyPlan) {
        const startYear = Number(_find<any>({ id: this.config.startYearId })(learnPeriods).period_number);
        const endYear = Number(_find<any>({ id: this.config.endYearId })(learnPeriods).period_number);
        learnPeriods = _filter((year: any) => {
          return year.period_number >= startYear && year.period_number <= endYear;
        })(learnPeriods);
      }

      this.beginYears = _cloneDeep(learnPeriods);
      this.endYears = _cloneDeep(learnPeriods);
      this.disableYears(true);
    } catch (e) {
      console.error(e);
    }
  }

  disableYears(init = false) {
    if (init) {
      _forEach((year: any) => {
        if (this.studyPlan && !this.config.previousPeriods && Number(year.id) < Number(this.config.current_approval_year_id)) {
          year.disabled = true;

          return;
        }
        if (this.studyPlan && !this.config.previousPeriods && year.id === this.config.current_approval_year_id) {
          year.disabled = ['15', '16'].indexOf(this.config.plan_status_id) === -1;

          return;
        }
        year.disabled = false;
      })(this.beginYears);
      _forEach((year: any) => {
        year.disabled = true;
      })(this.endYears);
    }

    if (this.progress.period.start) {
      const start = _find<any>({ id: this.progress.period.start })(this.beginYears);
      _forEach((year: any) => {
        year.disabled = year.period_number < start.period_number;
      })(this.endYears);
    }
  }

  setProjectDescription() {
    this.progress[this.path[this.iterationIndex]].label = `Проект. ${this.practiceTypeDescription}`;
    this.progress[this.path[this.iterationIndex]].description = this.practiceTypeDescription;
    this.progress[this.path[this.iterationIndex]].value = '89';

    this.setValue();
  }

  selectValue({ title, value }) {
    if (this.change || !this.studyPlan) {
      if (['type', 'fromCatalogue'].indexOf(this.path[this.iterationIndex]) > -1) {
        return;
      }
    }
    this.progress[this.path[this.iterationIndex]].label = title;
    this.progress[this.path[this.iterationIndex]].description = title;
    this.progress[this.path[this.iterationIndex]].value = value;

    if (this.path[this.iterationIndex] === 'practiceType' && value === '89') {
      return;
    }

    if (this.path[this.iterationIndex] === 'fromCatalogue' && !value) {
      // this.path = [...this.path, 'subjectType', 'filial', 'department', 'planDiscipline', 'subjectType'];
      // this.callbacks = [...this.callbacks, null, 'setFilial', null, 'addPlanDisciplines', null];
      this.path = ['type', 'period', 'fromCatalogue', 'subjectType', 'searchById'];
      this.callbacks = [null, null, null, null, 'searchById'];

      // if (['00000000004', '00000000005'].indexOf(this.progress.type.value) > -1 && this.config.specializations.length) {
      //   this.path.push('specialization');
      //   this.callbacks.push(null);
      // }
    }

    if (this.path[this.iterationIndex] === 'fromCatalogue' && value) {
      this.path = [...this.path, 'catalogueDiscipline', 'language', 'duration', 'subjectType'];
      this.callbacks = [...this.callbacks, null, null, 'setDuration', null];

      if (['00000000004', '00000000005'].indexOf(this.progress.type.value) > -1 && Boolean(this.subjectAreas.length)) {
        this.path = [...this.path, 'subjectAreas'];
        this.callbacks = [...this.callbacks, 'setSubjectAreas'];
      }

      if (['00000000004', '00000000005'].indexOf(this.progress.type.value) > -1 && this.config.specializations.length) {
        this.path.push('specialization');
        this.callbacks.push(null);
      }

      this.path = [...this.path, 'final'];
      this.callbacks = [...this.callbacks, 'addElements'];
    }

    if (this.path[this.iterationIndex] === 'department') {
      this.planDisciplineFullMatch = false;
      this.planDisciplineSearch = '';
    }

    this.setValue();
  }

  selectLanguage($event, item) {
    item.selected = !item.selected;

    const {name, id} = item;
    const currentIteration = this.progress[this.path[this.iterationIndex]];
    const currentIx = currentIteration.value.indexOf(id);
    if (currentIx === -1) {
      currentIteration.value.push(id);
      currentIteration.label = currentIteration.label
        ? currentIteration.label.split(', ').concat(name).join(', ')
        : name;
    } else {
      const names = currentIteration.label.split(', ');
      currentIteration.value.splice(currentIx, 1);
      names.splice(currentIx, 1);
      currentIteration.label = names.join(', ');
    }

    currentIteration.description = currentIteration.label;
  }

  checkSubjectAreasDisable() {
    return this.config.baseDirectory && !Boolean(_find('selected')(this.subjectAreas));
  }

  setSubjectAreas(): void {
    if (this.checkSubjectAreasDisable()) {
      return;
    }
    const selectedAreas = _filter('selected')(this.subjectAreas);
    this.progress.subjectAreas.value = _map('subject_area_id')(selectedAreas);
    this.progress.subjectAreas.label = Boolean(selectedAreas.length) ? _map('description')(selectedAreas).join(', ') : this.translations.NOT_SET;
    this.progress.subjectAreas.passed = true;

    this.updatePassedItems();
    this.nextIteration();
  }

  selectLearnPeriod(year, begin = false) {
    if (year.disabled) {
      return;
    }

    if (begin) {
      this.progress.period.start = year.id;
      this.progress.period.end = null;
    } else {
      this.progress.period.end = year.id;
    }

    if (this.progress.period.start && this.progress.period.end) {
      this.progress.period.label = this.progress.period.start === this.progress.period.end
        ? _find<any>({ id: this.progress.period.start })(this.beginYears).description
        : [
          `${this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.YEARS')}`,
          `${_find<any>({ id: this.progress.period.start })(this.beginYears).description.split(' ')[0]}`,
          '-',
          `${_find<any>({ id: this.progress.period.end })(this.endYears).description.split(' ')[0]}`
        ].join(' ');

      this.creditsByYears = this.studyPlan && (this.progress.period.start !== this.progress.period.end);
      this.setProperDurationCallback();

      this.prepareCreditsByYears();

      this.setValue();
    }

    this.disableYears();
  }

  setProperDurationCallback() {
    const callbacks = [this.callbacks.indexOf('setYearsForCredits'), this.callbacks.indexOf('setDuration')];
    const index = _max(callbacks);

    if (index > -1) {
      this.callbacks.splice(index, 1, this.creditsByYears ? 'setYearsForCredits' : 'setDuration');
    }
  }

  prepareCreditsByYears() {
    if (!(Boolean(this.progress.period.start) && Boolean(this.progress.period.end))) {
      this.progress.duration.label = this.progress.duration.value;
      this.creditsByYears = false;
      this.setProperDurationCallback();

      return;
    }
    const start = _find<any>({ id: this.progress.period.start })(this.beginYears);
    const end = _find<any>({ id: this.progress.period.end })(this.beginYears);

    this.progress.duration.yearsForCredits = _flow([
      _filter((year: any) => {
        return year.learn_year >= start.learn_year && year.learn_year <= end.learn_year;
      }),
      _map((year: any) => {
        year.credits = '';
        year.label = year.name.split(' ')[0];
        year.invalid = false;

        return year;
      })
    ])(this.beginYears);

    this.creditsByYears = (this.disableCreditsButton = this.studyPlan && this.progress.duration.yearsForCredits.length > 1);
    this.setProperDurationCallback();
    if (!this.creditsByYears) {
      this.progress.duration.label = this.progress.duration.value;
    }
  }

  setYearsForCredits() {
    if (this.disableCreditsButton) {
      return;
    }
    this.progress.duration.passed = true;
    this.progress.duration.label = `Всего кредитов - ${this.progress.duration.value}\n${_map((year: any) => {
      return `${year.label} - ${year.credits}`;
    })(this.progress.duration.yearsForCredits).join('\n')}`;

    this.updatePassedItems();
    this.nextIteration();
  }

  addPlanDisciplines() {
    if (this.disablePlanDisciplines()) {
      return;
    }

    this.addElements();
  }

  setFilial() {
    if (this.checkFilialsDisable()) {
      return;
    }

    const filial = _find<FilialItem>('selected')(this.filials);

    this.updateDepartments = true;
    this.departmentFilter.filial_id = filial.id;

    this.progress.filial.label = filial.name;
    this.progress.filial.value = filial.id;
    this.progress.filial.passed = true;
    this.updatePassedItems();
    this.nextIteration();
  }

  setValue() {
    this.progress[this.path[this.iterationIndex]].passed = true;

    this.updatePassedItems();
    this.nextIteration();
  }

  updatePassedItems() {
    this.passedItems = [];

    for (const key of Object.keys(this.progress)) {
      if (!(this.config.education_level_id === '5' && key === 'specialization') && this.progress[key].passed) {
        this.passedItems.push({
          title: this.progress[key].title,
          value: this.progress[key].label,
          key
        });
      }
    }
  }

  addElements() {
    const elementType: ElementType = _find<ElementType>({ id: this.progress.type.value })(this.elementTypes);

    if (this.studyPlan && !(['00000000004', '00000000005'].indexOf(elementType.id) > -1 && !this.progress.fromCatalogue.value)
      && (['00000000015', '00000000021'].indexOf(elementType.id) === -1 && this.config.education_level_id !== '6' && this.disableCreditsButton)
    ) {
      this.alert.error(this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.ALERT-1'));

      return;
    }

    let discipline: any = {};
    const creditsByYear = this.studyPlan
      ? this.progress.period.start === this.progress.period.end
        ? [{
          year_id: this.progress.period.start,
          credits: this.progress.duration.value,
          basic_entire_hours: Number(this.progress.duration.value) * 38
        }]
        : _map((year: any) => {
          return {
            year_id: year.id,
            credits: year.credits,
            basic_entire_hours: Number(year.credits) * 38
          };
        })(this.progress.duration.yearsForCredits)
      : null;

    switch (elementType.id) {
      case '00000000005':
        if (this.progress.fromCatalogue.value) {
          discipline = {
            structure_id: _find<EducationStandardStructureBackend>({ element_type_id: elementType.id })(this.config.structures).id,
            element_type_id: elementType.id,
            custom_plan_item: [
              {
                subject_class_id: elementType.subject_class_id,
                parent_id: this.config.properties.parent.id,
                id: this.edit ? this.config.item.id : null,
                subject_areas: this.progress.subjectAreas.value || [],
                subject_type_id: this.progress.subjectType.value,
                basic_credits: Number(this.progress.duration.value),
                basic_entire_hours: Number(this.progress.duration.value) * 38,
                credits_by_year: creditsByYear,
                begin_year_id: this.progress.period.start,
                end_year_id: this.progress.period.end,
                sbj_languages: this.progress.language.value /*.join(',')*/,
                dict_university_subject_id: this.progress.catalogueDiscipline.value,
                specialization_id: this.progress.specialization.value || null
              }
            ]
          };
        } else {
          let customPlanItem;

          if (this.edit) {
            customPlanItem = [{
              stud_count: Number(this.studCount || '0'),
              subject_class_id: elementType.subject_class_id,
              description: this.config.item.description,
              name: this.config.item.description,
              subject_areas: this.progress.subjectAreas.value,
              subject_type_id: this.progress.subjectType.value,
              parent_id: this.config.properties.parent.id,
              source_id: this.progress.planDiscipline.value,
              id: this.config.item.id,
              specialization_id: this.progress.specialization.value || null
            }];
          } else if (Boolean(this.progress.searchById.value)) {
            customPlanItem = [{
              stud_count: Number(this.studCount || '0'),
              subject_class_id: elementType.subject_class_id,
              description: this.disciplineById.name,
              subject_areas: this.progress.subjectAreas.value,
              subject_type_id: this.progress.subjectType.value,
              parent_id: this.config.properties.parent.id,
              source_id: this.disciplineById.id,
              specialization_id: this.progress.specialization.value || null
            }];
          } else {
            customPlanItem = _flow([
              _filter('selected'),
              _map((item: PlanDisciplineExtended) => {
                return {
                  stud_count: Number(this.studCount || '0'),
                  subject_class_id: elementType.subject_class_id,
                  description: item.description,
                  subject_areas: this.progress.subjectAreas.value,
                  name: item.description,
                  subject_type_id: this.progress.subjectType.value,
                  parent_id: this.config.properties.parent.id,
                  source_id: item.id,
                  specialization_id: this.progress.specialization.value || null
                };
              })
            ])(this.planDisciplines);
          }

          discipline = {
            structure_id: _find<EducationStandardStructureBackend>({ element_type_id: elementType.id })(this.config.structures).id,
            element_type_id: elementType.id,
            custom_plan_item: customPlanItem
          };
        }

        break;
      case '00000000004':
        if (this.progress.fromCatalogue.value) {
          discipline = {
            structure_id: _find<EducationStandardStructureBackend>({ element_type_id: elementType.id })(this.config.structures).id,
            element_type_id: elementType.id,
            custom_plan_item: [
              {
                subject_class_id: elementType.subject_class_id,
                parent_id: this.config.properties.parent.id,
                id: this.edit ? this.config.item.id : null,
                subject_type_id: this.progress.subjectType.value,
                basic_credits: Number(this.progress.duration.value),
                basic_entire_hours: Number(this.progress.duration.value) * 38,
                credits_by_year: creditsByYear,
                begin_year_id: this.progress.period.start,
                end_year_id: this.progress.period.end,
                sbj_languages: this.progress.language.value /*.join(',')*/,
                dict_university_subject_id: this.progress.catalogueDiscipline.value,
                specialization_id: this.progress.specialization.value || null,
                subject_areas: this.progress.subjectAreas.value
              }
            ]
          };
        } else {
          let customPlanItem;

          if (this.edit) {
            customPlanItem = [{
              stud_count: Number(this.studCount || '0'),
              subject_class_id: elementType.subject_class_id,
              description: this.config.item.description,
              name: this.config.item.description,
              subject_areas: this.progress.subjectAreas.value,
              subject_type_id: this.progress.subjectType.value,
              parent_id: this.config.properties.parent.id,
              source_id: this.progress.planDiscipline.value,
              id: this.config.item.id,
              specialization_id: this.progress.specialization.value || null
            }];
          } else if (Boolean(this.progress.searchById.value)) {
            customPlanItem = [{
              stud_count: Number(this.studCount || '0'),
              subject_class_id: elementType.subject_class_id,
              description: this.disciplineById.name,
              name: this.disciplineById.name,
              subject_areas: this.progress.subjectAreas.value,
              subject_type_id: this.progress.subjectType.value,
              parent_id: this.config.properties.parent.id,
              source_id: this.disciplineById.id,
              specialization_id: this.progress.specialization.value || null
            }];
          } else {
            customPlanItem = _flow([
              _filter('selected'),
              _map((item: PlanDisciplineExtended) => {
                return {
                  stud_count: Number(this.studCount || '0'),
                  subject_class_id: elementType.subject_class_id,
                  description: item.description,
                  subject_areas: this.progress.subjectAreas.value,
                  name: item.description,
                  subject_type_id: this.progress.subjectType.value,
                  parent_id: this.config.properties.parent.id,
                  source_id: item.id,
                  specialization_id: this.progress.specialization.value || null
                };
              })
            ])(this.planDisciplines);
          }

          discipline = {
            structure_id: _find<EducationStandardStructureBackend>({ element_type_id: elementType.id })(this.config.structures).id,
            element_type_id: elementType.id,
            custom_plan_item: customPlanItem
          };
        }

        break;
      case '00000000015':
        discipline = {
          structure_id: _find<EducationStandardStructureBackend>({ element_type_id: elementType.id })(this.config.structures).id,
          element_type_id: elementType.id,
          custom_plan_item: [
            {
              subject_class_id: elementType.subject_class_id,
              parent_id: this.config.properties.parent.id,
              id: this.edit ? this.config.item.id : null,
              description: this.progress.practiceType.description,
              eng_description: this.progress.practiceType.eng_description,
              eng_name: this.progress.practiceType.eng_description,
              name: this.progress.practiceType.description,
              credits_by_year: this.config.education_level_id === '6' ? _map((year: any) => {
                year.credits = 0;
                year.basic_entire_hours = 0;

                return year;
              })(creditsByYear) : creditsByYear,
              practice_type_id: this.progress.practiceType.value,
              practice_profile_id: this.config.properties.parent.practice_profile_id,
              practice_place_type_id: this.studyPlan ? this.progress.practicePlaceType.value || '1' : null,
              practice_form: this.studyPlan ? this.progress.practiceForm.value : null,
              basic_credits: this.studyPlan ? (this.config.education_level_id === '6' ? 0 : this.progress.duration.value) : null,
              basic_entire_hours: this.studyPlan ? (this.config.education_level_id === '6' ? 0 : Number(this.progress.duration.value) * 38) : null,
              subject_type_id: this.progress.subjectType.value,
              sbj_languages: this.studyPlan ? this.progress.language.value : null,
              begin_year_id: this.progress.period.start,
              end_year_id: this.progress.period.end,
            }
          ]
        };

        break;
      case '00000000017':
        discipline = {
          structure_id: _find<EducationStandardStructureBackend>({ element_type_id: elementType.id })(this.config.structures).id,
          element_type_id: elementType.id,
          custom_plan_item: [
            {
              subject_class_id: elementType.subject_class_id,
              parent_id: this.config.properties.parent.id,
              credits_by_year: creditsByYear,
              basic_credits: this.studyPlan ? this.progress.duration.value : null,
              basic_entire_hours: this.studyPlan ? Number(this.progress.duration.value) * 38 : null,
              sbj_languages: this.studyPlan ? this.progress.language.value : null,
              description: this.studyPlan ? this.progress.courseDescription.label : this.progress.name.value,
              eng_description: this.progress.engName.value,
              eng_name: this.progress.engName.value,
              name: this.studyPlan ? this.progress.courseDescription.label : this.progress.name.value,
              dict_university_subject_id: this.progress.courseDescription.value,
              id: this.edit ? this.config.item.id : null,
              subject_type_id: this.progress.subjectType.value,
              practice_profile_id: this.config.properties.parent.practice_profile_id,
              begin_year_id: this.progress.period.start,
              end_year_id: this.progress.period.end,
            }
          ]
        };

        if (this.progress.duration.passed) {
          discipline.custom_plan_item[0].basic_credits = this.progress.duration.value;
        }

        break;
      case '00000000016':
        discipline = {
          structure_id: _find<EducationStandardStructureBackend>({ element_type_id: elementType.id })(this.config.structures).id,
          element_type_id: elementType.id,
          custom_plan_item: [
            {
              subject_class_id: elementType.subject_class_id,
              parent_id: this.config.properties.parent.id,
              description: this.progress.name.value,
              eng_description: this.progress.engName.value,
              eng_name: this.progress.engName.value,
              name: this.progress.name.value,
              credits_by_year: creditsByYear,
              sbj_languages: this.progress.language.value /*.join(',')*/,
              id: this.edit ? this.config.item.id : null,
              subject_type_id: this.progress.subjectType.value,
              basic_credits: this.progress.duration.value,
              basic_entire_hours: Number(this.progress.duration.value) * 38,
              begin_year_id: this.progress.period.start,
              end_year_id: this.progress.period.end,
            }
          ]
        };

        break;
      case '00000000014':
        discipline = {
          structure_id: _find<EducationStandardStructureBackend>({ element_type_id: elementType.id })(this.config.structures).id,
          custom_plan_item: [
            {
              subject_class_id: elementType.subject_class_id,
              parent_id: this.config.properties.parent.id,
              sbj_languages: this.progress.language.value /*.join(',')*/,
              begin_year_id: this.progress.period.start,
              end_year_id: this.progress.period.end,
              id: this.edit ? this.config.item.id : null,
              subject_type_id: this.progress.subjectType.value,
              description: this.progress.name.value,
              eng_description: this.progress.engName.value,
              eng_name: this.progress.engName.value,
              name: this.progress.name.value,
              credits_by_year: creditsByYear,
              basic_credits: this.progress.duration.value,
              basic_entire_hours: Number(this.progress.duration.value) * 38
            }
          ]
        };

        break;
      case '00000000013':
      case '00000000018':
        discipline = {
          structure_id: _find<EducationStandardStructureBackend>({ element_type_id: elementType.id })(this.config.structures).id,
          element_type_id: elementType.id,
          custom_plan_item: [
            {
              subject_class_id: elementType.subject_class_id,
              parent_id: this.config.properties.parent.id,
              description: this.progress.name.value,
              eng_description: this.progress.engName.value,
              eng_name: this.progress.engName.value,
              name: this.progress.name.value,
              credits_by_year: creditsByYear,
              basic_credits: this.studyPlan ? this.progress.duration.value : null,
              basic_entire_hours: this.studyPlan ? Number(this.progress.duration.value) * 38 : null,
              sbj_languages: this.studyPlan ? this.progress.language.value : null,
              id: this.edit ? this.config.item.id : null,
              subject_type_id: this.progress.subjectType.value,
              practice_profile_id: this.config.properties.parent.practice_profile_id,
              begin_year_id: this.progress.period.start,
              end_year_id: this.progress.period.end,
            }
          ]
        };

        if (this.progress.duration.passed) {
          discipline.custom_plan_item[0].basic_credits = this.progress.duration.value;
        }

        break;

      case '00000000025':
        discipline = {
          structure_id: _find<EducationStandardStructureBackend>({ element_type_id: elementType.id })(this.config.structures).id,
          element_type_id: elementType.id,
          custom_plan_item: [
            {
              subject_class_id: elementType.subject_class_id,
              parent_id: this.config.properties.parent.id,
              description: this.progress.name.value,
              eng_description: this.progress.engName.value,
              eng_name: this.progress.engName.value,
              name: this.progress.name.value,
              basic_credits: this.progress.duration.value,
              basic_entire_hours: Number(this.progress.duration.value) * 38,
              sbj_languages: this.progress.language.value /*.join(',')*/ || null,
              id: this.edit ? this.config.item.id : null,
              subject_type_id: this.progress.subjectType.value,
              begin_year_id: this.studyPlan ? this.progress.period.start : null,
              end_year_id: this.studyPlan ? this.progress.period.end : null,
              is_not_in_cost: true
            }
          ]
        };

        break;
      case '00000000020':
        discipline = {
          structure_id: _find<EducationStandardStructureBackend>({ element_type_id: elementType.id })(this.config.structures).id,
          element_type_id: elementType.id,
          custom_plan_item: [
            {
              subject_class_id: elementType.subject_class_id,
              parent_id: this.config.properties.parent.id,
              description: this.progress.name.value,
              eng_description: this.progress.engName.value,
              eng_name: this.progress.engName.value,
              name: this.progress.name.value,
              basic_credits: this.studyPlan ? this.progress.duration.value : null,
              basic_entire_hours: this.studyPlan ? Number(this.progress.duration.value) * 38 : null,
              sbj_languages: this.progress.language.value /*.join(',')*/ || null,
              id: this.edit ? this.config.item.id : null,
              subject_type_id: this.progress.subjectType.value,
              begin_year_id: this.studyPlan ? this.progress.period.start : null,
              end_year_id: this.studyPlan ? this.progress.period.end : null
            }
          ]
        };

        break;
      case '00000000022':
        discipline = {
          structure_id: _find<EducationStandardStructureBackend>({ element_type_id: elementType.id })(this.config.structures).id,
          element_type_id: elementType.id,
          custom_plan_item: [
            {
              subject_class_id: elementType.subject_class_id,
              parent_id: this.config.properties.parent.id,
              description: this.progress.name.value,
              eng_description: this.progress.engName.value,
              eng_name: this.progress.engName.value,
              name: this.progress.name.value,
              basic_credits: this.studyPlan ? this.progress.duration.value : null,
              basic_entire_hours: this.studyPlan ? Number(this.progress.duration.value) * 38 : null,
              sbj_languages: this.progress.language.value /*.join(',')*/ || null,
              id: this.edit ? this.config.item.id : null,
              subject_type_id: this.progress.subjectType.value,
              begin_year_id: this.studyPlan ? this.progress.period.start : null,
              end_year_id: this.studyPlan ? this.progress.period.end : null,
              is_not_in_cost: true
            }
          ]
        };

        break;
      case '00000000021':
        discipline = {
          structure_id: _find<EducationStandardStructureBackend>({ element_type_id: elementType.id })(this.config.structures).id,
          element_type_id: elementType.id,
          custom_plan_item: [
            {
              subject_class_id: elementType.subject_class_id,
              parent_id: this.config.properties.parent.id,
              description: this.progress.name.value,
              eng_description: this.progress.engName.value,
              eng_name: this.progress.engName.value,
              name: this.progress.name.value,
              basic_credits: this.studyPlan ? (this.config.education_level_id === '6' ? 0 : this.progress.duration.value) : null,
              basic_entire_hours: this.studyPlan ? (this.config.education_level_id === '6' ? 0 : Number(this.progress.duration.value) * 38) : null,
              sbj_languages: this.progress.language.value /*.join(',')*/ || null,
              id: this.edit ? this.config.item.id : null,
              subject_type_id: this.progress.subjectType.value,
              begin_year_id: this.studyPlan ? this.progress.period.start : null,
              end_year_id: this.studyPlan ? this.progress.period.end : null
            }
          ]
        };

        break;
      case '00000000024':
        discipline = {
          structure_id: _find<EducationStandardStructureBackend>({ element_type_id: elementType.id })(this.config.structures).id,
          element_type_id: elementType.id,
          custom_plan_item: [
            {
              subject_class_id: elementType.subject_class_id,
              parent_id: this.config.properties.parent.id,
              description: this.progress.name.value,
              name: this.progress.name.value,
              basic_credits: this.studyPlan ? this.progress.duration.value : null,
              basic_entire_hours: this.studyPlan ? Number(this.progress.duration.value) * 38 : null,
              sbj_languages: this.progress.language.value /*.join(',')*/ || null,
              id: this.edit ? this.config.item.id : null,
              subject_type_id: this.progress.subjectType.value,
              begin_year_id: this.studyPlan ? this.progress.period.start : null,
              end_year_id: this.studyPlan ? this.progress.period.end : null
            }
          ]
        };

        break;
      case '00000000023':
        discipline = {
          structure_id: _find<EducationStandardStructureBackend>({ element_type_id: elementType.id })(this.config.structures).id,
          element_type_id: elementType.id,
          custom_plan_item: [
            {
              subject_class_id: elementType.subject_class_id,
              practice_type_id: this.progress.practiceType.value,
              parent_id: this.config.properties.parent.id,
              description: this.progress.name.value,
              name: this.progress.name.value,
              basic_credits: this.progress.duration.value,
              basic_entire_hours: Number(this.progress.duration.value) * 38,
              id: this.edit ? this.config.item.id : null,
              subject_type_id: this.progress.subjectType.value,
              begin_year_id: this.studyPlan ? this.progress.period.start : null,
              end_year_id: this.studyPlan ? this.progress.period.end : null
            }
          ]
        };

        break;
      case '00000000029':
        discipline = {
          structure_id: _find<EducationStandardStructureBackend>({ element_type_id: elementType.id })(this.config.structures).id,
          element_type_id: elementType.id,
          custom_plan_item: [
            {
              subject_class_id: elementType.subject_class_id,
              parent_id: this.config.properties.parent.id,
              description: this.progress.name.value,
              name: this.progress.name.value,
              basic_credits: this.studyPlan ? this.progress.duration.value : null,
              basic_entire_hours: this.studyPlan ? Number(this.progress.duration.value) * 38 : null,
              sbj_languages: this.progress.language.value /*.join(',')*/ || null,
              id: this.edit ? this.config.item.id : null,
              subject_type_id: this.progress.subjectType.value,
              begin_year_id: this.studyPlan ? this.progress.period.start : null,
              end_year_id: this.studyPlan ? this.progress.period.end : null
            }
          ]
        };

        break;
      default:
        discipline = {
          structure_id: _find<EducationStandardStructureBackend>({ element_type_id: elementType.id })(this.config.structures).id,
          element_type_id: elementType.id,
          custom_plan_item: [
            {
              subject_class_id: elementType.subject_class_id,
              parent_id: this.config.properties.parent.id,
              description: this.progress.name.value,
              name: this.progress.name.value,
              basic_credits: this.studyPlan ? this.progress.duration.value : null,
              basic_entire_hours: this.studyPlan ? Number(this.progress.duration.value) * 38 : null,
              sbj_languages: this.progress.language.value /*.join(',')*/ || null,
              id: this.edit ? this.config.item.id : null,
              subject_type_id: this.progress.subjectType.value,
              begin_year_id: this.studyPlan ? this.progress.period.start : null,
              end_year_id: this.studyPlan ? this.progress.period.end : null
            }
          ]
        };

        break;
    }

    if (this.studyPlan) {
      discipline.learn_plan_id = this.config.standart_id;
    } else {
      discipline.standart_id = this.config.standart_id;
    }

    this.close.emit({ discipline });
  }

  reset(keys) {
    for (const key of keys) {
      if (key === 'final') {
        return;
      }
      this.progress[key].passed = false;
      this.progress[key].value = null;
      this.progress[key].label = null;
    }
  }

  filterPracticeTypes(event) {
    this.filteredPracticeTypes = _filter((type: PracticeType) => type.name.toLowerCase().includes(event.toLowerCase()))(this.practiceTypes);
  }

  filterDepartments(event) {
    this.departmentSearch
      .next(event);
  }

  filterDisciplines(event) {
    this.disciplineSearch
      .next(event);
  }

  checkFilialsDisable() {
    return !Boolean(_find('selected')(this.filials));
  }

  select(option: any, key: string) {
    this[key] = _map((item: any) => {
      item.selected = false;

      return item;
    })(this[key]);

    option.selected = true;
  }

  abortPropagation(event) {
    event.stopPropagation();
  }

  disablePlanDisciplines() {
    return !Boolean(this.chips.length) || Boolean(_find((discipline: PlanDisciplineExtended) => {
      return discipline.selected && (discipline.invalid || discipline.disabled);
    })(this.planDisciplines));
  }

  setName() {
    if (!this.progress.name.value) {
      return;
    }
    this.progress.name.label = this.progress.name.value;
    this.progress.name.passed = true;

    this.updatePassedItems();
    this.nextIteration();
  }

  setEngName() {
    if (!this.progress.engName.value) {
      return;
    }
    this.progress.engName.label = this.progress.engName.value;
    this.progress.engName.passed = true;

    this.updatePassedItems();
    this.nextIteration();
  }

  setDuration() {
    if (!this.progress.duration.value) {
      return;
    }
    this.progress.duration.label = this.progress.duration.value;
    this.progress.duration.passed = true;

    this.updatePassedItems();
    this.nextIteration();
  }

  setCourse() {
    if (!this.progress.courseDescription.label && !this.progress.engName.value) {
      return;
    }
    this.progress.courseDescription.passed = true;
    this.progress.engName.passed = true;
    this.progress.engName.label = this.progress.engName.value;
    this.updatePassedItems();
    this.nextIteration();
  }

  selectCourse({ title, value, engName }) {
    if (!Boolean(value)) {
      this.progress.courseDescription.value = value;
      this.progress.courseDescription.label = '';
      this.progress.engName.value = '';

      return;
    }
    this.progress.courseDescription.label = `Курсовая работа по дисциплине ${title}`;
    this.progress.engName.value = engName;
    this.progress.courseDescription.value = value;
  }

  checkDiscipline(item: PlanDisciplineExtended, checkbox = false) {
    if (item.disabled) {
      return;
    }

    if (!checkbox) {
      item.selected = !item.selected;
    }

    if (item.invalid && item.selected) {
      this.alert.error(`${this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.ALERT-2-1')} "${item.name}" ${this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.MISC.ALERT-2-2')}`);
    }

    this.chips = _filter<PlanDisciplineExtended>('selected')(this.planDisciplines);

    this.progress.planDiscipline.buttonLabel = this.chips.length
      ? `${this.chips.length} ${_getNumEndings(this.endings)(this.chips.length)}`
      : '';

    this.progress.planDiscipline.label = _map('name')(this.chips).join(', ');
  }

  calculateMargin() {
    const passedNumber = this.passedItems.length;

    // @ts-ignore
    this.margin = 216 - 16 * passedNumber;
  }

  nextIteration() {
    if (this.edit) {
      this.goToIteration('final');

      return;
    }
    this.iterationIndex++;

    this.updateCollections();

    this.checkAvailabilityToShift();
    this.calculateMargin();
  }

  updateCollections() {
    if (['catalogueDiscipline', 'courseDescription'].indexOf(this.path[this.iterationIndex]) > -1 && !this.edit) {
      this.disciplineSearch
        .pipe(debounceTime(500))
        .pipe(distinctUntilChanged())
        .subscribe((resp) => {
          this.disciplineFilter.search_string = resp;
          this.disciplineFilter.page_num = 1;
          this.getDisciplines(this.disciplineFilter);
        });
    }

    if (this.path[this.iterationIndex] === 'department') {
      this.departmentSearch
        .pipe(debounceTime(500))
        .pipe(distinctUntilChanged())
        .subscribe((resp) => {
          this.departmentFilter.search_string = resp;
          this.departmentFilter.page_num = 1;
          this.getDepartments(this.departmentFilter);
        });
    }

    if (this.path[this.iterationIndex] === 'language') {
      this.getLanguages();
    }

    if (this.path[this.iterationIndex] === 'practiceType') {
      this.getPracticeTypes();
    }

    if (this.path[this.iterationIndex] === 'subjectType') {
      this.getSubjectTypes();
      this.progress.subjectType.title = this.translate.instant('EDUCATIONAL-STANDARD.STRUCTURE.ELEMENT-TYPE') + ':';
    }

    if (this.path[this.iterationIndex] === 'practicePlaceType') {
      this.getPracticePlaceTypes();
    }

    if (this.path[this.iterationIndex] === 'filial') {
      this.getFilials();
    }

    if (this.path[this.iterationIndex] === 'planDiscipline') {
      this.getDisciplinePlan();
    }
  }

  goBack() {
    if (!this.isAvailableToBack) {
      return;
    }

    this.goToIteration(this.path[this.iterationIndex - 1]);

    this.checkAvailabilityToShift();
  }

  goForward() {
    if (!this.isAvailableToForward) {
      return;
    }

    this.goToIteration(this.path[this.iterationIndex + 1]);

    this.checkAvailabilityToShift();
  }

  checkAvailabilityToShift() {
    this.isAvailableToBack = this.iterationIndex > 0;
    this.isAvailableToForward = this.iterationIndex < this.path.length - 1 && this.progress[this.path[this.iterationIndex]].passed;
  }

  onClose() {
    this.close.emit({ disciplines: null, level: null });
  }

  goToIteration(key: string) {
    const keys = [];
    if (['00000000004', '00000000005'].indexOf(this.progress.type.value) > -1) {
      if (key === 'fromCatalogue' && !this.change) {
        for (let i = 2; i < this.path.length; i++) {
          keys.push(this.path[i]);
        }
        if (this.studyPlan) {
          this.path = ['type', 'period', 'fromCatalogue'];
          this.callbacks = [null, null, null];
        } else {
          this.path = ['type', 'fromCatalogue'];
          this.callbacks = [null, null];
        }
        this.reset(keys);
        this.updatePassedItems();
      }

      if (key === 'searchById') {
        this.reset(['filial', 'department', 'planDiscipline']);
        this.updatePassedItems();
      }
    }
    if (key === 'type' && !this.change) {
      for (const item of this.path) {
        keys.push(item);
      }
      this.reset(keys);
      this.updatePassedItems();
      this.path = ['type'];
      this.callbacks = [null];
    }
    if (this.progress.type.value === '00000000017' && key === 'engName') {
      this.iterationIndex = this.path.indexOf('courseDescription');

      return;
    }
    this.iterationIndex = this.path.indexOf(key);
    if (key === 'department' && this.updateDepartments) {
      this.updateDepartments = false;
      this.departmentFilter.search_string = '';
      this.departmentSearch.next('');
    }
  }

  validateCredits() {
    const invalid = _flow([
      _map((item: any) => {
        return Number(item.credits);
      }),
      _sum
    ])(this.progress.duration.yearsForCredits) !== Number(this.progress.duration.value);
    _forEach((year: any) => {
      year.invalid = invalid || !Boolean(year.credits);
    })(this.progress.duration.yearsForCredits);

    this.disableCreditsButton = invalid || Boolean(_find('invalid')(this.progress.duration.yearsForCredits));
  }

  // changeSort(column) {
  //   if (column.name === this.tableSortConfig.sortColumn) {
  //     this.tableSortConfig.sortDirection = this.tableSortConfig.sortDirection === 'asc' ? 'desc' : 'asc';
  //   } else {
  //     this.tableSortConfig.sortDirection = 'asc';
  //   }
  //
  //   const isAsc = this.tableSortConfig.sortDirection === 'asc';
  //
  //   this.tableSortConfig.sortColumn = column.name;
  //
  //   this.filteredPlanDisciplines = _orderBy<any>(this.tableSortConfig.sortColumn, isAsc ? 'asc' : 'desc')(this.filteredPlanDisciplines);
  // }

  hoverItem(option: any) {
    option.hovered = true;
  }

  resetDisciplineHover() {
    _forEach((item: PlanDisciplineExtended) => {
      item.hovered = false;
    })(this.filteredPlanDisciplines);
  }

  resetDepartmentHover() {
    _forEach((item: DepartmentExtended) => {
      item.hovered = false;
    })(this.departments);
  }

  slideDisciplineTable(event: any) {
    this.disciplineTablePosition = -event.target.scrollLeft;
  }

  slideDepartmentTable(event: any) {
    this.departmentTablePosition = -event.target.scrollLeft;
  }

  selectPracticeType(option: any) {
    if (this.studyPlan) {
      if (option.formId === '1') {
        if (this.path.indexOf('practicePlaceType') < 0) {
          this.path.splice(4, 0, 'practicePlaceType');
          this.callbacks.splice(4, 0, null);
        }
      } else {
        if (this.path.indexOf('practicePlaceType') > -1) {
          this.progress.practicePlaceType.value = null;
          this.progress.practicePlaceType.label = null;
          this.progress.practicePlaceType.passed = false;
          this.path.splice(4, 1);
          this.callbacks.splice(4, 1);
        }
      }
    }

    this.progress.practiceType.eng_description = option.eng;
    this.selectValue({ title: option.title, value: option.value });
  }

  ngOnDestroy(): void {
    this.disciplineSearch.unsubscribe();
    this.departmentSearch.unsubscribe();
    this.enterSubscription.unsubscribe();
    this.resizeSubscription.unsubscribe();
  }

  checkInputParam($event: KeyboardEvent, value) {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ',', 'Backspace', 'Delete'];

    if (!_includes($event.key)(allowedKeys) || (['.', ','].includes($event.key) && !value)) {
      $event.preventDefault();

      return;
    }

    if (value) {
      if (['.', ','].includes($event.key) && (value.toString().indexOf('.') !== -1 || value.toString().indexOf(',') !== -1)) {
        $event.preventDefault();
      }

      if (value.toString().indexOf('.') !== -1) {
        if (value.toString().split('.')[1].length > 0) {
          $event.preventDefault();
        } else {
          if ($event.key !== '5') {
            $event.preventDefault();
          }
        }
      }

      if (value.toString().indexOf(',') !== -1) {
        if (value.toString().split(',')[1].length > 0) {
          $event.preventDefault();
        } else {
          if ($event.key !== '5') {
            $event.preventDefault();
          }
        }
      }
    }
  }
}
