import { Injectable } from '@angular/core';
import { CatalogueService } from '@backend/kosuip/catalogue/catalogue.service';
import { HseRadioGroup } from '@shared/components/common-ui/hse-radio-button-group/hse-radio-button-group.component';
import * as moment from 'moment';
import { filter as _filter, find as _find, flatten as _flatten, flow as _flow, includes as _includes, map as _map, uniq as _uniq, uniqBy as _uniqBy } from 'lodash/fp';
import { CustomPlanItemExtended } from '../educational-standards/components/educational-standard-structure/educational-standard-structure.component';
import { Subject } from 'rxjs';
import { UserProfileService } from '@backend/user-profile/user-profile.service';
import { EducationStandardStructureBackend } from '@backend/kosuip/catalogue/interfaces/education-standard-structure.interface';
import { ElementType } from '@backend/kosuip/catalogue/interfaces/element-type.interface';
import { LearnPeriod } from '@backend/kosuip/catalogue/interfaces/learn-period.interface';

@Injectable({
  providedIn: 'root'
})
export class AdditionalStudyPlanService {
  private readonly availableStatusIds = ['10', '15', '16', '22', '27', '33', '34', '99'];
  private readonly FILTER_PREFIX = 'additionalModuleFilters';

  public readonly learnPeriodTypes: HseRadioGroup = [
    { label: 'По модулям', value: '1' },
    { label: 'По семестрам', value: '2' },
  ];
  public readonly learnPeriodCounts = [
    { learn_period_type_id: '1', label: 3, value: 3 },
    { learn_period_type_id: '1', label: 4, value: 4 },
    { learn_period_type_id: '2', label: 1, value: 1 },
    { learn_period_type_id: '2', label: 2, value: 2 },
  ];
  public readonly BASIC_INFO_FIELDS = [
    'name', 'eng_name', 'additional_module_description', 'languages', 'department_id', 'begin_year_id', 'end_year_id', 'period_type_id', 'period_count'
  ];
  public readonly ADDITIONAL_MODULE_FIELDS = [
    'benefits', 'target_audience', 'period', 'credit', 'self_proportion', 'student_load', 'access_method', 'learning_technologies',
    'microdegree_name', 'microdegree_name_eng', 'microdegree_level_id', 'certification_description', 'teachers'
  ];
  public readonly MINOR_FIELDS = ['min_stud_count', 'max_stud_count', 'education_programs', 'microdegree_name', 'microdegree_name_eng', 'microdegree_level_id'];

  public readonly SUPERVISOR_ROLE_ID = 1;
  public readonly MANAGER_ROLE_ID = 2;
  public readonly CO_LEADER_ROLE_ID = 3;
  public readonly TEACHER_ROLE_ID = 4;
  public readonly ADDITIONAL_MODULE_PLAN_TYPE_ID = 11;
  public readonly MINOR_PLAN_TYPE_ID = 10;
  public readonly PARTIALLY_INCLUDED_IN_PLAN_ID = 3;
  public readonly planTypes = [
    { id: this.ADDITIONAL_MODULE_PLAN_TYPE_ID, name: 'Дополнительный модуль' },
    { id: this.MINOR_PLAN_TYPE_ID, name: 'Майнор' },
  ];

  public loadPlan: Subject<void> = new Subject<void>();
  // public loadData: Subject<void> = new Subject<void>();
  public changeEditFilter: Subject<any> = new Subject<any>();
  public resetCheckbox: Subject<any> = new Subject<any>();

  additionalModuleFieldsStatuses: boolean[] = [];
  basicFieldsStatuses: boolean[] = [];
  minorFieldsStatuses: boolean[] = [];
  planFillingInPercents = 0;
  selectedTab = 0;
  allPlansSelected = [];
  selectedRows = [];
  unselectedRows = [];

  filters = {
    planTypeId: null,
    departmentIds: [],
    learnPeriodIds: [],
    statusIds: [],
    description: '',
    complete_match: false,
    page: 1,
    perPage: 30,
    sort_direction: null,
    sort_by: null
  };

  additionalLearnPlan = null;
  additionalLearnPlans = [];
  additionalModuleRoles = [];
  annotations = [];
  audienceReach = [];
  controlTypes = [];
  costTypes = [];
  dataCulture = [];
  departments = [];
  distantSubject = [];
  elementTypes = [];
  faculties = [];
  filials = [];
  microdegreeLevels = [];
  languages = [];
  learnPeriods = [];
  learnProgram = [];
  rawElements = [];
  rawStructure = null;
  persons = [];
  ratio = [];
  statuses = [];
  studyAries = [];
  studyFormat = [];
  structure = null;
  subjectType = [];
  typesDoc = [];
  includedInPlanStates = [];
  planCampaign = [];
  teachers = [];

  learnPlanStructure = null;
  learnPlanStructureByYears = null;

  filtersLoaded = false;
  isOpened = true;
  totalPages = 0;

  isDepartmentManager = false;
  isTeacher = false;
  isFacultyDecan = false;
  isFilialDeputy = false;
  isProrecror = false;
  isAdmin = false;
  isUROP = false;
  isDOOP = false;
  isReadOnly = false;

  public filtersEdit = {
    departments: [],
    studyFormat: null,
    audienceReach: [],
    languages: [],
    learnPrograms: [],
  };


  constructor(
    private readonly catalogue: CatalogueService,
    private readonly userService: UserProfileService
  ) { }

  async loadInitialData() {
    try {
      const [departments, filials, learnPeriods, statuses] = await Promise.all([
        this.catalogue.getDepartments(),
        this.catalogue.getFilials(),
        this.catalogue.getLearnPeriods({ from_learn_year: 2020 }),
        this.catalogue.getPlanStatuses()
      ]);

      const [
        isTeacher, isFacultyDecan, isFilialDeputy, isProrecror, isAdmin, isUROP, isDOOP, isDepartmentManager, isReadOnly
      ] = await Promise.all([
        this.userService.isTeacher(),
        this.userService.isFacultyDecan(),
        this.userService.isFilialDeputy(),
        this.userService.isProrecror(),
        this.userService.isAdmin(),
        this.userService.isUROP(),
        this.userService.isDOOP(),
        this.userService.isDepartmentManager(),
        this.userService.isReadOnly()
      ]);

      this.filials = filials;
      this.departments = this.mapDepartments(departments.body);
      this.learnPeriods = learnPeriods;
      this.statuses = statuses.filter((status: any) => this.availableStatusIds.includes(status.id));
      this.isTeacher = isTeacher;
      this.isFacultyDecan = isFacultyDecan;
      this.isFilialDeputy = isFilialDeputy;
      this.isProrecror = isProrecror;
      this.isAdmin = isAdmin;
      this.isUROP = isUROP;
      this.isDOOP = isDOOP;
      this.isDepartmentManager = isDepartmentManager;
      this.isReadOnly = isReadOnly;

      this.filtersLoaded = true;

      this.planCampaign = await this.catalogue.getPlanCampaign({});

      if (this.isFacultyDecan) {
        const facultyIds = this.userService.currentProfile && this.userService.currentProfile.length
          ? this.userService.currentProfile.map((profile: any) => profile.Faculty_id)
          : [];
        this.departments = this.departments.filter((department: any) => facultyIds.includes(department.faculty_id));
      }

      if (this.isFilialDeputy) {
        const filialIds = this.userService.currentProfile && this.userService.currentProfile.length
          ? this.userService.currentProfile.map((profile: any) => profile.Filial_id)
          : [];
        this.departments = this.departments.filter((department: any) => filialIds.includes(department.filial_id));
      }

      if (this.isDepartmentManager) {
        const departmentIds = this.userService.currentProfile && this.userService.currentProfile.length
          ? this.userService.currentProfile.map((profile: any) => profile.Department_id)
          : [];
        this.departments = this.departments.filter((department: any) => departmentIds.includes(department.id));
      }

      this.setInitialFilterValues();

      await this.submitFilters();
    } catch (err) {
      console.error(err);
    }
  }

  async loadInitialDataForCreate(isEditMode) {
    try {
      const [
        languages, departments, persons, learnPeriods, costTypes, additionalModuleRoles, includedInPlanStates, microdegreeLevels, faculties, filials
      ] = await Promise.all([
        this.catalogue.getLanguages(),
        this.catalogue.getDepartments(!isEditMode ? { remove_archived: true } : null),
        this.catalogue.getPersons2({ sort_by: 'fio', sort_direction: 'asc', is_employee: true }),
        this.catalogue.getLearnPeriods({ from_learn_year: 2020 }),
        this.catalogue.getCostTypeAdditionalModule(),
        this.catalogue.getRoleInAdditionalModule(),
        this.catalogue.getIncludedInPlan(),
        this.catalogue.getMicrodegreeLevels(),
        this.catalogue.getFaculties(),
        this.catalogue.getFilials()
      ]);

      this.filials = filials;
      this.languages = languages;
      this.departments = this.mapDepartments(departments.body);
      this.persons = persons;
      this.learnPeriods = learnPeriods;
      this.costTypes = costTypes;
      this.additionalModuleRoles = additionalModuleRoles;
      this.includedInPlanStates = includedInPlanStates;
      this.microdegreeLevels = microdegreeLevels;
      this.faculties = faculties;

    } catch (err) {
      console.error(err);
    }
  }

  async loadAdditionalModule(id: number) {
    try {
      const response = await this.catalogue.getAdditionalLearnPlan({ id, detail_info: true });
      this.additionalLearnPlan = this.mapAdditionalLearnPlans(response.body).pop();
    } catch (err) {
      console.error(err);
    }
  }

  async loadLearnProgramsForMinor() {
    try {
      this.learnProgram = await this.catalogue.getLearnProgram4({ education_level_id: [3, 4].join(','), is_actual: true });
    } catch (err) {
      console.error(err);
    }
  }

  async loadTeachersForAdditionalModule() {
    try {
      this.teachers = await this.catalogue.getPersons2({ sort_by: 'fio', sort_direction: 'asc', category_id: '000000001'});
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * находим все структурые элементы и скачиваем их аннотации
   */
  async loadAnnotationsForStructureElements(): Promise<void> {
    const structureElementIds = _flow(
      _filter((item: any) => item.isStructureElement),
      _map('id')
    )(this.structure);

    try {
      this.annotations = structureElementIds.length
        ? await this.catalogue.getAnnotation({id: structureElementIds.join(',')})
        : [];
    } catch (err) {
      console.error(err);
    }
  }

  async submitFilters(page: number = 1) {
    this.filters.page = page;

    try {
      const params: any = {
        page_num: this.filters.page,
        page_limit: this.filters.perPage,
        remove_archived: true
      };

      if (this.filters.planTypeId) {
        if (this.filters.planTypeId === this.MINOR_PLAN_TYPE_ID) {
          params.is_minor = true;
        } else {
          params.is_for_other = true;
        }
      }

      if (this.filters.departmentIds.length) {
        params.department_id = this.filters.departmentIds.join(',');
      }

      if (this.filters.learnPeriodIds.length) {
        params.begin_year_id = this.filters.learnPeriodIds.join(',');
      }

      if (this.filters.statusIds.length) {
        params.plan_status_id = this.filters.statusIds.join(',');
      }

      if (this.filters.sort_by) {
        params.sort_direction = this.filters.sort_direction;
        params.sort_by = this.filters.sort_by;
      }

      if (this.filters.description) {
        params.description = this.filters.description;

        if (this.filters.complete_match) {
          params.complete_match = true;
        }
      }

      if (this.isTeacher) {
        params.user = this.userService.profileInfo && this.userService.profileInfo.length
          ? this.userService.profileInfo[0].main_user
          : this.userService.login;
      }

      if (this.isFacultyDecan && this.userService.currentProfile && this.userService.currentProfile.length) {
        const facultyIds = this.userService.currentProfile.map((profile: any) => profile.Faculty_id);
        params.faculty_id = facultyIds.join(',');
      }

      if (this.isFilialDeputy && this.userService.currentProfile && this.userService.currentProfile.length) {
        const filialIds = this.userService.currentProfile.map((profile: any) => profile.Filial_id);
        params.filial_id = filialIds.join(',');
      }

      if (this.isDepartmentManager && this.userService.currentProfile && this.userService.currentProfile.length && !this.filters.departmentIds.length) {
        const departmentIds = this.userService.currentProfile.map((profile: any) => profile.Department_id);
        params.department_id = departmentIds.join(',');
      }

      window.localStorage.setItem(`${this.FILTER_PREFIX}_${this.userService.login}`, JSON.stringify(this.filters));

      const response = await this.catalogue.getAdditionalLearnPlan(params);

      this.totalPages = +response.headers.get('totalpages');
      this.additionalLearnPlans = this.mapAdditionalLearnPlans(response.body);
    } catch (err) {
      console.error(err);
    }
  }

  async saveAdditionalModule(data: any) {
    try {
      await this.catalogue.saveAdditionalModule(data);
    } catch (err) {
      console.error(err);
    }
  }

  async downloadReport(params) {
    try {
      const unload: any = await this.catalogue.getPrintForm(params);

      return unload;
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Переключить страницу
   */
  changePage(page: number) {
    if (this.filters.page === page) {
      return;
    }

    this.submitFilters(page);
  }

  /**
   * Изменить количество элементов на странице
   */
  changePerPage(perPage: number) {
    if (this.filters.perPage === perPage) {
      return;
    }

    this.filters.perPage = perPage;
    this.submitFilters();
  }

  resetFilters() {
    this.filters = {
      planTypeId: '',
      departmentIds: [],
      learnPeriodIds: [],
      statusIds: [],
      description: '',
      complete_match: false,
      page: 1,
      perPage: 30,
      sort_direction: null,
      sort_by: null
    };

    this.setInitialFilterValues();
    this.submitFilters();
  }

  toggleFiltersPanel() {
    this.isOpened = !this.isOpened;
  }

  checkAdditionalModule(): boolean {
    this.additionalModuleFieldsStatuses = [];

    this.ADDITIONAL_MODULE_FIELDS.forEach((field: string) => {
      switch (field) {
        case 'microdegree_name':
        case 'microdegree_name_eng':
          if (this.additionalLearnPlan.is_microdegree) {
            this.additionalModuleFieldsStatuses.push(Boolean(this.additionalLearnPlan[field]));
          }

          break;
        case 'microdegree_level_id':
          if (this.additionalLearnPlan.is_microdegree_level) {
            this.additionalModuleFieldsStatuses.push(Boolean(this.additionalLearnPlan[field]));
          }

          break;
        case 'certification_description':
          if (this.additionalLearnPlan.is_certification) {
            this.additionalModuleFieldsStatuses.push(Boolean(this.additionalLearnPlan[field]));
          }

          break;
        case 'teachers':
          this.additionalModuleFieldsStatuses.push(this.additionalLearnPlan.persons.filter((person) => person.role_id === this.TEACHER_ROLE_ID).length > 0);
          break;
        case 'costs':
          this.additionalModuleFieldsStatuses.push(this.additionalLearnPlan[field] && this.additionalLearnPlan[field].length);
          break;
        case 'credit':
        case 'self_proportion':
          this.additionalModuleFieldsStatuses.push(Boolean(this.additionalLearnPlan[field] && this.additionalLearnPlan[field] > 0));
          break;
        default:
          this.additionalModuleFieldsStatuses.push(Boolean(this.additionalLearnPlan[field]));
      }
    });

    return this.additionalModuleFieldsStatuses.every((status: boolean) => status);
  }

  checkMinor(): boolean {
    this.minorFieldsStatuses = [];

    this.MINOR_FIELDS.forEach((field: string) => {
      switch (field) {
        case 'microdegree_name':
        case 'microdegree_name_eng':
          if (this.additionalLearnPlan.is_microdegree) {
            this.minorFieldsStatuses.push(Boolean(this.additionalLearnPlan[field]));
          }

          break;
        case 'microdegree_level_id':
          if (this.additionalLearnPlan.is_microdegree_level) {
            this.minorFieldsStatuses.push(Boolean(this.additionalLearnPlan[field]));
          }

          break;
        case 'education_programs':
          if (this.additionalLearnPlan.education_programs && this.additionalLearnPlan.education_programs.length > 0) {
            this.minorFieldsStatuses.push(this.additionalLearnPlan[field] && this.additionalLearnPlan[field].length > 0);
          }

          break;
        default:
          this.minorFieldsStatuses.push(this.additionalLearnPlan[field] && this.additionalLearnPlan[field] !== 0);
          break;
      }
    });

    return this.minorFieldsStatuses.every((status: boolean) => status);
  }

  checkBasic(): boolean {
    this.basicFieldsStatuses = [];

    this.BASIC_INFO_FIELDS.forEach((field: string) => {
      let status = false;

      switch (field) {
        case 'languages':
          status = Boolean(this.additionalLearnPlan[field] && this.additionalLearnPlan[field].length);
          break;
        case 'supervisor':
          status = Boolean(this.additionalLearnPlan.persons.find((person) => person.role_id === this.SUPERVISOR_ROLE_ID));
          break;
        case 'managers':
          status = this.additionalLearnPlan.persons.filter((person) => person.role_id === this.MANAGER_ROLE_ID).length > 0;
          break;
        default:
          status = Boolean(this.additionalLearnPlan[field]);
          break;
      }

      this.basicFieldsStatuses.push(status);
    });

    return this.basicFieldsStatuses.every((status: boolean) => status);
  }

  updatePlanFillingInPercent(): void {
    const allPlanFieldsStatuses = [...this.basicFieldsStatuses, ...this.additionalModuleFieldsStatuses, ...this.minorFieldsStatuses];
    const fieldsIsFilled = allPlanFieldsStatuses.filter((status: boolean) => status);

    this.planFillingInPercents = Math.round((fieldsIsFilled.length / allPlanFieldsStatuses.length) * 100);
  }

  checkInput($event) {
    if ($event.keyCode === 13) {
      this.setFiltersEdit();
    }
  }

  setFiltersEdit() {
    this.changeEditFilter.next(this.filtersEdit);
  }

  switchTab(tab) {
    this.selectedTab = tab;

    // if (this.selectedTab === 0) {
    //   this.rightBarStatus.next(true);
    // } else {
    //   this.rightBarStatus.next(false);
    // }
  }

  // setCompMatchEdit($event) {
  //   this.filtersEdit.complete_match = $event;
  //   this.setFiltersEdit();
  // }

  private setInitialFilterValues() {
    const savedFilters = window.localStorage.getItem(`${this.FILTER_PREFIX}_${this.userService.login}`);

    if (savedFilters) {
      const parsedFilters = JSON.parse(savedFilters);

      for (const prop in parsedFilters) {
        if ((this.isTeacher && prop === 'user') || (this.isFacultyDecan && prop === 'faculty_id')
          || (this.isFilialDeputy && prop === 'filial_id') || (this.isDepartmentManager && prop === 'department_id')
          || !['user', 'faculty_id', 'filial_id', 'department_id'].includes(prop)
        ) {
          this.filters[prop] = parsedFilters[prop];
        }
      }
    } else {
      this.filters.learnPeriodIds = this.learnPeriods.filter((lp: any) => lp.is_current).map((lp: any) => lp.id);
    }
  }

  private mapAdditionalLearnPlans(learnPlans): any[] {
    return learnPlans.map((plan: any) => {
      return {
        ...plan,
        plan_type: plan.is_minor && !plan.is_for_other
          ? 'Майнор'
          : !plan.is_minor && plan.is_for_other
            ? 'Дополнительный модуль'
            : null,
        plan_status_update_date: this.changeDateFormat(plan.plan_status_update_date),
        created_at: this.changeDateFormat(plan.created_at),
        updated_at: this.changeDateFormat(plan.updated_at),
        supervisor: plan.persons.find((person: any) => person.role_id === this.SUPERVISOR_ROLE_ID) || null,
        coLeader: plan.persons.filter((person: any) => person.role_id === this.CO_LEADER_ROLE_ID),
        manager: plan.persons.filter((person: any) => person.role_id === this.MANAGER_ROLE_ID),
        selected: false,
        isExpandable: false
      };
    });
  }

  private changeDateFormat(date: string): string | null {
    return date && moment(date, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY') !== '01.01.0001' ? moment(date, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY HH:mm') : null;
  }

  private mapDepartments(departments: any) {
    return departments.map((department: any) => {
      const foundFilial = this.filials.find((filial: any) => filial.id === department.filial_id) || null;
      const additionalDescriptionPart = foundFilial ? `(${foundFilial.description})` : '';

      department.customDescription = [department.description, additionalDescriptionPart].join(' ');

      return department;
    });
  }

  private async mapLearnPlanStructure() {
    let maxLevel = 0;
    this.structure = _flow(
      _map((item: EducationStandardStructureBackend) => {
        item.custom_plan_item.forEach((planItem: CustomPlanItemExtended) => {
          planItem.level = item.level;
          planItem.min = item.min;
          planItem.max = item.max;
          planItem.elementTypeId = item.element_type_id;
        });

        return item.custom_plan_item;
      }),
      _flatten,
      _filter((item: any) => {
        if (parseInt(item.level, 0) > maxLevel) {
          maxLevel = parseInt(item.level, 0);
        }

        return item.parent_id !== 0;
      })
    )(this.rawStructure);

    this.structure.forEach((item: any) => {
      const elementTypeIds = _flow([
        _filter({ parent_id: item.structure_id }),
        _map('element_type_id'),
        _uniq
      ])(this.rawStructure);

      item.children = _filter<CustomPlanItemExtended>((planItem: CustomPlanItemExtended) => {
        return planItem.parent_id === item.id;
      })(this.structure);

      item.expanded = true;
      item.isParent = Boolean(_find({ parent_id: item.structure_id })(this.rawStructure));
      item.elementTypes = _filter((type: ElementType) => _includes(type.id)(elementTypeIds))(this.elementTypes);
      item.isStructureElement = item.isParent === false && item.parent_id !== 0;
    });

    await this.mapDisciplines();

    return this.structure.filter((item: any) => item.level.toString() === '1');
  }

  private async mapDisciplines(): Promise<void> {
    await this.loadAnnotationsForStructureElements();

    this.rawElements = _flow(
      _filter((item: any) => item.isStructureElement),
      _map((item: any) => {
        const startLearnYear = this.learnPeriods.find((period: any) => period.id === item.start_learn_year_id);
        const endLearnYear = this.learnPeriods.find((period: any) => period.id === item.end_learn_year_id);
        const foundAnnotation = this.annotations.find(($ann: any) => Number($ann.id) === Number(item.id));
        const foundAudienceReach = this.audienceReach.find((audienceReach: any) => audienceReach.id === item.audience_reach_id);

        item.annotation = foundAnnotation ? foundAnnotation.annotation : null;
        item.audienceReach = foundAudienceReach ? foundAudienceReach.description : null;
        item.startLearnYear = startLearnYear ? startLearnYear.description : null;
        item.endLearnYear = endLearnYear ? endLearnYear.description : null;

        return item;
      })
    )(this.structure);
  }

  private mapLearnPlanStructureByYears() {
    const beginYear = _find<LearnPeriod>({ id: this.additionalLearnPlan.begin_year_id })(this.learnPeriods);
    const endYear = _find<LearnPeriod>({ id: this.additionalLearnPlan.end_year_id })(this.learnPeriods);
    const beginYearString: string = this.additionalLearnPlan.begin_year.substr(0, 9);
    const endYearString: string = this.additionalLearnPlan.end_year.substr(0, 9);
    const filteredLearnPeriods = _flow(
      _uniqBy<any>('id'),
      _filter((period: any) => period.learn_year >= beginYear.learn_year && period.learn_year <= endYear.learn_year),
      _filter((period: any) => !period.parent_id)
    )(this.learnPeriods);

    return [
      {
        description: `Период обучения ${beginYearString} - ${endYearString}`,
        expanded: true,
        children: filteredLearnPeriods
          .map((period: any) => ({
            description: period.description.substr(0, 9),
            learn_year_id: period.id,
            expanded: true,
            is_year: true,
            children: this.rawElements
              .filter((el: any) =>
                Boolean(el.custom_plan_item_by_year.find((plan: any) => plan.item_learn_year_id === period.id))
              )
              .map((el: any) => {
                return { ...el, $learn_year_id: period.id };
              })
          }))
      }
    ];
  }
}
