import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { SupportDialogComponent } from './components/support-dialog/support-dialog.component';
import { SupportDialogService } from './support-dialog.service';


@NgModule({
  declarations: [
    SupportDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  providers: [
    SupportDialogService
  ],
  exports: [
    SupportDialogComponent
  ]
})
export class SupportDialogModule { }
