import { NgModule } from '@angular/core';
import { CatalogueService } from '@backend/kosuip/catalogue/catalogue.service';
import { AuthService } from '@backend/auth/auth.service';
import { CustomCookieService } from '@backend/cookies/custom-cookie.service';
import { InterceptorService } from '@backend/interceptor.service';
import { DiplomService } from '@backend/kosuip/diplom/diplom.service';
import { HttpClientModule } from '@angular/common/http';
import { UserSettingsService } from '@backend/user-settings/user-settings.service';
import { UserProfileService } from '@backend/user-profile/user-profile.service';
import { EventsService } from '@backend/kosuip/events/events.service';
import { PrintFormsUnloadService } from '@backend/kosuip/print-forms-unload/print-forms-unload.service';
import { CommentsService } from '@backend/kosuip/comments/comments.service';
import { ApprovalService } from '@backend/kosuip/approval/approval.service';
import { MtkVerificationService } from '@backend/kosuip/mtk-verification/mtk-verification.service';


@NgModule({
  declarations: [],
  imports: [HttpClientModule],
  providers: [
    CatalogueService,
    AuthService,
    CustomCookieService,
    InterceptorService,
    DiplomService,
    UserSettingsService,
    UserProfileService,
    PrintFormsUnloadService,
    EventsService,
    CommentsService,
    ApprovalService,
    MtkVerificationService,
  ],
  exports: []
})
export class BackendModule {}
