import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SupportDialogService } from '../../support-dialog.service';
import { TranslateService } from '@ngx-translate/core';

export interface DialogForm {
  msg_type_id: string;
  fio: string;
  msg_topic: string;
  msg_text: string;
  files: {filename: string, b64data: string}[];
}

export interface Translations {
  messageType: string;
  fullName: string;
  topic: string;
  content: string;
}

@Component({
  selector: 'hse-support-dialog',
  templateUrl: './support-dialog.component.html',
  styleUrls: ['./support-dialog.component.scss']
})
export class SupportDialogComponent implements OnInit, OnDestroy {

  @ViewChild('fileUpload', { static: true }) fileUpload: ElementRef;

  showDialog = false;
  initAnimation = false;
  finishAnimation = false;
  dialogForm: DialogForm = {
    msg_type_id: null,
    fio: null,
    msg_topic: null,
    msg_text: null,
    files: []
  };
  // translations: Translations = {
  //   messageType: null,
  //   fullName: null,
  //   topic: null,
  //   content: null
  // };
  fileData: {name: string, content: any} = {
    name: null,
    content: null
  };

  constructor(public base: SupportDialogService,
              private translate: TranslateService) { }

  ngOnInit() {
    this.base.showDialog.subscribe((value: boolean) => {
      if (value) {
        this.initAnimation = true;
        this.finishAnimation = false;
        this.showDialog = true;
      } else {
        this.close();
      }
    });
    this.init();
  }

  init(): void {
    // setTimeout(() => {
    //   this.translations.messageType = this.translate.instant('SUPPORT-DIALOG.MESSAGE-TYPE');
    //   this.translations.fullName = this.translate.instant('SUPPORT-DIALOG.FULL-NAME');
    //   this.translations.topic = this.translate.instant('SUPPORT-DIALOG.TOPIC');
    //   this.translations.content = this.translate.instant('SUPPORT-DIALOG.CONTENT');
    // }, 0);
  }

  resetForm(): void {
    this.dialogForm = {
      msg_text: null,
      msg_topic: null,
      fio: null,
      msg_type_id: null,
      files: []
    };
    this.resetFileData();
  }

  close(): void {
    this.resetForm();
    this.initAnimation = false;
    this.finishAnimation = true;

    setTimeout(() => {
      this.showDialog = false;
      // @ts-ignore
    }, 300);
  }

  upload(): void {
    this.fileUpload.nativeElement.click();
  }

  async onUpload(): Promise<void> {
    try {
      this.fileData.content = await this.toBase64(this.fileUpload.nativeElement.files[0]);
      this.fileData.name = this.fileUpload.nativeElement.files[0].name;
      let ext: any = this.fileData.name.split('.');
      let addch;
      ext = ext[ext.length - 1].toLowerCase();
      switch (ext) {
        case 'jpg':
          addch = '/';
          break;
        case 'jpeg':
          addch = '/';
          break;
        case 'png':
          addch = 'i';
          break;
        case 'gif':
          addch = 'R';
          break;
        case 'pdf':
          addch = 'J';
          break;
        default:
          addch = '/';
          break;
      }

      this.fileData.content = `${addch}${this.fileData.content}`;
      this.dialogForm.files = [{filename: this.fileData.name, b64data: this.fileData.content}];
    } catch (e) {
      console.error(e);
    }
  }

  resetFileData(): void {
    this.fileData = {
      name: null,
      content: null
    };
    this.dialogForm.files = [];
  }

  toBase64(file): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      // @ts-ignore
      reader.onload = () => resolve(reader.result.slice(23));
      reader.onerror = error => reject(error);
    });
  }

  async postMessage(): Promise<void> {
    try {
      await this.base.postMessage(this.dialogForm);
      this.close();
    } catch (e) {
      console.error(e);
    }
  }

  disableButton(): boolean {
    return Boolean(!this.dialogForm.msg_type_id)
      || Boolean(!this.dialogForm.fio)
      || Boolean(!this.dialogForm.msg_topic)
      || Boolean(!this.dialogForm.msg_text);
  }

  ngOnDestroy(): void {
    this.base.showDialog.unsubscribe();
  }
}
