import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  compact as _compact,
  find as _find,
  flow as _flow,
  includes as _includes,
  intersection as _intersection,
  map as _map,
  sortBy as _sortBy
} from 'lodash/fp';
import { UserProfile } from '@backend/user-profile/user-profile.interface';
import { UserRole } from '@backend/user-profile/user-role.enum';
import { UserRoleDetailed } from '@backend/user-profile/user-role-detailed.interface';
import { UserInfo } from '@backend/user-profile/user-info.interface';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  private ROLES_URL = '/api/user-roles';
  private PROFILE_URL = '/api/user-profile';
  private INFO_URL = '/api/user-info';
  private DETAILED_URL = '/api/user-roles-detailed';
  private CHANGE_URL = '/api/changeuser';
  private loadingPromise: Promise<[UserInfo, UserRoleDetailed[]]>;

  public selectedRole: UserRole;
  public availableRoles: UserRole[];
  public currentProfile: UserProfile[] = [];
  public profileInfo: UserRoleDetailed[];
  public login: string = null;

  constructor(private http: HttpClient) {
  }

  async getUserInfo() {
    try {
      const selectedRole: UserRole = localStorage.getItem('selectedRole') as UserRole;

      this.loadingPromise = Promise.all([
        this.http.get<UserInfo>(this.INFO_URL).toPromise(),
        this.http.get<UserRoleDetailed[]>(this.DETAILED_URL).toPromise(),
      ]);

      const [userInfo, userRoles] = await this.loadingPromise;

      this.profileInfo = userRoles;
      this.availableRoles = _flow(
        _map<UserRoleDetailed, UserRole>((profile) => {
          profile.role = profile.Roles.length ? profile.Roles[0].Role : null;

          return profile.role;
        }),
        _compact
      )(this.profileInfo);

      const usedProfile = _find<UserRoleDetailed>({ is_used: true })(this.profileInfo);
      if (usedProfile && usedProfile.role) {
        await this.selectRole(usedProfile.role, false);
      } else if (this.availableRoles.length === 1) {
        await this.selectRole(this.availableRoles[0]);
      } else if (selectedRole) {
        await this.selectRole(selectedRole);
      }

      this.login = userInfo.login;

      console.log('availableRoles: ', this.availableRoles);
      console.log('currentProfile: ', this.currentProfile);
      console.log('profileInfo: ', this.profileInfo);
    } catch (err) {
      this.availableRoles = [];
    }
  }

  async selectRole(role: UserRole, sendRequest = true) {
    try {
      localStorage.setItem('selectedRole', role);

      this.selectedRole = role;
      const selectedRole = _find<UserRoleDetailed>({ role })(this.profileInfo);
      this.currentProfile = selectedRole.Profiles;

      if (sendRequest) {
        await this.http.post<any>(this.CHANGE_URL, { email: selectedRole.changed_user + '@hse.ru' }).toPromise();
      }
    } catch (e) {
      console.error(e);
    }
  }

  async checkRoleAvailable(roleToCheck: UserRole) {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      return _includes(roleToCheck)(this.availableRoles);
    } catch (e) {
      console.error(e);
    }
  }

  async checkRolesAvailable(rolesToCheck: UserRole[]) {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      const sortRoles = _sortBy(role=>role);

      return _intersection(sortRoles(rolesToCheck))(sortRoles(this.availableRoles)).length > 0;
    } catch (e) {
      console.error(e);
    }
  }

  async isRoleSelected(roleToCheck: UserRole) {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      const selectedRole = localStorage.getItem('selectedRole');

      return selectedRole === roleToCheck;
    } catch (e) {
      console.error(e);
    }
  }

  async getUserRoleMap(): Promise<Map<UserRole, boolean>> {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      const selectedRole = localStorage.getItem('selectedRole');
      const result = new Map<UserRole, boolean>();


      for (const roleKey in UserRole) {
        const role: UserRole = UserRole[roleKey];
        result.set(role, role === selectedRole);
      }

      return result;
    } catch (e) {
      console.error(e);
    }
  }

  async hasRoleSelected(rolesToCheck: UserRole[]) {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      const selectedRole = localStorage.getItem('selectedRole');

      return rolesToCheck.includes(selectedRole as UserRole);
    } catch (e) {
      console.error(e);
    }
  }

  getSelectedChangedUser() {
    const role = localStorage.getItem('selectedRole');
    let selectedRole;

    if (role) {
      selectedRole = _find<any>({ role })(this.profileInfo);
    }

    return selectedRole ? selectedRole.changed_user : null;
  }

  async isOnlyManagerOp() {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      const selectedRole = localStorage.getItem('selectedRole');

      return selectedRole === 'Менеджер ОП';
    } catch (e) {
      console.error(e);
    }
  }

  async isManagerOP() {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      const selectedRole = localStorage.getItem('selectedRole');

      return selectedRole === 'Менеджер ОП';
    } catch (e) {
      console.error(e);
    }
  }

  async isUROP() {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      const selectedRole = localStorage.getItem('selectedRole');

      return selectedRole === 'УРОП';
    } catch (e) {
      console.error(e);
    }
  }

  async isDOOP() {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      const selectedRole = localStorage.getItem('selectedRole');

      return selectedRole === 'ДООП/Траектория';
    } catch (e) {
      console.error(e);
    }
  }

  async isDOO() {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      const selectedRole = localStorage.getItem('selectedRole');

      return selectedRole === 'ДОО';
    } catch (err) {
      console.error(err);
    }
  }

  async isManagerUP() {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      const selectedRole = localStorage.getItem('selectedRole');

      return selectedRole === 'Менеджер УП';
    } catch (e) {
      console.error(e);
    }
  }

  async isAcademicDeputy() {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      const selectedRole = localStorage.getItem('selectedRole');

      return selectedRole === 'Академический руководитель УП';
    } catch (e) {
      console.error(e);
    }
  }

  async isFilialDeputy() {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      const selectedRole = localStorage.getItem('selectedRole');

      return selectedRole === 'Заместитель директора филиала';
    } catch (e) {
      console.error(e);
    }
  }

  async isFacultyDecan() {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      const selectedRole = localStorage.getItem('selectedRole');

      return selectedRole === 'Декан факультета УП';
    } catch (e) {
      console.error(e);
    }
  }

  async isProrecror() {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      const selectedRole = localStorage.getItem('selectedRole');

      return selectedRole === 'Проректор';
    } catch (e) {
      console.error(e);
    }
  }

  async isDepartmentManager() {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      const selectedRole = localStorage.getItem('selectedRole');

      return selectedRole === 'Менеджер кафедры';
    } catch (e) {
      console.error(e);
    }
  }

  async isAdmin() {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      const selectedRole = localStorage.getItem('selectedRole');

      return selectedRole === 'Администраторы';
    } catch (e) {
      console.error(e);
    }
  }

  async isAcademicDirectorASH() {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      const selectedRole = localStorage.getItem('selectedRole');

      return selectedRole === 'Академический директор АШ';
    } catch (e) {
      console.error(e);
    }
  }

  async isUAD() {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      const selectedRole = localStorage.getItem('selectedRole');

      return selectedRole === 'УАД';
    } catch (e) {
      console.error(e);
    }
  }

  async isManagerASH() {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      const selectedRole = localStorage.getItem('selectedRole');

      return selectedRole === 'Менеджер АШ';
    } catch (e) {
      console.error(e);
    }
  }

  async isAcademicMaster() {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      const selectedRole = localStorage.getItem('selectedRole');

      return selectedRole === 'Академический наставник';
    } catch (e) {
      console.error(e);
    }
  }

  async isMezhvuzManager() {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      const selectedRole = localStorage.getItem('selectedRole');

      return selectedRole === 'Менеджер общевузовских планов';
    } catch (e) {
      console.error(e);
    }
  }

  async isTeacher() {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      const selectedRole = localStorage.getItem('selectedRole');

      return selectedRole === 'Преподаватель';
    } catch (err) {
      console.error(err);
    }
  }

  async isReadOnly() {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      const selectedRole = localStorage.getItem('selectedRole');

      return selectedRole === 'Просмотр';
    } catch (e) {
      console.error(e);
    }
  }

  async getLogin() {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      return this.login;
    } catch (e) {
      console.error(e);
    }
  }

  async getLoginExclusively() {
    try {
      const response = await this.http.get<UserInfo>(this.INFO_URL).toPromise();

      return response.login;
    } catch (e) {
      console.error(e);
    }
  }

  async isDepartmentDisciplineAvailable() {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      return this.checkRolesAvailable([UserRole.UROP, UserRole.DepartmentManager, UserRole.Admin])
    } catch (e) {
      console.error(e);
    }
  }

  async getCurrentProfile() {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      return this.currentProfile;
    } catch (e) {
      console.error(e);
    }
  }

  async getCurrentRoles() {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      return [localStorage.getItem('selectedRole')];
    } catch (e) {
      console.error(e);
    }
  }

  async getAvailableRoles() {
    try {
      if (this.loadingPromise) {
        await this.loadingPromise;
      }

      return this.availableRoles;
    } catch (e) {
      console.error(e);
    }
  }

  async getUserRoleMatrix() {
    const isAdmin = await this.isAdmin();
    const isUROP = await this.isUROP();
    const isManagerOP = await this.isOnlyManagerOp();
    const isMezhvuzManager = await this.isMezhvuzManager();
    const isManagerASH = await this.isManagerASH();
    const isDepartmentManager = await this.isDepartmentManager();
    const isAcademicDirectorASH = await this.isAcademicDirectorASH();
    const isAcademicDeputy = await this.isAcademicDeputy();
    const isReadOnly = await this.isReadOnly();
    const isTeacher = await this.isTeacher();
    const isUAD = await this.isUAD();
    const isFacultyDecan = await this.isFacultyDecan();
    const isFilialDeputy = await this.isFilialDeputy();
    const isProrecror = await this.isProrecror();
    const isDOOP = await this.isDOOP();
    const isDOO = await this.isDOO();

    return {
      isAdmin,
      isUROP,
      isManagerOP,
      isMezhvuzManager,
      isManagerASH,
      isAcademicDeputy,
      isAcademicDirectorASH,
      isDepartmentManager,
      isReadOnly,
      isTeacher,
      isUAD,
      isFacultyDecan,
      isFilialDeputy,
      isProrecror,
      isDOOP,
      isDOO
    };
  }
}
