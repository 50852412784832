import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_MENU_SCROLL_STRATEGY, MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HseStickyModule } from '@shared/modules/hse-sticky';

import { AddDirectoryComponent } from '@shared/components/add-directory/add-directory.component';
import { AddElementComponent } from '@shared/components/add-element/add-element.component';
import { BaseLayoutComponent } from '@shared/components/layouts/base-layout/base-layout.component';
import { ConstructorLayoutComponent } from '@shared/components/layouts/constructor-layout/constructor-layout.component';
import { HseButtonComponent } from '@shared/components/common-ui/hse-button/hse-button.component';
import { HseCheckboxComponent } from '@shared/components/common-ui/hse-checkbox/hse-checkbox.component';
import { HseComboBoxComponent } from '@shared/components/common-ui/hse-combo-box/hse-combo-box.component';
import { HseDateRangePickerComponent } from '@shared/components/common-ui/hse-date-range-picker/hse-date-range-picker.component';
import { HseDatePickerCalendarComponent } from '@shared/components/common-ui/hse-date-range-picker/hse-date-picker-calendar/hse-date-picker-calendar.component';
import { HseFooterComponent } from '@shared/components/footer/hse-footer.component';
import { HseIconComponent } from '@shared/components/common-ui/hse-icon/hse-icon.component';
import { HseInputComponent } from '@shared/components/common-ui/hse-input/hse-input.component';
import { HseLeftMenuComponent } from '@shared/components/left-menu/left-menu.component';
import { HseMenuComponent } from '@shared/components/common-ui/hse-menu/hse-menu.component';
import { HsePaginationComponent } from '@shared/components/common-ui/hse-pagination/hse-pagination.component';
import { HseRadioGroupComponent } from '@shared/components/common-ui/hse-radio-group/hse-radio-group.component';
import { HseRangeSelectComponent } from '@shared/components/common-ui/hse-range-select/hse-range-select.component';
import { HseSelectComponent } from '@shared/components/common-ui/hse-select/hse-select.component';
import { HseStageShiftComponent } from '@shared/components/common-ui/hse-stage-shift/hse-stage-shift.component';
import { HseTableNewComponent } from '@shared/components/common-ui/hse-table-new/hse-table-new.component';
import { HseTableComponent } from '@shared/components/common-ui/hse-table/hse-table.component';
import { HseTableRowComponent } from '@shared/components/common-ui/hse-table-row/hse-table-row.component';
import { HseTabSwitchComponent } from '@shared/components/common-ui/hse-tab-switch/hse-tab-switch.component';
import { HseTextareaComponent } from '@shared/components/common-ui/hse-textarea/hse-textarea.component';
import { MainLayoutComponent } from '@shared/components/layouts/main-layout/main-layout.component';
import { UserInfoComponent } from '@shared/components/user-info/user-info.component';

import { StringMaskService } from '@shared/directives/input-mask/string-mask.service';
import { HseYaComboBoxComponent } from '@shared/components/common-ui/hse-ya-combo-box/hse-ya-combo-box.component';
import { SortByPipe } from '@shared/pipes/sort-by.pipe';
import { RejectPipe } from '@shared/pipes/reject.pipe';
import { Overlay } from '@angular/cdk/overlay';
import { HseLoaderModule } from '@shared/components/hse-loader/hse-loader.module';
import { HseTableColumnSettingsComponent } from '@shared/components/common-ui/hse-table-column-settings/hse-table-column-settings.component';
import { HseDebounceDirective } from '@shared/directives/debounce/debounce.directive';
import { InfiniteScrollDirective } from './directives/infinite-scroll.directive';
import { HseInputDiplomComponent } from '@shared/components/common-ui/hse-input-diplom/hse-input-diplom.component';
import { ConfirmPopupComponent } from '@shared/components/confirm-popup/confirm-popup.component';
import { OptionsPopupComponent } from '@shared/components/options-popup/options-popup.component';
import { EducationResultsPopupComponent } from '@shared/components/education-results-popup/education-results-popup.component';
import { AddCompetenciesComponent } from './components/add-competencies/add-competencies.component';
import { HseTableColumnComponent } from './components/common-ui/hse-table-column/hse-table-column.component';
import { TranslateModule } from '@ngx-translate/core';
import { EventsComponent } from './components/events/events.component';
import { PrintFormsUnloadComponent } from './components/print-forms-unload/print-forms-unload.component';
import { TableFilterPopupComponent } from './components/table-filter-popup/table-filter-popup.component';
import { CommentsComponent } from './components/comments/comments.component';
import { MtkVerificationComponent } from './components/mtk-verification/mtk-verification.component';
import { CgBusyDirective } from './directives/cg-busy/cg-busy.directive';
import { PrintFormsUnloadLearnPlanComponent } from './components/print-forms-unload-learn-plan/print-forms-unload-learn-plan.component';
import { EditTranslationComponent } from './components/edit-translation/edit-translation.component';
import { HseBreadcrumbsComponent } from './components/hse-breadcrumbs/hse-breadcrumbs.component';
import { ExpandableLeftMenuComponent } from './components/expandable-left-menu/expandable-left-menu.component';
import { CreateEditDirectoryComponent } from './components/create-edit-directory/create-edit-directory.component';
import { HseEducationTrajectorySelectComponent } from './components/common-ui/hse-education-trajectory-select/hse-education-trajectory-select.component';
import { FileDropDirective } from './directives/file-drop/file-drop.directive';
import { AddRequisitesComponent } from './components/add-requisites/add-requisites.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { HseInfoModalComponent } from './components/hse-info-modal/hse-info-modal.component';
import { HseStepperComponent } from './components/common-ui/hse-stepper/hse-stepper.component';
import { HseSwitchComponent } from './components/common-ui/hse-switch/hse-switch.component';
import { HseRadioButtonGroupComponent } from './components/common-ui/hse-radio-button-group/hse-radio-button-group.component';
import { AddEducationResultsComponent } from './components/add-education-results/add-education-results.component';
import { CreateEducationResultComponent } from './components/create-education-result/create-education-result.component';
import { EllipsisModule } from 'ngx-ellipsis';
import { PrintFormModalComponent } from './components/print-form-modal/print-form-modal.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PageContentComponent } from './components/page-content/page-content.component';
import { TranslateCustomService } from '@shared/services/translate-custom.service';

const declarations = [
  AddDirectoryComponent,
  AddEducationResultsComponent,
  AddElementComponent,
  BaseLayoutComponent,
  ConstructorLayoutComponent,
  CreateEducationResultComponent,
  HseButtonComponent,
  HseCheckboxComponent,
  HseComboBoxComponent,
  HseDateRangePickerComponent,
  HseDatePickerCalendarComponent,
  HseFooterComponent,
  HseIconComponent,
  HseInputComponent,
  HseInputDiplomComponent,
  HseLeftMenuComponent,
  HseMenuComponent,
  HsePaginationComponent,
  HseRadioGroupComponent,
  HseRangeSelectComponent,
  HseSelectComponent,
  HseStageShiftComponent,
  HseTableNewComponent,
  HseTableComponent,
  HseTableRowComponent,
  HseTableColumnComponent,
  HseTableColumnSettingsComponent,
  HseTabSwitchComponent,
  HseTextareaComponent,
  MainLayoutComponent,
  UserInfoComponent,
  HseYaComboBoxComponent,
  InfiniteScrollDirective,
  SortByPipe,
  RejectPipe,
  HseDebounceDirective,
  OptionsPopupComponent,
  ConfirmPopupComponent,
  EducationResultsPopupComponent,
  AddCompetenciesComponent,
  EventsComponent,
  PrintFormsUnloadComponent,
  PrintFormsUnloadLearnPlanComponent,
  CommentsComponent,
  MtkVerificationComponent,
  CgBusyDirective,
  TableFilterPopupComponent,
  EditTranslationComponent,
  HseBreadcrumbsComponent,
  ExpandableLeftMenuComponent,
  CreateEditDirectoryComponent,
  FileDropDirective,
  HseEducationTrajectorySelectComponent,
  AddRequisitesComponent,
  ConfirmModalComponent,
  HseInfoModalComponent,
  HseStepperComponent,
  HseSwitchComponent,
  HseRadioButtonGroupComponent,
  PrintFormModalComponent,
  PageHeaderComponent,
  PageContentComponent
];

@NgModule({
  declarations,
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    HseStickyModule,
    MatDialogModule,
    MatMenuModule,
    MatTooltipModule,
    ReactiveFormsModule,
    RouterModule,
    ScrollingModule,
    HseLoaderModule,
    TranslateModule,
    EllipsisModule
  ],
  providers: [
    StringMaskService,
    TranslateCustomService,
    {
      provide: MAT_MENU_SCROLL_STRATEGY,
      useFactory: function (overlay: Overlay) {
        return () => overlay.scrollStrategies.block();
      },
      deps: [Overlay]
    }
  ],
  exports: [
    ...declarations,
    FormsModule,
    HseStickyModule,
    MatDialogModule,
    MatMenuModule,
    MatTooltipModule,
    ReactiveFormsModule,
    HseLoaderModule,
    TranslateModule
  ]
})
export class SharedModule {}
