<div class="add-competencies-background fx column stretch" [style.min-height.px]="bodyHeight" (window:resize)="setBodyHeight()">
    <div class="add-competencies-wrapper fx column fx-1">
        <div class="title fx row stretch">

            <div class="fx center fx-4 column h-center">

              <div class="fx space-between" style="width: 100%">
                <div class="fx fx-3 row h-start">
                  <h1>{{ 'STUDY-PLAN.ADD-COMPETENCIES-POPUP.ADD-COMPETENCY' | translate }}</h1>
                </div>

                <div class="fx column" [style.margin-bottom.px]="margin">
                  <div class="passed-item fx row" *ngFor="let item of passedItems">
                    <span class="title">{{item.title}}</span>
                    <span class="value">{{item.value}}</span>
                  </div>
                </div>

                <div class="controls fx fx-3 column v-start">
                  <div class="fx row h-end">
                    <hse-icon [name]="'close'" class="close" (click)="onClose()"></hse-icon>
                  </div>
                </div>
              </div>

              <span class="iteration-title"
                    [class.shifted]="!structureState">{{competenceTypeName}}</span>

                <div class="type-switch fx row" *ngIf="structureState">
                    <div class="competence-type"
                         *ngFor="let type of competenceTypes"
                         (click)="selectCompetenceType(type)"
                         [class.selected]="type.id === competenceTypeId">
                        <span>{{type.code}}</span>
                    </div>
                </div>

                <div class="fx fx-1 column competencies-wrapper">
                    <div *ngIf="competenceTypeId === '2' && showSpecialitySelect && studyPlan.speciality && studyPlan.speciality.length" style="padding-bottom: 16px;">
                        <hse-select [collection]="studyPlan.speciality"
                                    [(ngModel)]="selectedSpeciality"
                                    (ngModelChange)="filterCompetencies($event)"
                                    [width]="500"
                                    [disablePlaceholderAnimation]="true"
                                    [nameField]="'direction_speciality'"
                                    [idField]="'speciality_id'"
                                    [placeholder]="'Направление подготовки'"></hse-select>
                    </div>

                    <div class="fx fx-1 row" *ngIf="structureState">
                      <div #competencies class="competencies fx fx-4" *ngIf="competenceTypeId !== 'KOR'">
                          <div class="fx column item" [class.selected]="item.selected" *ngFor="let item of filteredCompetencies">
                              <div class="fx row v-center space-between button">
                                    <div class="fx row">
                                        <hse-checkbox [(model)]="item.selected"
                                                      (modelChange)="checkCompetence(item)"
                                                      [stopClickPropagation]="true"></hse-checkbox>
                                        <span class="code-label">{{item.code}}</span>
                                    </div>
                                    <div class="fx row">
                                        <hse-icon [name]="'info'" *ngIf="item.hint" matTooltip="{{item.hint}}"></hse-icon>
                                    </div>
                                </div>
                              <div class="fx fx-1 description full-description" [id]="'item-' + item.id" *ngIf="item.isFullDescriptionShow">
                                <p>{{item.description}}</p>
                              </div>
                                <div class="fx fx-1 description" [id]="'item-' + item.id" *ngIf="item.description && !item.isFullDescriptionShow">
                                    <p ellipsis [ellipsis-content]="item.description"></p>
                                </div>
                                <div class="read-full-button" (click)="item.isFullDescriptionShow = true" *ngIf="isOverflow(item) && !item.isFullDescriptionShow"
                                     matTooltip="{{item.description}}"
                                     matTooltipClass="item-full-description-tooltip">
                                  {{ 'STUDY-PLAN.ADD-COMPETENCIES-POPUP.READ-FULL' | translate }}
                                </div>

                                <div class="read-full-button" (click)="item.isFullDescriptionShow = false" *ngIf="item.isFullDescriptionShow">
                                  {{ 'STUDY-PLAN.ADD-COMPETENCIES-POPUP.HIDE-FULL' | translate }}
                                </div>
                            </div>
                        </div>

                        <div class="competencies fx fx-4 column" *ngIf="competenceTypeId === 'KOR'">
                            <div class="fx fx-1 column item" *ngFor="let item of educationResults">
                                <div class="fx fx-1 row v-center space-between button" (click)="checkCompetence(item)">
                                    <div class="fx row">
                                        <hse-checkbox [(model)]="item.selected"
                                                      (modelChange)="updatePassedItems()"
                                                      [stopClickPropagation]="true"></hse-checkbox>
                                        <span>{{item.code_link}}</span>
                                    </div>
                                    <div class="fx row">
                                        <hse-icon [name]="'info'" *ngIf="item.hint" matTooltip="{{item.hint}}"></hse-icon>
                                    </div>
                                </div>
                                <div class="fx fx-1 description" *ngIf="item.description">
                                    <p>{{item.description}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="fx fx-1 confirm">
                            <hse-button (click)="confirm()">{{ 'STUDY-PLAN.ADD-COMPETENCIES-POPUP.ADD' | translate }}</hse-button>
                        </div>
                    </div>


                    <div class="fx fx-1 row" *ngIf="!structureState">
                        <div class="language-block">
                            <div class="fx row v-center space-between">
                                <span [class.selected]="languagePrefix === ''" (click)="selectLanguage('')">RU</span>
                                <div class="divider"></div>
                                <span [class.selected]="languagePrefix === 'eng_'" (click)="selectLanguage('eng_')">EN</span>
                            </div>
                        </div>
                        <div class="competencies fx fx-4 column">
                            <div class="fx fx-1 row v-center button add"
                                 (click)="addCompetence()">
                                <hse-icon [name]="'add'"></hse-icon>
                                <span>{{ 'STUDY-PLAN.ADD-COMPETENCIES-POPUP.ADD-PK-CODE' | translate }}</span>
                            </div>
                            <div class="fx fx-1 column item" *ngFor="let item of filteredCompetencies">
                                <div class="fx fx-1 row v-center space-between button">
                                    <div class="fx row">
                                        <span>{{item.code}}</span>
                                    </div>
                                </div>
                                <hse-textarea [rowsCount]="3"
                                              [growOnFocus]="3"
                                              [width]="textareaWidth"
                                              [(ngModel)]="item[languagePrefix + 'description']"></hse-textarea>
                            </div>
                        </div>

                        <div class="fx fx-1 confirm">
                            <hse-button [disabled]="isDisabledAddButton()" (click)="confirm()">
                                {{ 'STUDY-PLAN.ADD-COMPETENCIES-POPUP.ADD' | translate }}
                            </hse-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
