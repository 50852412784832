// tslint:disable:no-magic-numbers
import { Injectable } from '@angular/core';
import { HseStickyNode } from '@shared/modules/hse-sticky/hse-sticky-node.interface';
import { HseStickyEvent } from '@shared/modules/hse-sticky/hse-sticky-event.interface';
import { HseStickyConfig } from '@shared/modules/hse-sticky/hse-sticky-config.interface';
import { combineLatest, concatMap, debounceTime, from, fromEvent, Observable, shareReplay } from 'rxjs';

@Injectable({providedIn: 'root'})
export class HseStickyService {
  private static readonly OFFSET_TOP = 0;
  private static readonly Z_INDEX = 1000;
  private stickyMap = new Map<string, HseStickyNode>();
  private stickyStackMap = new Map<string, HseStickyNode>();

  public events: Observable<HseStickyEvent>;

  constructor() {
    this.events = combineLatest(
      fromEvent(window, 'scroll'),
      fromEvent(window, 'resize')
    ).pipe(
      debounceTime(30),
      shareReplay(1),
      concatMap(() => {
        return from([
          ...this.createEventArrayFromMap(),
          ...this.createEventArrayFromStackMap()
        ]);
      })
    );
  }

  /**
   * Добавить липкий элемент
   */
  public addItem(elem: HTMLElement, id: string, config?: HseStickyConfig) {
    const elemBounding = elem.getBoundingClientRect();
    const preparedConfig: HseStickyConfig = this.prepareConfig(config);
    const node: HseStickyNode = {
      itemId: id,
      bounding: elemBounding,
      config: preparedConfig
    };

    this.addNode(node);
  }

  /**
   * Удалить липкий элемент
   */
  public removeItem(id: string, stackName?: string) {
    if (!Boolean(stackName)) {
      this.stickyMap.delete(id);

      return;
    }

    this.removeStackItem(id, stackName);
  }

  /**
   * Удалить элемент из стэка липких элементов
   */
  private removeStackItem(id: string, stackName: string) {
    const foundItem: HseStickyNode = this.findStackItemById(id, stackName);

    if (!Boolean(foundItem)) {
      return;
    }

    if (!Boolean(foundItem.parentNode)) {
      return this.removeRootNode(foundItem, stackName);
    }

    if (Boolean(foundItem.parentNode) && foundItem.parentNode.leftNode === foundItem) {
      return this.removeLeftNode(foundItem);
    }

    if (Boolean(foundItem.parentNode) && foundItem.parentNode.rightNode === foundItem) {
      return this.removeRightNode(foundItem);
    }
  }

  /**
   * Удалить корневой элемент из стэка липких элементов
   */
  private removeRootNode(node: HseStickyNode, stackName: string) {
    let rootNode: HseStickyNode = null;

    if (Boolean(node.rightNode)) {
      rootNode = node.rightNode;
    }

    if (Boolean(node.leftNode)) {
      if (Boolean(rootNode)) {
        node.leftNode.parentNode = rootNode;
        rootNode.leftNode = node.leftNode;
      } else {
        rootNode = node.leftNode;
      }
    }

    if (Boolean(rootNode)) {
      rootNode.parentNode = null;
      this.stickyStackMap.set(stackName, rootNode);
    } else {
      this.stickyStackMap.delete(stackName);
    }
  }

  /**
   * Удалить левый элемент из стэка липких элементов
   */
  private removeLeftNode(node: HseStickyNode) {
    node.parentNode.leftNode = node.leftNode;

    if (Boolean(node.leftNode)) {
      node.leftNode.parentNode = node.parentNode;
    }
  }

  /**
   * Удалить правый элемент из стэка липких элементов
   */
  private removeRightNode(node: HseStickyNode) {
    node.parentNode.rightNode = node.rightNode;

    if (Boolean(node.rightNode)) {
      node.rightNode.parentNode = node.parentNode;
    }
  }

  /**
   * Найти элемент в стэке липких элементов по ID
   */
  private findStackItemById(id: string, stackName: string): HseStickyNode | null {
    const rootNode: HseStickyNode = this.stickyStackMap.get(stackName);
    let node: HseStickyNode = this.getLeftLeaf(rootNode);

    if (!Boolean(rootNode)) {
      return null;
    }

    // Подняться по левой ветке
    while (Boolean(node)) {
      if (node.itemId === id) {
        return node;
      }

      node = node.parentNode;
    }

    // Спуститься по правой ветке
    node = rootNode.rightNode;
    while (Boolean(node)) {
      if (node.itemId === id) {
        return node;
      }

      node = node.rightNode;
    }

    return null;
  }

  /**
   * Подготовить конфигурационный объект
   */
  private prepareConfig(config?: HseStickyConfig): HseStickyConfig {
    const {OFFSET_TOP, Z_INDEX} = HseStickyService;

    return Boolean(config)
      ? {
        stackName: config.stackName,
        offsetTop: typeof config.offsetTop !== 'undefined' ? config.offsetTop : OFFSET_TOP,
        zIndex: typeof config.zIndex !== 'undefined' ? config.zIndex : Z_INDEX
      }
      : {
        offsetTop:  OFFSET_TOP,
        zIndex: Z_INDEX
      };
  }

  /**
   * Добавить Node в карту липких элементов или в карту стэков липких элементов
   */
  private addNode(node: HseStickyNode) {
    const {config, itemId} = node;

    if (Boolean(config.stackName)) {
      this.addNodeToStackMap(node);
    } else {
      this.stickyMap.set(itemId, node);
    }
  }

  /**
   * Добавить Node в карту стэков липких элементов
   */
  private addNodeToStackMap(node: HseStickyNode) {
    const {bounding, config} = node;
    const {stackName} = config;
    const {top} = bounding;

    if (!this.stickyStackMap.has(stackName)) {
      this.stickyStackMap.set(stackName, node);

      return;
    }

    const rootNode: HseStickyNode = this.stickyStackMap.get(stackName);
    const {bounding: rootNodeBounding} = rootNode;

    if (top < rootNodeBounding.top) {
      this.addLeftNode(rootNode, node);
    }

    if (top > rootNodeBounding.top) {
      this.addRightNode(rootNode, node);
    }
  }

  /**
   * Добавить левый элемент в стэк липких элементов
   */
  private addLeftNode(rootNode: HseStickyNode, node: HseStickyNode) {
    let parentNode: HseStickyNode = rootNode;
    const {bounding: {top}} = node;

    while (true) {
      const {leftNode, bounding: {top: parentTop}} = parentNode;

      if (parentTop < top) {
        console.error('HseStickyService: Left branch error!');
        break;
      }

      if (!Boolean(leftNode)) {
        node.parentNode = parentNode;
        parentNode.leftNode = node;
        break;
      }

      const {bounding: {top: leftTop}} = leftNode;

      if (leftTop < top) {
        parentNode.leftNode = node;
        node.parentNode = leftNode.parentNode;
        node.leftNode = leftNode;
        leftNode.parentNode = node;
        break;
      }

      parentNode = leftNode;
    }
  }

  /**
   * Добавить правый элемент в стэк липких элементов
   */
  private addRightNode(rootNode: HseStickyNode, node: HseStickyNode) {
    let parentNode: HseStickyNode = rootNode;
    const {bounding: {top}} = node;

    while (true) {
      const {rightNode, bounding: {top: parentTop}} = parentNode;

      if (parentTop > top) {
        console.error('HseStickyService: Right branch error!');
        break;
      }

      if (!Boolean(rightNode)) {
        node.parentNode = parentNode;
        parentNode.rightNode = node;
        break;
      }

      const {bounding: {top: rightTop}} = rightNode;

      if (rightTop > top) {
        parentNode.rightNode = node;
        node.parentNode = rightNode.parentNode;
        node.rightNode = rightNode;
        rightNode.parentNode = node;
        break;
      }

      parentNode = rightNode;
    }
  }

  /**
   * Создать массив Event из карты липких элементов
   */
  private createEventArrayFromMap(): HseStickyEvent[] {
    const eventArray: HseStickyEvent[] = [];
    const x: number = window.pageXOffset;
    const y: number = window.pageYOffset;

    this.stickyMap.forEach((node: HseStickyNode, id: string) => {
      eventArray.push({
        itemId: id,
        offsetTop: node.config.offsetTop,
        zIndex: node.config.zIndex,
        x,
        y
      });
    });

    return eventArray;
  }

  /**
   * Создать массив Event из стэка липких элементов
   */
  private createEventArrayFromStackMap(): HseStickyEvent[] {
    const eventArray: HseStickyEvent[] = [];
    const x: number = window.pageXOffset;
    const y: number = window.pageYOffset;

    this.stickyStackMap.forEach((rootNode: HseStickyNode) => {
      let node: HseStickyNode = this.getLeftLeaf(rootNode);
      let commonOffsetTop = 0;
      let commonZIndex = 0;

      // Подняться по левой ветке
      while (Boolean(node)) {
        [commonOffsetTop, commonZIndex] = this.addEventToArray(node, eventArray, [x, y, commonOffsetTop, commonZIndex]);
        node = node.parentNode;
      }

      // Спуститься по правой ветке
      node = rootNode.rightNode;
      while (Boolean(node)) {
        [commonOffsetTop, commonZIndex] = this.addEventToArray(node, eventArray, [x, y, commonOffsetTop, commonZIndex]);
        node = node.rightNode;
      }
    });

    return eventArray;
  }

  /**
   * Найти самый левый лист в дереве
   */
  private getLeftLeaf(rootNode: HseStickyNode): HseStickyNode | null {
    let leftLeaf: HseStickyNode = rootNode;

    while (Boolean(leftLeaf) && Boolean(leftLeaf.leftNode)) {
      leftLeaf = leftLeaf.leftNode;
    }

    return leftLeaf;
  }

  /**
   * Добавить Event в массив из стэка липких элементов
   */
  private addEventToArray(
    node: HseStickyNode,
    eventArray: HseStickyEvent[],
    [x, y, $commonOffsetTop, $commonZIndex]: [number, number, number, number]
  ): [number, number] {
    const {bounding: {height}, config: {offsetTop, zIndex}} = node;
    let commonOffsetTop = $commonOffsetTop;
    let commonZIndex = $commonZIndex;

    eventArray.push({
      itemId: node.itemId,
      offsetTop: commonOffsetTop === 0
        ? (commonOffsetTop = offsetTop + height, offsetTop)
        : (commonOffsetTop += (offsetTop + height), commonOffsetTop - height),
      zIndex: commonZIndex === 0
        ? (commonZIndex = zIndex)
        : ++commonZIndex,
      x,
      y
    });

    return [commonOffsetTop, commonZIndex];
  }
}
