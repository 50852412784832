<div class="popup-wrapper fx row h-v-center">
    <div class="popup fx column">
        <div class="fx row h-center">
            <span>{{message}}</span>
        </div>
        <div class="buttons fx row space-between" *ngIf="!yesNo">
            <hse-button [color]="'primary'" (callback)="callback.emit(true)">{{ 'COMMON.DELETE' | translate }}</hse-button>
            <hse-button [color]="'secondary'" (callback)="callback.emit(false)">{{ 'COMMON.CANCEL' | translate }}</hse-button>
        </div>
        <div class="buttons fx row space-between" *ngIf="yesNo">
            <hse-button [color]="'primary'" (callback)="callback.emit(true)">{{ 'EDUCATIONAL-STANDARD.CREATE.YES' | translate }}</hse-button>
            <hse-button [color]="'secondary'" (callback)="callback.emit(false)">{{ 'EDUCATIONAL-STANDARD.CREATE.NO' | translate }}</hse-button>
        </div>
    </div>
</div>
