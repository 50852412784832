import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import {
  find as _find,
  filter as _filter,
  forEach as _forEach,
  some as _some,
  flow as _flow,
  map as _map,
  flatten as _flatten
} from 'lodash/fp';
import { CatalogueService } from '@backend/kosuip/catalogue/catalogue.service';
import * as moment from 'moment';
import { UserProfileService } from '@backend/user-profile/user-profile.service';

@Component({
  selector: 'hse-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, OnChanges {

  @Output() toggleCallback: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendCallback: EventEmitter<any> = new EventEmitter<any>();
  @Output() markAsSolvedCallback: EventEmitter<any> = new EventEmitter<any>();
  @Output() importantCallback?: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteCommentCallback: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateCommentCallback: EventEmitter<any> = new EventEmitter<any>();
  @Input() comments: any;
  @Input() ableToEditField = 'ableToEdit';
  @Input() disabled = false;
  @Input() isStudyPlan = false;
  @Input() currentYear?;
  @Input() studyPlanId?;
  @ViewChild('commentInput', { static: true }) commentInput: ElementRef;

  public commentText = '';
  public newCommentAdding = false;
  public newAnswerAdding = false;
  public markAsSolved = false;
  public commentEditText = '';
  public mainComments;
  public filteredMainComments;
  public filterByYear = false;
  public showAllModal = false;
  public allModalTabs = [
    {
      id: 0,
      title: 'Комментарии',
      selected: false
    },
    {
      id: 1,
      title: 'История согласования',
      selected: false
    }
  ];
  public selectedTab = 0;
  public acceptHistory: any = [];
  public filteredAcceptHistory: any = [];
  public learnPeriods;
  public rawElements;
  public allComments;
  public filteredAllComments;
  public filteredAllCommentsByYear;
  public login;
  public studyPlan;
  public approveDate;
  public isUROP;
  public isAdmin;
  public filterAllByYear = false;
  public withArchiveComment = false;

  public isManagerOP = false;
  public isManagerUP = false;
  public isAcademicDeputy = false;
  public isAcademicMaster = false;
  public isFacultyDecan = false;
  public isProrecror = false;
  public isFilialDeputy = false;
  public isAcademicDirectorASH = false;
  public isManagerASH = false;
  public isUAD = false;
  public isMezhvuzManager = false;

  constructor(
    private readonly catalogue: CatalogueService,
    private readonly userProfile: UserProfileService
  ) { }

  ngOnInit() {
    if (!this.comments || !this.comments.length) {
      this.newCommentAdding = true;
    } else {
      this.mainComments = this.filteredMainComments = _filter(($comment: any) => {
        $comment.childComments = _filter(($$comment: any) => {
          return $$comment.parent_id.toString() === $comment.id.toString();
        })(this.comments);

        return !$comment.parent_id || $comment.parent_id === '0';
      })(this.comments);

      this.filterArchiveComments(false);
    }
  }

  ngOnChanges(changes) {
    if (changes.comments) {
      if (!this.comments || !this.comments.length) {
        this.mainComments = this.filteredMainComments = [];
        this.newCommentAdding = true;
      } else {
        this.mainComments = this.filteredMainComments = _filter(($comment: any) => {
          $comment.childComments = _filter(($$comment: any) => {
            return $$comment.parent_id.toString() === $comment.id.toString();
          })(this.comments);

          return !$comment.parent_id || $comment.parent_id === '0';
        })(this.comments);

        this.filterArchiveComments(false);
      }
    }
  }

  toggleMenu(opened) {
    this.commentText = '';

    setTimeout(() => {
      if (!this.comments || !this.comments.length) {
        this.newCommentAdding = true;
      } else {
        this.newCommentAdding = false;

        _forEach(($comment: any) => {
          $comment.editMode = false;

          _forEach(($$comment: any) => {
            $$comment.editMode = false;
          })($comment.childComments);
        })(this.mainComments);
      }
    }, 0);

    this.toggleCallback.emit(opened);
  }

  addComment(event) {
    event.stopPropagation();
    this.newCommentAdding = true;

    _forEach(($comment: any) => {
      $comment.newAnswerAdding = false;
    })(this.mainComments);

    setTimeout(() => {
      this.commentInput.nativeElement.focus();
    }, 0);
  }

  sendComment() {
    this.sendCallback.emit({
      text: this.commentText,
      parent_id: null
    });
    this.commentText = '';
    setTimeout(() => {
      this.newCommentAdding = false;
    }, 0);
  }

  markCommentsAsSolved(event, comment) {
    this.markAsSolved = event;
    this.markAsSolvedCallback.emit({
      event,
      comment
    });
  }

  deleteComment(comment) {
    this.deleteCommentCallback.emit(comment);
  }

  editModeComment(comment) {
    _forEach(($comment: any) => {
      $comment.editMode = false;

      _forEach(($$comment: any) => {
        $$comment.editMode = false;
      })($comment.childComments);
    })(this.mainComments);

    comment.editMode = true;
    this.commentEditText = comment.Comment;
  }

  cancelEditingComment(comment) {
    comment.editMode = false;
    this.commentEditText = '';
  }

  updateComment(comment) {
    comment.Comment = this.commentEditText;
    comment.editMode = false;
    this.commentEditText = '';
    this.updateCommentCallback.emit(comment);
  }

  addAnswer(event, comment) {
    event.stopPropagation();
    comment.newAnswerAdding = true;
    this.newCommentAdding = false;

    setTimeout(() => {
      this.commentInput.nativeElement.focus();
    }, 0);
  }

  sendAnswer(comment) {
    this.sendCallback.emit({
      text: this.commentText,
      parent_id: comment.id
    });
    this.commentText = '';
    setTimeout(() => {
      comment.newAnswerAdding = false;
    }, 0);
  }

  handleMenuClick() {
    this.newCommentAdding = false;

    _forEach(($comment: any) => {
      $comment.newAnswerAdding = false;
    })(this.mainComments);
  }

  filterCommentsByYear($event) {
    this.filteredMainComments = $event
      ? this.mainComments.filter((comment: any) => comment.learn_period_id === this.currentYear)
      : this.mainComments;

    if (!this.withArchiveComment) {
      this.filteredMainComments = this.filteredMainComments.filter((comment: any) => !comment.is_arhive);
    }
  }

  filterArchiveComments(withArchiveComment: boolean): void {
    this.withArchiveComment = withArchiveComment;

    const collectionFieldName = this.showAllModal ? 'filteredAllCommentsByYear' : 'filteredMainComments';
    const originalCollectionFieldName = this.showAllModal ? 'filteredAllComments' : 'mainComments';
    const filterByYearModel = this.showAllModal ? 'filterAllByYear' : 'filterByYear';

    this[collectionFieldName] = !withArchiveComment
      ? this[originalCollectionFieldName].filter((comment: any) => !comment.is_arhive)
      : [...this[originalCollectionFieldName]];

    if (this[filterByYearModel]) {
      this[collectionFieldName] = this[collectionFieldName].filter((comment: any) => comment.learn_period_id === this.currentYear);
    }
  }

  getIndicatorClass(comment) {
    if (comment.is_important && !comment.solved) {
      return 'red';
    }

    if (!comment.is_important && !comment.solved) {
      return 'blue';
    }

    if (comment.solved) {
      return 'green';
    }
  }

  importantChange(event, comment) {
    this.importantCallback.emit({
      event,
      comment
    });
  }

  getMainIndicatorClass() {
    if (_some(($comment: any) => $comment.is_important && !$comment.solved)(this.mainComments)) {
      return 'red';
    }

    if (_some(($comment: any) => !$comment.solved)(this.mainComments)) {
      return 'blue';
    }

    if (this.mainComments && this.mainComments.length) {
      return 'green';
    }

    return '';
  }

  showAll() {
    this.showAllModal = true;
    this.selectedTab = 0;
    this.allModalTabs[0].selected = true;

    this.loadHistoryAndComments();
  }

  closeShawAll() {
    this.showAllModal = false;
  }

  selectTab(tab) {
    _forEach(($tab: any) => {
      $tab.selected = $tab.id === tab.id;
    })(this.allModalTabs);

    this.selectedTab = tab.id;
  }

  async loadHistoryAndComments() {
    try {
      [
        this.allComments,
        this.acceptHistory,
        this.learnPeriods,
        this.login,
        this.isAdmin,
        this.isUROP,
        this.isManagerOP,
        this.isManagerUP,
        this.isAcademicDeputy,
        this.isFacultyDecan,
        this.isProrecror,
        this.isFilialDeputy,
        this.isUAD,
        this.isMezhvuzManager,
        this.isAcademicDirectorASH,
        this.isManagerASH,
        this.isAcademicMaster
      ] = await Promise.all([
        this.catalogue.getComment3({ learn_plan_id: this.studyPlanId, is_arhive: true }),
        this.catalogue.getStatusHistory({ id: this.studyPlanId }),
        this.catalogue.getLearnPeriods(),
        this.userProfile.getLogin(),
        this.userProfile.isAdmin(),
        this.userProfile.isUROP(),
        this.userProfile.isManagerOP(),
        this.userProfile.isManagerUP(),
        this.userProfile.isAcademicDeputy(),
        this.userProfile.isFacultyDecan(),
        this.userProfile.isProrecror(),
        this.userProfile.isFilialDeputy(),
        this.userProfile.isUAD(),
        this.userProfile.isMezhvuzManager(),
        this.userProfile.isAcademicDirectorASH(),
        this.userProfile.isManagerASH(),
        this.userProfile.isAcademicMaster()
      ]);

      this.filteredAcceptHistory = this.acceptHistory;

      const studyPlan = await this.catalogue.getStudyPlans({ id: this.studyPlanId, detail_info: true });
      this.studyPlan = studyPlan.body[0];

      this.filteredAllCommentsByYear = this.filteredAllComments = _filter(($comment: any) => {
        $comment.childComments = _filter(($$comment: any) => {
          return $$comment.parent_id.toString() === $comment.id.toString();
        })(this.allComments);
        $comment.ableToEdit = $comment.user === this.login && this.ableApprove();
        $comment.ableToMark = $comment.ableToEdit && ($comment.parent_id === '0' || !$comment.parent_id) || this.isUROP || this.isAdmin;

        $comment.expanded = false;

        return !$comment.parent_id || $comment.parent_id === '0';
      })(this.allComments);

      this.filterArchiveComments(false);
    } catch (e) {
      console.error(e);
    }
  }

  getApproveDate(period) {
    return moment(period, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY HH:mm');
  }

  getLearnPeriod(learnPeriodId) {
    const lp = _find<any>(($period: any) => $period.id === learnPeriodId)(this.learnPeriods);

    return lp ? lp.description : '-';
  }

  getAnswerCountLabel(item) {
    switch (item.childComments.length) {
      case 1:
        return 'ответ';
      case 2:
      case 3:
      case 4:
        return 'ответа';
      default:
        return 'ответов';
    }
  }

  toggleFilterAllByYear($event) {
    this.filteredAllCommentsByYear = $event
      ? this.filteredAllComments.filter((comment: any) => comment.learn_period_id === this.studyPlan.current_approval_year_id)
      : this.filteredAllComments;
    this.filteredAcceptHistory = $event
      ? _filter<any>({ learn_period_id: this.studyPlan.current_approval_year_id })(this.acceptHistory)
      : this.acceptHistory;

    if (!this.withArchiveComment) {
      this.filteredAllCommentsByYear = this.filteredAllCommentsByYear.filter((comment: any) => !comment.is_arhive);
    }
  }

  ableApprove() {
    if (this.studyPlan.education_level_id === '6') {
      const aspLastStep = this.studyPlan.plan_status_id === '27';
      const aspNoRole = !(this.isManagerOP || this.isProrecror || this.isAcademicDirectorASH || this.isUAD);

      if (this.isManagerOP && (this.studyPlan.plan_status_id === '15' || this.studyPlan.plan_status_id === '16')) {
        return true;
      } else if (this.isAcademicDirectorASH && this.studyPlan.plan_status_id === '99') {
        return true;
      } else if (this.isManagerASH && (this.studyPlan.plan_status_id === '15' || this.studyPlan.plan_status_id === '16')) {
        return true;
      } else if (this.isUAD && this.studyPlan.plan_status_id === '29') {
        return true;
      } else if (this.isFilialDeputy && this.studyPlan.filial_id !== '4' && this.studyPlan.plan_status_id === '31') {
        return true;
      } else if (this.isProrecror && this.studyPlan.filial_id !== '4' && this.studyPlan.plan_status_id === '8') {
        return true;
      } else if (this.isProrecror && this.studyPlan.filial_id === '4' && this.studyPlan.plan_status_id === '31') {
        return true;
      } else if (aspNoRole) {
        return false;
      } else if (aspLastStep) {
        return false;
      }

      return false;
    }
  }
}
