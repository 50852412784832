import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'hse-page-header',
  template: `
    <div class="hse-page-header__title-wrapper">
      <h1 class="hse-page-header__title">{{ pageTitle }}</h1>
    </div>
    <div class="hse-page-header__actions-wrapper">
      <ng-content></ng-content>
    </div>
  `,
})
export class PageHeaderComponent {
  @HostBinding('class.hse-page-header') private readonly baseCss: boolean = true;
  @Input() pageTitle: string = '';
}
