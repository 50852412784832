import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HseLoaderComponent } from './components/hse-loader.component';
import { HseLoaderService } from './services/hse-loader.service';
import { LoaderInterceptorService } from './services/loader-interceptor.service';

@NgModule({
  declarations: [
    HseLoaderComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
    HseLoaderService,
    LoaderInterceptorService
  ],
  exports: [
    HseLoaderComponent
  ]
})
export class HseLoaderModule { }
