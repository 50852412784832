import { Component, OnInit } from '@angular/core';
import { breadcrumbsCollection } from './hse-breadcrumbs.const';
import { NavigationEnd, Router } from '@angular/router';
import { find as _find } from 'lodash/fp';
import { UnfinishedChangesIndicatorService } from '@core/unfinished-changes-indicator/unfinished-changes-indicator.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'hse-breadcrumbs',
  templateUrl: './hse-breadcrumbs.component.html',
  styleUrls: ['./hse-breadcrumbs.component.scss']
})
export class HseBreadcrumbsComponent implements OnInit {

  private url;
  public breadcrumb;

  constructor(private router: Router, private changesIndicator: UnfinishedChangesIndicatorService) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        this.url = event.urlAfterRedirects;
        this.getBreadcrumb();
      });
  }

  ngOnInit() {
  }

  getBreadcrumb() {
    const breadcrumb = _find((item: any) => {
      return this.url.indexOf(item.url) !== -1;
    })(breadcrumbsCollection);

    this.breadcrumb = breadcrumb || null;
  }

  getIdFromURL(url: string) {
    const id = url.split('/').pop();
    if (id.match(/\d/g)) {
      return id;
    }

    return null;
  }

  getUrl(item) {
    let url = item.url;
    const hasId = url.indexOf(':id') !== -1;

    if (hasId) {
      url = url.replace(':id', this.getIdFromURL(this.url));
    }

    return url;
  }

  goTo(item, event) {
    event.preventDefault();

    if (this.changesIndicator.unfinishedChangesEditByYear) {
      this.changesIndicator.actionToDo = {
        type: 'navigate',
        url: this.getUrl(item)
      };

      this.changesIndicator.fire$.next();

      return;
    }

    this.router.navigateByUrl(this.getUrl(item));
  }

}
