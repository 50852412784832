<div *ngIf="visible$ | async"
     class="loader-overlay">
  <div class="lds-ellipsis">
    <div class="loader-dot"></div>
    <div class="loader-dot"></div>
    <div class="loader-dot"></div>
    <div class="loader-dot"></div>
  </div>
</div>

<div *ngIf="background$ | async" class="background-loader">
  <div class="lds-ellipsis">
    <div class="loader-dot"></div>
    <div class="loader-dot"></div>
    <div class="loader-dot"></div>
    <div class="loader-dot"></div>
  </div>
</div>
