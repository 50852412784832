import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import jwtDecode from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';

import { environment } from '@env/environment';
import { UserSettingsService } from '@backend/user-settings/user-settings.service';
import { UserProfileService } from '@backend/user-profile/user-profile.service';

export interface Session {
  commonname: string;
  given_name: string;
  exp: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  AUTHORIZE_URL = 'https://auth.hse.ru/adfs/oauth2/authorize';
  LOGOUT_URL = 'https://auth.hse.ru/adfs/oauth2/logout';
  CLIENT_ID = 'e7cc3f5b-6528-405f-ab1b-4c6c5e7b2a25';
  BASE_URL = '/api/auth';

  session: Session = null;
  nonceId = null;
  stateId = null;
  userLogin;
  userNotFound = false;

  constructor(private http: HttpClient,
              private userSettings: UserSettingsService,
              private userProfile: UserProfileService) {
  }

  login(credentials) {
    return this.http.post(`${this.BASE_URL}/login`, credentials).subscribe((res) => {
      console.log(res);
    });
  }

  authorize() {
    this.nonceId = uuidv4();
    this.stateId = uuidv4();
    // window.open(`${this.AUTHORIZE_URL}?response_type=code&client_id=${this.CLIENT_ID}&redirect_uri=http://${window.location.host}/callback`);
    const params = {
      response_type: 'id_token token',
      scope: 'openid profile',
      state: this.stateId,
      nonce: this.nonceId,
      client_id: this.CLIENT_ID,
      redirect_uri: environment.redirectURI
    };

    window.open(`${this.AUTHORIZE_URL}${this.makeParams(params)}`);
  }

  async authByToken(accessToken: string) {
    try {
      const response: any = await this.http.post(`${this.BASE_URL}/token`, {token: accessToken}).toPromise();
      console.log(response);
      if (response.token) {
        localStorage.setItem('HSE-Auth-Token', String(response.token));
        console.log(this.session);
      } else {
        this.userNotFound = true;
      }

      this.initSession();

      return this.session;
    } catch (e) {
      console.error(e);

      this.userNotFound = true;
    }
  }

  initSession() {
    const token = localStorage.getItem('HSE-Auth-Token');
    if (token) {
      this.session = jwtDecode(token);
      console.log(this.session);
      this.userSettings.getUserSettings();
      this.userProfile.getUserInfo();
    }
  }

  getToken() {
    return localStorage.getItem('HSE-Auth-Token');
  }

  isAuth() {
    const authToken = localStorage.getItem('HSE-Auth-Token');
    // const authToken = true;

    return Boolean(authToken);

    // if (authToken) {
    //     try {
    //         await this.http.get(`${this.BASE_URL}/session`).toPromise();
    //
    //         return true;
    //     } catch (err) {
    //         console.log(err);
    //         this.cookies.delete('HSE-Auth-Token');
    //     }
    // }
    //
    // return false;
  }

  dropSession() {
    localStorage.removeItem('HSE-Auth-Token');
    localStorage.removeItem('selectedRole');
    this.session = null;
  }

  logout() {
    this.dropSession();
    // TODO при размещении на домене hse.ru можно будет грохать куки чтобы не заходить на LOGOUT_URL (нет)
    window.open(this.LOGOUT_URL, '_blank');
    window.location.reload();
  }

  private makeParams(params) {
    let result = '';
    if (params) {
      const data = [];
      for (const param in params) {
        if (params.hasOwnProperty(param)) {
          data.push(`${param}=${params[param]}`);
        }
      }
      result = `?${data.join('&')}`;
    }

    return result;
  }
}
