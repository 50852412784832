import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forEach as _forEach, isString as _isString } from 'lodash/fp';

export interface HSEArray<T> extends Array<T>{
  translateField?(field: string): HSEArray<T>;
}

export interface HSEObject {
  // @ts-ignore
  translateFields?(): HSEObject;
  [key: string]: string;
}

@Injectable({
  providedIn: 'root'
})

export class TranslateCustomService {
  constructor(private translate: TranslateService) {

  }

  initExtensions() {
    this.extendArray();
    this.extendObject();
  }

  /**
   * Локализует значения полей внутри массива объектов по ключу
   * */
  fillTranslateFields<T>(array: T[], key: string): T[] {
    _forEach((item) => {
      if (item[key]) {
        item[key] = this.translate.instant(item[key]);
      }
    })(array);

    return array;
  }

  fillTranslateObject(object: Object) {
    for (const objectKey in object) {
      const value = object[objectKey];
      if (_isString(value)) {
        object[objectKey] = this.translate.instant(value);
      }
    }

    return object;
  }

  private extendArray() {
    const self = this;
    // @ts-ignore
    Array.prototype.translateField = function(field) {
      self.fillTranslateFields(this, field);

      return this;
    }
  }

  private extendObject() {
    const self = this;
    // @ts-ignore
    Object.prototype.translateFields = function(a) {
      self.fillTranslateObject(this);

      return this;
    }
  }
}
