import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'hse-page-content',
  template: `
    <ng-content></ng-content>  
  `
})
export class PageContentComponent {
  @HostBinding('class.hse-page-content') private readonly baseCss: boolean = true;
}
