import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrintFormsUnloadService {

  BASE_URL = '/api/common';

  mockDataPlan = [
    {
      type: 'pdf',
      caption: 'Печатная форма pdf'
    },
    {
      type: 'xlsx',
      caption: 'Печатная форма xlsx'
    },
  ];

  mockDataPassport = [
    {
      type: 'pdf',
      caption: 'Печатная форма pdf'
    },
    {
      type: 'docx',
      caption: 'Печатная форма docx'
    },
  ];

  cache: any = [];

  constructor(private http: HttpClient) {
  }

  public getAllFileType(type = 'plan') {
    try {
      return type === 'plan' ? of(this.mockDataPlan) : of(this.mockDataPassport);
    } catch (e) {
      console.error(e);
    }
  }

  public async getFormTypes() {
    try {
      if (!this.cache['getprintform']) {
        this.cache['getprintform'] = await this.http.get('/api/getprintform').toPromise();
      }

      return this.cache['getprintform'];
    } catch (e) {
      console.error(e);
    }

    return this.http.get('/api/getprintform').toPromise();
  }

  public getFileLearnPlan(type, format, date: string, plan: any, locale = 'rus', groupPrint = false, short = false): Promise<any> {
    return this.http.get(`${this.BASE_URL}/getprintformbase64?form_type=${type}&file_format=${format}&learn_plan_id=${plan}&approvement_date=${date}&language=${locale}${groupPrint ? `&group_print=${groupPrint}` : ''}${short ? `&short=${short}` : ''}`).toPromise();
  }

  public getFileLearnPlanByYear(type, format, date: string, plan: any, year, locale = 'rus', groupPrint = false): Promise<any> {
    return this.http.get(`${this.BASE_URL}/getprintformbase64?form_type=${type}&file_format=${format}&learn_plan_id=${plan}&approvement_date=${date}&learn_year_id=${year}&language=${locale}${groupPrint ? `&group_print=${groupPrint}` : ''}`).toPromise();
  }

  public getFilePassport(format, plan: number): Promise<any> {
    return this.http.get(`${this.BASE_URL}/getprintformbase64?form_type=trainingdirectionpassport&file_format=${format}&standart_id=${plan}`).toPromise();
  }
}
